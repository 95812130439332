import styled, { StyledComponent } from 'styled-components'

interface FormWrapperProps {
  orientation?: string
}

export const FormWrapper: StyledComponent<'div', any, FormWrapperProps> = styled.div<
  FormWrapperProps
>`
  display: grid;
  gap: 20px;
  margin-bottom: 24px;

  ${({orientation}) => orientation == 'horizontal' ? 'grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));' : ''}
  max-width: ${({orientation}) => orientation == 'horizontal' ? '100%' : '500px'};
  align-items: flex-end;
  
  @media (max-width: 1200px) {
    gap: 10px;
  }
`