import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { JSONSchema7, JSONSchema7Definition } from 'json-schema'
import { createSelector } from 'reselect'
import WhiteSpace from 'antd-mobile/lib/white-space'
import WingBlank from 'antd-mobile/lib/wing-blank'
import Toast from 'antd-mobile/lib/toast'
import { debounce } from 'lodash'

import fetchAPI from '../../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import {
  clearCaseStore,
  initialCase, reloadActionsTimeLine,
  setAppealStage,
  setTargetId,
} from '../../../store/appeals/actions'
import { cardName, collectionName } from './consts'
import { B2BMobile } from '../../../layouts/b2b-mobile'
import {
  ErrorsList,
  HeaderBlock,
  ClientCard,
  ManagerCard,
  TDocumentItem,
  DocumentCard,
  ValidationsMenu,
  NoteCard,
  TeamOrderAcceptanceModal,
  HeaderLeftContent,
  TDataListItem,
} from '../components'
import { BackToMountingList } from './styles'
import { ActionsList } from './actions-list'
import { setStageFromCard } from '../../../../lib/utils/cards'
import { MountingCard } from './mounting-card'
import { parseDate } from '../../../utilits'
import { getDocumentList } from '../utils'
import modalOrderCancellation from '../../../views/modal-order-cancellation'
import ActivityTimeLine from  '../../../connectors/tab-stuck-files-and-note'

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  stageCode => stageCode,
  workflows => workflows,
  targetId => targetId,
)
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

export const MountingEditPage = () => {
  const { id } = useParams() as any
  const dispatch = useDispatch()
  const history = useHistory()
  const parentIdRef = useRef<any>(null)
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [mountingData, setMountingData] = useState<Record<string, any>>({})
  const { workflows, stageCode, targetId } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)
  const [forecast, setForecast] = useState<Record<string, any>>({})
  const [managerData, setManagerData] = useState<Record<string, any>>({})
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<JSONSchema7 | null>(null)
  const [showSubMenuModal, setShowSubMenuModal] = useState(false)
  const [validations, setValidations] = useState<JSONSchema7Definition[]>([])
  const [isAdditionalValidations, setIsAdditionalValidations] = useState(false)
  const [nextStage, setNextStage] = useState('')
  const [documents, setDocuments] = useState<TDocumentItem[]>([])
  const [author, setAuthor] = useState(0)
  const [hideHeaderPopover, setHideHeaderPopover] = useState<boolean | undefined>(undefined)
  const [teamOrderAcceptanceShow, setTeamOrderAcceptanceShow] = useState<undefined | boolean>()
  const [reRenderNotes, setReRenderNotes] = useState(false)

  const getValidatorsData = useCallback((validator: string): Record<string, any> | null => {
    let result: Record<string, any> | null = null
    if (matchedJSONSchema && matchedJSONSchema['properties'] && matchedJSONSchema['properties'][validator]) {
      result = matchedJSONSchema['properties'][validator] as Record<string, any>
    }
    return result ? { ...result, code: validator } : null
  }, [matchedJSONSchema])

  const prepareForecast = useCallback(forecastList => {
    const mountingForecast = forecastList.find(f => f['code'] === cardName)
    const planDate = mountingData && mountingData['date-plan-assembly-team-arrived'] ?
      `${parseDate(mountingData['date-plan-assembly-team-arrived'], 'dd.MM.yyyy')} ${mountingData['time-plan-assembly-team-arrived']}`
      : null
    const adjustedDate = mountingData && mountingData['date-adjusted-mounting'] ?
      `${parseDate(mountingData['date-adjusted-mounting'], 'dd.MM.yyyy')} ${mountingData['time-adjusted-mounting']}`
      : null
    return {
      ...mountingForecast,
      'date-plan': planDate,
      'date-adjusted': adjustedDate,
    }
  }, [mountingData])

  const updateForecast = useCallback(async () => {
    const forecastResponse = await fetchAPI(`/api/order-forecast/${parentIdRef.current}`)
    const managerData = forecastResponse?.[0]?.responsibles?.[0] 
    managerData && setManagerData(managerData)
    if (forecastResponse && forecastResponse['data'] && Array.isArray(forecastResponse['data'])) {
      const mountingForecast = prepareForecast(forecastResponse['data'])
      if (mountingForecast) {
        setForecast(mountingForecast)
      }
    }
  }, [prepareForecast])

  const authorId = useMemo(() => getDataOfType(sessionData, 'user.id', Number, 0)  , [sessionData])
  const currentStages = useMemo(() => getDataOfType(workflows, cardName, Array, []), [workflows])

  const getData = useCallback(async () => {
    const fields = [
      'title',
      'description',
      'type',
      'properties',
      'required',
      'created_by',
      'created_at',
      'updated_at',
    ].join(',')
    const [schemasResponse, collectionsObjectsResponse] = await Promise.all([
      fetchAPI(`/api/schemas?access_key=axioma&fields=${fields}`),
      fetchAPI(`/api/collections/objects/${id}`),
    ])
    const fetchedSchemas: JSONSchema7[] = getDataOfType(schemasResponse, 'data.data', Array, [])
    const selectedSchemas = fetchedSchemas.filter(item => item['name'] === collectionName)
    const selectedSchema = (typeof selectedSchemas[0] === 'object') ? selectedSchemas[0] : null

    if (selectedSchema === null) {
      setPageErrors(['Некорректный ответ сервера при схемы коллекции'])
      return
    }

    const mountingDataObject = getDataOfType(collectionsObjectsResponse, 'data.data.attributes', Object, {})
    const parentId = Number(getDataOfType(collectionsObjectsResponse, 'data.parent-id', String, ''))
    setMatchedJSONSchema(selectedSchema)
    setAuthor(authorId)
    dispatch(setTargetId({ targetId: parentId }))
    dispatch(reloadActionsTimeLine())
    parentIdRef.current = parentId
    setPageErrors([])
    setMountingData(mountingDataObject)
    dispatch(setAppealStage({ stageCode: getDataOfType(collectionsObjectsResponse, 'data.stage-code', String, ''), type: cardName }))
    setIsDataLoading(false)
  }, [authorId, dispatch, id])

  useEffect(() => {
    getData()
    const timer = setInterval(() => getData(), 3000)
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (mountingData) {
      updateForecast()
    }
  }, [mountingData, updateForecast])

  useEffect(() => {
    const updateDocList = async () => {
      const docList = await getDocumentList(matchedJSONSchema, mountingData)
      if (docList.length > 0) {
        setDocuments(docList)
      }
    }
    if (matchedJSONSchema && matchedJSONSchema['properties']) {
      updateDocList()
    }
  }, [matchedJSONSchema, mountingData])

  useEffect(() => {
    if(sessionData === null || workflows === null){
      return
    }
    getData()
  }, [getData, sessionData, workflows])

  useEffect(() => {
    dispatch(initialCase({ targetId: null, cardName: cardName }))
    return () => {
      dispatch(clearCaseStore())
    }
  }, [id, dispatch])

  useEffect(() => {
    if (isDataLoading) {
      Toast.loading('Загрузка...', 1)
      return
    } 
    Toast.hide()
  }, [isDataLoading])

  const handleOpenMountingList = useCallback(() => {
    history.push('/sales/mounting')
  }, [history])

  const stagesName = useMemo(() => {
    const stage = currentStages.find(item => item?.['code'] === stageCode)
    return stage?.['name'] ?? ''
  }, [currentStages, stageCode])

  const haveManagerData = useMemo(() => {
    return Object.keys(managerData).length > 0 && managerData['profile-data']
  }, [managerData])

  const haveDocument = useMemo(() => documents.length > 0, [documents])

  const handleCall = useCallback((value: string) => () => {
      Object.assign(window.location, { href: `tel:${value}` })
    },
  [])
// eslint-disable-next-line
  const saveCase = useCallback(debounce(async formData => {
    const requestBody = {
      data: {
        name: '',
        title: '',
        attributes: formData,
        metadata: { schema: collectionName }
      }
    }
    const result = await fetchAPI(`/api/collections/-/objects/${id}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
    const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)
    if (updated === null) {
      console.error('Ошибка сохранения записи')
    }
    return result
  }, 1000), [])

  const setData = useCallback(newFromData => {
    let result = { ...mountingData, ...newFromData }
    setMountingData(prevState => {
      result = {
        ...prevState,
        ...newFromData,
      }

      return result
    })

    return result
  }, [mountingData])

  const updateData = useCallback(async nextFormData => {
    const formData = setData(nextFormData)
    const response = await saveCase(formData)
    dispatch(reloadActionsTimeLine())
    return response
  }, [dispatch, saveCase, setData])

  const handleSubMenuClose = useCallback(() => {
    setShowSubMenuModal(false)
  }, [])

  const handleTeamOrderAcceptanceClose = useCallback(() => {
    setTeamOrderAcceptanceShow(false)
  }, [])

  const isDisabledSwitchStage = useMemo(() => {
    let result = validations.length > 0

    if (result && mountingData) {
      /** result = false if all validations check */
      result = validations.some(valid => !mountingData[valid['code']])
    }

    return result
  }, [mountingData, validations])

  const handleCloseHeaderPopover = useCallback(() => {
    setHideHeaderPopover(true)
  }, [])

  const actionsList = useMemo(() => {
    const result: TDataListItem[] = []

    const handleTransition = async (parentId, _, stageCodeNext) => {
      const nextDataCard = await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      const nextFormData = getDataOfType(nextDataCard, 'data.formData', Object, null)
      const resultStageCode = getDataOfType(nextDataCard, 'data.nextStageCode', String, 'new')
      dispatch(reloadActionsTimeLine())
      dispatch(setAppealStage({ stageCode: resultStageCode, type: cardName }))
      setData(nextFormData)
    }

    const handleTeamAcceptance = () => {
      setTeamOrderAcceptanceShow(true)
    }

    const handleOpenSubMenu = (validationsList, nextStage, _, isAdditional = false) => {
      setValidations(validationsList)
      setIsAdditionalValidations(isAdditional)
      setShowSubMenuModal(true)
      setNextStage(nextStage)
    }

    const onCancel = async () => {
      const result = await modalOrderCancellation()
      const stageCodeNext = 'new'
      await setStageFromCard({
        cardName: cardName, stageCode: stageCodeNext, targetId , isHistory: true,
        addFormData: {
          'reason-cancellation': result?.['reason-cancellation'],
          comment: result?.['comment'],
        }
      })
      dispatch(reloadActionsTimeLine())
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      setData({ stage: 0 })
    }

    result.push(
      <ActionsList
        id={id}
        stageList={currentStages}
        formData={mountingData}
        parentId={parentIdRef.current}
        matchedJSONSchema={matchedJSONSchema}
        onStageToWork={handleTransition}
        onMountingLeft={handleTransition}
        onMountingArrived={handleTransition}
        onContractSigned={() => null}
        onDone={handleTransition}
        onTransition={handleTransition}
        onTeamAcceptance={handleTeamAcceptance}
        sessionData={sessionData}
        getValidatorsData={getValidatorsData}
        openSubMenu={handleOpenSubMenu}
        onClose={handleCloseHeaderPopover}
        onCancel={onCancel}
      />
    )
    return result
  }, [dispatch, getValidatorsData, handleCloseHeaderPopover, id,  matchedJSONSchema, mountingData, sessionData, setData, currentStages])

  const handleModalStageChange = useCallback(async () => {
    const nextDataCard = await setStageFromCard({
      cardName: cardName,
      stageCode: nextStage || 'team-order-acceptance',
      targetId: parentIdRef.current,
      isHistory: true,
    })
    const nextFormData = getDataOfType(nextDataCard, 'data.formData', Object, null) 
    const resultStageCode = getDataOfType(nextDataCard, 'data.nextStageCode', String, 'new')
    setNextStage(resultStageCode)
    dispatch(setAppealStage({ stageCode: resultStageCode, type: cardName }))
    setMountingData(nextFormData)
    setShowSubMenuModal(false)
  }, [dispatch, nextStage])

  const currentManagerData = useMemo(() => {
    return managerData['profile-data'] ? managerData['profile-data'] : {
      name: '',
      phone: '',
      email: '',
    }
  }, [managerData])

  const isShowHeaderPopover = useMemo(() => {
    return showSubMenuModal || (hideHeaderPopover === true) ? false : undefined
  }, [hideHeaderPopover, showSubMenuModal])

  const handlerDropHeaderPopoverShowState = useCallback(() => {
    setHideHeaderPopover(undefined)
  }, [])

  const onFallBackStage = useCallback(async dateData => {
    const timeObject = dateData['time'] || {}
    const time = timeObject ? `${timeObject['start']}-${timeObject['end']}` : ''
    const stageFormCardResponse = await setStageFromCard({
      cardName: cardName,
      stageCode: nextStage,
      targetId: parentIdRef.current,
      isHistory: true,
      'add-form-data': {
        'date-adjusted-mounting': dateData['date'],
        'time-adjusted-mounting': time
      }
    })
    const newFormData = getDataOfType(stageFormCardResponse, 'data.formData', Object, null)
    setMountingData(newFormData)
    setShowSubMenuModal(false)
    setReRenderNotes(true)
  }, [nextStage])

  const handleDropReRender = useCallback(() => {
    setReRenderNotes(false)
  }, [])

  return (
    <B2BMobile>
      <HeaderBlock
        leftContent={
          <HeaderLeftContent id={parentIdRef.current} name={stagesName} />
        }
        popoverOverlay={actionsList}
        show={isShowHeaderPopover}
        onAfterClose={handlerDropHeaderPopoverShowState}
      />
      <WhiteSpace size="lg" />
      <ErrorsList pageErrors={pageErrors} />
      <WingBlank size="lg">
        <BackToMountingList onClick={handleOpenMountingList}>{'<'} К списку заказов</BackToMountingList>
        <WhiteSpace size="lg" />
        <ClientCard data={mountingData} onCall={handleCall} />
        <MountingCard data={forecast} mountingData={mountingData} />
        <NoteCard
          targetId={parentIdRef.current}
          reRenderNotes={reRenderNotes}
          onDropReRender={handleDropReRender}
        />
        {haveManagerData && <ManagerCard data={currentManagerData} onCall={handleCall} />}
        {haveDocument && (
          <DocumentCard data={documents} />
        )}
      </WingBlank>
      <ValidationsMenu
        cardName={cardName}
        targetId={parentIdRef.current}
        author={author}
        validations={validations}
        show={showSubMenuModal}
        isCantSwitchStage={isDisabledSwitchStage}
        onClose={handleSubMenuClose}
        onSwitchStage={handleModalStageChange}
        data={mountingData}
        updateData={updateData}
        isAdditional={isAdditionalValidations}
        adjustedDateKey="date-adjusted-mounting"
        adjustedTimeKey="time-adjusted-mounting"
      />
      <TeamOrderAcceptanceModal
        onSwitchStage={handleModalStageChange}
        initShow={teamOrderAcceptanceShow}
        onClose={handleTeamOrderAcceptanceClose}
        author={author}
        targetId={parentIdRef.current}
        onFallBackStage={onFallBackStage}
      />
      <ActivityTimeLine />
    </B2BMobile>
  )
}
