import React, {useState, useRef, useEffect, useCallback} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {Form, Alert, Skeleton, Button} from 'antd'
import {SaveOutlined} from '@ant-design/icons'
import {MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper} from './styles'
import B2BLayout from '../../layouts/b2b'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'
import {DefaultJsonForm} from '../../components/default-json-form'
import AppChat from '../../components/app-chat'
import {
  createUISchemaVertical,
} from '../../../lib/utils/grid-fild-orientation'
import claimFieldsSchema from './json-schema.json'
import fetchAPI from '../../../lib/utils/fetch-api'
import {createSelector} from 'reselect'
import {useSelector} from 'react-redux'

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
}

const breadcrumbs =
  [{
    title: 'Отчеты Satels',
    href: '/',
  }, {
    title: 'Список заявок на рекламацию',
    href: '/claim-requests',
  }]

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

export default function AdminNewObjectPage() {
  const {id} = useParams() as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [files, setFiles] = useState<string[]>([])
  const [chatData, setChatData] = useState<any>([])
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [formChat] = Form.useForm()
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const { sessionData } = useSelector(createSession)

  const saveCase = useCallback(
    async () => {
      const formData = {...formDataRef.current}
      const claimId = id !== 'new' && id || ''

      await fetchAPI(`/api/claims_request/${claimId}`, {method: 'POST', body: JSON.stringify(formData)})

      history.push('/claim-requests')
    },
    [history, id]
  )

  const getData = useCallback(async () => {
    {
      const result = id !== 'new' && await fetchAPI(`/api/claims_request/${id}`) || []

      const data = getDataOfType(result, 'data', Array, [])
      const claimData = getDataOfType(data, '[0]', Object, {})

      {
        await fetchAPI('/api/claims_request/enum')
          .then(result => {
            const enumItems = result?.['data'] || {}

            Object.keys(enumItems).forEach(code => {
              if (claimFieldsSchema?.properties.hasOwnProperty(code)) {
                const enumValues = result?.['data']?.[code] || {}
                const oneOf: Record<string, string>[] = []

                Object.keys(enumValues).forEach(enumValue => {
                  return oneOf.push({
                    'const': enumValue,
                    'title': enumValues[enumValue]
                  })
                })

                Object.assign(claimFieldsSchema.properties[code], {
                  oneOf: oneOf,
                  'enum': Object.keys(enumValues),
                  view: {'name': 'select-from-enum'}
                })

                if (claimData.hasOwnProperty(code)) {
                  claimData[code] = claimData[code]?.toString() || null
                }
              }
            })
          })
      }

      if (claimData.files) {
        setFiles(JSON.parse(claimData.files));
      }

      formDataRef.current = {...claimData}

      setMatchedJSONSchema(claimFieldsSchema)
      const UISchema = createUISchemaVertical(claimFieldsSchema)

      setUISchema(UISchema)

      setUISchema(UISchema => {
        const UISchemaNext = {...UISchema}
        const properties = Object.entries(UISchemaNext)
          .map(([key, value]: [string, any], index) => {
            const options = {}
            if (key !== 'status') {
              options['disabled'] = true;
            }
            return [key, {...value, options: options}]
          })
        return Object.fromEntries(properties)
      })
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  const getChatData = useCallback(async () => {
    const resultChat = await fetchAPI(`/api/claims_request_chat/?filter[claims_request_id]=${id}`)
    setChatData( resultChat?.data?.rows || [])
  }, [id])

  useEffect(() => {
    getData()
    getChatData()
  }, [getData, getChatData])

  const onFormData = useCallback(
    ({formData}) => {
      formDataRef.current = formData
    }, []
  )

  const onChatSend = async (values: any) => {
    const userId = getDataOfType(
      sessionData,
      'user.id',
      Number,
      null,
    );

    await fetchAPI(`/api/claims_request_chat/`, {
      method: 'POST',
      body: JSON.stringify({
        claims_request_id: id,
        manager_id: userId,
        msg: values.message
      })
    })

    getChatData();
    formChat.resetFields();
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>

      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            Заявка на рекламацию <span>{`№ ${id}`}</span>
          </MapTitle>
        </TaskWrapper>
        <MapContent>
          <Form {...formItemLayout} layout="vertical" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {!isDataLoading ? (
              UISchema && (
                <>
                  <DefaultJsonForm
                    formData={formDataRef.current}
                    schema={matchedJSONSchema}
                    onChange={onFormData}
                    UISchema={UISchema}
                  />
                  <Form.Item label="Файлы">
                    {files.map((item) => {
                      return (
                        <p>
                          <a href={item} download>
                            {item}
                          </a>
                        </p>
                      )
                    })}
                  </Form.Item>
                </>
              )
            ) : (
              <Skeleton active/>
            )}
          </Form>
          <Button onClick={saveCase} style={{marginBottom: 16, marginTop: 16}} type="primary">
            <SaveOutlined rev={undefined}/>
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            Обсуждения по заявке
          </MapTitle>
        </TaskWrapper>
        <AppChat
          data={chatData}
          form={formChat}
          onSubmit={onChatSend}
        />
      </MapWrapper>
    </B2BLayout>
  )
}
