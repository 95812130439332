import React, { useCallback, useEffect, useState } from 'react'
import { Badge, Layout } from 'antd'
import { CalculatorOutlined, CalendarOutlined, MessageOutlined } from '@ant-design/icons'
import { NavigationButton, NavigationButtonIcon, NavigationButtons, NavigationButtonTitle } from './styles'
import { useHistory } from 'react-router-dom'
import { getLoadViewedTasks } from '../../store/viewed/actions'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import Ntf from './ntf'

const createViewed = createSelector(
  (state: any) => state.viewed,
  tasks => tasks
)

const { Header } = Layout


function getHost() {
  return '/'
}

export const TopMenu = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const viewed = useSelector(createViewed)
  const [isOpen, setOpen] = useState<boolean>(false)
  const [clientId, setClientId] = useState(753)


  useEffect(() => {
    const controller = new AbortController()
    const body = { status: true, controller }
    dispatch(getLoadViewedTasks(body))

    return () => {
      body.status = false
      controller.abort()
    }
  }, [dispatch])

  const handleCalculatorClick = useCallback(() => {
    history.push('/calculator')
  }, [history])

  const handleCalendarClick = useCallback(() => {
    history.push('/calendar')
  }, [history])

  const handleChatClick = useCallback(() => {
    setOpen(true)
  }, [history])

  return (
    <Header style={{ padding: 0, backgroundColor: '#f6f8fa' }}>
      <NavigationButtons>
        <Ntf />
        <NavigationButton onClick={handleChatClick}>
          <Badge count={0} offset={[5, 5]}>
            <NavigationButtonIcon>
              <MessageOutlined rev={undefined}/>
            </NavigationButtonIcon>
          </Badge>
          <NavigationButtonTitle>
            Чат
          </NavigationButtonTitle>
        </NavigationButton>
        <NavigationButton onClick={handleCalendarClick} style={{ display: 'none' }}>
          <Badge count={viewed['tasks']} offset={[5, 5]}>
            <NavigationButtonIcon>
              <CalendarOutlined rev={undefined} />
            </NavigationButtonIcon>
          </Badge>
          <NavigationButtonTitle>Календарь</NavigationButtonTitle>
        </NavigationButton>
        <NavigationButton onClick={handleCalculatorClick}>
          <NavigationButtonIcon>
            <CalculatorOutlined rev={undefined} />
          </NavigationButtonIcon>
          <NavigationButtonTitle>Калькулятор</NavigationButtonTitle>
        </NavigationButton>
      </NavigationButtons>
    </Header>
  )
}
