import React, { useEffect, useState, useMemo, FC, useCallback } from 'react'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import Item from 'antd-mobile/lib/popover/Item'
import { JSONSchema7Definition } from 'json-schema'

import { IActionsListItemProps } from './interface'
import { ActionListItemText } from './styles'
import { TRANSITIONS_TYPES } from '../measurements-edit/interface'

function filterRoles(currentRoles, fieldRoles){
  return fieldRoles.filter(role => currentRoles.includes(role))
}

function PreparedItem({ item, oneOf, disabled, onClick }) {
  const nextValidators: any = useMemo(() => {
    const result: Record<string, string>[] = []
    const nextValidators = getDataOfType(item, `nextValidators`, Array, [])
      .map(nextValidator => nextValidator['title'])

    if (nextValidators.length !== 0) {
      nextValidators.forEach(name => {
        result.push({
          name,
          title: getDataOfType(
            oneOf.find(one => one['const'] === name),
            'title',
            String,
            name,
          )
        })
      })
    }

    return result
  }, [item, oneOf])

  const isProduct = useMemo(() => {
    const nextStageCode = getDataOfType(item, 'target', String, null)
    const stageProducts: Array<string> = ['shipped-from-production', 'team-order-acceptance', 'accepted-driver', 'accepted-client']
    return stageProducts.includes(nextStageCode)
  }, [item])

  return isProduct && nextValidators.length > 0 ?
    nextValidators.map(({ name, title }) => (
      <Item key={name}>
        <ActionListItemText onClick={onClick}>{title}</ActionListItemText>
      </Item>
    ))
  : (
    <Item key={item['target']} disabled={disabled}>
      <ActionListItemText onClick={onClick}>
        {item['name']}
      </ActionListItemText>
    </Item>
  )
}

export const ActionsListItem: FC<IActionsListItemProps> = props => {
  const {
    getTransition,
    checkTransitionRequired,
    stage,
    stageList,
    sessionData,
    getValidatorsData,
    openSubMenu,
    onClose = () => {},
  } = props
  const [oneOf,setOneOf] = useState([])

  useEffect(()=> {
    (async () => {
      const result = await fetchAPI('/api/schemas?fields=properties&filter[schemas]=workflows')
      const oneOf = getDataOfType(result, 'data.data[0].properties.access-rights.items.properties.field.oneOf', Array, [])
      setOneOf(oneOf)
    })()
  }, [])

  const transitionsList: any[] = useMemo(() => {
    const currentStage = stageList[stage]
    const transitions: any[] = getDataOfType(currentStage, `transitions`, Array, [])
      .map(item => {
        const target = getDataOfType(item, `target`, String, null)
        const nextStage = stageList.find(item => item['code'] === target)

        return {
          nextValidators: getDataOfType(nextStage, 'validators', Array, []),
          ...item
        }
      })

    return transitions
  }, [stageList, stage])

  const handleValidatorClick = useCallback(item => () => {
    if (typeof getTransition === 'function') {
      const target = item['target']
      const isAdditional = item['type'] && item['type'] === TRANSITIONS_TYPES.ADDITIONAL
      const nextStageIndex = stageList.findIndex(stage => stage['code'] === target)
      const validatorsList: JSONSchema7Definition[] = []

      if(nextStageIndex === -1 && !isAdditional){
        console.error(`В справочнике не найдена целевая стадия: ${target}`)
        return
      }

      const prevReq = getDataOfType(stageList, `[${item['stage']}].validators`, Array, []).map(item => item['title'])
      const nextReq = getDataOfType(stageList, `[${nextStageIndex}].validators`, Array, []).map(item => item['title'])
      const required  = checkTransitionRequired(prevReq, nextReq)

      nextReq.forEach(validate => {
        if (getValidatorsData) {
          const validatorData: JSONSchema7Definition | null = getValidatorsData(validate)

          if (validatorData) {
            validatorsList.push(validatorData)
          }
        }
      })

      if (isAdditional && item['nextValidators'] && Array.isArray(item['nextValidators'])) {
        validatorsList.push(...item['nextValidators'])
      }

      if (validatorsList.length > 0 || isAdditional) {
        openSubMenu(validatorsList, target, nextStageIndex, isAdditional)
      } else if (required) {
        getTransition(item, {
          prevStageIndex: item['stage'],
          prevStage: getDataOfType(stageList, `[${item['stage']}]`, Object, []),
          nextStageIndex,
          nextStage: stageList[nextStageIndex]
        })
      }
    }

    onClose()
  }, [
    getTransition,
    onClose,
    stageList,
    checkTransitionRequired,
    getValidatorsData,
    openSubMenu
  ])

  const calcRoleDisabled: any = useCallback(item => {
    let result: boolean
    const currentRoles = getDataOfType(sessionData, 'roles', Array, [])
    const fieldRoles = getDataOfType(item, 'roles', Array, [])
    const roles = filterRoles(currentRoles, fieldRoles)
    const skipCheck = item['type'] && item['type'] === TRANSITIONS_TYPES.ADDITIONAL

    if (skipCheck) {
      result = false
    } else {
      result = !roles.length
    }

    return result
  }, [sessionData])

  return (
    <div>
      {transitionsList.map((item, index) => {
        const disabled = calcRoleDisabled(item)

        return (
          <div key={item['target']}>
            <PreparedItem
              key={index}
              item={item}
              oneOf={oneOf}
              disabled={disabled}
              onClick={handleValidatorClick(item)}
            />
          </div>
        )
      })}
    </div>
  )
}
