import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Form, Button, Alert, Skeleton, Space } from 'antd'
import { JSONSchema7 } from 'json-schema'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import StepCollectionObject from '../../views/step-collection-object'
import ModalCreateTask  from '../../views/modal-create-task'
import EventRibbonTab from '../../forms/order-event-ribbon-tab'
import { createUISchemaHorizontal, recalcUISchemaHorizontal } from '../../../lib/utils/grid-fild-orientation'
import ButtonAddNotes from '../../views/modal-add-notes'
import { clearCaseStore, setAppealStage, reloadActionsTimeLine, initialCase, setTargetId, addActionsTimeLine } from '../../store/appeals/actions'
import { debounce } from 'lodash'
import ModalReasonCancellation from '../../views/modal-cancellation'
import StuckFiles from '../../components/tab-stuck-files'
import TableProduct from '../../connectors/json-form-product-table/adaptor-tab'
import { UpdateTaskIsStage, UISchemaDisabled } from '../card-order/utils'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import StepButtonsTransition from '../../views/step-buttons-transition'
import { addValidateFieldsUISchema, accessUISchema, isFormDataDifferenceFilesS3 } from '../card-order/utils'
import { isFormDataRequired } from '../../../lib/utils/collections'
import { setStageFromCard } from '../../../lib/utils/cards'

function genJSONSchemaStage(JSONSchema, requiredArr) {
  const required = getDataOfType(JSONSchema, 'required', Array, []).concat(requiredArr)
  const requiredUnic = Array.from(new Set(required))
  return ({ ...JSONSchema, required: requiredUnic })
}

function genJSONSchemaNamesrequired(JSONSchema){
  return getDataOfType(JSONSchema, 'required', Array, [])
}

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  appealsCurrentStep => appealsCurrentStep,
  workflows => workflows
)
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [{
  href: '/call-center/cases',
  title: 'Контактный центр',
}, {
  href: `/call-center/cases`,
  title:  'Обращения',
}]

const name = 'appeal'
const cardName = 'case'

export default function AdminNewObjectPage() {
  const history = useHistory()
  const { id } = useParams() as any
  const dispatch = useDispatch()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(false)
  const [isModalCreateTask, setModalCreateTask] = useState<boolean>(false)
  const [isCancellation, setCancellation] = useState<boolean>(false)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [selectedSchema, setSelectedSchema] = useState<string[]>([])
  const formDataRef = useRef<any>(null)
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<JSONSchema7 | null>(null)
  const [UISchema, setUISchema] = useState<any>(null)
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const [form] = Form.useForm()
  const { appealsCurrentStep, workflows } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)

  useEffect(() => {
    const currentStageAccess = getDataOfType(workflows, `${cardName}[${appealsCurrentStep}].access-rights`, Array, null)
    if(currentStageAccess === null){
      return
    }
    setUISchema(UISchema => recalcUISchemaHorizontal(accessUISchema(UISchema || {}, currentStageAccess, sessionData)))
  },[workflows, appealsCurrentStep, sessionData])

  const customTabs = useMemo(() => ({
    'stuck-products': {
      isVirtual: true,
      schema: {
        properties: {
          'stuck-products': {
            title: 'Данные заказа',
            description: null,
          }
        }
      },
      render: () => <TableProduct currentStage={'manufacture'} disabled={true} id={Number(id)} />
    },
    'stuck-files': {
      isVirtual: true,
      schema: {
        properties: {
          'stuck-files': {
            title: 'Прикрепленные файлы',
            description: null,
          }
        }
      },
      render: () => <StuckFiles />
    },
    'event-ribbon': EventRibbonTab
  }), [id])

  const saveCase = useCallback( debounce(async formData => {
    const requestBody = {
      data: {
        name: '',
        title: '',
        attributes: formData,
        metadata: { schema: name }
      }
    }

    const result = await fetchAPI(`/api/collections/-/objects/${id}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })

    const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)
    if (updated === null) {
      console.warn('Ошибка сохранения записи')
    }
  }, 1000), [])

  useEffect(() => {
    dispatch(initialCase({ targetId: Number(id), cardName: 'case' }))
    dispatch(setTargetId({ targetId: Number(id) }))
    dispatch(reloadActionsTimeLine())
    return () => { dispatch(clearCaseStore()) }
  }, [id, dispatch])

  const getData = useCallback(async () => {
    if (isDataLoading)
      return
    setIsDataLoading(true)
    const fields = [
      'title',
      'description',
      'type',
      'properties',
      'required',
      'created_by',
      'created_at',
      'updated_at',
    ].join(',')
    const responseSchemas = await fetchAPI(`/api/schemas?access_key=axioma&fields=${fields}`)
    const fetchedSchemas: JSONSchema7[] = getDataOfType(responseSchemas, 'data.data', Array, [])
    const selectedSchemas = fetchedSchemas.filter(item => item['name'] === name)
    const selectedSchema = (typeof selectedSchemas[0] === 'object') ? selectedSchemas[0] : null
    if (selectedSchema === null) {
      setPageErrors(['Некорректный ответ сервера при получении схемы коллекции'])
      return
    }
    const responseObject = await fetchAPI(`/api/collections/objects/${id}`)
    const object = responseObject['data']
    const schema = object['data']['metadata']['schema']
    const formData = object['data']['attributes']
    const stageCode = getDataOfType(object, 'stage-code', String, null)
    if (object['created-at']) {
      const paramsDispatch = addActionsTimeLine({
        type: 'info',
        items: [
          {
            date: object['created-at'],
            text: 'Создание записи',
          },
        ],
      })
      dispatch(paramsDispatch)
    }
    dispatch(setTargetId({ targetId: id }))
    setSelectedSchema(schema)
    setPageErrors([])
    formDataRef.current = formData
    const stage = formData['stage'] || 0
    const required = ["source-appeal", "telephone"].filter( i => !formData[i])
    setMatchedJSONSchema({...selectedSchema, required})
    const UISchema = createUISchemaHorizontal(selectedSchema)
    const currentCardOrganizationId = Number( getDataOfType(object, 'owner-organization', String, null) )
    if ('case-manager' in UISchema) {
      UISchema['case-manager'] = {
        ...UISchema['case-manager'],
        options: {
          roleId: 2,
          organizationId: currentCardOrganizationId
        }
      }
    }
    if ('measurer' in UISchema) {
      UISchema['measurer'] = {
        ...UISchema['measurer'],
        options: {
         roleId: 10,
          organizationId: currentCardOrganizationId
        }
      }
    }
    if ('leader-installer' in UISchema) {
      UISchema['leader-installer'] = {
        ...UISchema['leader-installer'],
        options: {
          roleId: 15,
          organizationId: currentCardOrganizationId
        }
      }
    }
    if ('additional-contact' in UISchema) {
      UISchema['additional-contact'] = {
        ...UISchema['additional-contact'],
        options: {
          targetId: id,
        }
      }
    }
    setUISchema(UISchema)
    const currentStageAccess = getDataOfType(workflows, `${cardName}[${stage}].access-rights`, Array, [])
    setUISchema(UISchema => recalcUISchemaHorizontal(accessUISchema(UISchema || {}, currentStageAccess, sessionData)))
    dispatch(setAppealStage({ stageCode: stageCode, type: cardName, isHistory: false }))
    setIsDataLoading(false)
  }, [dispatch, id, sessionData, workflows])

  useEffect(() => {
    if(sessionData === null || workflows === null){
      return
    }
    getData()
  }, [getData, sessionData, workflows])

  const onFormData = useCallback(
    ({ formData }) => {
      if( isBusy )
        return
      const isFilesEdit = isFormDataDifferenceFilesS3(formDataRef.current, formData, matchedJSONSchema)
      if (isFilesEdit) {
        dispatch(reloadActionsTimeLine())
      }
      const nextFormData = { ...formData, stage: appealsCurrentStep }
      formDataRef.current = nextFormData
      saveCase(nextFormData)
    },
    [saveCase, appealsCurrentStep, matchedJSONSchema, dispatch, isBusy]
  )

  const onCancellation = useCallback(async ({ comment }) => {
    await UpdateTaskIsStage({ parentId: id, stage: 'consultation', formData: {
      'date-completion': new Date().toISOString(),
      'status': 'cancel',
      'comment' : comment
    }})
    setCancellation(false)
    setUISchema(UISchema => UISchemaDisabled(UISchema))
    dispatch(reloadActionsTimeLine())
  },[ id, dispatch])

  const transition = useCallback(async transition => {
    const targetId = Number(id)
    if (transition['target'] === 'done') {
      setIsBusy(true)
      await fetchAPI(`/api/web-hooks/create-contract/${id}`)
      const result = await setStageFromCard({
        cardName: cardName, stageCode: 'done', targetId: targetId, isHistory: false
      })
      const orderId = getDataOfType(result, 'data.onStageData.order-id', Number, null)
      if (orderId === null) {
        setIsBusy(false)
        return
      }
      history.push(`/order/${orderId}/edit`)
      setIsBusy(false)
      return
    }
  },[history, id, setIsBusy])

  const transitionRequired = useCallback((_, nextFields) => {
    const gen = genJSONSchemaStage(matchedJSONSchema, nextFields)
    const names = genJSONSchemaNamesrequired(gen)
    const isRequired = isFormDataRequired(formDataRef.current, gen)
    if(isRequired){
      return true
    }
    setUISchema(UISchema => addValidateFieldsUISchema(UISchema, names))
    return false
  }, [matchedJSONSchema])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Обращение <span>{`№ ${id}`}</span></MapTitle>
          <Space size={'small'}>
            <ButtonAddNotes />
            <Button disabled>Отправить сообщение</Button>
            <Button onClick={() => setModalCreateTask(true)}>Создать задачу</Button>
            <StepButtonsTransition type={cardName} transition={transition} transitionRequired={transitionRequired} isBusy={isBusy}/>
          </Space>
        </TaskWrapper>
        <MapContent>
          <ModalCreateTask
            visible={isModalCreateTask}
            onClose={() => setModalCreateTask(false)}
          />
          <ModalReasonCancellation visible={isCancellation}  onClose={() => setCancellation(false)} onOk={onCancellation} />
          <>
            <StepCollectionObject type={cardName}/>
            <Form
              {...formItemLayout}
              layout="horizontal"
              form={form}
              fields={[
                {
                  name: 'schema',
                  value: selectedSchema,
                },
              ]}
              scrollToFirstError={true}
            />
              {pageErrors.length > 0 ? (
                <AlertWrapper>
                  <Alert
                    message="При выполнении операции возникла ошибка:"
                    showIcon
                    type="error"
                    description={pageErrors.join('. ')}
                  />
                </AlertWrapper>
              ) : null}
              {isDataLoading === false ? (
                matchedJSONSchema !== null && UISchema && (
                  <DefaultJsonForm
                    formData={formDataRef.current}
                    schema={matchedJSONSchema}
                    onChange={onFormData}
                    customTabs={customTabs}
                    UISchema={UISchema}
                  />
                )
              ) : (
                <Skeleton active />
              )}
          </>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
