import React, { useEffect, useState, useMemo, useCallback } from 'react'
import fetchAPI from '../../../lib/utils/fetch-api'
import { Select } from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

function filterOption(input, option){
  return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

function SelectFrom({ value, schema, onChange, UISchema }:{ value?:any, schema?:any, onChange?:any, UISchema }) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const allowClear = useMemo(() => Boolean(UISchema?.options?.allowClear), [UISchema])
  const [objects, setObjects] = useState<any[]>([])
  useEffect(() => {
    (async () => {
      const collectionName = 'cities'
      const objectsResult: any = await fetchAPI(`/api/collections/${collectionName}`)
        .then(item => getDataOfType(item, 'data.data', Array, []))
      const objectsFormat = objectsResult.map(item => {
        return { 
        id: getDataOfType(item, 'data.name', String, null), 
        value: getDataOfType(item, 'data.name', String, 
          getDataOfType(item, 'data.name', String, null)
        )}
      })
      .filter(item => item['id'])
      setObjects(objectsFormat)
    })()
  }, [])
   
  const onSearch = useCallback(text => text, [])

  return (
    <Select
      onSearch={onSearch}
      allowClear={allowClear}
      showSearch
      placeholder="Город"
      optionFilterProp="children"
      onChange={onChange}
      value={value}
      disabled={disabled}
      filterOption={filterOption}
    >
    {objects.map(({ id, value }) => (
      <Select.Option value={id}>{value}</Select.Option>
    ))}
    </Select>
  )
}

export default SelectFrom

