import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react'
import { Alert, Button, Cascader, Form, Input, Modal, Skeleton, Tabs, Timeline, Tooltip, Popconfirm } from 'antd'
import { SaveOutlined, MinusCircleOutlined, PlusOutlined, EditOutlined, SettingOutlined } from '@ant-design/icons'

import {
  AlertWrapper,
  FormItemGroup,
  MapContent,
  MapTitle,
  MapWrapper,
  TabContent,
} from './styles'
import { formItemLayout, formItemLayoutWithOutLabel, initialFormDataValues } from './consts'
import { useSchemaForm } from '../../hooks/use-schema-form'
import { TEditCollectionForm } from './interface'
import { SelectGroup } from '../../views/admin-select-group'

const prepareSchemaData = data => {
  let result: any | null = null

  if (data) {
    result = {
      id: data['name'],
      name: data['name'],
      title: data['title'],
      description: data['description'],
      required: data['required'],
      schema: {
        type: data['type'],
        required: data['required'],
        properties: data['properties'],
      },
    }
  }

  return result
}

export const EditCollectionForm: TEditCollectionForm = (
  {
    title,
    form,
    formData,
    setFormData,
    onFinish,
    onFinishFailed,
    onFormChange,
    pageErrors,
    idFieldExtra,
    isEdit,
    isSchemasLoading,
    schemaForCascader,
    selectedSchemaForCascader,
    createHandleSelectSchema,
    onSchemaCreateOnSave,
    onGetCollectionTitle,
    onGetCollectionDescription,
    schemaData,
  }
) => {
  const [showModal, setShowModal] = useState(false)
  const [currentSchemaIndex, setCurrentSchemaIndex] = useState(0)
  const [isEditOnce, setEditOnce] = useState<any>(() => true)
  const nameRef = useRef<any>(null)
  const [descriptionPlaceholder, setDescriptionPlaceholder] = useState(() => '')

  useEffect(()=>{
    if(typeof nameRef?.current?.onBlur === 'function'){
      // @ts-ignore
      nameRef.current.onBlur = () => {
        // @ts-ignore
        const title = nameRef?.current?.state?.value
        setDescriptionPlaceholder(() => title)
      }
    }
  },[])

  useEffect(() => {
    // @ts-ignore
    const title = nameRef?.current?.state?.value
    setDescriptionPlaceholder(() => title)
  }, [isSchemasLoading])
  const currentGroupId = useMemo<number | undefined>(() =>
    isEdit
      ? (formData.group_id || undefined)
      : undefined,
    [formData.group_id, isEdit])

  const toggleModal = useCallback(() => {
    setShowModal(prevState => !prevState)
  }, [])

  const handleOpenModal = useCallback((index) => () => {
    setCurrentSchemaIndex(index)
    setShowModal(true)
  }, [])

  const isStructureEdit = useCallback(index => (
      selectedSchemaForCascader[index]
      && selectedSchemaForCascader[index].length > 0
    ),
    [selectedSchemaForCascader])

  const editorButtonTitle = useCallback(index => isStructureEdit(index)
    ? 'Открыть на редактирование'
    : 'Создать структуру',
    [isStructureEdit])

  const editorModalTitle = useMemo(() => isStructureEdit(currentSchemaIndex)
    ? 'Редактирование структуры'
    : 'Создание структуры',
    [currentSchemaIndex, isStructureEdit])

  const editorButtonIcon = useCallback(index => isStructureEdit(index)
    ? <EditOutlined rev={undefined}/>
    : <PlusOutlined rev={undefined}/>,
    [isStructureEdit])

  const handleSchemaCreateOnSave = useCallback((data) => {
    toggleModal()

    if (onSchemaCreateOnSave && onSchemaCreateOnSave.constructor === Function) {
      onSchemaCreateOnSave(data, currentSchemaIndex)
    }
  }, [currentSchemaIndex, onSchemaCreateOnSave, toggleModal])

  /** Form for creating schema */
  const { render: SchemaForm, onSubmit: handleSchemaFromSubmit, onReloadForm } = useSchemaForm({
    noFooter: true,
    onAfterSave: handleSchemaCreateOnSave,
    getTitle: onGetCollectionTitle,
    getDescription: onGetCollectionDescription,
    isEdit: isStructureEdit(currentSchemaIndex),
    formData: prepareSchemaData(schemaData[currentSchemaIndex]),
    isNewOpen: showModal,
  })

  const handleSchemaModalSubmitForm = useCallback(() => {
    handleSchemaFromSubmit()
    setTimeout(onReloadForm, 2000)
  }, [handleSchemaFromSubmit, onReloadForm])

  const getItemLayout = useCallback(index => index === 0
    ? formItemLayout
    : formItemLayoutWithOutLabel, [])

  const handleSubmit = useCallback(() => {
    if (form && form.submit) {
      form.submit()
    }
  }, [form])

  const getItemStructureLabel = useCallback((index) => index === 0 ? 'Структуры записей' : '', [])

  const confirmMessage = useMemo(() => isEdit ? 'Изменить данные справочника?' : 'Создать справочник?', [isEdit])

  const confirmSchemaMessage = useMemo(() => isStructureEdit(currentSchemaIndex)
    ? 'Изменить данные структуры?'
    : 'Создать структуру?', [currentSchemaIndex, isStructureEdit])

  const handleSelectGroup = useCallback((value) => {
    const formFieldsValue = form.getFieldsValue()

    form.setFieldsValue({
      ...formFieldsValue,
      group_id: value,
    })
    setFormData(prevState => ({
      ...prevState,
      group_id: value,
    }))
  }, [form, setFormData])

  const getCreateGroupURL = useCallback(() => 'collections/group/', [])
  const getUpdateGroupURL = useCallback(id => `collections/group/${id}`, [])

  return (
    <>
      <MapWrapper>
        <MapTitle>{title}</MapTitle>
        <MapContent>
          <Form
            {...formItemLayout}
            layout="horizontal"
            form={form}
            scrollToFirstError={true}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            onChange={onFormChange}
            initialValues={initialFormDataValues}
          >
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            <Form.Item
              label="Наименование справочника"
              name="title"
              rules={[
                {
                  required: true,
                  message: 'Поле обязательно к заполнению',
                },
              ]}
            >
              <Input ref={nameRef} />
            </Form.Item>
            <Form.Item
              label="Идентификатор справочника"
              extra={idFieldExtra}
              name="name"
              rules={[
                {
                  required: true,
                  message: 'Поле обязательно к заполнению',
                },
              ]}
            >
              <Input disabled={isEditOnce} addonAfter={isEdit? null: <SettingOutlined rev={undefined} onClick={() => setEditOnce(old => !old)} />} />
            </Form.Item>
            <Form.Item
              label="Описание справочника"
              name="description"
            >
              <Input placeholder={descriptionPlaceholder} />
            </Form.Item>
            <Form.Item
              label="Post функция"
              name="post-function"
            >
              <Input placeholder="Укажите post функцию" />
            </Form.Item>
            <Form.Item
              label="Группа справочника"
              name="group_id"
            >
              <FormItemGroup>
                <SelectGroup
                  onChange={handleSelectGroup}
                  initSelectedGroup={currentGroupId}
                  getCreateGroupURL={getCreateGroupURL}
                  getUpdateGroupURL={getUpdateGroupURL}
                  getGroupsURL='collections/groups'
                />
              </FormItemGroup>
            </Form.Item>
            {isSchemasLoading ? <Skeleton active /> : null}
            <Form.List name="schema">
              {(fields, { add, remove }) => {
                if (isSchemasLoading) {
                  return
                }
                return (
                  <>
                    {fields.map((field, index) => (
                      <Form.Item
                        {...(getItemLayout(index))}
                        label={getItemStructureLabel(index)}
                        required={false}
                        key={field.key}
                      >
                        <Form.Item
                          required={true}
                          {...field}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              type: 'array',
                              required: true,
                              message: 'Выберите используемую структуру',
                            },
                          ]}
                          style={{
                            paddingRight: 40,
                            position: 'relative',
                          }}
                        >
                          <FormItemGroup>
                            <Cascader
                              options={schemaForCascader}
                              value={selectedSchemaForCascader[index]}
                              onChange={createHandleSelectSchema(index)}
                              placeholder="Выбрать"
                            />
                            <Tooltip placement="top" title={editorButtonTitle(index)}>
                              <Button
                                icon={editorButtonIcon(index)}
                                onClick={handleOpenModal(index)}
                              />
                            </Tooltip>
                          </FormItemGroup>
                        </Form.Item>
                        {fields.length > 1 ? (
                          <MinusCircleOutlined
                            style={{
                              cursor: 'pointer',
                              top: 4,
                              right: 8,
                              position: 'absolute',
                              fontSize: '24px',
                              color: '#999',
                              display: 'block',
                            }}
                            rev={undefined}
                            onClick={() => {
                              remove(field.name)
                            }}
                          />
                        ) : null}
                      </Form.Item>
                    ))}
                    <Form.Item {...formItemLayoutWithOutLabel}>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                      >
                        <PlusOutlined rev={undefined} />
                        Добавить структуру записей
                      </Button>
                    </Form.Item>
                  </>
                )
              }}
            </Form.List>
            <Popconfirm title={confirmMessage} okText="Да" cancelText="Нет" onConfirm={handleSubmit}>
              <Button type="primary" style={{ marginBottom: 16, float: 'right' }}>
                <SaveOutlined rev={undefined} />
                Сохранить
              </Button>
            </Popconfirm>
            <Tabs type="card" style={{ marginTop: 60 }}>
              <Tabs.TabPane tab="История изменений" key="1">
                <TabContent>
                  <Timeline>
                    <Timeline.Item>
                      {new Date().toLocaleDateString('ru-ru')} Инициировано создание справочника
                    </Timeline.Item>
                  </Timeline>
                </TabContent>
              </Tabs.TabPane>
            </Tabs>
          </Form>
        </MapContent>
      </MapWrapper>
      <Modal
        title={editorModalTitle}
        visible={showModal}
        forceRender
        width="90vw"
        okText={
          <Popconfirm title={confirmSchemaMessage} okText="Да" cancelText="Нет" onConfirm={handleSchemaModalSubmitForm}>
            <span>
              <SaveOutlined rev={undefined}/>&nbsp;
              Сохранить
            </span>
          </Popconfirm>
        }
        cancelText="Отмена"
        onCancel={toggleModal}
      >
        <SchemaForm needUpdateData={showModal} />
      </Modal>
    </>
  )
}
