import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Table, Alert, Pagination, Space } from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import B2BLayout from '../../../react-app/layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import FieldHideText from '../../components/field-hide-text'
import EditCard from '../../components/list-operations/edit-card'
import { formDataToUrlParams } from '../list-orders/utils'
import { calcTotalWidth, tableRowClick, getColumnsOfSchema, fetchSchema } from '../../../lib/utils/list'
import { TableWrapper, PaginationWrapper } from './styles'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import OrderFilter from './orders-filter'
import './index.css'


const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)

const name = 'call-log'

export default function AdminObjectsListPage() {
  const history = useHistory()
  const location = useLocation()
  const orderFiltersRef = useRef<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [page, setPage] = useState<number>(1)
  const { counts } = useSelector(createViewed)
  const breadcrumbs = useMemo(() => (
    [{
      href: '/',
      title: 'Контактный центр',
    }, {
      href: location.pathname,
      title: 'Список клиентов',
    }]
  ), [location.pathname])

  const addDataColumns = useMemo(() => {
    const addDataColumns: any[] = [
      {
        title: 'ID',
        width: 100,
        dataIndex: 'owner-user',
        key: 'owner-user',
        render: (_, item) => getDataOfType(item, 'owner-user', String, '')
      },
      {
        title: 'ФИО',
        width: 200,
        dataIndex: 'profile-data.name',
        key: 'profile-data.name',
        render: (_, item) => getDataOfType(item, 'profile-data.name', String, '')
      },
      {
        title: 'Телефон',
        width: 200,
        dataIndex: 'profile-data.phone',
        key: 'profile-data.phone',
        render: (_, item) => getDataOfType(item, 'profile-data.phone', String, '')
      },
      {
        title: 'Адрес',
        width: 200,
        dataIndex: 'address',
        key: 'address',
        render: (key, item) => getDataOfType(item, 'profile-data.address', String, '')
      },
      {
        title: 'Количество заказов',
        width: 200,
        dataIndex: 'orders-quantity',
        key: 'orders-quantity',
        render: (key, item) => key
      },
      {
        title: 'Сумма заказов',
        width: 150,
        dataIndex: 'orders-sum',
        key: 'orders-sum',
        render: (key, item) => key
      },
      {
        title: 'Дата регистрации',
        width: 150,
        dataIndex: 'created-at',
        key: 'created-at',
        render: (key) => key && dayjs(key).format('DD.MM.YYYY hh:mm')
      },

    ]
/*     if (getDataOfType(sessionData, 'organization.id', Number, null) === 1) {
      return [{
        title: 'Организация',
        width: 200,
        dataIndex: 'organization-title',
        key: 'organization-title',
      }].concat(addDataColumns)
    } */
    return addDataColumns
  }, []) 

  const totalWidth: any = useMemo(() => calcTotalWidth( addDataColumns), [ addDataColumns])

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(addDataColumns).filter(item => item.title), [addDataColumns])
  const cbTableRowClick = useCallback((item) => item['owner-user'] && history.push(`/clients/${item['owner-user']}`), [history])

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  }, [])

  const getData = useCallback(async (value, addUrlParams?: string) => {
    const urlParams = formDataToUrlParams(value)
    const pageErrors: string[] = []
    const [selectedSchema, metadata, collections] = await Promise.all([
      fetchSchema({ name }),
      fetchAPI(`/api/collections/${name}/metadata`),
      fetchAPI(`/api/clients?access_key=axioma&${urlParams}&${addUrlParams}`)
    ])
    //const columnsHidden = getDataOfType(metadata, 'data.columnsHidden', Array, [])
    const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])
    //const ordersIds = collectionsResultData.map(item => item['id']).join(',')
  
    const isMetadata = getDataOfType(metadata, 'data', Object, null)
    if(isMetadata === null) {
      pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
    }
    const dataSource: any[] = collectionsResultData
    const newDataSource = dataSource.map((item) => ({
      ...item,
      deleteRecord,
    }))
    const preparedDataColumns: any[] = getColumnsOfSchema(selectedSchema)

    const newDataColumns = preparedDataColumns.map(column => ({ 
        ...column, 
        fixed: 'left', 
        width: 120, 
        render: (text, { id, schema }) => (
        <EditCard id={id} schema={schema} title="Редактирование">
         {FieldHideText({ text })}
        </EditCard>
      )}
    ))
    const totalRows = getDataOfType(collections, 'data.total-rows', Number, 0)
    setTotalRows(totalRows)
    setDataColumns(newDataColumns)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [deleteRecord])

  const onPagination = useCallback(async ( page, pageSize) => {
    setPage(page)
    const urlParams = `page=${page}&pageSize=${pageSize}`
    setIsDataLoading(true)
    await getData(orderFiltersRef.current, urlParams)
    setIsDataLoading(false)
  }, [getData])

  useEffect(() => {
    const urlParams = `page=${page}&pageSize=10`
    page === 1 && getData(orderFiltersRef.current,urlParams)
  }, [counts, page, getData])

  const rowClassName = useCallback(record => {
    const isColor = record['orders-quantity'] >= 2 && record['orders-quantity-new'] >= 1
    return isColor ? 'table-client-row-dark' :  'table-client-row-light'
  }, [])

  const onOrderFilter = useCallback(value => {
    Object.assign(orderFiltersRef.current, value)
    getData(value)
  }, [getData])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <Space align="baseline">
        <OrderFilter onChange={onOrderFilter} />
      </Space>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}
      <TableWrapper>
        <Table
          columns={finalColumns}
          rowClassName={rowClassName}
          size="small"
          dataSource={dataSource}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={false}
          loading={isDataLoading}
          onHeaderRow={(column: any) => ({
            style: {
              fontSize: column.key && column.key.substr(-3) === '-at' ? 9 : 13,
            },
          })}
          onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick) })}
        />
      </TableWrapper>
      {Boolean(totalRows) && <PaginationWrapper>
        <Pagination defaultCurrent={1} pageSize={10} total={totalRows} onChange={onPagination} showSizeChanger={false} />
      </PaginationWrapper>}
    </B2BLayout>
  )
}
