import React, { useEffect, useState, useMemo } from 'react'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { Steps, Space  } from 'antd'
import { StepsContainer, HeaderTitle, ItemTitleWrapper } from './styles'
import dayjs from 'dayjs'

function getStages(data, name){
  const target = data.find(item => item['name'] === name)
  const stages = getDataOfType(target, 'data.attributes.field_1', Array, null)
  if(stages === null){
    return null
  }
  return stages
}

function stagesDateCalc(stages){
  let currentDateMin = dayjs().valueOf()
  let currentDateMax = dayjs().valueOf()
  return stages.map(item => {
    currentDateMin = dayjs(currentDateMin).add(item['days-min'], 'day').valueOf() as number
    currentDateMax = dayjs(currentDateMax).add(item['days-max'], 'day').valueOf() as number
    return {
      title: item['stage-title'],
      code: item['stage-code'],
      dateMin: dayjs(currentDateMin).format("DD.MM.YYYY"),
      dateMax: dayjs(currentDateMax).format("DD.MM.YYYY"),
    }
  })
}

function Title({ title, code, dateMin, dateMax, index }){
  const text = useMemo(() => {
    if(dateMin === dateMax){
      return dateMin
    }
    return `${dateMin} - ${dateMax}`
  }, [dateMin, dateMax])
  return (
    <ItemTitleWrapper>
      <Space size={4}>
        <span>{`${title}${![0,1].includes(index) ? ':' : ''}`}</span>
        {![0,1].includes(index) &&<span style={{ fontWeight: 'bold'}}>{text}</span>}
      </Space>
    </ItemTitleWrapper>
  )
} 

function OrderExecutionPlan(){
  const [stagesCase, setStagesCase] = useState([])

  useEffect(()=> {
    (async () => {
        const name = 'performance-forecast'
        const response = await fetchAPI(`/api/collections/${name}/?access_key=axioma`)
        const data = getDataOfType(response, 'data.data', Array, [])
        const casePlan = getStages(data, 'new-case')
        const casePlanFormat = stagesDateCalc(casePlan)
        setStagesCase(casePlanFormat)
    })()
  }, [])
  return <>
      <StepsContainer>
        <HeaderTitle>Прогноз исполнения</HeaderTitle>
        <Steps size="small" direction="vertical" >
          {stagesCase.map((item: any, index) => (
            <Steps.Step key={index+'_'+item['stageCode']} title={<Title {...item} index={index} />}  />
          ))}
        </Steps>
    </StepsContainer>
  </>
}

export default OrderExecutionPlan
