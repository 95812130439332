import React, {useCallback, useEffect, useMemo, useState} from 'react'
import B2BLayout from '../../../layouts/b2b'
import {MapTitle} from '../../card-appeal/styles'
import {Button, Form, Input, Popconfirm, Table, Typography} from 'antd'
import {EditOutlined, PlusOutlined, SaveOutlined, StopOutlined} from '@ant-design/icons'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {ButtonActionWrapper} from '../../../components/list-operations/styles'
import {useHistory} from 'react-router-dom'
import {objectToUrl} from '../../utils'


const requiredFields = ['name', 'code']

const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {

  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{margin: 0}}
          rules={[
            {
              required: requiredFields.includes(dataIndex),
              message: `Обязательное поле ${title}!`,
            },
          ]}
        >
          <Input/>
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

type TablePaginationPosition = 'bottomCenter'

const Collections = () => {
  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => (
    [{
      href: '/admin',
      title: 'Админка',
    }]
  ), [])

  const [form] = Form.useForm()
  const [data, setData] = useState<any>([])
  const [editingKey, setEditingKey] = useState<null | number>(null)
  const [loading, setLoading] = useState(false)
  const [sorter, setSorter] = useState<any>({})
  const history = useHistory()
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 10,
    position: [bottomCenter],
    showSizeChanger: false
  })

  const isEditing = record => record.id === editingKey

  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(newState => {
    setPagination({
      ...pagination,
      ...newState
    })
  }, [pagination, setPagination])

  useEffect(() => {
    getData()
  }, [])

  useEffect( () => {
    setPagination( cur => ({ ...cur, disabled: editingKey !== null}) )
  }, [editingKey])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    try {
      setLoading(true)

      const urlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/enum-collections/?${objectToUrl(urlParams)}`)
        .then(({data, pagination, sorter}) => {
          if (data) {
            updatePagination(pagination)
            setSorter(sorter)
            setData(data)
          }
          setLoading(false)
        })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [])


  const edit = record => {
    form.setFieldsValue({name: '', code: '', module: '', ...record})
    setEditingKey(record.id)
  }

  const cancel = (id) => {
    if (id === 0)
      setData(rows => rows.filter(item => item.id !== id))
    form.setFieldsValue({name: '', code: '', module: ''})
    setEditingKey(null)
  }

  const save = async id => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex(item => id === item.id)
      const newValue = newData[index]?.id === 0
      const valueId = newData[index]?.id
      if (index > -1) {
        const url = newValue ? '/api/enum-collection/new' : `/api/enum-collection/${valueId}`
        fetchAPI(
          url,
          {
            method: 'POST',
            body: JSON.stringify(row)
          })
          .then(result => {
            const {id, name, module, code} = result
            setData(data => data.map(item => {
              if (item?.id === 0)
                item = {id, name, module, code}
              if(item?.id === id)
                item = {id, name, module, code}
              return item
            }))
            setEditingKey(null)
            form.setFieldsValue({name: '', code: '', module: ''})
          })
          .catch(e => {
            console.error('Save error', {e})
          })
      }
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo)
    }
  }

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    let current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined || current?.sort_order === null) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({search: urlParams})
    getData()
  }

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 60,
      render: v => (v !== 0) ? v : null,
      sorter: editingKey === null,
      sortOrder: (sorter.sort_field === 'id') ? sorter.sort_order : null
    },
    {
      title: 'Название',
      dataIndex: 'name',
      editable: true,
      sorter: editingKey === null,
      sortOrder: (sorter.sort_field === 'name') ? sorter.sort_order : null
    },
    {
      title: 'Код',
      dataIndex: 'code',
      width: 190,
      editable: true,
      sorter: editingKey === null,
      sortOrder: (sorter.sort_field === 'code') ? sorter.sort_order : null
    },
    {
      title: 'Модуль',
      dataIndex: 'module',
      width: 190,
      editable: true,
      sorter: editingKey === null,
      sortOrder: (sorter.sort_field === 'module') ? sorter.sort_order : null
    },
    {
      title: 'Действия',
      dataIndex: 'operation',
      width: 110,
      style: '',
      render: (_: any, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span style={{display: 'flex', justifyContent: 'center'}} onClick={e => {e.stopPropagation()}}>
            <Typography.Link onClick={() => save(record.id)} style={{marginRight: 8}}>
            <ButtonActionWrapper title="Редактировать">
              <SaveOutlined rev={undefined}/>
            </ButtonActionWrapper>
            </Typography.Link>
            <ButtonActionWrapper title="Сохранить">
              <Popconfirm title="Изменения не сохранятся, отменить?" onConfirm={() => cancel(record.id)}>
                <StopOutlined rev={undefined}/>
              </Popconfirm>
            </ButtonActionWrapper>
          </span>
        ) : (
          <span style={{display: 'flex', justifyContent: 'center'}} onClick={e => {e.stopPropagation()}}>
            <Typography.Link disabled={editingKey !== null} onClick={() => edit(record)}>
              <ButtonActionWrapper title="Редактировать">
                <EditOutlined rev={undefined}/>
              </ButtonActionWrapper>
            </Typography.Link>
          </span>
        )
      },
    },
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable)
      return col

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const handleRowClick = useCallback( record => {
    if( editingKey === null )
      history.push(`/admin/enum-collection/${record.id}`)
  }, [editingKey])

  const addRow = useCallback(() => {
    setData(rows => {
      const newRow = {
        id: 0,
        name: null,
        code: null,
        module: null
      }
      return [newRow, ...rows]
    })
    setEditingKey(0)
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <MapTitle>Коллекции</MapTitle>
        <Button onClick={addRow} disabled={editingKey !== null}>
          <PlusOutlined rev={undefined}/> Добавить коллекцию
        </Button>
      </div>
      <Form form={form} component={false}>
        <Table
          loading={loading}
          components={{body: {cell: EditableCell}}}
          size="small"
          dataSource={data}
          columns={mergedColumns}
          rowClassName={ (!editingKey) ? "editable-row cursor-pointer" : "editable-row" }
          onRow={ record => ({onClick: () => handleRowClick(record)})}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </Form>
    </B2BLayout>
  )
}

export default Collections