import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Form, Alert, Skeleton, Button, Tabs, Modal } from 'antd'
import { Radio } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import { createUISchemaVertical } from '../../../lib/utils/grid-fild-orientation'
import userFieldsSchema from './json-schema.json'
import TableSelected from './table-selected'
import  RatingUser  from '../../../react-app/connectors/rating-user'
import { isFormDataRequired } from '../../../lib/utils/collections'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [
  {
    href: '/users',
    title: 'Пользователи',
  },
  {
    href: `/users`,
    title: 'Список пользователей',
  },
]

function concatSchema(s1, s2){
  const properties1 = getDataOfType(s1, 'properties', Object, {})
  const properties2 = getDataOfType(s2, 'properties', Object, {})
  return { 
    ...s2,
    ...s1,
    properties: { ...properties1, ...properties2 }
  }
}


export default function AdminNewObjectPage() {
  const { id } = useParams()  as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [roles, setRoles] = useState<any[]>([])
  const [initRoles, setInitRoles] = useState<any[]>([])
  const [profilesType, setProfilesType] = useState<any[]>([])
  const [profileTypeId, setProfileTypeId] = useState<any>(null)
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)


  const rolesRef = useRef<any[]>([])

   const saveCase = useCallback(
    async () => {
      const isRequired = isFormDataRequired(formDataRef.current, matchedJSONSchema)
      if (isRequired === false) {
        Modal.warning({
          title: 'Предупреждение',
          content: 'Заполните все обязательные поля'
        })
        return
      }

      const rolesIds = rolesRef.current 
      const formData = { ...formDataRef.current }
      const userData = {
        'login': formData['login'],
        'password': formData['password']
      }
      delete formData['login']
      delete formData['password']

      await fetchAPI(`/api/roles-user/${id}?roles-ids=${rolesIds.join(',')}`, { method: 'PUT'})
      await fetchAPI(`/api/user-create-or-update/${id}`, { method: 'POST', body: JSON.stringify({
        'profile-type-id': profileTypeId,
        'profile-data': formDataRef.current,
        'user-data': userData
      })})

      history.push('/users')
    },
    [history, profileTypeId, id, matchedJSONSchema],
  ) 

  const getData = useCallback(async () => {
    {
      const result = await fetchAPI(`/api/profiles-types?type=user`)
      const profilesTypes = getDataOfType(result, 'data', Array, [])
      setProfilesType(profilesTypes)
      const defaultPrifileId = getDataOfType(profilesTypes, '[0].id', [String, Number], null)
      const defaultSelectedSchema = getDataOfType(profilesTypes, '[0].profile-schema', Object, null)
      {
        const result = await fetchAPI(`/api/profiles?type=user&user-id=${id}`)
        const data = getDataOfType(result, 'data', Array, [])       
        const profileData = getDataOfType(data, '[0].profile-data', Object, {})
        const prifileId = getDataOfType(data, '[0].id', [String, Number], defaultPrifileId)
        const prifileTypeId = Number(getDataOfType(data, '[0].profile-type', [String, Number], 1))
        console.log(prifileId, prifileTypeId)
        const selectedSchema = getDataOfType(data, '[0].profile-schema', Object, defaultSelectedSchema)
        {
          const result = await fetchAPI(`/api/users/${id}`)
          const login = getDataOfType(result, 'data.login', String, '') 
          formDataRef.current = { profileData, 'login': login }
        }
        setProfileTypeId(prifileTypeId)
        setMatchedJSONSchema(concatSchema(userFieldsSchema, selectedSchema))
        const UISchema = createUISchemaVertical(concatSchema(userFieldsSchema, selectedSchema))
        setUISchema(UISchema)
      }
    }
    {
      const result = await fetchAPI(`/api/roles-user/${id}`)
      const data = getDataOfType(result, 'data', Array, [])
      rolesRef.current = data
      setInitRoles(data)
    }
    {
      const result = await fetchAPI(`/api/roles?filter[user-id]=${id}`)
      const data = getDataOfType(result, 'data', Array, null)
      console.log('roles',data)
      setRoles(data)
    }
    setPageErrors([])

    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData()
  }, [getData])

  const onFormData = useCallback(
    ({ formData }) => {
      formDataRef.current = formData
    }, []
  )

  const onTableChange = useCallback(async (ids) => {
    rolesRef.current = ids
  }, [])

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const onSchema = useCallback(({ target }) => {
    const id = target?.value
    const data = profilesType.find(item => item['id'] === id)
    const selectedSchema = getDataOfType(data, 'profile-schema', Object, {})
    setMatchedJSONSchema(concatSchema(userFieldsSchema, selectedSchema))
    setProfileTypeId(Number(id))
  }, [profilesType])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            Пользователь <span>{`№ ${id}`}</span>
          </MapTitle>
        </TaskWrapper>
        <MapContent>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div>
              {isDataLoading || (
                <Radio.Group onChange={onSchema} value={profileTypeId}>
                  {profilesType.map(item => (
                    <Radio style={radioStyle} value={item['id']}>
                      {item['title']}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </div>
          </div>

          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {isDataLoading === false ? (
              UISchema && (
                <DefaultJsonForm
                  formData={formDataRef.current}
                  schema={matchedJSONSchema}
                  onChange={onFormData}
                  UISchema={UISchema}
                />
              )
            ) : (
              <Skeleton active />
            )}
          </Form>
          <Tabs type="card">
            <Tabs.TabPane tab="Роли" key="1">
              { roles === null ? 'Нет дотупных ролей, организация не присвоена' :
                <TableSelected dataSource={roles} onChange={onTableChange} selected={initRoles} />
              }
            </Tabs.TabPane>
            <Tabs.TabPane tab="Рейтинг" key="2">
              <RatingUser id={id} />
            </Tabs.TabPane>
          </Tabs>
          <Button onClick={saveCase} style={{ marginBottom: 16, marginTop: 16 }} type="primary">
            <SaveOutlined rev={undefined}/>
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}



