import React, { useEffect, useMemo, useState } from 'react'
import { InputNumber } from 'antd'
import { INumber } from './interface'
import { getObjectFirst } from '../../pages/card-order/utils'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { reloadTablePayment } from "../../store/appeals/actions";

const createCase = createSelector(
  (state: any) => state.appeals,
  targetId => targetId,
  tablePayment => tablePayment
)

function sumPrice(products){
  return products.reduce((acc, item) => acc + getDataOfType(item, 'price', Number, 0) ,0)
}

function formatCurrency(value){
  return (value || 0).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', currencyDisplay: 'symbol' }).replace("₽", "");
}

function InputInteger({ value, onChange, UISchema }: INumber) {
  const { targetId, tablePayment } = useSelector(createCase)
  const [localValue, setLocalValue] = useState<any>()
  const dispatch = useDispatch()
  useEffect(() => {
    (async (targetId) => {
      const objectProducts = await getObjectFirst({ collectionName: 'order-specification', parentId: targetId })
      const objectServices = await getObjectFirst({ collectionName: 'order-services', parentId: targetId })
      const products = getDataOfType(objectProducts, 'data.attributes.table', Array, [])
      const services = getDataOfType(objectServices, 'data.attributes.table', Array, [])
      const sum = sumPrice(products) + sumPrice(services)
      setLocalValue( formatCurrency(sum) )
      if(tablePayment === null){
        dispatch(reloadTablePayment())
      }
    })(targetId)
  }, [targetId, tablePayment])

  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  return <InputNumber value={localValue} onChange={onChange} disabled={disabled} />
}

export default React.memo(InputInteger, (prev, next) => {
  if(prev.value === next.value){
    return true
  }
  return prev?.UISchema === next?.UISchema;
})
