import React, { useRef, useCallback, useState, useEffect } from 'react'
import { Modal } from 'antd'
import { DefaultJsonForm } from '../../components/default-json-form'
import { isFormDataRequired } from '../../../lib/utils/collections'
import { BrowserRouter } from 'react-router-dom'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { Provider, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { store } from '../../../browser-app'
const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  targetId => targetId 
)


const selectedSchema = {
  type: 'object',
  required: ['number', 'image'],
  properties: {
    'number': {
      type: 'number',
      title: 'Номер изделия',
    },
    'image': {
      type: 'string',
      format: 'file-s3',
      title: 'Изображение',
    },
  },
}

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)


export function modalClose(event){
  let target = event.target
  while (Boolean(target?.tagName) === true) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}


export function modalImage(): Promise<any> {
  let formData = {}
  return new Promise(resolve => Modal.confirm({
    icon: null,
    //width: 500,
    // maskStyle: { display: 'none' },
    //style: { display: 'none' },
    title: 'Добавить изображние',
    /* @ts-ignore */
    content: <Provider store={store}><Content onChange={value => { formData = value }} /></Provider>,
    onCancel: () => resolve(null),
    okButtonProps: { 
      onClick: (event) => {
        const isRequired = isFormDataRequired(formData, selectedSchema)
        if(isRequired){
          resolve(formData)
          modalClose(event)
        }
      }
    }
  }))
}

function Content({ onChange }){
  const formDataRef =  useRef({})
  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = formData
    onChange(formData)
  }, [onChange])
  const [UISchema, setUISchema] = useState<any>({})
  const { sessionData } = useSelector(createSession)
  const { targetId } = useSelector(createAppealsCurrentStep)

  useEffect(() => {
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, null)
    console.log(organizationId)
    const UISchema = {
      'number': {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '1',
        },
      },
      'image': {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '2',
        },
      },
    }
    ;(async (targetId) => {
      setUISchema(UISchema)
    })(targetId)
  }, [sessionData, targetId])

  return (
    // @ts-ignore
    <BrowserRouter>
      <DefaultJsonForm
        formData={formDataRef.current}
        schema={selectedSchema}
        onChange={localOnChange}
        UISchema={UISchema}
      />
    </BrowserRouter>
  )
}