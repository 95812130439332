import React, { useCallback, useMemo } from 'react'
import { IPhone } from './interface'
import { PhoneOutlined, PlusOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { modalPhone } from './mango-call'
import { modalOpen } from './modal'
import fetchAPI from '../../../lib/utils/fetch-api'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ru from 'react-phone-input-2/lang/ru.json'

function InputPhone({ value, onChange, UISchema } : IPhone) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const targetId = useMemo(() => UISchema?.options?.targetId, [UISchema])
  const handleChange = ({phone}) => {
    onChange(`+${phone}`)
  }

  const onCall = useCallback(() => {
    modalPhone({ phone: value })
  }, [value])

  async function createSource( source) {
    const userId = source['created-by']
    return fetchAPI(`/api/web-hooks/address-book/${userId}`, {
      method: 'POST',
      body: JSON.stringify({
        phone: source['entity_data'],
      }),
    }).then(item => item?.['data']?.['id'] || Date.now())
  }

  const onPlus = useCallback(async () => {
    const result: any = await modalOpen( )
    if (result === null) {
      return
    }
    const label = result?.['label']
    if (!Boolean(label)) {
      return
    }
    const clientId = await fetchAPI(`/api/collections/objects/${targetId}`).then(item => item?.['data']?.['owner-user'])
    await createSource({ 'created-by': clientId, 'entity_data': label })
  }, [targetId, createSource])

  const areaCodes = ['310', '311', '312', '313', '315', '318', '321', '324', '325', '326', '327', '336', '7172', '73622'];
  const areaCodesWithBrackets = areaCodes.map((code) => `(${code})`);

  return (
    <div style={{display: 'flex', width: 'max-content'}} className="input_phone_number">
      <PhoneInput
        enableSearch={true}
        searchPlaceholder={'Поиск'}
        localization={ru}
        country={'ru'}
        onlyCountries={['ru','kz','by','tj','uz','am','kg','az','md','tm']}
        value={value}
        disabled={disabled}
        containerStyle={{width: 'auto'}}
        inputStyle={{borderRadius: '2px',height: '32px'}}
        areaCodes={areaCodesWithBrackets}
        masks={{kz: '(...) ..-..-..'}}
        isValid={(number, country) => {
          if( number.length < 3)
            return true
          const regex = /\./g;
          const format = country?.['format']
          if( format ) {
            const length = (format.match(regex) || []).length;
            if (number.length === length) {
              handleChange({phone: number})
              return true
            }
          }
          return false
        }}
      />
      {Number.isInteger(targetId) && <Button onClick={onPlus} icon={<PlusOutlined rev={undefined}/>} />}
      <Button type="primary" icon={<PhoneOutlined rev={undefined}/>} onClick={onCall}  />
    </div>
  )
}

export default React.memo(InputPhone, (prev, next) => {
  const isNewValue = prev.value === next.value
  const isNewUISchema = prev?.UISchema === next?.UISchema
  return isNewValue && isNewUISchema;
})
