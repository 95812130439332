import type {ColumnsType} from 'antd/es/table'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {Popconfirm} from 'antd'
import {DataType} from './interface'
import {ButtonActionWrapper} from '../../components/list-operations/styles'
import {DeleteTwoTone} from '@ant-design/icons'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'
import {Stage} from '../../components/activity-timeline/styles'
import {formatPrice} from "../utils";
import {Value} from "../elements";


const ColumnId: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left'
  },
  {
    title: 'Стадия',
    dataIndex: 'stage',
    key: 'stage',
    width: 170,
    render: (_, {stage_code, stages}) => <StageContainer stage={stage_code} stages={stages}/>
  }
]

const ColumnOrganization: ColumnsType<DataType> = [
  {
    title: 'Организация',
    width: 200,
    dataIndex: 'organization',
    key: 'organization',
    sorter: true,
    render: val => <Value data={val}/>
  }
]

const ColumnsGeneral: ColumnsType<DataType> = [
  {
    title: 'Дата обращения',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 130,
    sorter: true,
    render: key => dayjs(key).format('DD.MM.YYYY HH:mm')
  },
  {
    title: 'ФИО менеджера',
    dataIndex: 'manager_name',
    key: 'manager_name',
    width: 120,
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Контактное лицо клиента',
    dataIndex: 'name',
    width: 160
  },
  {
    title: 'Телефон клиента',
    dataIndex: 'phone',
    key: 'phone',
    width: 120
  },
  {
    title: 'Регион',
    dataIndex: 'region',
    key: 'region',
    width: 120,
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Город',
    dataIndex: 'city',
    key: 'city',
    width: 120,
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Адрес объекта',
    dataIndex: 'address',
    key: 'address',
    width: 120,
    render: val => <Value data={val}/>
  },
  {
    title: 'Вид контакта',
    dataIndex: 'source_appeal',
    key: 'source_appeal',
    width: 200,
    render: val => <Value data={val}/>
  },
  {
    title: 'Сумма заказа',
    dataIndex: 'order_price',
    key: 'order_price',
    width: 130,
    sorter: true,
    render: key => formatPrice(key || 0)
  },
  {
    title: 'Номер производственного заказа',
    dataIndex: 'production_number',
    key: 'production_number',
    width: 180,
    render: val => <Value data={val}/>
  },
  {
    title: 'В работе менеджера с',
    dataIndex: 'order_created_at',
    key: 'order_created_at',
    width: 130,
    sorter: true,
    render: val => val ? dayjs(val).format('DD.MM.YYYY HH:mm') : <Value data={val}/>
  },
  {
    title: 'Причина отмены',
    width: 200,
    dataIndex: 'reason_cancel',
    key: 'reason_cancel',
    render: val => <Value data={val}/>
  }
]

const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title="Вы уверены, что хотите удалить эту запись?"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title="Удалить">
        <DeleteTwoTone rev={undefined} />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}

export const getColumns = (leader, sorter) => {
  // собирает колонки для разных ролей
  const columns = leader ? ColumnId.concat(ColumnOrganization, ColumnsGeneral, ColumnAction) : ColumnId.concat(ColumnsGeneral)
  // устанавливает порядок сортировки
  return columns.map(item => {
    if (item.key === sorter.sort_field)
      item.sortOrder = sorter.sort_order
    else
      delete item.sortOrder
    return item
  })
}


export function StageContainer({stage, stages}) {
  if (stages && stages.length) {
    const current = stages.find(item => item.code === stage)
    const style = getDataOfType(current, 'style', Object, {})
    return <Stage style={style}>{current.name}</Stage>
  }
  return <>...</>
}
