import React, { useEffect, useMemo, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Form } from 'antd'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getPreparedSchema } from '../../../lib/utils/schema'
import localizedServerErrors from '../../../lib/utils/localized-server-errors'
import { AdminCollectionItemConnector, IFromData, initialFormDataValues } from '../../connectors/admin-collection-item'
import B2BLayout from '../../layouts/b2b'
import { EditCollectionForm } from '../../forms/app-edit-collection-form'

function getExtractedSchema(value: Array<string>): Array<any> {
  return value ? value.map(item => item.split('/')) : [undefined]
}

export default function AdminEditCollectionPage() {
  const history = useHistory()
  const { id } = useParams() as any
  const [selectedSchemaForCascader, setSelectedSchemaForCascader] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [formData, setFormData] = useState<IFromData>(initialFormDataValues)
  const [form] = Form.useForm()
  const breadcrumbs = useMemo(() => [
    {
      href: '/admin',
      title: 'Администрирование',
    },
    {
      href: '/admin/collections',
      title: 'Справочники',
    },
    {
      title: formData.title
    }
  ], [formData.title])

  // @todo Extract to Redux Store to avoid double call
  useEffect(() => {
    const getCollectionData = async () => {
      try {
        const response = await fetchAPI(`/api/collections/${id}/metadata`)

        if (typeof response['data'] === 'object') {
          const data = response['data']
          const schema = getExtractedSchema(data['schema'])
          const formValue = {
            name: data['name'],
            title: data['title'],
            'post-function': data['post-function'],
            description: data['description'] || data['title'],
            group_id: data['group_id'],
            schema,
          }

          form.setFieldsValue(formValue)
          setFormData(formValue)
          setSelectedSchemaForCascader(schema)
          setPageErrors([])
        } else {
          setPageErrors([
            'Некорректный ответ сервера'
          ])
        }
      } catch(err) {
        setPageErrors([
          'Ошибка загрузки данных: ' + err
        ])
      }
    }

    getCollectionData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onFinish = async values => {
    //Удалим пробелы и табуляцию, если есть
    const newName = String(values.name).trim()
    if(values.name !== newName){
      values.name = newName
      form.setFieldsValue({ name: newName })
    }
    const preparedSchema = values.schema && getPreparedSchema(values.schema)
    const requestBody = {
      'data': {
        'name': values.name ? `${values.name}`.toLowerCase() : '',
        'title': values.title,
        'description': values.description,
        'post-function': values['post-function'] ?? '',
        'schema': preparedSchema,
        'group_id': values['group_id'],
      }
    }
    const result = await fetchAPI(`/api/collections/${values.name}/metadata`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
    if (result['data'] && result['data']['updated-at']) {
      setPageErrors([])
      history.push('/admin/collections')
    } else {
      const errors =
        ((Array.isArray(result['errors'])) && (result['errors'].length > 0))
        ? result['errors'].map(error => localizedServerErrors(error['detail']))
        : ['Неизвестная ошибка при выполнении запроса']
      setPageErrors(errors)
    }
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {AdminCollectionItemConnector({
        title: 'Редактирование справочника',
        isEdit: true,
        form,
        formData,
        setFormData,
        pageErrors,
        initSelectedSchemaForCascader: selectedSchemaForCascader,
        onFinish,
      })(EditCollectionForm)}
    </B2BLayout>
  )
}
