import React, {  useMemo, useCallback, useEffect, useState, forwardRef, useRef, useImperativeHandle } from 'react'
import Tabs from '../json-form-tabs'
import getTableTransformSchemaAndFormData, { getListObject } from '../../../lib/utils/get-table-transform-schema-and-form-data'
import ArrayTable from '../json-form-array-table'
import FormDataEditor from '../json-form-object-editor'
import AdaptorFormDataEditor from '../json-form-object-editor/adaptor'
import { IDefaultJsonFormProps } from './interface'

// По аналогии filter для Array. Вызывает filter([key, value], index)
export function objectFilter(object, cb){
  return Object.fromEntries(Object.entries(object).filter(cb))
}
// По аналогии map для Array. Вызывает map([key, value], index)
function objectMap(object: any, cb: (arg0: readonly [string, any]) => readonly [string, any]) {
  return Object.fromEntries(Object.entries(object).map(cb));
}

function DefaultJsonFormEditor({ formData, schema, UISchema, onChange, customTabs = {} }: IDefaultJsonFormProps, ref){
  const [localFormData, setLocalFormData] = useState(formData)
  const formRef = useRef<any>()
  console.log('UISchema', UISchema)
  useEffect(() => {
    setLocalFormData(formData)
  }, [formData, localFormData])

  const [ selectedProps, selectedObjectProps, formSchema ] = useMemo(() => {
    const { tables, newSchema } = getTableTransformSchemaAndFormData(schema, localFormData)
    const propertiesNotRootObject = objectFilter(newSchema.properties, ([, value]) => {
      return (value?.type === 'object') === false 
    })
    const objectProps = getListObject(schema)
    return [ tables, objectProps, { ...newSchema, properties: propertiesNotRootObject}]
  }, [schema, localFormData])

  const selectedVirtualTabs = useMemo(() => {
    const customTabsVirtual = objectFilter(customTabs, ([, value]) => value?.isVirtual)
    const customTabsMap = objectMap(customTabsVirtual, ([name, value]) => [name, { ...value, name }])
    return Object.values(customTabsMap)
  },[customTabs])

  const onFormData = useCallback(({ formData }) => {
    setLocalFormData(formData)
    onChange({ formData, schema })
  }, [onChange, schema])

  useImperativeHandle(ref, () => ({
    validateFields: params => formRef?.current?.validateFields(params)
  }))

  return (
    <>
      <FormDataEditor ref={formRef} formData={localFormData} schema={formSchema} onChange={onFormData} UISchema={UISchema} />
      {selectedProps.length > 0 ||
      selectedObjectProps.length > 0  ||
      selectedVirtualTabs.length  > 0 ? (
        <Tabs>
          {selectedProps.map(({ name }, i) => {
            const CustomTab = customTabs[name]
            if(name in customTabs){
              return <CustomTab name={name} schema={schema} formData={localFormData} onChange={onFormData} key={i}/>
            }
            return <ArrayTable name={name} schema={schema} formData={localFormData} onChange={onFormData} />
          })}
          {selectedObjectProps.map(({ name }) => {
            const CustomTab = customTabs[name]
            if(name in customTabs){
              return <CustomTab name={name} schema={schema} formData={localFormData} onChange={onFormData} />
            }
            return <AdaptorFormDataEditor name={name} schema={schema} formData={localFormData} onChange={onFormData} />
          })}
          {selectedVirtualTabs.map(({ name, schema, render: Render }, i) => (
            <Render name={name} schema={schema} key={i}/>
          ))}
        </Tabs>
      ) : null}
    </>
  )
}

export const DefaultJsonForm = forwardRef(DefaultJsonFormEditor)

