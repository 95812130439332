import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { getTaskStageId } from '../../../lib/utils/utils-task'
import { getWorkflows } from '../../store/appeals/utils-case'
import moment from 'moment'

export async function createTaskOrderStage({ parentId }){
  const name = 'tasks'
  const currentProfileId = await getCurrentUserProfileId()
  const taskStageId = await getTaskStageId('new')
  const comment = 'Заказ. Согласование заказа. Задача создана автоматически.'
  const date = moment().add(10, 'minute').toISOString()

  const attributes = {
    'date': date,
    'time': date,
    'responsible': [currentProfileId],
    'author': currentProfileId,
    'comment': comment,
    'stage': 'zamra-approval',
    'status': taskStageId
  }

  const requestBody = {
    'parent_id': parentId || 0,
    'data': {
      'name': '',
      'attributes': attributes,
      'metadata': {
        'schema': name,
      },
    },
  }

  const result = await fetchAPI(`/api/collections/${name}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
  return getDataOfType(result, 'data.id', [Number, String], null)
} 

async function getCurrentUserProfileId(){
  const result = await fetchAPI('/api/current-user-profile')
  return getDataOfType(result, 'data.id', [Number, String], null)
}

export async function getStages(type) {
  const nameStageCollection = 'pipelines'
  const responseStageCollection = await fetchAPI(`/api/collections/${nameStageCollection}/?access_key=axioma`)
  const stageCollecton = getDataOfType(responseStageCollection, 'data.data', Array, [])
  const stageObject = stageCollecton.filter(item => item['name'] === type)[0]
  if(typeof stageObject === 'undefined'){
    return
  }
  const stages = getDataOfType(stageObject, 'data.attributes.stage', Array, [])
  return stages
}

export async function getStagesGroup(type) {
  const workflows = await getWorkflows()
  const stages = getDataOfType(workflows, type, Array, []).map(item => ({
    name: item['name'],
    code: item['code'],
    group: item['group'] || item['name'],
    color: item['color'] || '',
    'font-weight': item['font-weight'],
    'font-color': item['font-color'],
  }))
  return stages
}

export function formDataToUrlParams (formData) {
  const urlParams = Object.entries(formData)
    .filter(([, value]) => value !== undefined)
    .reduce((acc, [key, value]) => `${acc}&${key}=${value}`, '')
    .slice(1)
  return urlParams
}