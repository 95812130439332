import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Alert, Skeleton, Space, Button } from 'antd'
import { JSONSchema7 } from 'json-schema'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import EventRibbonTab from '../../forms/case-event-ribbon-tab'
import { createUISchemaHorizontal } from '../../../lib/utils/grid-fild-orientation'
import ButtonAddNotes from '../../views/modal-add-notes'
import StepCollectionObject from '../../views/step-collection-object'
import { debounce } from 'lodash'
import { addActionsTimeLine, setCaseId, clearCaseStore, setAppealStage, initialCase, setTargetId  } from '../../store/appeals/actions'
import {  useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  appealsCurrentStep => appealsCurrentStep
)

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [{
  href: '/sales/audit',
  title: 'Аудит',
}, {
  href: `/sales/audit`,
  title:  'Список аудита',
}]

const name = 'card-audit'

export default function AdminNewObjectPage() {
  const { id } = useParams()  as any
  const dispatch = useDispatch()
  const parentIdRef = useRef<any>(null)
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [selectedSchema, setSelectedSchema] = useState<string[]>([])
  const formDataRef = useRef<any>(null)
  const matchedJSONSchemaRef = useRef<JSONSchema7 | null>(null)
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const { appealsCurrentStep } = useSelector(createAppealsCurrentStep)

  const custumTabs = useMemo(() => ({
    'general': {
      isVirtual: true,
      schema: {
        properties: {
          'general': {
            title: 'Общее',
            description: null,
          }
        }
      },
      render: () => <></>
    },
    'services': {
      isVirtual: true,
      schema: {
        properties: {
          'services': {
            title: 'Услуги',
            description: null,
          }
        }
      },
      render: () => <div />
    },
    'event-ribbon': EventRibbonTab
  }), [])
// eslint-disable-next-line
  const saveCase = useCallback(debounce(async formData => {
      const requestBody = {
        data: {
          name: '',
          title: '',
          attributes: formData,
          metadata: { schema: name }
        }
      }

      const result = await fetchAPI(`/api/collections/-/objects/${id}`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      })

      const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)
      if (updated === null) {
        console.warn('Ошибка сохранения записи')
      }
  }, 1000), [],)

  const getData = useCallback(async () => {
    const fields = [
      'title',
      'description',
      'type',
      'properties',
      'required',
      'created_by',
      'created_at',
      'updated_at',
    ].join(',')
    const responseSchemas = await fetchAPI(`/api/schemas?access_key=axioma&fields=${fields}`)
    const fetchedSchemas: JSONSchema7[] = getDataOfType(responseSchemas, 'data.data', Array, [])
    const selectedSchemas = fetchedSchemas.filter(item => item['name'] === name)
    const selectedSchema = (typeof selectedSchemas[0] === 'object') ? selectedSchemas[0] : null
    if (selectedSchema === null) {
      setPageErrors(['Некорректный ответ сервера при cхемы коллекции'])
      return
    }

    const responseObject = await fetchAPI(`/api/collections/objects/${id}`)
    const object = responseObject['data']
    const schema = object['data']['metadata']['schema']
    const formData = object['data']['attributes']
    const caseId = getDataOfType(formData, 'caseId', [Number, String], 0)
    const parentId = Number(object['parent-id'])
    dispatch(setTargetId({ targetId: parentId }))

    parentIdRef.current = parentId

    const parentIds = [parentId]
    if(caseId){
      parentIds.push(caseId)
    }

    if(Boolean(Number(parentId)) === true){
      dispatch(setCaseId({ caseId: parentId, parentIds }))
    }

    setSelectedSchema(schema)
    if(object['created-at']){
      const paramsDispatch = addActionsTimeLine({ type: 'info', items: [
        {
          date: object['created-at'],
          text: 'Создание записи'
        }
      ]})
      dispatch(paramsDispatch)
    }
    setPageErrors([])
    formDataRef.current = formData
    const stage = getDataOfType(formData, 'stage', [String, Number], 0)
    matchedJSONSchemaRef.current = selectedSchema
    const UISchema = createUISchemaHorizontal(selectedSchema)
    setUISchema(UISchema)
    dispatch(setAppealStage({ step: stage }))
    setIsDataLoading(false)
  }, [dispatch, id])

  useEffect(() => { getData() }, [getData])


  useEffect(() => {
    dispatch(initialCase({ targetId: null, cardName: 'audit'}))
    const result = setCaseId({ caseId: id })
    dispatch(result)
    return () => { dispatch(clearCaseStore()) }
}, [id, dispatch])

  const onStage = useCallback(formData => {
    const newformData = { ...formDataRef.current, ...formData }
    formDataRef.current = newformData
    saveCase(newformData)
    dispatch(setAppealStage({ step: formData['stage'], type: 'audit' }))
  }, [saveCase, dispatch])

  const onFormData = useCallback(
    ({ formData }) => {
      const nextFormData = { ...formData, stage: appealsCurrentStep }
      formDataRef.current = nextFormData
      saveCase(nextFormData)
    },
    [saveCase, appealsCurrentStep],
  )

  const ButtonsStage = useCallback(() => {
    if (appealsCurrentStep === 0) {
      return <Button onClick={() => onStage({ stage: 1 })}>Аудит произведен</Button>
    }
    return <></>
  }, [appealsCurrentStep, onStage])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Аудит <span>{`№ ${id}`}</span></MapTitle>
          <Space size={'small'}>
            <ButtonAddNotes />
            <ButtonsStage />
          </Space>
        </TaskWrapper>
        <MapContent>
            <StepCollectionObject type="audit" />
            <Form
              {...formItemLayout}
              layout="horizontal"
              form={form}
              fields={[
                {
                  name: 'schema',
                  value: selectedSchema,
                },
              ]}
              scrollToFirstError={true}
            >
              {pageErrors.length > 0 ? (
                <AlertWrapper>
                  <Alert
                    message="При выполнении операции возникла ошибка:"
                    showIcon
                    type="error"
                    description={pageErrors.join('. ')}
                  />
                </AlertWrapper>
              ) : null}
              {isDataLoading === false ? (
                <Form.Item>
                  {matchedJSONSchemaRef !== null && matchedJSONSchemaRef.current && UISchema && (
                    <DefaultJsonForm
                      formData={formDataRef.current}
                      schema={matchedJSONSchemaRef.current}
                      onChange={onFormData}
                      customTabs={custumTabs}
                      UISchema={UISchema}
                    />
                  )}
                </Form.Item>
              ) : (
                <Skeleton active />
              )}
            </Form>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
