import React from 'react'
import { Tabs } from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

function getPropsChild({ props }){
  const { name, schema } = props
  return getDataOfType(schema, ['properties', name], Object, {})
}

function TableTabs({ children }) {
  if(Boolean(children?.length) === false) {
    return null
  }
  return (
    <Tabs type="card" size="small">
      {React.Children.map(children, (child, index) => {
        const { title, description } = getPropsChild(child)
        return (
          <Tabs.TabPane tab={title} key={index}>
            <p>{description}</p>
            {child}
          </Tabs.TabPane>
        )
      })}
    </Tabs>
  )
}
export default TableTabs