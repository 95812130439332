export const vacancy = {
  "type": "string",
  "title": "Вакансия",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

export const resource = {
  "type": "string",
  "title": "Ресурс",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

export const status = {
  "type": "string",
  "title": "Статус",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

export const gender = {
  "type": "string",
  "title": "Пол",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

export const cameRequest = {
  "type": "string",
  "title": "Пришел на собеседование",
  "enum": [
    1,
    0
  ],
  "oneOf": [
    {
      const: 1,
      title: "Да"
    },
    {
      const: 0,
      title: "Нет"
    },
  ],
  "view": {
    "name": "select-from-enum"
  }
}

export const workComment = {
  "type": "string",
  "title": "Конечный результат о выходе на работу",
  "enum": [
    1,
    0
  ],
  "oneOf": [
    {
      const: 1,
      title: "Да"
    },
    {
      const: 0,
      title: "Нет"
    },
  ],
  "view": {
    "name": "select-from-enum"
  }
}

export const resultTrialPeriod = {
  "type": "string",
  "title": "Результат по истечению испытательно срока 2 недели",
  "enum": [
    1,
    0
  ],
  "oneOf": [
    {
      const: 1,
      title: "Да"
    },
    {
      const: 0,
      title: "Нет"
    },
  ],
  "view": {
    "name": "select-from-enum"
  }
}

export const city_id = {
  "type": "string",
  "title": "Город",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}

export const region_id = {
  "type": "string",
  "title": "Район",
  "enum": [],
  "oneOf": [],
  "view": {
    "name": "select-from-enum"
  }
}