import React, { useMemo } from 'react'
import { Stage } from '../activity-timeline/styles'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

function StageCell(props) {
  const [text, color, fontWeight, fontColor] = useMemo(() => {
    const stageIndex = getDataOfType(props, 'stage', [Number, String], 0)
    const name = getDataOfType(props, `stages[${stageIndex}].group`, String, '')
    const color = getDataOfType(props, `stages[${stageIndex}].color`, String, null)
    const fontWeight = getDataOfType(props, `stages[${stageIndex}].font-weight`, String, null)
    const fontColor = getDataOfType(props, `stages[${stageIndex}].font-color`, String, null)
    return [name, color, fontWeight, fontColor]
  },[props])

  return (<Stage fontWeight={fontWeight} fontColor={fontColor}  color={color}>{text}</Stage>)
}

export default StageCell
