import styled, { StyledComponent } from 'styled-components'
import { Button } from 'antd'

interface IHeadingStyled {
  disabled?: boolean
}

export const ContentWrapper: StyledComponent<'div', any, IHeadingStyled> = styled.div<IHeadingStyled>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 32px;
  flex-direction: row;
  padding: 0 !important;
  border-radius: 3px;
  border-color: #d9d9d9;
  border-style: solid;
  border-top-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-right-width: 1px;
  background-color: ${props => props.disabled ? '#f5f5f5': 'unset'};

  &:hover, &:focus {
    border-color: ${props => props.disabled ? '#d9d9d9': '#40a9ff'};
  }
  &:hover svg, &:focus svg {
    visibility: visible;
  }
`


export const FileLink: StyledComponent<'a', any> = styled.a`
  width: 165px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const FileNoLink = styled.span`
  white-space: nowrap;
  color: #d9d9d9;
`

interface FileLinkWrapper {
  isMobile?: boolean
}

export const FileLinkWrapper: StyledComponent<'div', any, FileLinkWrapper> = styled.div<FileLinkWrapper>`
  width: 100%;
  white-space: nowrap;
  align-items: center;
  padding-left: 10px !important;
  padding-right: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  svg {
    font-size: 16px;
    color: #d9d9d9;
    visibility: ${({ isMobile = false }) => (isMobile ? 'visible' : 'hidden')};
  }
`;

export const FileButton = styled(Button)`
  width: 32px;
  padding: 0 !important;
  border-color: inherit !important;
  border-right-width: 0 !important;
  &:hover, &:focus {
    border-right-width: 0 !important;
  }
`;
