import { JSONSchema7 } from 'json-schema'
// По аналогии filter для Array. Вызывает filter([key, value], index)
export function objectFilter(object = {}, cb){
  return Object.fromEntries(Object.entries(object).filter(cb))
}
// По аналогии map для Array. Вызывает map([key, value], index)
export function objectMap(object, cb){
  //@ts-ignore
  return Object.fromEntries(Object.entries(object).map(cb))
}
// Функция итератор, оставляет только таблицы
function iteratorTableFilter([,value]) {
  const isArray = value?.type === 'array'
  const isTable = value?.format === 'table'
  const isItemsObject = value?.items?.type === 'object'
  return isArray && isItemsObject && isTable
}
// Функция итератор, оставляет только объекты
function iteratorObjectFilter([,value]) {
  const isObject = value?.type === 'object'
  return isObject
}
// Функция итератор, преобразует поля схемы в колонки для таблицы Ант Дизайн
// И так же преобразует данные для таблицы
function iteratorTableMap([key, value], formData, required) {
  const requiredItems: string[] = value?.items?.required || []
  const properties = objectMap(value.items.properties, ([key, value]) => {
    return [
      key,
      {
        editable: true,
        ...value,
        dataIndex: key,
        required: requiredItems.includes(key),
      },
    ]
  })
  const data: any[] = []
  if (formData) {
    const value: any[] = Array.isArray(formData[key]) ? formData[key] : []
    value.forEach((item, index) => {
      data.push(({ ...item, key: index }))
    })
  }

  return [
    key,
    {
      name: key,
      title: value?.title || null,
      description: value?.description || null,
      columns: Object.values(properties),
      data: data,
      required: required?.includes(key) ?? false,
    },
  ]
}
// eslint-disable-next-line
export default function (schema, formData) {
  const tables = objectFilter(schema.properties, iteratorTableFilter)
  const tablesFormat = objectMap(tables, item => iteratorTableMap(item, formData, schema.required))
  const tablesKeys: string[] = Object.keys(tables)
  const newSchema: JSONSchema7 = {
    ...schema,
    properties: objectFilter(schema.properties, ([key]) => tablesKeys.includes(key) === false)
  }
  return { newSchema, tables: Object.values(tablesFormat) }
}

export function getListObject(schema: any){
  const objects = objectFilter(schema.properties, iteratorObjectFilter)
  const objectsFormDataList = Object.entries(objects).map(([name, schema]: any) => ({ name, schema }))
  return objectsFormDataList
}

