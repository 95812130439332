import React, { useState, useCallback, useEffect, useRef } from 'react'
import { Input, Button, Modal } from 'antd'
import moment from 'moment'
import { RibonWrapper } from './styles'
import { addActionsTimeLine } from '../../store/appeals/actions'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { insetNoteToCollection } from './utils-notes'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

const createCase = createSelector(
  (state: any) => state.appeals,
  targetId => targetId
)

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)


function ModalAddNotes() {
  const { targetId } = useSelector(createCase)
  const { sessionData } = useSelector(createSession)
  const inputRef = useRef<any>(null)
  const dispatch = useDispatch()
  const [value, setValue] = useState<string>('')
  const [isVisible, setVisible] = useState<boolean>(false)
  useEffect(()=> {
    setTimeout(inputRef.current?.focus, 200)
  }, [isVisible])

  const onSaveData = useCallback(async () => {
    const date = moment().toISOString()
    setValue('')
    setVisible(false)
    const authorId = getDataOfType(sessionData, 'user.id', Number, 0)
    const id = await insetNoteToCollection({ date, content: value, author: authorId }, targetId)
    if (id) {
      const paramsDispatch = addActionsTimeLine({ type: 'note', items: [{
        date,
        id,
        author: authorId,
        content: value
      }]})
      dispatch(paramsDispatch)
      return
    }
    console.warn('Ошибка записи в объект')
  }, [value, dispatch, targetId, sessionData])
  return (
    <>
      <Button onClick={() => setVisible(true)}>Добавить заметку</Button>
      <Modal
        title="Добавить заметку"
        visible={isVisible}
        onCancel={() => setVisible(false)}
        footer={[
          <Button key="back" onClick={() => setVisible(false)}>
            Отмена
          </Button>,
          <Button key="submit" onClick={onSaveData}>
            Добавить заметку
          </Button>,
        ]}
      >
        <RibonWrapper>
          <Input.TextArea
            placeholder="Введите заметку"
            autoSize={{ minRows: 7, maxRows: 7 }}
            value={value}
            onChange={({ target }) => setValue(target.value)}
            ref={inputRef}
          />
        </RibonWrapper>
      </Modal>
    </>
  )
}

export default ModalAddNotes
