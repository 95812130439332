import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Form, Alert, Skeleton, Button, Tabs, Radio } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import { createUISchemaVertical } from '../../../lib/utils/grid-fild-orientation'
import fieldsSchema from './json-schema.json'
import TableSelected from './table-selected'
import RatingDiler  from '../../connectors/rating-diler'
import TabReport from './tab-report'
import fieldsSchema3 from './json-schema3.json'
import RequisiteTable from './requisite'
import TabMail from './tab-mail'


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [
  {
    href: '/organizations',
    title: 'Организации',
  },
  {
    href: `/organizations`,
    title: 'Список организаций',
  },
]

function concatSchema(s1, s2){
  const properties1 = getDataOfType(s1, 'properties', Object, {})
  const properties2 = getDataOfType(s2, 'properties', Object, {})
  return { 
    ...s2,
    ...s1,
    properties: { ...properties1, ...properties2 }
  }
}


export default function AdminNewObjectPage() {
  const { id } = useParams()  as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [selectedUsers, setSelectedUsers] = useState<any[]>([])
  const [profilesType, setProfilesType] = useState<any[]>([])
  const [profileTypeId, setProfileTypeId] = useState<any>(null)
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)

  const saveCase = useCallback(
    async () => {

      const formData = { ...formDataRef.current }
      const organizationData = {
        'title': formData['title'] ||  formData['name'],
        'type': formData['organization-type']
      }
      delete formData['title']
      delete formData['organization-type']

      await fetchAPI(`/api/users-in-organizations/${id}?users-ids=${selectedUsers.join(',')}`, { method: 'PUT' })
      const orgres = await fetchAPI(`/api/organization-create-or-update/${id}`, { method: 'POST', body: JSON.stringify({
        'profile-type-id': profileTypeId,
        'profile-data': formDataRef.current,
        'organization-data': organizationData
      })})

      const nextUserId = orgres?.['data']?.['organization-id']
      await fetchAPI(`/api/web-hooks/mango-add-dealer/${nextUserId}`, { method: 'POST', body: JSON.stringify({ })})

      history.push('/organizations')
    },
    [history, selectedUsers, id, profileTypeId]
  ) 

  const getData = useCallback(async () => {
    {
      const result = await fetchAPI(`/api/profiles-types?type=organization`)
      const profilesTypes = getDataOfType(result, 'data', Array, [])
      setProfilesType(profilesTypes)
      {
        const result = await fetchAPI(`/api/profiles?type=organization&organization-id=${id}`)
        const data = getDataOfType(result, 'data', Array, [])       
        const profileData = getDataOfType(data, '[0].profile-data', Object, {})
        const profileTypeId = Number(getDataOfType(data, '[0].profile-type', [String, Number], 3))
        const selectedSchema = getDataOfType(data, '[0].profile-schema', Number, fieldsSchema3)
        {
          const result = await fetchAPI(`/api/organizations/${id}`)
          const title = getDataOfType(result, 'data.title', String, '') 
          const type = getDataOfType(result, 'data.type', [String, Number], '') 
          formDataRef.current = { ...profileData, 'title': title,  'organization-type': type}
        }
        setProfileTypeId(profileTypeId)
        setMatchedJSONSchema(concatSchema(fieldsSchema, selectedSchema))
        const UISchema = createUISchemaVertical(concatSchema(fieldsSchema, selectedSchema))
        setUISchema(UISchema)
      }
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData()
      .catch(e => {console.error(e)})
  }, [getData])

  const onFormData = useCallback(
    ({ formData }) => {
      formDataRef.current = formData
    }, []
  )

  const radioStyle = {
    display: 'block',
    height: '30px',
    lineHeight: '30px',
  }

  const onSchema = useCallback(({ target }) => {
    const id = target?.value
    const data = profilesType.find(item => item['id'] === id)
    const selectedSchema = getDataOfType(data, 'profile-schema', Object, {})
    setMatchedJSONSchema(concatSchema(fieldsSchema, selectedSchema))
    setProfileTypeId(Number(id))
  }, [profilesType])

  const onTableSelected = useCallback(selected => setSelectedUsers(selected), [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>

      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            Организация <span>{`№ ${id}`}</span>
          </MapTitle>
        </TaskWrapper>
        <MapContent>
          <div
            style={{
              width: '100%',
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            <div>
              {isDataLoading || (
                <Radio.Group onChange={onSchema} value={profileTypeId} style={{ display: 'none' }}>
                  {profilesType.map(item => (
                    <Radio style={radioStyle} value={item['id']}>
                      {item['title']}
                    </Radio>
                  ))}
                </Radio.Group>
              )}
            </div>
          </div>
          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {!isDataLoading ? (
              UISchema && (
                <DefaultJsonForm
                  formData={formDataRef.current}
                  schema={matchedJSONSchema}
                  onChange={onFormData}
                  UISchema={UISchema}
                />
              )
            ) : (
              <Skeleton active />
            )}
          </Form>
          <Tabs type="card">
            <Tabs.TabPane tab="Пользователи" key="1">
              <TableSelected
                onChange={onTableSelected}
                id={id}
              />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Расходы и доходы" key="2">
              <TabReport />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Рейтинг" key="3">
              <RatingDiler id={id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Банковские реквизиты" key="4">
              <RequisiteTable rData={getDataOfType(formDataRef.current, 'requisite', Array, [])} onChange={requisite => {
                Object.assign(formDataRef.current, { 'requisite': requisite})
              }} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Почта" key="5">
              <TabMail
                onChange={onTableSelected}
                id={id}
              />
            </Tabs.TabPane>
          </Tabs>
          <Button onClick={saveCase} style={{ marginBottom: 16, marginTop: 16 }} type="primary">
            <SaveOutlined rev={undefined}/>
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
