import fetchAPI from './fetch-api'
import { getDataOfType } from './get-data-of-type'
import dayjs from 'dayjs'

/**
 * 
 * @param orderId 
 * @param stageCode 
 * "manufacture": Производство
 * "loading": "Погрузка"
 * "shipment": Клиент
 * "mounting": "Монтаж"
 */

/*
export async function isProductsSpcificationStage (orderId, stageCode) {
  const response = await fetchAPI(`/api/collections/order-specification/?parentId=${orderId}&access_key=axioma`)
  const first = getDataOfType(response, 'data.data[0].data.attributes.stages', Object, {})
  const productsStage = getDataOfType(first, stageCode, Array, [])
  return  Boolean(productsStage.length)
}
*/
export async function isProductsSpcificationStage (orderId, stageCode) {
  const response = await fetchAPI(`/api/collections/order-specification/?parentId=${orderId}&access_key=axioma`)
  const table = getDataOfType(response, 'data.data[0].data.attributes.table', Array, [])
  if (table.length === 0) {
    return false
  }
  return table.map(item => dayjs(item?.[stageCode] ?? null).isValid()).includes(false) === false
}

export async function setStageFromCard({ cardName, stageCode, targetId, isHistory = true, addFormData = null }: any) {
  return fetchAPI('/api/web-hooks/set-card-stage', { method: 'POST', body: JSON.stringify({
    'card-name': cardName,
    'stage-code': stageCode,
    'target-id': targetId,
    'is-history': isHistory,
    'add-form-data': addFormData
  })})
}

export async function setCardCancel({ cardName, stageCode, targetId, isHistory = true, addFormData = null, commnet = {}, id = 0 }: any) {
  return fetchAPI('/api/web-hooks/set-card-cancel', { method: 'POST', body: JSON.stringify({
    'card-name': cardName,
    'stage-code': stageCode,
    'target-id': targetId,
    'is-history': isHistory,
    'add-form-data': addFormData,
    'commnet': commnet,
    id
  })})
}