import React, { useState, useEffect, useCallback, useMemo, useContext } from 'react'
import { Table, Button } from 'antd'
import { useHistory } from 'react-router-dom'
import modalAddUser from '../card-employee/modal-add'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'


const TableSelectedContext = React.createContext({})

function ButtonEdit({ id }) {
  const props = useContext<any>(TableSelectedContext)
  return <Button onClick={() => props.editRow(id)}>Редактировать</Button>
}

function TableSelected({   onChange, id }){
  const history = useHistory()
  const columns = useMemo(() => [{
      title: 'Логин (e-mail или телефон)',
      width: 290,
      key: 'login',
      dataIndex: 'login'
    },
    {
      title: 'ФИО',
      width: 290,
      key: 'login',
      dataIndex: 'login',
      render: (_, item) => item?.['profile-data']?.['name'] || ''
    },
    {
      title: null,
      width: 290,
      key: 'edit',
      dataIndex: 'id',
      render: id => <ButtonEdit id={id}/> ,
    }
  ], [])

  
				
				
const [dataSource, setUsers] = useState<any[]>([])
const [selected, setSelectedUsers] = useState<any[]>([])

const getData = useCallback(async () => {
    const result = await fetchAPI(`/api/users?filter[organization]=${id}`)
    const data = getDataOfType(result, 'data', Array, [])
    setSelectedUsers(data.map(item => item['id']))
    // const result2 = await fetchAPI(`/users`)
    //const data2 = getDataOfType(result2, 'data', Array, []).filter(item => data.includes(item['id']))
    setUsers(data)
    //console.log({ result, data2 })
}, [id])

useEffect(() => {
  getData()
}, [getData])

  const [selectedRowKeys, setSelectedRowKeys] = useState<any[]>(selected)
  const onSelect = useCallback(({ id }, isSelected, selectedRows) =>  {
    const selectedRowsKeys = selectedRows.map(item => item['id'])
    const result = isSelected
    ? selectedRowsKeys.concat(id)
    : selectedRowsKeys.filter(key => key !== id)
    onChange([...new Set(result)])
  }, [onChange])
  useEffect(() => {
    setSelectedRowKeys(selected)
  },[selected])
  const rowSelection = useMemo(() => ({
    selectedRowKeys,
    onSelect,
    hideSelectAll: true
  }), [selectedRowKeys, onSelect])
  const onHeaderRow = useCallback((column: any) => ({
    style: {
      fontSize: (column.key && column.key.substr(-3) === '-at') ? 9 : 13,
    }
  }), [])
  const loading = useMemo(() => Boolean(dataSource?.length) === false, [dataSource])

  const contextProps = useMemo(() => ({
    editRow: (userId) => {
      history.push(`/employees/${userId}?back=organizations/${id}`)
    }
  }), [history, id])

  const addUser = useCallback(async () => {
    const result = await modalAddUser(id)
    console.log(result)

    setTimeout(() => {
      getData()
    }, 2000)
    
  }, [id, getData])

  return (<>
  <Button onClick={addUser}>Добавить сотрудника</Button>
  
  <TableSelectedContext.Provider value={contextProps}>
    <Table
      size="small"
      rowKey="id"
      pagination={false}
      rowSelection={rowSelection}
      columns={columns}
      dataSource={dataSource}
      loading={loading}
      onHeaderRow={onHeaderRow}
    />
  </TableSelectedContext.Provider>
  </>)
}

export default TableSelected
