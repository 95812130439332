import styled, { StyledComponent } from 'styled-components'

export const BackToMeasurementsList: StyledComponent<'a', any> = styled.a`
  font-size: 19px;
  color: #404040;
  padding: 21px 0;
  text-align: left;
`

export const PhoneButton: StyledComponent<'a', any> = styled.a`
  display: inline-flex;
`

export const FieldGroup: StyledComponent<'div', any> = styled.div``

export const FieldName = styled.span`
  font-weight: bold;
  margin-right: 10px;
`

export const FieldContent: StyledComponent<'div', any> = styled.div`
  display: flex;
  margin: 10px 0;
`

export const ActionListItemText: StyledComponent<'span', any> = styled.span`
  white-space: nowrap;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  a {
    width: 100%;
  }
  .am-button span {
    font-size: 14px;
  }
`
