import React, { useCallback, useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { SchemaEditor } from '../../components/app-schema-editor'

export default function AdminEditSchemaPage() {
  const history = useHistory()
  const { id } = useParams() as any
  const [formData, setFormData] = useState<any>()
  const breadcrumbs = [
    {
      href: '/admin',
      title: 'Администрирование',
    },
    {
      href: '/admin/schemas',
      title: 'Структуры (схемы)',
    },
  ]

  useEffect(() => {
      fetchAPI(`/api/schemas/${id}/metadata`)
        .then((response) => {
          if (typeof response['data'] === 'object') {
            const data = response['data']
            setFormData({
              id,
              name: data['name'],
              title: data['title'],
              description: data['description'],
              schema: {
                type: data['type'],
                required: data['required'],
                properties: data['properties'],
              },
            })
          }
        })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnSave = useCallback(() => {
    history.push('/admin/schemas')
  }, [history])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <SchemaEditor title="Редактирование структуры (схемы)" formData={formData} isEdit={true} onSave={handleOnSave} />
    </B2BLayout>
  )
}
