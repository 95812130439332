import React, { useRef, useEffect, useCallback } from 'react'
import { Checkbox, Input, Tooltip } from 'antd'
import { debounce } from 'lodash'
import { getDataOfType } from '../../../../../../lib/utils/get-data-of-type'

const _fn = () => null

function InputTitle({ value, checked = false, placeholder = 'Заголовок', onChange, onChecked = _fn }) {
  const ref = useRef<any>(null)
  useEffect(()=> {
    const refType = typeof getDataOfType(ref, 'current.input.value', String, null)
    if(refType === 'string'){
      ref.current.input.value = value
    }
  }, [value])
// eslint-disable-next-line
  const localOnChange = useCallback(debounce(() => {
    const refType = typeof getDataOfType(ref, 'current.input.value', String, null)
    if(refType === 'string'){
      onChange(ref.current.input.value)
    }
  }, 500), [onChange])

  return (
    <Input
      ref={ref}
      addonAfter={
        <Tooltip placement="top" title={'Обязательно к заполнению'}>
          <Checkbox onChange={onChecked} checked={checked} />
        </Tooltip>
      }
      placeholder={placeholder}
      onChange={localOnChange}
      defaultValue={value}
    />
  )
}

export default InputTitle