import type {ColumnsType} from 'antd/es/table'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {DataType} from './interface'
import {formatPrice} from '../../utils'
import {Popconfirm} from 'antd'
import {ButtonActionWrapper} from '../../../components/list-operations/styles'
import {DeleteTwoTone} from '@ant-design/icons'
import {Value} from '../../elements'

const Columns: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left'
  },
  {
    title: 'ФИО',
    dataIndex: 'name',
    key: 'name',
    width: 170,
  },
  {
    title: 'Телефон',
    width: 120,
    dataIndex: 'phone',
    key: 'phone',
    render: val => <Value data={val}/>
  },
  {
    title: 'Адрес',
    dataIndex: 'address',
    key: 'address',
    width: 130,
  },
  {
    title: 'Кол-во заказов',
    dataIndex: 'orders_count',
    key: 'orders_count',
    width: 120,
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Сумма заказов',
    dataIndex: 'products_total',
    key: 'products_total',
    width: 120,
    sorter: true,
    render: val => formatPrice(val || 0)
  },
  {
    title: 'Дата регистрации',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 120,
    sorter: true,
    render: key => dayjs(key).format('DD.MM.YYYY HH:mm')
  }
]

const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title="Вы уверены?"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title="Удалить">
        <DeleteTwoTone rev={undefined} />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}

export const getColumns = (leader, sorter) => {
  // собирает колонки для разных ролей
  const columns = leader ? Columns.concat(ColumnAction) : Columns
  // Формирует колонки и устанавливает порядок сортировки
  return columns.map(item => {
    if (item.key === sorter.sort_field)
      item.sortOrder = sorter.sort_order
    else
      delete item.sortOrder
    return item
  })
}

