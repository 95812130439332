import React, { useState, useEffect, useMemo, useCallback } from 'react'
import B2BLayout from '../../layouts/b2b'
import { Table } from 'antd'
import { getColumns } from './columns'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import fetchAPI from '../../../lib/utils/fetch-api'
import { useHistory } from 'react-router-dom'
import FiltersList from '../../components/v2/filters'

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData,
)

type TablePaginationPosition = 'bottomCenter'

const OrdersList = () => {
  const history = useHistory()
  const [data, setData] = useState([])
  const [availableFilters, setAvailableFilters] = useState({})
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters] = useState<any>({
    phone: urlParams?.phone,
    manager: urlParams.manager ? +urlParams.manager : null,
    id: urlParams.id ? +urlParams.id : null,
    organization: urlParams.organization ? +urlParams.organization : null,
    stage: urlParams?.stage,
    dates: urlParams.dates,
  })
  const { sessionData } = useSelector(createSession)
  const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1
  const [loading, setLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [stages, setStages] = useState<Record<any, any> | null>(null)
  const [sorter, setSorter] = useState<any>({})
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 10,
    position: [bottomCenter],
    showSizeChanger: false,
  })

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => {
    return [
      {
        href: '/cases',
        title: 'Продажи',
      },
      {
        href: location.pathname,
        title: 'Заказы',
      },
    ]
  }, [])

  useEffect(() => {
    try {
      /* Пытаюсь получить стадии и фильтры */
      getStages()
      getAvailableFilters()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])

  useEffect(() => {
    getData()
  }, [stages])

  /**
   * Получает стадии
   */
  const getStages = useCallback(() => {
    fetchAPI('/api/get-stages/cards_order')
      .then(data => {
        setStages(data)
      })
      .catch(() => {
        console.error('Не удалось получить стадии')
      })
  }, [])

  /**
   * Удаляет запись
   */
  const deleteRecord = useCallback(currentId => {
    fetchAPI(`/api/delete/order/${currentId}`, { method: 'DELETE' })
      .then(() => {
        getStages()
      })
      .catch(() => {
        console.error('Не удалось удалить запись')
      })
  }, [])

  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(
    newState => {
      setPagination({
        ...pagination,
        ...newState,
      })
    }, [pagination, setPagination])

  /**
   * Получает список доступных фильтров для обращений
   */
  const getAvailableFilters = useCallback(() => {
    fetchAPI('/api/get-filter-orders')
      .then(data => {
        setAvailableFilters(data)
      })
      .catch(() => {
        console.error('Не удалось получить список доступных фильтров')
      })
  }, [])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    try {
      setLoading(true)
      const urlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI('/api/list/order', {
        method: 'POST',
        body: JSON.stringify(urlParams),
      }).then(({ data, pagination, sorter }) => {
        if (data) {
          setData(
            data.map(item => ({
              ...item,
              deleteRecord,
              stages,
            })),
          )
          updatePagination(pagination)
          setSorter(sorter)
        }
        setLoading(false)
      })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [deleteRecord, stages])

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    let current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current,
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({ search: urlParams })
    getData()
  }

  /**
   * Отправляет на страницу деталки
   * @param record
   */
  const handleRowClick = record => {
    const { id } = record
    history.push(`/appeal/${id}`)
  }

  /**
   * Отслеживает состояние фильтров
   * @param filters
   */
  const changeFiltersHandler = useCallback(filters => {
    const newUrlParams = new URLSearchParams(filters).toString()
    history.push({ search: newUrlParams })
    getData()
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <FiltersList
        filters={[
          {
            name: 'id',
            placeholder: 'ID',
            type: 'input-integer',
            value: currentFilters.id || undefined,
            options: {
              width: '170px',
            },
          },
          {
            name: 'phone',
            type: 'phone',
            value: currentFilters.phone || undefined,
          },
          {
            name: 'stage',
            placeholder: 'Фильтр по стадии',
            type: 'select',
            value: currentFilters.stage || undefined,
          },
          {
            name: 'manager',
            placeholder: 'Фильтр по менеджеру',
            type: 'select',
            value: currentFilters.manager || undefined,
            options: {
              enum: [{ value: 'some', label: 'some' }],
            },
          },
          {
            name: 'dates',
            type: 'dates',
          },
        ]}
        onChange={changeFiltersHandler}
      />
      <Table
        showSorterTooltip={{ overlay: 'Нажмите для сортировки', placement: 'bottom' }}
        columns={getColumns(isLeader, sorter)}
        rowKey={r => r?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={record => ({ onClick: () => handleRowClick(record) })}
        scroll={{
          x: 'max-content',
          y: '72vh',
        }}
        size="small"
      />
    </B2BLayout>
  )
}

export default OrdersList
