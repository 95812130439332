import React, {useCallback, useEffect, useMemo, useState} from 'react'
import B2BLayout from '../../../layouts/b2b'
import {MapTitle} from '../../card-appeal/styles'
import {Button, Form, Popconfirm, Select, Table, Typography} from 'antd'
import {EditOutlined, PlusOutlined, SaveOutlined, StopOutlined} from '@ant-design/icons'
import fetchAPI from '../../../../lib/utils/fetch-api'
import {ButtonActionWrapper} from '../../../components/list-operations/styles'
import {useHistory} from 'react-router-dom'
import {objectToUrl} from '../../utils'
import localEnums from './enums.json'

const {Option} = Select

const requiredFields = [
  'role_id',
  'card_name',
  'stage',
  'field',
  'state'
]

const Collections = () => {
  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => (
    [{
      href: '/admin',
      title: 'Админка',
    }]
  ), [])

  const [form] = Form.useForm()
  const [data, setData] = useState<any>([])
  const [stages, setStages] = useState<any>([])
  const [roles, setRoles] = useState<any>([])
  const [editingKey, setEditingKey] = useState<null | number>(null)
  const [loading, setLoading] = useState(false)
  const [sorter, setSorter] = useState<any>({})
  const history = useHistory()
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 10,
    position: [bottomCenter],
    showSizeChanger: false
  })
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters, setCurrentFilters] = useState<any>({
    card: urlParams?.card,
    role_id: urlParams?.role_id,
    field: urlParams?.field,
    state: urlParams?.state,
    stage: urlParams?.stage,
  })

  const isEditing = record => record.id === editingKey

  const EditableCell = ({
    editing,
    dataIndex,
    title,
    inputType,
    record,
    index,
    children,
    ...restProps
  }) => {

    const getDataEnums = name => {
      if (name === 'role_id')
        return Object.keys(roles).map( val => ({ id: roles[val].id, name: roles[val].name }) )
      if (name === 'card_name') {
        return Object.keys(localEnums.card_type).map(val => ({id: val, name: localEnums.card_type[val]}))
      }
      if (name === 'stage') {
        const card_name = form.getFieldValue('card_name')
        return (card_name && stages[card_name])
          ? getCardStages( card_name ).map(val => ({id: val.value, name: val.label}))
          : []
      }
      if (name === 'field') {
        const card_name = form.getFieldValue('card_name')
        return (localEnums.fields?.[card_name])
          ? Object.keys(localEnums.fields[card_name]).map(
            key => ({id: key, name: localEnums.fields[card_name][key]})
          )
          : []
      }
      if (name === 'state')
        return Object.keys(localEnums.states).map(val => ({ id: val, name: localEnums.states[val] }) )
      return []
    }
    const _enums = getDataEnums(dataIndex)

    const isDisabled = () => {
      return ['stage', 'field'].includes(dataIndex) && !form.getFieldValue('card_name')
    }

    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{margin: 0}}
            rules={[
              {
                required: requiredFields.includes(dataIndex),
                message: `Укажите ${title}!`,
              },
            ]}
          >
            <Select
              disabled={ isDisabled() }
            >
              {Object.keys(_enums).map(
                key => (<Option key={_enums[key].id} value={_enums[key].id}>{_enums[key].name}</Option>)
              )}
            </Select>
          </Form.Item>
        ) : (
          children
        )}
      </td>
    )
  }

  type TablePaginationPosition = 'bottomCenter'


  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(newState => {
    setPagination({
      ...pagination,
      ...newState
    })
  }, [pagination, setPagination])

  useEffect(() => {
    getStages()
    getRoles()
    getData()
  }, [])

  useEffect( () => {
    setPagination( cur => ({ ...cur, disabled: editingKey !== null}) )
  }, [editingKey])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    try {
      setLoading(true)
      const urlParams = Object.fromEntries(new URLSearchParams(location.search))
      fetchAPI(`/api/get-fields/?${objectToUrl(urlParams)}`)
        .then(({data, pagination, sorter}) => {
          updatePagination(pagination)
          setSorter(sorter)
          setData(data)
          setLoading(false)
        })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [])

  /**
   * Получает стадии
   */
  const getStages = useCallback(() => {
    try {
      setLoading(true)
      fetchAPI(`/api/stages-list/`)
        .then(data => {
          setStages(data)
        })
    } catch (error) {
      console.error('Ошибка при получении стадий:', error)
    }
  }, [])

  /**
   * Получает список ролей
   */
  const getRoles = useCallback(() => {
    try {
      setLoading(true)
      fetchAPI(`/api/roles/get-list/`)
        .then(data => {
          setRoles(data)
        })
    } catch (error) {
      console.error('Ошибка при получении ролей:', error)
    }
  }, [])

  const edit = record => {
    form.setFieldsValue({role_id: '', card_name: '', stage: '', field: '', state: '', ...record})
    setEditingKey(record.id)
  }

  const cancel = (id) => {
    if (id === 0)
      setData(rows => rows.filter(item => item.id !== id))
    form.setFieldsValue({role_id: '', card_name: '', stage: '', field: '', state: ''})
    setEditingKey(null)
  }

  const save = async id => {
    try {
      const row = await form.validateFields()
      const newData = [...data]
      const index = newData.findIndex(item => id === item.id)
      const newValue = newData[index]?.id === 0
      const valueId = newData[index]?.id
      if (index > -1) {
        const url = newValue ? '/api/field/new' : `/api/field/${valueId}`
        fetchAPI(
          url,
          {
            method: 'POST',
            body: JSON.stringify(row)
          })
          .then(result => {
            const {id, role_id, card_name, stage, field, state} = result
            setData(data => data.map(item => {
              if (item?.id === 0)
                item = {id, role_id, card_name, stage, field, state}
              if(item?.id === id)
                item = {id, role_id, card_name, stage, field, state}
              return item
            }))
            setEditingKey(null)
            form.setFieldsValue({name: '', code: '', module: ''})
          })
          .catch(e => {
            console.error('Save error', {e})
          })
      }
    } catch (errInfo) {
      console.error('Validate Failed:', errInfo)
    }
  }

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    let current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current
    }
    if (current?.page === 1)
      delete current.page

    if (current?.sort_order === undefined || current?.sort_order === null) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({search: urlParams})
    getData()
  }

  const columns = [
    {
      title: 'Роль',
      dataIndex: 'role_id',
      width: 190,
      editable: true,
      render: val => val ? roles.find(item => item.id === val).name : null,
      sorter: editingKey === null,
      sortOrder: (sorter.sort_field === 'role_id') ? sorter.sort_order : null
    },
    {
      title: 'Карточка',
      dataIndex: 'card_name',
      width: 190,
      editable: true,
      render: val => val ? localEnums.card_type[val] : null,
      sorter: editingKey === null,
      sortOrder: (sorter.sort_field === 'role_id') ? sorter.sort_order : null
    },
    {
      title: 'Стадия',
      dataIndex: 'stage',
      width: 190,
      editable: true,
      sorter: editingKey === null,
      render: (val, row) => val ? Object.keys(stages).length && stages[row.card_name].find(item => item.code === row.stage).name : null,
      sortOrder: (sorter.sort_field === 'state') ? sorter.sort_order : null
    },
    {
      title: 'Поле',
      dataIndex: 'field',
      editable: true,
      render: (val, row) => val ? localEnums.fields?.[row.card_name]?.[row.field] : null,
      sorter: editingKey === null,
      sortOrder: (sorter.sort_field === 'field') ? sorter.sort_order : null
    },
    {
      title: 'Состояние',
      dataIndex: 'state',
      width: 190,
      editable: true,
      sorter: editingKey === null,
      render: val => val ? localEnums.states[val] : null,
      sortOrder: (sorter.sort_field === 'state') ? sorter.sort_order : null
    },
    {
      title: 'Действия',
      dataIndex: 'operation',
      width: 110,
      style: '',
      render: (_: any, record) => {
        const editable = isEditing(record)
        return editable ? (
          <span style={{display: 'flex', justifyContent: 'center'}} onClick={e => {e.stopPropagation()}}>
            <Typography.Link onClick={() => save(record.id)} style={{marginRight: 8}}>
            <ButtonActionWrapper title="Редактировать">
              <SaveOutlined rev={undefined}/>
            </ButtonActionWrapper>
            </Typography.Link>
            <ButtonActionWrapper title="Сохранить">
              <Popconfirm title="Изменения не сохранятся, отменить?" onConfirm={() => cancel(record.id)}>
                <StopOutlined rev={undefined}/>
              </Popconfirm>
            </ButtonActionWrapper>
          </span>
        ) : (
          <span style={{display: 'flex', justifyContent: 'center'}} onClick={e => {e.stopPropagation()}}>
            <Typography.Link disabled={editingKey !== null} onClick={() => edit(record)}>
              <ButtonActionWrapper title="Редактировать">
                <EditOutlined rev={undefined}/>
              </ButtonActionWrapper>
            </Typography.Link>
          </span>
        )
      },
    },
  ]

  const mergedColumns = columns.map(col => {
    if (!col.editable)
      return col

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  const handleRowClick = useCallback( record => {
    if( editingKey === null )
      history.push(`/admin/enum-collection/${record.id}`)
  }, [editingKey])

  const addRow = useCallback(() => {
    setData(rows => {
      const newRow = {
        id: 0,
        role_id: null,
        card: null,
        stage: null,
        field: null,
        state: null,
      }
      return [newRow, ...rows]
    })
    setEditingKey(0)
  }, [])

  /**
   * Отслеживает состояние фильтров
   * @param filters
   */
  const changeFiltersHandler = useCallback( filters => {
    const clear = Object.keys(filters).reduce( (acc, val) => {
      if( filters[val] )
        acc[val] = filters[val]
      return acc
    }, {} )

    setCurrentFilters(clear)
    const newUrlParams = new URLSearchParams(clear).toString()
    history.push({search: newUrlParams})
    getData()
  }, [setCurrentFilters])

  const getCardStages = useCallback( (name) => {
    const cardName = currentFilters.card || name
    if (!cardName || !Object.keys(stages).length) return []
    const cardStages = stages?.[cardName]
    if (cardStages) {
      return cardStages.reduce((arr, value) => {
        const {code, name} = value
        arr.push({value: code, label: name})
        return arr
      }, [])
    }
    return []
  }, [currentFilters, stages])

  const getFilterValue = useCallback( name => {
    if(name === 'role_id' && currentFilters?.[name])
      return +currentFilters[name]
    return currentFilters?.[name] || undefined
  }, [currentFilters])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <div style={{display: 'flex', justifyContent: 'space-between'}}>
        <MapTitle>Видимость полей</MapTitle>
        <Button onClick={addRow} disabled={editingKey !== null}>
          <PlusOutlined rev={undefined}/> Добавить правило
        </Button>
      </div>
      { Object.keys(stages).length && (
        <div>
          <div style={{display: 'flex', gap: 4, paddingBottom: 10}}>
            <Select
              placeholder='Роль'
              value={getFilterValue('role_id')}
              style={{width: 200}}
              onChange={ val => changeFiltersHandler({...currentFilters, role_id: val}) }
              allowClear
              onClear={ () => setCurrentFilters(cur => ({...cur, role_id: undefined})) }
              disabled={editingKey !== null}
              >
              {
                Object.keys(roles).map(
                  val => (<Option key={roles[val].id} value={roles[val].id}>{roles[val].name}</Option>)
                )
              }
            </Select>
            <Select
              placeholder='Карточка'
              value={getFilterValue('card')}
              style={{width: 200}}
              onChange={ val => changeFiltersHandler({...currentFilters, card: val, stage: undefined, field: undefined}) }
              allowClear
              onClear={ () => setCurrentFilters(cur => ({...cur, card: undefined, stage: undefined, field: undefined})) }
              disabled={editingKey !== null}
              >
              {
                Object.keys(localEnums.card_type).map(
                  option => (
                    <Option key={option} value={option}>{localEnums.card_type[option]}</Option>
                  )
                )
              }
            </Select>
            <Select
              placeholder='Стадия'
              allowClear
              onClear={ () => setCurrentFilters(cur => ({...cur, stage: undefined})) }
              value={getFilterValue('stage')}
              style={{width: 200}}
              disabled={!currentFilters?.card || editingKey !== null}
              onChange={ val => changeFiltersHandler({...currentFilters, stage: val}) }
              >
              {
                (currentFilters?.card && stages[currentFilters.card])
                ? getCardStages(null).map(
                    option => (<Option key={option.value} value={option.value}>{option.label}</Option>)
                  )
                : null
              }
            </Select>
            <Select
              placeholder='Поле'
              disabled={!currentFilters?.card || editingKey !== null}
              allowClear
              onClear={ () => setCurrentFilters(cur => ({...cur, field: undefined})) }
              value={getFilterValue('field')}
              style={{width: 200}}
              onChange={ val => changeFiltersHandler({...currentFilters, field: val}) }
              >
              {
                (localEnums.fields?.[currentFilters.card])
                ? Object.keys(localEnums.fields[currentFilters.card]).map(
                  key => (<Option key={key} value={key}>{localEnums.fields[currentFilters.card][key]}</Option>)
                )
                : null
              }
            </Select>
            <Select
              placeholder='Состояние'
              value={getFilterValue('state')}
              disabled={editingKey !== null}
              style={{width: 200}}
              onChange={ val => changeFiltersHandler({...currentFilters, state: val}) }
              allowClear
              onClear={ () => setCurrentFilters(cur => ({...cur, state: undefined})) }
            >
              {
                Object.keys(localEnums.states).map(
                  option => (
                    <Option key={option} value={option}>{localEnums.states[option]}</Option>
                  )
                )
              }
            </Select>
          </div>
        </div>
      )}
      <Form form={form} component={false}>
        <Table
          rowKey={'id'}
          loading={loading}
          components={{body: {cell: EditableCell}}}
          size="small"
          dataSource={data}
          columns={mergedColumns}
          rowClassName={ (!editingKey) ? "editable-row cursor-pointer" : "editable-row" }
          onRow={ record => ({onClick: () => handleRowClick(record)})}
          onChange={handleTableChange}
          pagination={pagination}
        />
      </Form>
    </B2BLayout>
  )
}

export default Collections