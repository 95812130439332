import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import fetchAPI from '../../../lib/utils/fetch-api'
import { Select, Button, Space } from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { ISelect } from './interface' 

function filterOption(input, option){
  return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

function SelectFrom({ value, schema, UISchema, onChange }: ISelect) {
  const [isOpen, setOpen] = useState(false)
  const [objects, setObjects] = useState<any[]>([{ id: value, value }])
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const filter = useMemo(() => {
    const roleId = [].concat(UISchema?.options?.roleId).join(',')
    const organizationId = UISchema?.options?.organizationId

    if(roleId && organizationId){
      return `filter[role]=${roleId}&filter[organization]=${organizationId}`
    }  
    if(roleId){
      return `filter[role]=${roleId}`
    }
    if(organizationId){
      return `filter[organization]=${organizationId}`
    }  
    return ''
  }, [UISchema])

  useEffect(() => {
    (async () => {
      const objectsResult: any = await fetchAPI(`/api/users?${filter}`)
      const objectsFormat = getDataOfType(objectsResult, 'data', Array, []).map(({ id, login, ...item }) => {
        const name = item?.['profile-data']?.['name']
        if (name) {
          return { id: id, value: name }
        }
        return { id: id, value: login }
      })
      setObjects(objectsFormat)
    })()
  }, [filter])
  const values: any[] = useMemo(() =>  Array.isArray(value) ? value: [], [value])


  const selectAll = useCallback(() => {
    onChange(objects.map(item => item?.id).filter(Number.isInteger))
  }, [onChange, objects])

  const selectClear = useCallback(() => {
    onChange([])
  }, [onChange])

  const selectClose = useCallback(() => {
    //console.log({ selectRef })
    //selectRef.current?.focus?.()
    setOpen(false)
  }, [])
  const onDropdownVisibleChange = useCallback(() => {
    //console.log({ selectRef })
    //selectRef.current?.focus?.()
    setOpen(true)
  }, [])


  return (
    <Select
      open={isOpen}
      onDropdownVisibleChange={onDropdownVisibleChange}
      mode="multiple"
      showSearch
      placeholder="Выберите запись"
      optionFilterProp="children"
      onChange={onChange}
      value={values}
      filterOption={filterOption}
      disabled={disabled}
      dropdownRender={menu => (
        <>
          {menu}
          <Space style={{ padding: '10px 10px 8px 4px' }}>
            <Button onClick={selectAll}>
              Выбрать все
            </Button>
            <Button onClick={selectClear}>
              Снять выбор
            </Button>
            <div style={{ width: 133 }} />
            <Button onClick={selectClose}>
              Готово
            </Button>
          </Space>
        </>
      )}
    >
    {objects.map(({ id, value }) => (
      <Select.Option value={id}>{value}</Select.Option>
    ))}
    </Select>
  )
}

export default SelectFrom







