import React, { useEffect, useMemo, useState, useCallback, useRef } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Table, Alert, Pagination, Space, Button } from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import B2BLayout from '../../../react-app/layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import FieldHideText from '../../components/field-hide-text'
import EditCard from '../../components/list-operations/edit-card'
import { formDataToUrlParams } from '../list-orders/utils'
import { calcTotalWidth, tableRowClick, getColumnsOfSchema, fetchSchema } from '../../../lib/utils/list'
import { TableWrapper, PaginationWrapper } from './styles'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import dayjs from 'dayjs'
import OrderFilter from './orders-filter'
import { PlusOutlined } from '@ant-design/icons'
import './index.css'


const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)

const name = 'call-log'

function formatDate(item, path) {
  const date = getDataOfType(item, path, String, null)
  if (date === null) {
    return ''
  }
  return dayjs(date).format('DD.MM.YYYY')
}

export default function AdminObjectsListPage() {
  const history = useHistory()
  const location = useLocation()
  const orderFiltersRef = useRef<any>({})
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [page, setPage] = useState<number>(1)
  const { counts } = useSelector(createViewed)
  const breadcrumbs = useMemo(() => (
    [{
      href: '/',
      title: '',
    }, {
      href: location.pathname,
      title: 'Список сотрудников',
    }]
  ), [location.pathname])

  const addDataColumns = useMemo(() => {
    const addDataColumns: any[] = [
      {
        title: 'ID',
        width: 60,
        dataIndex: 'user-id',
        key: 'user-id',
        render: (_, item) => getDataOfType(item, 'id', Number, '')
      },
      {
        title: 'Логин',
        width: 250,
        dataIndex: 'login',
        key: 'login',
        render: (_, item) => getDataOfType(item, 'login', String, '')
      },
      {
        title: 'ФИО',
        width: 200,
        dataIndex: 'profile-data.name',
        key: 'profile-data.name',
        render: (_, item) => getDataOfType(item, 'profile-data.name', String, '')
      },
      {
        title: 'Роль',
        width: 200,
        dataIndex: 'roles-title',
        key: 'roles-title',
        render: (key, item) => getDataOfType(item, 'roles-title', String, '')
      },
      {
        title: 'Дата приема на работу',
        width: 150,
        dataIndex: 'date-employment',
        key: 'date-employment',
        render: (key, item) => formatDate(item, 'profile-data.date-employment')
      },
      {
        title: 'Дата увольнения',
        width: 150,
        dataIndex: 'date-dismissal',
        key: 'date-dismissal',
        render: (key, item) => formatDate(item, 'profile-data.date-dismissal')
      },
      {
        title: 'Рейтинг',
        width: 70,
        dataIndex: 'rating',
        key: 'rating',
        render: (key, item) => getDataOfType(item, 'rating', Number, 0) + ' %'
      },
      {
        title: 'Оклад',
        width: 150,
        dataIndex: 'salary',
        key: 'salary',
        render: (key, item) => getDataOfType(item, 'profile-data.salary', Number, '')
      },
      {
        title: 'Переменная часть оплаты',
        width: 120,
        dataIndex: 'salary2',
        key: 'salary2',
        render: (key, item) => getDataOfType(item, 'profile-data.salary-variable-part', Number, 0) + ' %'
      },
    ]
    return addDataColumns
  }, []) 

  const totalWidth: any = useMemo(() => calcTotalWidth( addDataColumns), [ addDataColumns])

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(addDataColumns).filter(item => item.title), [addDataColumns])
  const cbTableRowClick = useCallback((item) => item['id'] && history.push(`/employees/${item['id']}`), [history])

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  }, [])

  const getData = useCallback(async (value, addUrlParams?: string) => {
    const urlParams = formDataToUrlParams(value)
    const pageErrors: string[] = []
    const [selectedSchema, metadata, collections] = await Promise.all([
      fetchSchema({ name }),
      fetchAPI(`/api/collections/${name}/metadata`),
      fetchAPI(`/api/employees?access_key=axioma&${urlParams}&${addUrlParams}`)
    ])
    const collectionsResultData = getDataOfType(collections, 'items', Array, [])
  
    const isMetadata = getDataOfType(metadata, 'data', Object, null)
    if(isMetadata === null) {
      pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
    }
    const dataSource: any[] = collectionsResultData
    const newDataSource = dataSource.map((item) => ({
      ...item,
      deleteRecord,
    }))
    const preparedDataColumns: any[] = getColumnsOfSchema(selectedSchema)

    const newDataColumns = preparedDataColumns.map(column => ({ 
        ...column, 
        fixed: 'left', 
        width: 120, 
        render: (text, { id, schema }) => (
        <EditCard id={id} schema={schema} title="Редактирование">
         {FieldHideText({ text })}
        </EditCard>
      )}
    ))
    const totalRows = getDataOfType(collections, 'data.total-rows', Number, 0)
    setTotalRows(totalRows)
    setDataColumns(newDataColumns)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [deleteRecord])

  const onPagination = useCallback(async ( page, pageSize) => {
    setPage(page)
    const urlParams = `page=${page}&pageSize=${pageSize}`
    setIsDataLoading(true)
    await getData(orderFiltersRef.current, urlParams)
    setIsDataLoading(false)
  }, [getData])

  useEffect(() => {
    const urlParams = `page=${page}&pageSize=10`
    page === 1 && getData(orderFiltersRef.current,urlParams)
  }, [counts, page, getData])

  const rowClassName = useCallback(record => {
    return record['stage'] === 0 ? 'table-row-dark' :  'table-row-light'
  }, [])
  const onButtonCreate = useCallback(() => history.push(`/employees/new`), [history])

  const onOrderFilter = useCallback(value => {
    Object.assign(orderFiltersRef.current, value)
    getData(value)
  }, [getData])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <Space direction="vertical">
      <Button onClick={onButtonCreate} style={{ marginBottom: 16 }}>
        <PlusOutlined rev={undefined}/> Добавить сотрудника
      </Button>
      <Space align="baseline">
        <OrderFilter onChange={onOrderFilter} />
      </Space>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}
      </Space>
      <TableWrapper>
        <Table
          columns={finalColumns}
          rowClassName={rowClassName}
          size="small"
          dataSource={dataSource}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={false}
          loading={isDataLoading}
          onHeaderRow={(column: any) => ({
            style: {
              fontSize: column.key && column.key.substr(-3) === '-at' ? 9 : 13,
            },
          })}
          onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick) })}
        />
      </TableWrapper>
      {Boolean(totalRows) && <PaginationWrapper>
        <Pagination defaultCurrent={1} pageSize={10} total={totalRows} onChange={onPagination} showSizeChanger={false} />
      </PaginationWrapper>}
    </B2BLayout>
  )
}
