import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Table, Alert } from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import FieldHideText from '../../components/field-hide-text'
import { afterDataColumns, beforeDataColumns } from '../../components/list-operations/consts'
import EditCard from '../../components/list-operations/edit-card'
import { calcTotalWidth, tableRowClick, getColumnsOfSchema, fetchSchema, listColumnsHidden, formatListData } from '../../../lib/utils/list'
import { getStagesGroup } from '../list-orders/utils'

const name = 'card-complaint'

export default function AdminObjectsListPage() {
  const location = useLocation()
  const history = useHistory()
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [dataColumns, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const breadcrumbs = useMemo(() => (
    [{
      href: location.pathname,
      title: 'Рекламации',
    }, {
      href: location.pathname,
      title: 'Список рекламаций',
    }]
  ), [location.pathname])
  const totalWidth: any = useMemo(() => calcTotalWidth(beforeDataColumns, dataColumns, afterDataColumns), [dataColumns])

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(beforeDataColumns, dataColumns, afterDataColumns).filter(item => item.title), [dataColumns])
  const cbTableRowClick = useCallback(({ id }) => history.push(`/complaint/${id}/edit`), [history])

  useEffect(() => {
    const getData = async () => {
      const pageErrors: string[] = []
      const [selectedSchema, metadata, collections, stages] = await Promise.all([
        fetchSchema({ name }),
        fetchAPI(`/api/collections/${name}/metadata`),
        fetchAPI(`/api/collections/${name}?access_key=axioma`),
        getStagesGroup('complaint')
      ])
      const columnsHidden = getDataOfType(metadata, 'data.columnsHidden', Array, [])
      const collectionsResultData = getDataOfType(collections, 'data.data', Array, [])
      const isMetadata = getDataOfType(metadata, 'data', Object, null)
      if(isMetadata === null) {
        pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
      }
      const dataSource: any[] = formatListData(collectionsResultData, selectedSchema)
      const newDataSource = dataSource.map(item => ({
        ...item,
        deleteRecord,
        stages
      }))
      const preparedDataColumns: any[] = getColumnsOfSchema(selectedSchema)
      const datalistColumnsHidden = listColumnsHidden(preparedDataColumns, columnsHidden)
      const newDataColumns = datalistColumnsHidden.map(column => ({
          ...column,
          fixed: 'left',
          width: 120,
          render: (text, { id, schema }) => (
          <EditCard id={id} schema={schema} title="Редактирование">
           {FieldHideText({ text })}
          </EditCard>
        )}
      ))
      setDataColumns(newDataColumns)
      setDataSource(newDataSource)
      setPageErrors(pageErrors)
      setIsDataLoading(false)
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  },[])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {(pageErrors.length > 0) ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
            />
        </div>
      ): null}
      <Table
        columns={finalColumns}
        size="small"
        dataSource={dataSource}
        scroll={{ x: totalWidth, y: '72vh' }}
        pagination={false}
        loading={isDataLoading}
        onHeaderRow={(column: any) => ({
          style: {
            fontSize: (column.key && column.key.substr(-3) === '-at') ? 9 : 13,
          }
        })}
        onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick)})}
        />
    </B2BLayout>
  )
}
