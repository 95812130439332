import React, { FC, useCallback, useMemo } from 'react'
import { Alert, Button, Form, Popconfirm, Radio, Skeleton, Input } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { DefaultJsonForm } from '../../components/default-json-form'
import { formItemLayout } from './consts'
import { AlertWrapper, FooterWrapper, MapContent, MapTitle, MapWrapper } from './styles'
import { IEditObjectFormProps } from './interface'
import { FormItemGroup } from '../app-edit-collection-form/styles'
import { SelectGroup } from '../../views/admin-select-group'

export const EditObjectForm: FC<IEditObjectFormProps> = (props) => {
  const {
    title,
    footer,
    id,
    groupId,
    pageErrors,
    form,
    selectedSchema,
    isDataLoading,
    availableSchemas,
    matchedJSONSchema,
    formData = {},
    name,
    collectionName,
    onFinish,
    onFinishFailed,
    onValuesChange,
    onFormData,
    onSubmit,
    onChangeName,
  } = props

  const confirmMessage = useMemo(() => id ? 'Изменить данные записи?' : 'Создать запись?', [id])

  const handleSelectGroup = useCallback((value) => {
    const formFieldsValue = form.getFieldsValue()

    form.setFieldsValue({
      ...formFieldsValue,
      'group-id': value,
    })
  }, [form])

  const getCreateGroupURL = useCallback(() => '/collections/objects-groups/', [])
  const getUpdateGroupURL = useCallback(id => `/collections/objects-groups/${id}`, [])

  return (
    <MapWrapper>
      <MapTitle>{title}</MapTitle>
      <MapContent>
        {pageErrors.length > 0 ? (
          <AlertWrapper>
            <Alert
              message="При выполнении операции возникла ошибка:"
              showIcon
              type="error"
              description={pageErrors.join('. ')}
            />
          </AlertWrapper>
        ) : null}
        <Form
          {...formItemLayout}
          layout="vertical"
          form={form}
          fields={[
            {
              name: 'schema',
              value: selectedSchema,
            },
          ]}
          scrollToFirstError={true}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          initialValues={{
            schema: selectedSchema,
          }}
          onValuesChange={onValuesChange}
        >
          {isDataLoading ? <Skeleton active /> : (
            <>
              <Form.Item
                label="Наименование"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Поле обязательно к заполнению',
                  },
                ]}
              >
                <Input value={name} onChange={({ target }) => onChangeName(target.value)} />
              </Form.Item>
              {availableSchemas?.length > 1 ? (
                <Form.Item
                  label="Структура (схема) записи"
                  name="schema"
                  rules={[
                    {
                      required: true,
                      message: 'Поле обязательно к заполнению',
                    },
                  ]}
                >
                  <Radio.Group>
                    {availableSchemas.map((item, index) => (
                      <Radio
                        key={index}
                        value={item['name']}
                        style={{
                          display: 'block',
                          height: '30px',
                          lineHeight: '30px',
                        }}
                      >
                        {item['title']}
                      </Radio>
                    ))}
                  </Radio.Group>
                </Form.Item>
              ) : (
                <Form.Item
                  name="schema"
                  rules={[
                    {
                      required: true,
                      message: 'Поле обязательно к заполнению',
                    },
                  ]}
                />
              )}
            </>
          )}
          <Form.Item
            label="Группа объекта"
            name="group-id"
          >
            <FormItemGroup>
              <SelectGroup
                onChange={handleSelectGroup}
                initSelectedGroup={groupId}
                getCreateGroupURL={getCreateGroupURL}
                getUpdateGroupURL={getUpdateGroupURL}
                getGroupsURL={`/collections/objects-groups/${collectionName}`}
                targetData={{ 'collection-name': collectionName }}
              />
            </FormItemGroup>
          </Form.Item>
        </Form>
        {matchedJSONSchema !== null && (
          <DefaultJsonForm formData={formData} schema={matchedJSONSchema} onChange={onFormData} />
        )}
        <FooterWrapper>
          <Popconfirm title={confirmMessage} okText="Да" cancelText="Нет" onConfirm={onSubmit}>
            <Button type="primary">
              <SaveOutlined rev={undefined}/>
              {id ? 'Сохранить' : 'Добавить'}
            </Button>
          </Popconfirm>
        </FooterWrapper>
        {footer && footer()}
      </MapContent>
    </MapWrapper>
  )
}
