import React, {useEffect, useMemo, useState} from 'react'
import {Button, InputNumber, Modal} from 'antd'
import {INumber} from './interface'
import fetchAPI from '../../../lib/utils/fetch-api'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'
import {useDispatch, useSelector} from 'react-redux'
import {createSelector} from 'reselect'
import {PlusOutlined} from "@ant-design/icons";
import {reloadActionsTimeLine, reloadTablePayment} from "../../store/appeals/actions";
import {modalAddPayment} from "../../views/modal-add-payment";

const createCase = createSelector(
  (state: any) => state.appeals,
  targetId => targetId,
  stageCode => stageCode,
  tablePayment => tablePayment,
)

function formatCurrency(value){
  return (value || 0).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', currencyDisplay: 'symbol' }).replace("₽", "");
}

function InputInteger({ value, onChange, UISchema }: INumber) {
  const { targetId, tablePayment, stageCode } = useSelector(createCase)
  const [localValue, setLocalValue] = useState<any>(null)
  const [showModalPrepayment, setShowModalPrepayment] = useState<boolean>(false)
  const dispatch = useDispatch()

  useEffect(() => {
    if(tablePayment === null){
      dispatch(reloadTablePayment())
    }
  }, [targetId, tablePayment, localValue])

  const addPayment = useMemo( () => async () => {
    const result: any = await modalAddPayment({})
    if(result !== null){
      const scanID = getDataOfType(result, 'prepayment-scan[0]', Number, null);
      const success = await fetchAPI(`/api/web-hooks/add-payment-contract`, {
        method: 'POST',
        body: JSON.stringify({
          targetId: targetId,
          pay: {
            sum: result['payment'],
            date: result['date'],
            scan: scanID
          },
        }),
      })
      if( !Object.keys(success).length ) {
        Modal.warning({
          title: 'Данные не сохранены',
          content: 'Проверте подключение к сети интернет, после чего повторите попытку',
        })
      }else{
        dispatch(reloadActionsTimeLine())
        dispatch(reloadTablePayment())
        setLocalValue( formatCurrency( tablePayment?.paymentsSumAll ) )
      }
    }
  }, [tablePayment])

  const disabledButton = useMemo(()=> ['measurement', 'prepayment', 'manufacture'].indexOf(stageCode) === -1, [stageCode])

  const hasError = useMemo( () => {
    return stageCode == 'measurement' && Boolean(tablePayment?.paymentsSumAll === 0)
  }, [tablePayment])

  return (
    <>
      <div style={{display:'flex'}} className={"prepaymentInput" + (hasError && " red_outline")}>
        <InputNumber value={formatCurrency(tablePayment?.paymentsSum)} onChange={onChange} disabled={true}/>
        <Button onClick={() => setShowModalPrepayment(true)} disabled={disabledButton}>
          <PlusOutlined onClick={addPayment} rev={undefined} />
        </Button>
      </div>
      { hasError &&
        (<div className="ant-form-item-with-help">
          <div className="ant-form-item-explain ant-form-item-explain-connected">
            <div role="alert" className="ant-form-item-explain-error">Обязательное поле</div>
          </div>
        </div>)
      }
    </>
  )
}

export default React.memo(InputInteger, (prev, next) => {
  if(prev.value === next.value)
    return true
  return prev?.UISchema === next?.UISchema;
})
