import React, { useMemo, useContext } from 'react'
import { Timeline, Space, Tooltip } from 'antd'
import { formatDate } from './activity-timeline-Item'
import {
  ArrowRightOutlined,
  InfoOutlined
} from '@ant-design/icons'
import {
  TimeLineContentWrapper,
  TimeLineContentHeaderWrapper,
  DotStyle,
  Stage,
  Avatar
} from './styles'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import ActivityTimelineContext from './activity-timeline-context'

const dotColor = '#2fc4f5'

const urlAvatar = 'https://bipbap.ru/wp-content/uploads/2017/05/VOLKI-krasivye-i-ochen-umnye-zhivotnye.jpg'

function ActivityTimelineStage({ type, body, date, id }: any){
  const contextProps = useContext(ActivityTimelineContext)
  const dateFormat = useMemo(() => formatDate(date), [date])

  const stageNextColor = useMemo(() => {
    return getDataOfType(body, 'stageNextColor', String, '#e8f1fb')
  }, [body])

  const stagePrevColor = useMemo(() => {
    return getDataOfType(body, 'stagePrevColor', String, '#e8f1fb')
  }, [body])

  const stagePrevFontColor = useMemo(() => {
    return getDataOfType(body, ['stageDataPrev', 'font-color'], String, '#e8f1fb')
  }, [body])
  const stagePrevFontWeight = useMemo(() => {
    return getDataOfType(body, ['stageDataPrev', 'font-weight'], String, 'normal')
  }, [body])

  const stageNextFontColor = useMemo(() => {
    return getDataOfType(body, ['stageDataNext', 'font-color'], String, '#e8f1fb')
  }, [body])
  const stageNextFontWeight = useMemo(() => {
    return getDataOfType(body, ['stageDataNext', 'font-weight'], String, 'normal')
  }, [body])

  const author = useMemo(() => {
    const { usersProfile } = contextProps['task']
    const authorId = getDataOfType(body, 'userId', Number, null)
    if (authorId === null) {
      return null
    }
    const item = usersProfile.find(({ id }) => String(authorId) === String(id))
    const author =
      (getDataOfType( item, 'profile-data.name', String, '') || getDataOfType(item, 'name',  String, '') ) + ` (ID: ${authorId})`
      return author
  }, [contextProps, body])
  
  return (
    <Timeline.Item dot={<DotStyle color={dotColor}><InfoOutlined rev={undefined} /></DotStyle>}>
      <TimeLineContentWrapper>
        <TimeLineContentHeaderWrapper>
          <Space>
            <span style={{ fontWeight: 'bold' }}>Стадия изменена</span>
            <span style={{ color: '#a8adb4' }}>{dateFormat}</span>
          </Space>
          <Space>
            {author && (
              <Space>
                <Avatar src={urlAvatar} />
                <Tooltip placement="top" title="Автор"><span>{author}</span></Tooltip>
              </Space>
            )}
          </Space>
        </TimeLineContentHeaderWrapper>
        <Space>
          <Stage color={stagePrevColor} fontColor={stagePrevFontColor} fontWeight={stagePrevFontWeight}>{body.stagePrev}</Stage>
          <ArrowRightOutlined style={{ color: '#b1bac3' }} rev={undefined} />
          <Stage color={stageNextColor} fontColor={stageNextFontColor} fontWeight={stageNextFontWeight}>{body.stageNext}</Stage>
        </Space>
      </TimeLineContentWrapper>
    </Timeline.Item>
  )
}

export default ActivityTimelineStage
