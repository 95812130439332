import styled, { StyledComponent } from 'styled-components'
import { ToolTwoTone as ToolTwoToneIcon } from '@ant-design/icons'

export const OperationsWrapper = styled.div`
  display: flex;
  alignItems: center;
  max-width: 100px;
`

export const ButtonActionWrapper: StyledComponent<'div', any> = styled.button`
  margin: 0px;
  font-size: 20px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 6px;
`

export const ToolTwoTone = styled(ToolTwoToneIcon)`
  font-size: 20px;
  padding: 6px; 
  color: #52acff;
`