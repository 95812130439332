import React, {useCallback, useEffect, useMemo, useState} from 'react'
import {ConfigProvider, Input, Space } from 'antd'
import SelectFromEnum from '../../../components/select-from-enum'
import {
  status,
  vacancy,
  resource,
  gender,
  cameRequest,
  workComment,
  resultTrialPeriod,
  city_id,
  region_id
} from './utils'
import fetchAPI from '../../../../lib/utils/fetch-api'
import InputPhoneFind from '../../../components/input-phone-find'
import moment from "moment";
import locale from "antd/es/locale/ru_RU";
import {DateRangePicker} from '../../../components/input-date-range/styles'
import {objectMap} from '../../../../lib/utils/get-table-transform-schema-and-form-data'

function setEnumSchemaSelect(prop, oneOf, enumValues) {
  Object.assign(prop, {
    oneOf: oneOf,
    'enum': enumValues.map(item => item.id),
    view: {'name': 'select-from-enum'}
  })
}

function InputFilter({onChange, placeholder}) {
  const onPressEnter = useCallback(({target}) => {
    onChange(target?.value)
  }, [onChange])
  const onLocalChange = useCallback(({target}) => {
    const value = target?.value
    if (typeof value !== 'string' || value === '') {
      onChange(undefined)
    }
  }, [onChange])
  const onSearch = useCallback((value) => {
    onChange(value)
  }, [onChange])
  return <Input.Search placeholder={placeholder} onPressEnter={onPressEnter} onChange={onLocalChange}
                       onSearch={onSearch} allowClear style={{width: 200}}/>
}

function dateToMoment(date) {
  if (Boolean(date) === false) {
    return
  }
  return moment(date).utc(false)
}

function InputDateRange({value, onChange, name}) {
  const handleChange = useCallback(interval => {
    if (Array.isArray(interval) === false) {
      return onChange(undefined)
    }
    const [start, end] = interval
    if ((start && end) === false) {
      return onChange(undefined)
    }
    const timeRange = moment(start).format('YYYY.MM.DD') + '-' + moment(end).format('YYYY.MM.DD')
    onChange(timeRange)
  }, [onChange])
  const valueFormat = useMemo<any>(() => {
    if (Boolean(value) === false) {
      return undefined
    }
    const [start, end] = value.split('-')
    if ((start && end) === false) {
      return undefined
    }
    return [dateToMoment(start), dateToMoment(end)]
  }, [value])

  // @ts-ignore
  return (
    <ConfigProvider locale={locale}>
      <DateRangePicker
        onChange={handleChange}
        value={valueFormat}
        placeholder={[name, name]}
        style={{'width': '320px'}}
      />
    </ConfigProvider>
  )
}

function Filter({onChange}) {
  const [formData, setFormData] = useState<any>(() => ({
    'filter[hr_candidate.fio]': undefined,
    'filter[hr_candidate.city_id]': undefined,
    'filter[hr_candidate.region_id]': undefined,
    'filter[phone]': undefined,
    'filter[vacancy]': undefined,
    'filter[resource]': undefined,
    'filter[date_callback]': undefined,
    'filter[status]': undefined,
    'filter[date_interview]': undefined,
    'filter[came_request]': undefined,
    'filter[date_repeated_call]': undefined,
    'filter[date_planned_work]': undefined,
    'filter[work_comment]': undefined,
    'filter[date_probationary]': undefined,
  }))

  const [schemaStatus, setSchemaStatus] = useState<any>({})
  const [schemaCity, setSchemaCity] = useState<any>({})
  const [schemaRegion, setSchemaRegion] = useState<any>({})
  const [schemaGender, setSchemaGender] = useState<any>({})
  const [schemaVacancy, setSchemaVacancy] = useState<any>({})
  const [schemaResource, setSchemaResource] = useState<any>({})

  const [UISchemaSelectFromEnumCity] = useState({
    options: {width: 180, placeholder: 'Город', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumRegion] = useState({
    options: {width: 180, placeholder: 'Район', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumStatus] = useState({
    options: {width: 180, placeholder: 'Статус', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumVacancy] = useState({
    options: {width: 180, placeholder: 'Вакансия', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumResource] = useState({
    options: {width: 180, placeholder: 'Ресурс', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumCameRequest] = useState({
    options: {width: 180, placeholder: 'Пришел на собеседование', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumWorkComment] = useState({
    options: {width: 180, placeholder: 'Конечный результат о выходе на работу', allowClear: true, showSearch: true,}
  })
  const [UISchemaSelectFromEnumResultTrialPeriod] = useState({
    options: {
      width: 180,
      placeholder: 'Результат по истечению испытательно срока 2 недели',
      allowClear: true,
      showSearch: true,
    }
  })

  useEffect(() => {
    const getData = async () => {
      await fetchAPI('/api/hr/request/enum')
        .then(result => {
          const enumItems = result?.['enum'] || {}

          if (result?.['city']) {
            enumItems.city_id = Object.values(objectMap(result['city'], ([key, value]) => {
              return [key, {id: parseInt(key), value: value}]
            }))
          }
          if (result?.['region']) {
            enumItems.region_id = Object.values(objectMap(result['region'], ([key, value]) => {
              return [key, {id: parseInt(key), value: value}]
            }))
          }

          Object.keys(enumItems).forEach(code => {
            const enumValues = result?.['enum']?.[code] || []
            const oneOf: Record<string, string>[] = []

            Object.keys(enumValues).forEach(enumValue => {
              return oneOf.push({
                'const': enumValues[enumValue].id,
                'title': enumValues[enumValue].value
              })
            })

            if (code === 'gender') {
              setEnumSchemaSelect(gender, oneOf, enumValues)
              setSchemaGender(gender)
            }
            if (code === 'vacancy') {
              setEnumSchemaSelect(vacancy, oneOf, enumValues)
              setSchemaVacancy(vacancy)
            }
            if (code === 'resource') {
              setEnumSchemaSelect(resource, oneOf, enumValues)
              setSchemaResource(resource)
            }
            if (code === 'status') {
              setEnumSchemaSelect(status, oneOf, enumValues)
              setSchemaStatus(status)
            }
            if (code === 'city_id') {
              setEnumSchemaSelect(city_id, oneOf, enumValues)
              setSchemaCity(city_id)
            }
            if (code === 'region_id') {
              setEnumSchemaSelect(region_id, oneOf, enumValues)
              setSchemaRegion(region_id)
            }
          })
          /*const cities = result?.['city'] || {}
          const cityOptions: IOption[] = []

          Object.keys(cities).forEach(id => {
            const city = cities[id]
            const regionOptions: any[] = [];
            for (const regionId in city.region) {
              regionOptions.push({
                value: regionId,
                label: city.region[regionId],
              })
            }

            cityOptions.push({
              value: id,
              label: city.name,
              children: regionOptions
            })
          })

          setSchemaCity(cityOptions)*/
        })
    }
    getData()
  }, [])

  const onChangeLocal = useCallback((value, name) => {
    const nextFormData = {}
    setFormData(prevFormData => Object.assign(nextFormData, prevFormData, {[name]: value ? encodeURIComponent(value) : value}))

    onChange(nextFormData)
  }, [onChange])


  const onChangeFilter = useCallback(code => (value) => {
    onChangeLocal(value, `filter[${code}]`)
  }, [onChangeLocal])

  const onChangePhoneFilter = useCallback(code => (value) => {
    onChangeLocal(value ? value.substring(1) : undefined, `filter[${code}]`)
  }, [onChangeLocal])

  const onChangeCityFilter = useCallback((value) => {
    const nextFormData = {}
    setFormData(prevFormData => Object.assign(
        nextFormData,
        prevFormData,
        {[`filter[hr_candidate.city_id]`]: value ? value[0] : undefined},
        {[`filter[hr_candidate.region_id]`]: value ? value[1] : undefined}
      )
    )

    onChange(nextFormData)
  }, [onChangeLocal])

  return (
    <Space wrap>
      <InputFilter onChange={onChangeFilter('hr_candidate.fio')} placeholder="ФИО"/>
      <InputPhoneFind onEnter={onChangePhoneFilter('hr_candidate.phone')}/>
      {/*<Cascader options={schemaCity} onChange={onChangeCityFilter} placeholder="Город/район" changeOnSelect/>*/}
      <SelectFromEnum
        value={formData['hr_candidate.city_id']}
        schema={schemaCity}
        onChange={onChangeFilter('hr_candidate.city_id')}
        UISchema={UISchemaSelectFromEnumCity}
      />
      <SelectFromEnum
        value={formData['hr_candidate.region_id']}
        schema={schemaRegion}
        onChange={onChangeFilter('hr_candidate.region_id')}
        UISchema={UISchemaSelectFromEnumRegion}
      />
      <SelectFromEnum
        value={formData['vacancy']}
        schema={schemaVacancy}
        onChange={onChangeFilter('vacancy')}
        UISchema={UISchemaSelectFromEnumVacancy}
      />
      <SelectFromEnum
        value={formData['resource']}
        schema={schemaResource}
        onChange={onChangeFilter('resource')}
        UISchema={UISchemaSelectFromEnumResource}
      />
      <InputDateRange
        value={formData['date_callback']}
        name="Дата повторного звонка и отправка приглашения"
        onChange={onChangeFilter('date_callback')}
      />
      <SelectFromEnum
        value={formData['status']}
        schema={schemaStatus}
        onChange={onChangeFilter('status')}
        UISchema={UISchemaSelectFromEnumStatus}
      />
      <InputDateRange
        value={formData['date_interview']}
        name="Дата собеседования"
        onChange={onChangeFilter('date_interview')}
      />
      <SelectFromEnum
        value={formData['came_request']}
        schema={cameRequest}
        onChange={onChangeFilter('came_request')}
        UISchema={UISchemaSelectFromEnumCameRequest}
      />
      <InputDateRange
        value={formData['date_repeated_call']}
        name="Повторный звонок не пришедшему кандидату"
        onChange={onChangeFilter('date_repeated_call')}
      />
      <InputDateRange
        value={formData['date_planned_work']}
        name="Планируемая дата выхода на работу"
        onChange={onChangeFilter('date_planned_work')}
      />
      <SelectFromEnum
        value={formData['work_comment']}
        schema={workComment}
        onChange={onChangeFilter('work_comment')}
        UISchema={UISchemaSelectFromEnumWorkComment}
      />
      <InputDateRange
        value={formData['date_probationary']}
        name="Дата прохождения испытательного срока + 2 недели, после выхода на работу"
        onChange={onChangeFilter('date_probationary')}
      />
      <SelectFromEnum
        value={formData['work_comment']}
        schema={resultTrialPeriod}
        onChange={onChangeFilter('work_comment')}
        UISchema={UISchemaSelectFromEnumResultTrialPeriod}
      />
    </Space>
  )
}

export default Filter