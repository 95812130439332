import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useHistory, useLocation} from 'react-router-dom'
import {Alert, Button, Pagination, Space, Table} from 'antd'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import {formDataToUrlParams} from '../list-orders/utils'
import {calcTotalWidth, tableRowClick} from '../../../lib/utils/list'
import {PaginationWrapper, TableWrapper} from './styles'
import {useSelector} from 'react-redux'
import {createSelector} from 'reselect'
import dayjs from 'dayjs'
import Filters from './filters'
import './index.css'
import { PlusOutlined} from '@ant-design/icons'

const createViewed = createSelector(
  (state: any) => state.viewed,
  counts => counts
)

export default function AdminObjectsListPage() {
  const history = useHistory()
  const location = useLocation()
  const filtersRef = useRef<any>({})
  const [candidates, setCandidates] = useState<any[]>([])
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [totalRows, setTotalRows] = useState<number>(0)
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const [page, setPage] = useState<number>(1)
  const { counts } = useSelector(createViewed)
  const breadcrumbs = useMemo(() => (
    [{
      href: location.pathname,
      title: 'Учет кандидатов',
    }]
  ), [location.pathname])

  useEffect(()=> {
    (async () => {
      const [candidateCollections] = await Promise.all([
        fetchAPI(`/api/hr/candidate`),
      ])

      const candidates: any[] = getDataOfType(candidateCollections, 'data.data', Array, [])
      setCandidates(candidates)
    })()
  }, [])

  const addDataColumns = useMemo( () => {
    const addDataColumns: any[] = [
      {
        title: 'ID',
        width: 100,
        dataIndex: 'id',
        key: 'id',
        render: (_, item) => getDataOfType(item, 'id', Number, '')
      },
      {
        title: 'ФИО',
        width: 150,
        dataIndex: 'fio',
        key: 'fio',
        //render: (key) => candidates.find((item) => item.id === key)?.fio || ''
      },
      {
        title: 'Возраст',
        width: 150,
        dataIndex: 'age',
        key: 'age',
      },
      {
        title: 'Телефон',
        width: 150,
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: 'Вакансия',
        width: 150,
        dataIndex: 'vacancy',
        key: 'vacancy',
      },
      {
        title: 'Ресурс',
        width: 150,
        dataIndex: 'resource',
        key: 'resource',
      },
      {
        title: 'Город',
        width: 150,
        dataIndex: 'city',
        key: 'city',
      },
      {
        title: 'Район',
        width: 150,
        dataIndex: 'region',
        key: 'region',
      },
      {
        title: 'Дата повторного звонка и отправка приглашения',
        width: 200,
        dataIndex: 'date_callback',
        key: 'date_callback',
        render: key => key && dayjs(key).format('DD.MM.YYYY hh:mm')
      },
      {
        title: 'Причина отказа',
        width: 150,
        dataIndex: 'rejection',
        key: 'rejection',
      },
      {
        title: 'Статус обращения',
        width: 150,
        dataIndex: 'status',
        key: 'status',
      },
      {
        title: 'Дата собеседования',
        width: 200,
        dataIndex: 'date_interview',
        key: 'date_interview',
        render: key => key && dayjs(key).format('DD.MM.YYYY hh:mm')
      },
      {
        title: 'Пришел на собеседование',
        width: 150,
        dataIndex: 'came_request',
        key: 'came_request',
        render: (key) => key ? 'Да' : 'Нет'
      },
      {
        title: 'Повторный звонок не пришедшему кандидату',
        width: 150,
        dataIndex: 'date_repeated_call',
        key: 'date_repeated_call',
        render: key => key && dayjs(key).format('DD.MM.YYYY hh:mm')
      },
      {
        title: 'Причина отказа явки кандидата',
        width: 150,
        dataIndex: 'rejection_candidate',
        key: 'rejection_candidate',
      },
      {
        title: 'Планируемая дата выхода на работу',
        width: 200,
        dataIndex: 'date_planned_work',
        key: 'date_planned_work',
        render: key => key && dayjs(key).format('DD.MM.YYYY hh:mm')
      },
      {
        title: 'Конечный результат о выходе на работу',
        width: 150,
        dataIndex: 'work_comment',
        key: 'num_tech',
        render: (key) => key ? 'Да' : 'Нет'
      },
      {
        title: 'Дата прохождения испытательного срока + 2 недели, после выхода на работу',
        width: 200,
        dataIndex: 'date_probationary',
        key: 'date_probationary',
        render: key => key && dayjs(key).format('DD.MM.YYYY hh:mm')
      },
      {
        title: 'Результат по истечению испытательно срока 2 недели',
        width: 150,
        dataIndex: 'result_trial_period',
        key: 'result_trial_period',
        render: (key) => key ? 'Да' : 'Нет'
      },
      {
        title: 'Причина не прохождения испытательного срока',
        width: 150,
        dataIndex: 'result_trial',
        key: 'result_trial',
      },
      {
        title: 'Примечания',
        width: 150,
        dataIndex: 'comment',
        key: 'comment',
      },
    ]
    return addDataColumns
  }, [candidates])

  const totalWidth: any = useMemo(() => calcTotalWidth( addDataColumns), [ addDataColumns])
  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(addDataColumns).filter(item => item.title), [addDataColumns])
  const cbTableRowClick = useCallback((item) => item['id'] && history.push(`/hr/${item['id']}`), [history])

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  }, [])

  const getData = useCallback(async (value, addUrlParams?: string) => {
    const urlParams = formDataToUrlParams(value)
    const pageErrors: string[] = []

    const [requestCollections] = await Promise.all([
      fetchAPI(`/api/hr/request?sort_field=hr_request.id&${urlParams}&${addUrlParams}`),
    ])

    const dataSource: any[] = getDataOfType(requestCollections, 'data.data', Array, [])
    const newDataSource = dataSource.map(item => ({
      ...item,
      deleteRecord,
    }))

    const totalRows = getDataOfType(requestCollections, 'data.total-rows', Number, 0)
    setTotalRows(totalRows)
    setDataSource(newDataSource)
    setPageErrors(pageErrors)
    setIsDataLoading(false)
  }, [deleteRecord])

  const onPagination = useCallback(async ( page, pageSize) => {
    setPage(page)
    const urlParams = `page=${page}&pageSize=${pageSize}`
    setIsDataLoading(true)
    await getData(filtersRef.current, urlParams)
    setIsDataLoading(false)
  }, [getData])

  useEffect(() => {
    const urlParams = `page=${page}&pageSize=10`
    page === 1 && getData(filtersRef.current,urlParams)
  }, [counts, page, getData])

  const rowClassName = useCallback(record => {
    return record?.['is_read'] ? 'table-row-dark' :  'table-row-light'
  }, [])

  const onFilter = useCallback(value => {
    Object.assign(filtersRef.current, value)
    getData(value)
  }, [getData])

  const onButtonCreate = useCallback(() => history.push(`/hr/new`), [history])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <Space direction="horizontal">
        <Button onClick={onButtonCreate} style={{ marginBottom: 16 }}>
          <PlusOutlined rev={undefined}/> Создать
        </Button>
      </Space>
      <Space align="baseline">
        <Filters onChange={onFilter} />
      </Space>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}
      <TableWrapper>
        <Table
          columns={finalColumns}
          rowClassName={rowClassName}
          size="small"
          dataSource={dataSource}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={false}
          loading={isDataLoading}
          onHeaderRow={(column: any) => ({
            style: {
              fontSize: column.key && column.key.substr(-3) === '-at' ? 9 : 13,
            },
          })}
          onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick) })}
        />
      </TableWrapper>
      {Boolean(totalRows) && <PaginationWrapper>
		  <Pagination defaultCurrent={1} pageSize={10} total={totalRows} onChange={onPagination} showSizeChanger={false} />
	  </PaginationWrapper>}
    </B2BLayout>
  )
}
