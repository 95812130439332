import fetchAPI from '../../../lib/utils/fetch-api'

export async function insetFileToColletion(file, parentId ){
  const name = 'file-s3'
  const writeFile = {
    date: file['date'] || '',
    name: file['name'],
    size: file['size'],
    type: file['type'] || '',
    url: file['url']
  }

  const requestBody = {
    'parent_id': parentId || 0,
    'data': {
      'name': writeFile['name'],
      'attributes': writeFile,
      'metadata': {
        'schema': name,
      },
    },
  }

  const result = await fetchAPI(`/api/collections/${name}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
  if (result['data'] && result['data']['id']){
    return result['data']['id']
  }
  return null
}

export async function getFileData(id){
  try {
    const res = await fetchAPI(`/api/collections/objects/${id}`)
    const object = res['data']
    const formData = object['data']['attributes']
    return {
      id,
      date: formData['date'] || '',
      type: formData['type'] || '',
      name: formData['name'],
      size: formData['size'],
      url: formData['url'],
    }
  } catch {
    return null
  }
}