import React, { useState, useRef, useEffect, useCallback } from 'react'
import {Form, Alert, Skeleton, Button, Space} from 'antd'
import {SaveOutlined} from '@ant-design/icons'
import {MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper} from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'
import {DefaultJsonForm} from '../../components/default-json-form'
import {createUISchemaVertical} from '../../../lib/utils/grid-fild-orientation'
import fieldsSchema from './json-schema.json'
import TableConversion from './table-conversion'
import {useSelector} from 'react-redux'
import {createSelector} from 'reselect'


const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)


function downloadUrl(url, exportName) {
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', url)
  downloadAnchorNode.setAttribute('download', exportName)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

const fieldsFormDataCode = {
  'conversion-organization': ['date-range', 'organization', 'manager-name'],
  'rating-organization': ['organization'],
  'income-expenses': ['stage', 'order-group', 'date-range', 'organization']
}

function isFormDataValid(formData, reportCode) {
  const fields = getDataOfType(fieldsFormDataCode, reportCode, Array, []) as string[]
  const keys = Object.keys(formData)

  return fields.map(item => keys.includes(item)).every(item => item) &&
    Object.values(formData).every(item => item)
}

const rolesAll = [19, 12, 3, 4, 5, 6, 18]

function isDublicate(arr1, arr2) {
  const concatArray = arr1.concat(arr2)
  return concatArray.length > Array.from(new Set(concatArray)).length
}

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
}

const breadcrumbs = [
  {
    href: '/reports',
    title: 'Отчеты',
  },
  {
    href: `/reports`,
    title: 'Список отчетов',
  },
]

export default function AdminNewObjectPage() {
  const [id, setId] = useState<any>(null)
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [isDisabledButtons, disableButtons] = useState(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [object, setObject] = useState<any>({})
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [tableData, setTableData] = useState<any>({titles: [], rows: []})
  const {sessionData} = useSelector(createSession)

  const getData = useCallback(async (sessionData) => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    const userId = getDataOfType(sessionData, 'user.id', Number, 0)
    /*
    const isDisabledOrganization = !isDublicate(roles, rolesAll)
    if (isDisabledOrganization) {
      formDataRef.current.organization = organizationId
    }
    const isDisabledUsers = !isDublicate(roles, rolesAll.concat(24))
    if (isDisabledUsers) {
      formDataRef.current.users = [userId]
    }
*/


    {
      await fetchAPI('/api/order_report/enum')
        .then(result => {
            const enumItems = result || {}
            Object.keys(enumItems).forEach(code => {
              let schemaCode = code
              if (code === 'users') schemaCode = 'manager-name'
              if (code === 'organization') schemaCode = 'organization-title'

              if (fieldsSchema?.properties.hasOwnProperty(schemaCode)) {
                const enumValues = result?.[code] || {}
                const oneOf: Record<string, string>[] = []
                Object.keys(enumValues).forEach(enumValue => {
                  if( enumValues.length === 1 )
                    formDataRef.current[code] = enumValues[enumValue]

                  return oneOf.push({
                    'const': enumValues[enumValue],
                    'title': enumValues[enumValue]
                  })
                })

                Object.assign(fieldsSchema.properties[schemaCode], {
                  oneOf: oneOf,
                  'enum': enumValues,
                  view: {'name': 'select-from-enum'},
                })
              }
            })
        })
    }

    const UISchema = createUISchemaVertical(fieldsSchema)

    function isDisabled(fieldName: string) {
      return fieldsSchema.properties[fieldName].enum.length <= 1
    }

    if ('manager-name' in UISchema) {
      UISchema['manager-name'] = {
        ...UISchema['manager-name'],
        options: {
          multiple: true,
          disabled: isDisabled('manager-name')
        },
      }
    }
    if ('organization-title' in UISchema) {
      UISchema['organization-title'] = {
        ...UISchema['organization-title'],
        options: {
          multiple: false,
          disabled: isDisabled('organization-title')
        },
      }
    }
    if ('city' in UISchema) {
      UISchema['city'] = {
        ...UISchema['city'],
        options: {
          multiple: false,
          disabled: isDisabled('city')
        },
      }
    }

    setUISchema(UISchema)
    setMatchedJSONSchema(fieldsSchema)

    setPageErrors([])
    setTableData({titles: [], rows: []})
    setIsDataLoading(false)
  }, [formDataRef])

  useEffect(() => {
    getData(sessionData)
  }, [getData, sessionData])

  const onFormData = useCallback(
    ({formData}) => {

      formDataRef.current = {...formData}

      function isDisabled(fieldName: string) {
        return fieldsSchema.properties[fieldName].enum.length <= 1
      }

      setUISchema(UISchema => {
        const UISchemaNext = {...UISchema}
        if ('manager-name' in UISchemaNext) {
          UISchemaNext['manager-name'] = {
            ...UISchemaNext['manager-name'],
            options: {
              allowClear: true,
              multiple: true,
              disabled: isDisabled('manager-name')
            }
          }
        }
        if ('organization-title' in UISchemaNext) {
          UISchemaNext['organization-title'] = {
            ...UISchemaNext['organization-title'],
            options: {
              allowClear: true,
              multiple: true,
              disabled: isDisabled('organization-title')
            }
          }
        }
        if ('city' in UISchemaNext) {
          UISchemaNext['city'] = {
            ...UISchemaNext['city'],
            options: {
              allowClear: true,
              multiple: true,
              disabled: isDisabled('city')
            }
          }
        }
        return UISchemaNext
      })
      disableButtons(() => {
        return Object.values(formDataRef.current).some(el => !el )
      })
    }, [sessionData, formDataRef.current]
  )

  const updateTable = useCallback(async () => {
      const formData = formDataRef.current

      Object.keys(formData)
        .forEach(key => formData[key] === undefined || (Array.isArray(formData[key]) && formData[key].length === 0) ? delete formData[key] : {})

      const params = Object.entries(formData)
        .map(([key, value]: any[]) => {
          const localValue = Array.isArray(value) ? value.join('|') : value
          return `filter[${key}]=${encodeURIComponent(localValue)}`
        })
        .join('&')

      const result = await fetchAPI(`/api/order_report?${params}`)
      const data = getDataOfType(result, 'data', Object, {})

      setTableData(data)
    },
    [object]
  )

  const onPrint = useCallback(
    async () => {
      const formData = formDataRef.current

      Object.keys(formData)
        .forEach(key => formData[key] === undefined || (Array.isArray(formData[key]) && formData[key].length === 0) ? delete formData[key] : {})

      const params = Object.entries(formData)
        .map(([key, value]: any[]) => {
          const localValue = Array.isArray(value) ? value.join('|') : value
          return `filter[${key}]=${encodeURIComponent(localValue)}`
        })
        .join('&')

      downloadUrl(`/api/order_report/excel?${params}`, `report_marketing.xlsx`)
    },
    [object]
  )

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            Конверсии
          </MapTitle>
        </TaskWrapper>
        <MapContent>

          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {isDataLoading === false ? (
              UISchema && (
                <DefaultJsonForm
                  formData={formDataRef.current}
                  schema={matchedJSONSchema}
                  onChange={onFormData}
                  UISchema={UISchema}
                />
              )
            ) : (
              <Skeleton active/>
            )}
          </Form>
          <Space>
            <Button onClick={updateTable} style={{marginBottom: 16, marginTop: 16}} disabled={isDisabledButtons}>
              <SaveOutlined rev={undefined}/>
              Применить фильтры
            </Button>
            <Button onClick={onPrint} style={{marginBottom: 16, marginTop: 16}} disabled={isDisabledButtons} type="primary">
              <SaveOutlined rev={undefined}/>
              Экспорт в эксель
            </Button>
          </Space>
          {isDataLoading === false ? (
            <TableConversion titles={tableData.titles} rows={tableData.rows}/>
          ) : (
            <Skeleton active/>
          )}
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
