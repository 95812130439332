import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Table, Alert, Tooltip, Space, Radio } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import dayjs from '../../../lib/utils/configured-dayjs'
import FieldHideText from '../../components/field-hide-text'
import CellUserDataTime from './cell-user-data-time'
import CellDocument from './cell-document'
import moment from 'moment'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { createTableFields, calcTotalWidth } from '../../../lib/utils/list'
import './index.css'

const  name  = 'tasks'

function subDate(isoDate){
  const isoTime = 'T00:00:00.000Z'
  return moment(isoDate).format('YYYY-MM-DD') + isoTime
}

const beforeDataColumns: any = [
  {
    className: 'table-super',
    title: 'ID',
    width: 60,
    dataIndex: 'id',
    key: 'id',
    //fixed: 'left',
    defaultSortOrder: 'descend',
    sorter: (a, b) => a.id - b.id,
  },
  {
    className: 'table-super',
    title: 'Дата исполнения / ОТВ.',
    width: 180,
    //fixed: 'left',
    render: (_, { date, time, responsible }) => <CellUserDataTime responsible={responsible} date={date} time={time} />,
  },
  {
    className: 'table-super',
    title: 'Документ / Контакт',
    width: 180,
    //fixed: 'left',
    render: (_, props) => <CellDocument parentId={props['parentId']} />,
  },
  {
    className: 'table-super',
    title: 'Автор',
    width: 100,
    dataIndex: 'author',
    key: 'author',
    //fixed: 'left',
  },
  {
    className: 'table-super',
    title: 'Текст задачи',
    dataIndex: 'comment',
    key: 'comment',
    width: 180,
    //fixed: 'left',
    render: text => FieldHideText({ text }),
  },
]

const afterDataColumns: any = [{
    title: 'Дата создания',
    dataIndex: 'createdAt',
    key: 'created-at',
    sorter: (a, b) => a.id - b.id,
    render: (text, record) => (
      <Tooltip placement="top" title={dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
        <span>{dayjs(record.createdAt).fromNow()}</span>
      </Tooltip>
    ),
    width: 100,
  }, {
    title: 'Дата обновления',
    dataIndex: 'updatedAt',
    key: 'updated-at',
    sorter: (a, b) => a.id - b.id,
    render: (text, record) => (
      <Tooltip placement="top" title={dayjs(record.updatedAt).format('YYYY-MM-DD HH:mm:ss')}>
        <span>{dayjs(record.updatedAt).fromNow()}</span>
      </Tooltip>
    ),
    width: 100,
  }
]

function getDateRange(value) {
  switch (value) {
    case 'Overdue': {
      const time = moment.duration('01:00:00')
      return '0-' + moment().subtract(time).unix()
    }
    case 'Day': {
      const currentDate = moment(subDate(moment().toISOString()))
      const time = moment.duration('00:01:00')
      return currentDate.unix() + '-' + currentDate.add(1, 'days').subtract(time).unix()
    }
    case 'Week': {
      const currentDate = moment(subDate(moment().toISOString()))
      const time = moment.duration('00:01:00')
      return currentDate.unix() + '-' + currentDate.add(7, 'days').subtract(time).unix()
    }
    case 'Month': {
      const currentDate = moment(subDate(moment().toISOString()))
      const time = moment.duration('00:01:00')
      return currentDate.unix() + '-' + currentDate.add(30, 'days').subtract(time).unix()
    }
    default:
      return '0-0'
  }
}

export function TasksListPage() {
  const history = useHistory()
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const breadcrumbs = useMemo(() => (
    [{
      href: '/' + name,
      title: 'Задачи',
    }]
  ), [])

  const totalWidth: any = useMemo(() => calcTotalWidth(beforeDataColumns, afterDataColumns), [])

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(beforeDataColumns, afterDataColumns).filter(item => item.title), [])

  useEffect(() => {
    const getData = async () => {
      await onChange({ target: { value: 'Day' } })
    }

    getData()
    setPageErrors([])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  function handleCreateNewClick() {
    history.push(`/tasks/new`)
  }

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  },[])

   const onChange = useCallback(async ({ target }) => {
    const dateRange = getDateRange(target.value)
    setIsDataLoading(true)
    const collectionsResult = await fetchAPI(`/api/tasks/${name}?access_key=axioma&dateRange=${dateRange}`)
    const usersData = await fetchAPI(`/api/users`)
    if (collectionsResult) {
      const collectionsResultData = (collectionsResult['data'] && collectionsResult['data']['data']) || []
      const newDataSource: any[] = []
      const dataColumnByKey: object = {}

      const users = getDataOfType(usersData, 'data', Array, []).map(item => Object.assign({ 'name': item['login'] }, item))
      collectionsResultData.forEach(collectionItem => {
        const attributes = (collectionItem['data'] && collectionItem['data']['attributes']) || {}
        const fieldEnumNames = (collectionItem['data'] && collectionItem['data']['fieldEnumNames'])

        Object.keys(attributes).forEach(name => {
          dataColumnByKey[name] = {
            title: '',
            width: 120,
            dataIndex: name,
            key: name,
            fixed: 'left',
          }
        })
        const properties = (collectionItem['data'] && collectionItem['data']['attributes']) || {}
        const filteredProperties = {}

        Object.keys(properties).forEach(propertyKey => {
          if (properties[propertyKey] && properties[propertyKey].constructor !== Object) {
            filteredProperties[propertyKey] = properties[propertyKey]
            dataColumnByKey[propertyKey] = {
              title: propertyKey,
              width: 120,
              dataIndex: propertyKey,
              key: propertyKey,
              fixed: 'left',
            }
          }
        })
        const responsible = (attributes.responsible || []).map(id => {
          return users.find(item => item.id === id)
        }).filter(id => id)
        const fields = createTableFields(attributes, fieldEnumNames)

        const authorId = getDataOfType(attributes, 'author', [String, Number], null)
        const author = (users.find(item => Number(item.id) === authorId) || {})['name'] || ''


        newDataSource.push({
          id: collectionItem['id'],
          color: collectionItem['color'],
          parentId: collectionItem['parent-id'],
          ...fields,
          responsible,
          author,
          collectionName: name,
          createdAt: collectionItem['created-at'],
          updatedAt: collectionItem['updated-at'],
          deleteRecord,
        })
      })

      setDataSource(newDataSource)
      setIsDataLoading(false)
    }
  }, [deleteRecord])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <Space style={{ marginBottom: 16 }} size={40}>
        <Button onClick={handleCreateNewClick}>
          <PlusOutlined rev={undefined}/>
          Создать задачу
        </Button>
        <Radio.Group style={{ width: '100%' }} onChange={onChange} defaultValue="Day">
          <Space>
            <Radio value="Day">День</Radio>
            <Radio value="Week">Неделя</Radio>
            <Radio value="Month">Месяц</Radio>
            <Radio value="Overdue">Просроченные</Radio>
          </Space>
        </Radio.Group>
      </Space>
      {pageErrors.length > 0 ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
          />
        </div>
      ) : null}
      <Table
        columns={finalColumns}
        rowClassName={record =>  record?.color ? 'table-row-dark' :  'table-row-light'}
        size="small"
        dataSource={dataSource}
        scroll={{ x: totalWidth, y: '60vh' }}
        pagination={false}
        loading={isDataLoading}
        onHeaderRow={(column: any) => ({
          style: {
            fontSize: column.key && column.key.substr(-3) === '-at' ? 9 : 13,
          },
        })}
      />
    </B2BLayout>
  )
}
