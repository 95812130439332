import React, {useCallback, useMemo} from "react"
import {IPhone} from './interface'
import {DashOutlined, PhoneOutlined} from '@ant-design/icons'
import {Button} from 'antd'
import {FileButton} from './styles'
import fetchAPI from '../../../lib/utils/fetch-api'
import {modalPhone} from './mango-call'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import ru from 'react-phone-input-2/lang/ru.json'


function parsePhone(phone) {
  if (typeof phone === 'string') {
    return phone.replace(/[^\d\+]/g, '')
  }
  return null
}

function InputPhone({ value, onChange, UISchema } : IPhone) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const areaCodes = ['310', '311', '312', '313', '315', '318', '321', '324', '325', '326', '327', '336', '7172', '73622'];
  const areaCodesWithBrackets = areaCodes.map((code) => `(${code})`);

  const handleChange = ({phone}) => {
    onChange(`+${phone}`)
  }

  const onCall = useCallback(() => {
    modalPhone({phone: value})
   }, [value])

  const onClient = useCallback(async () => {
    const phone = parsePhone(value)
    const id = await fetchAPI(`/api/user/${phone}`).then(item => item?.['data']?.['id'])
    if (!Number.isInteger(id)) {
      alert('Клиент с таким номером не найден в базе')
      return
    }
    Object.assign(window.location, { href: `/clients/${id}` })
  }, [value])

  return (
    <div style={{ display: 'flex', width: 'max-content'}} className="input_phone_number">
      <FileButton disabled={false} onClick={onClient} className="ant-input"><DashOutlined rev={undefined}/></FileButton>
      <PhoneInput
        enableSearch={true}
        searchPlaceholder={'Поиск'}
        localization={ru}
        country={'ru'}
        onlyCountries={['ru','kz','by','tj','uz','am','kg','az','md','tm']}
        value={value}
        disabled={disabled}
        containerStyle={{width: 'auto'}}
        inputStyle={{borderRadius: '2px',height: '32px'}}
        areaCodes={areaCodesWithBrackets}
        masks={{kz: '(...) ..-..-..'}}
        isValid={(number, country) => {
          if( number.length < 3)
              return true
          const regex = /\./g;
          const format = country?.['format']
          if( format ) {
            const length = (format.match(regex) || []).length;
            if (number.length === length) {
              handleChange({phone: number})
              return true
            }
          }
          return false
        }}
      />
      <Button type="primary" icon={<PhoneOutlined rev={undefined}/>} onClick={onCall}  />
    </div>
  )
}

export default InputPhone