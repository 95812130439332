import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Button, Table } from 'antd'
import { PlusOutlined, FolderAddOutlined } from '@ant-design/icons'
import qs from 'query-string'

import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { columns } from './consts'
import { IGroupedCollections } from './interface'
import { useChunkDataload } from '../../hooks/use-chunk-dataload'
import { useSorter } from '../../hooks/use-sorter'
import { ButtonWrapper } from './styles'
import { AdminEditGroup } from '../../views/admin-edit-group'
import { ICollectionsGroup } from '../../views/admin-edit-group-form'
import { prepareGroups } from '../../utilits/groups'
import { EditableCell } from '../../components/editable-cell'

function textUser(response, userId){
  if(Boolean(response['data']) === false){
    return ''
  }
  const users = response['data']['users']
  const user = users.find(row => Number(row['id']) === Number(userId)) || { name: userId }
  return user['name']
}

export function AdminCollectionsListPage() {
  const history = useHistory()
  const breadcrumbs = [{
    href: '/admin',
    title: 'Администрирование'
  }, {
    href: '/admin/collections',
    title: 'Справочники',
  }]
  let totalWidth: any = 0
  columns().forEach(({ width }) => {
    totalWidth += width
  })

  const [dataSource, setDataSource] = useState<IGroupedCollections[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [tableNode, setTableNode] = useState<HTMLDivElement | null>(null)
  const [totalData, setTotalData] = useState(0)
  const [orderBy, setOrderBy] = useState('id')
  const [orderDir, setOrderDir] = useState('desc')
  const [zeroOutPage, setZeroOutPage] = useState(false)
  const [forceUpdate, setForceUpdate] = useState(false)
  const [currentOrder, setCurrentOrder] = useState({ orderBy: 'id', orderDir: 'desc' })
  const [showGroupModal, setShowGroupModal] = useState(false)
  const [collectionGroups, setCollectionGroups] = useState<ICollectionsGroup[]>([])
  const [preparedGroups, setPreparedGroups] = useState<any[]>([])
  const wrapperRef = useRef<HTMLDivElement>(null)
  const expandedList = useRef<Record<number, boolean>>({})

  const getCreateGroupURL = useCallback(() => 'collections/group/', [])
  const getUpdateGroupURL = useCallback(id => `collections/group/${id}`, [])

  const handleSort = useCallback((key, direction) => {
    setOrderBy(key)
    setOrderDir(direction)
  }, [])

  const { onColumnSort } = useSorter({
    onSort: handleSort,
  })

  /** Request data from API */
  const getData = useCallback((page = 0, limit = 10) => {
      setIsDataLoading(true)
      const collectionsQuery = qs.stringify({
        access_key: 'axioma',
        fields: 'scope,title,description,metadata,created_at,updated_at,owner_user',
        page,
        limit: 1000,
        order_by: orderBy,
        order_dir: orderDir,
      })
      const prepareDataSource: (rows: IGroupedCollections[]) => IGroupedCollections[] = rows => {
      const result: IGroupedCollections[] = []

      rows.forEach(item => {
        const isGroup = 'is-group' in item && item['is-group']
        const key = isGroup ? item['id'] * 1000 : item['id']
        let scopeCell = item['scope']
        const metadata = item['metadata'] || {}

        if (item['scope'] === 'organization') {
          scopeCell = 'Организация'
        }

        const children = item['children'] && item['children']?.length > 0
          ? prepareDataSource(item['children'])
          : null

          result.push({
            key,
            id: item['id'],
            ownerOrganization: item['owner-organization'],
            ownerUser: textUser({}, item['owner-user']),
            scope: scopeCell,
            name: isGroup ? '' : item['name'],
            title: isGroup ? item['name'] : item['title'],
            description: item['description'],
            schema: metadata['schema'],
            groupId: metadata['group_id'],
            children,
            isGroup: Boolean(isGroup),
            createdAt: item['created-at'],
            updatedAt: item['updated-at'],
          })
        })

      return result
    }

    fetchAPI(`/api/collections/grouped?${collectionsQuery}`)
      .then(response => {
        const rows = (response['data'] && response['data']['data']) || []
        const newDataSource: IGroupedCollections[] = page === 0 ? [] : [ ...dataSource ]
        const preparedRows = prepareDataSource(rows)

        setTotalData(response['data']['total'])
        setDataSource(newDataSource.concat(preparedRows))
        setIsDataLoading(false)
      })
      .catch(err => {
        setIsDataLoading(false)
      })
  }, [dataSource, orderBy, orderDir])

  const handleGetData = useCallback((page: number, limit: number) => {
    getData(page, limit)
    setZeroOutPage(false)
  }, [getData])

  /** Add event listener on scroll of table for getting data from getData */
  useChunkDataload({
    total: totalData,
    dataCount: dataSource.length,
    node: tableNode,
    zeroOutPage,
    forceUpdate,
    dataloadCallback: handleGetData
  })

  const getGroups = useCallback(async () => {
    let groups

    try {
      groups = await fetchAPI('/api/collections/groups')
      setCollectionGroups(groups['data'])
    } catch (error) {
      console.error('Something going wrong: ', error)
    }

    return groups['data']
  }, [])

  const handleAfterGroupCreate = useCallback(async () => {
    getData()
    await getGroups()
  }, [getData, getGroups])

  const handleGroupUpdate = useCallback(async record => {
    setIsDataLoading(true)
    const targetURL = `/api/collections/group/${record?.id}`
    const data = {
      name: record['title'],
    }
    await fetchAPI(targetURL, {
      method: 'PUT',
      body: JSON.stringify({
        data: data,
      })
    })
    await handleAfterGroupCreate()
    setIsDataLoading(false)
  }, [handleAfterGroupCreate])

  const parsedColumns = useMemo(() => columns(onColumnSort, preparedGroups, handleAfterGroupCreate, handleGroupUpdate, expandedList.current),
    [handleAfterGroupCreate, handleGroupUpdate, onColumnSort, preparedGroups])

  useEffect(() => {
    handleAfterGroupCreate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    setPreparedGroups(prepareGroups(collectionGroups))
  }, [collectionGroups])

  /** Start request data with zero out of sorting when changing order by or order direction */
  useEffect(() => {
    if (currentOrder['orderBy'] !== orderBy || currentOrder['orderDir'] !== orderDir) {
      setCurrentOrder({
        orderDir,
        orderBy,
      })
      setZeroOutPage(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, orderDir])

  /** Start request data when changed direction of current sorting */
  useEffect(() => {
    setForceUpdate(currentOrder['orderBy'] === orderBy && currentOrder['orderDir'] !== orderDir)
  }, [currentOrder, orderBy, orderDir])

  useEffect(() => {
    if (!isDataLoading && !tableNode) {
      if (wrapperRef !== null && wrapperRef.current !== null) {
        const node = wrapperRef.current.querySelector<HTMLDivElement>('.ant-table-body')

        if (node) {
          setTableNode(node)
        }
      }
    }
  }, [isDataLoading, tableNode])

  const handleCreateNewClick = useCallback(() => {
    history.push('/admin/collections/new')
  }, [history])

  const handleCreateGroup = useCallback(() => {
    setShowGroupModal(true)
  }, [])

  const toggleGroupModal = useCallback(() => {
    setShowGroupModal(prevState => !prevState)
  }, [])

  const getFontSize = useCallback((key?: string) => (key && key.substr(-3) === '-at') ? 10 : 13, [])

  const components = useMemo(() => ({
    body: {
      cell: EditableCell,
    },
  }), [])

  const handleExpand = useCallback((expand, record) => {
    expandedList.current[record['key']] = expand
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <div ref={wrapperRef}>
        <ButtonWrapper>
          <Button onClick={handleCreateNewClick}>
            <PlusOutlined rev={undefined}/>
            Создать справочник
          </Button>
          <AdminEditGroup
            createURL={getCreateGroupURL}
            updateURL={getUpdateGroupURL}
            showModal={showGroupModal}
            groups={preparedGroups}
            toggleModal={toggleGroupModal}
            onAfterSave={handleAfterGroupCreate}
          >
            <Button onClick={handleCreateGroup}>
              <FolderAddOutlined rev={undefined}/>
              Создать группу
            </Button>
          </AdminEditGroup>
        </ButtonWrapper>
        <Table
          columns={parsedColumns}
          dataSource={dataSource}
          components={components}
          scroll={{ x: totalWidth, y: '72vh' }}
          pagination={false}
          loading={isDataLoading}
          onHeaderRow={(column: any) => ({
            style: {
              fontSize: getFontSize(column.key),
            }
          })}
          expandable={{
            onExpand: handleExpand
          }}
        />
      </div>
    </B2BLayout>
  )
}

export default AdminCollectionsListPage
