import React, {useState, useRef, useEffect, useCallback} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import {DefaultJsonForm} from '../../components/v2/default-json-form'
import {Form, Alert, Skeleton, Button, Tabs} from 'antd'
import {SaveOutlined} from '@ant-design/icons'
import {MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper} from './styles'
import defaultSelectedSchema from './json-schema.json'
import {objectMap} from '../../../lib/utils/get-table-transform-schema-and-form-data'
import TabOrderList from './tab-order-list'

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
}

const breadcrumbs = [
  {
    href: '/',
    title: 'Контактный центр',
  },
  {
    href: `/users-new`,
    title: 'Список сотрудников',
  },
]

function setDisabledFields(data, schema) {
  const disabledFields = data.filter(obj => obj.disabled === true)

  disabledFields.forEach(function (obj) {
    if (schema.properties[obj.name]) {
      schema.properties[obj.name].options = {...schema.properties[obj.name].options, disabled: true}
    }
  })
}

function setEnumFields(enums, schema) {
  schema.properties = objectMap(schema['properties'], ([key, value]) => {
    if (enums[key]) {
      const enumItems = enums[key].map(a => a.id);
      const oneOf = enums[key].map(a => {
        return {
          const: a.id,
          title: a.name,
        }
      });
      value = {...value, "enum": enumItems, oneOf}
    }
    return [key, {...value}]
  })
}

export default function AdminNewObjectPage() {
  const {id} = useParams() as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [pageSuccess, setPageSuccess] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [form] = Form.useForm()
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)

  const saveCase = useCallback(
    async () => {
      const formData = {...formDataRef.current}
      await fetchAPI(`/api/users-new/${id}`, {
        method: 'POST', body: JSON.stringify(formData)
      }).then(result => {
        if (result?.error) {
          const errors =
            Array.isArray(result['errors']) && result['errors']?.length > 0
              ? result['errors'].map((error) => error['detail'])
              : ['Неизвестная ошибка при выполнении запроса']
          setPageSuccess([])
          setPageErrors(errors)
        } else {
          setPageSuccess(['Запись успешно обновлена.'])
          setPageErrors([])
        }
      })
    },
    [history, id],
  )

  const getData = useCallback(async () => {
    {
      const clientData = await fetchAPI(`/api/users-new/${id}`)
      const enums = await fetchAPI(
        `/api/enum-list?types[]=roles`,
      )

      const formData = clientData.reduce((obj, cur) => ({...obj, [cur.name]: cur.value}), {})

      setDisabledFields(clientData, defaultSelectedSchema)
      setEnumFields(enums, defaultSelectedSchema)

      formDataRef.current = {...formData}
      setMatchedJSONSchema(defaultSelectedSchema)
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData().catch(e => {
      console.error({e})
    })
  }, [getData])

  const onFormData = useCallback(({formData}) => {
    formDataRef.current = formData
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Сотрудник</MapTitle>
        </TaskWrapper>
        <MapContent>
          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {pageSuccess.length > 0 ? (
              <div style={{marginBottom: 40}}>
                <Alert
                  message={pageSuccess.join('. ')}
                  showIcon
                  type="success"
                />
              </div>
            ) : null}
            {!isDataLoading ? (
              <DefaultJsonForm
                formData={formDataRef.current}
                schema={matchedJSONSchema}
                onChange={onFormData}
                orientation="horizontal"
              />
            ) : (
              <Skeleton active/>
            )}
          </Form>
          <Button onClick={saveCase} style={{marginBottom: 16, marginTop: 16}} type="primary">
            <SaveOutlined rev={undefined}/>
            Сохранить
          </Button>
          <Tabs type="card">
            <Tabs.TabPane tab="История заказов" key="1">
              <TabOrderList clientId={id} />
            </Tabs.TabPane>
          </Tabs>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}



