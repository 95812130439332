import React, { useEffect, useState, useMemo, useCallback } from 'react'
import fetchAPI from '../../../lib/utils/fetch-api'
import { Select } from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

function filterOption(input, option){
  return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

function MultiSelect({ value, schema, onChange, UISchema }:{ value?:any, schema?:any, onChange?:any, UISchema?:any }) {
  const disabled = useMemo(() => getDataOfType( UISchema, 'options.disabled', Boolean, false ), [UISchema])
  const mode = useMemo(() => getDataOfType( UISchema, 'options.mode', String, null ), [UISchema])
  const placeholder = useMemo(() => getDataOfType( UISchema, 'options.placeholder', String, 'Выберите запись' ), [UISchema])

  const [values, setValues] = useState<any[]>((!mode) ? value : Array.isArray(value) ? value: [])
  const [objects, setObjects] = useState<any[]>([{ id: value, value }])

  useEffect(() => {
    (async () => {
      const collectionName = schema?.name;
      const objectsResult: any = await fetchAPI(`/api/collections/${collectionName}`)
        .then(item => getDataOfType(item, 'data.data', Array, {}))
      const objectsFormat = objectsResult.map(
        item => {
          return {
            id: getDataOfType(item, 'id', Number, null),
            value: getDataOfType(item, 'name', String, null)
          }
        })
        .filter(item => item['id'])
      setObjects(objectsFormat)

      if( objectsFormat.length === 1 && !mode && !value ) {
        setValues(objectsFormat[0])
        onChange(objectsFormat[0].id)
      }
    })()
  }, [])

  const onSearch = useCallback(text => {
    return text
  }, [])

  const changeHandler = useCallback( data => {
    setValues(data)
    onChange(data)
  }, [])

  return (
    <Select
      onSearch={onSearch}
      mode={mode}
      showSearch
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={changeHandler}
      value={values}
      disabled={disabled}
      filterOption={filterOption}
    >
    {objects.map(({ id, value }) => (
      <Select.Option key={id} value={id}>{value}</Select.Option>
    ))}
    </Select>
  )
}

export default React.memo(MultiSelect, (prev, next) => {
  if(prev.value === next.value){
    return true
  }
  return prev?.UISchema === next?.UISchema;
})
