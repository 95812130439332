import React, { useState, useRef, useEffect, useCallback } from 'react'
import { Form, Alert, Skeleton, Modal } from 'antd'
import { MapTitle, AlertWrapper, TaskWrapper } from './styles'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import { createUISchemaVertical } from '../../../lib/utils/grid-fild-orientation'
import userFieldsSchema from './json-schema.json'
import { isFormDataRequired } from '../../../lib/utils/collections'
import userFieldsSchemaAdd from './json.json'


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}



function concatSchema(s1, s2){
  const properties1 = getDataOfType(s1, 'properties', Object, {})
  const properties2 = getDataOfType(s2, 'properties', Object, {})
  return { 
    ...s2,
    ...s1,
    properties: { ...properties1, ...properties2 }
  }
}


export default function AdminNewObjectPage({ onChange, id: organizationId, data }) {
  //const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const refData = useRef<any>({})
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [, setRoles] = useState<any[]>([])
  const [, setInitRoles] = useState<any[]>([])
  const [, setProfilesType] = useState<any[]>([])
  const [profileTypeId, setProfileTypeId] = useState<any>(null)
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [organizationData, setOrganizationData] = useState<any>({})


  const rolesRef = useRef<any[]>([])



   const saveCase = useCallback(
    async (event) => {
      const id = 'new'
      const isRequired = isFormDataRequired(formDataRef.current, matchedJSONSchema)
      if (isRequired === false) {
        Modal.warning({
          title: 'Предупреждение',
          content: 'Заполните все обязательные поля'
        })
        return false
      }

      const rolesIds = rolesRef.current 
      const formData = { ...formDataRef.current }
      const userData = {
        'login': formData['login'],
        'password': formData['password']
      }
      delete formData['login']
      delete formData['password']

      await fetchAPI(`/api/roles-user/${id}?roles-ids=${rolesIds.join(',')}`, { method: 'PUT'})
      await fetchAPI(`/api/user-create-or-update/${id}/?organization-id=${organizationId}`, { method: 'POST', body: JSON.stringify({
        'profile-type-id': profileTypeId,
        'profile-data': formDataRef.current,
        'user-data': userData
      })})

        console.log('target', refData.current)
        onChange({ target: refData.current})



      return true
    },
    [onChange, profileTypeId, matchedJSONSchema, organizationId],
  ) 

  useEffect(() => {
    data.save = () => {
      return saveCase({})
    }
  }, [saveCase, data])

  const getData = useCallback(async () => {
    const id = 'new'
    {
      const result = await fetchAPI(`/api/profiles-types?type=user`)
      const profilesTypes = getDataOfType(result, 'data', Array, [])
      setProfilesType(profilesTypes)
      const defaultPrifileId = getDataOfType(profilesTypes, '[0].id', [String, Number], null)
      const defaultSelectedSchema = getDataOfType(profilesTypes, '[0].profile-schema', Object, null)
      const organizationData = await fetchAPI(`/api/organizations-from-user/${id}`)
      setOrganizationData(getDataOfType(organizationData, 'data', Object, {}))

      {
        const result = await fetchAPI(`/api/profiles?type=user&user-id=${id}`)
        const data = getDataOfType(result, 'data', Array, [])       
        const profileData = getDataOfType(data, '[0].profile-data', Object, {})
        const prifileId = getDataOfType(data, '[0].id', [String, Number], defaultPrifileId)
        const prifileTypeId = Number(getDataOfType(data, '[0].profile-type', [String, Number], 1))
        console.log(prifileId, prifileTypeId)
        const selectedSchema2 = getDataOfType(data, '[0].profile-schema', Object, defaultSelectedSchema)
        Object.assign(selectedSchema2)
        const selectedSchema = userFieldsSchemaAdd
        
        {
          const result = await fetchAPI(`/api/users/${id}`)
          const login = getDataOfType(result, 'data.login', String, '') 
          formDataRef.current = { ...profileData, 'login': login }
        }
        setProfileTypeId(prifileTypeId)
        setMatchedJSONSchema(concatSchema(userFieldsSchema, selectedSchema))
        const UISchema = createUISchemaVertical(concatSchema(userFieldsSchema, selectedSchema))
        setUISchema(UISchema)
      }
    }
    {
      const result = await fetchAPI(`/api/roles-user/${id}`)
      const data = getDataOfType(result, 'data', Array, [])
      rolesRef.current = data
      setInitRoles(data)
    }
    {
      const result = await fetchAPI(`/api/roles?filter[user-id]=${id}`)
      const data = getDataOfType(result, 'data', Array, null)
      console.log('roles',data)
      setRoles(data)
    }
    setPageErrors([])

    setIsDataLoading(false)
  }, [])

  useEffect(() => {
    getData()
  }, [getData])

  const onFormData = useCallback(
    ({ formData }) => {
      formDataRef.current = formData
    }, []
  )

/*   const onTableChange = useCallback(async (ids) => {
    rolesRef.current = ids
  }, []) */


  return (
    <div ref={refData}>

        <TaskWrapper>
          <MapTitle>
            {Boolean(organizationData.title) && <><span>{`(${organizationData.title},`}</span>
            <span>{`${organizationData['type-title']})`}</span></>}
          </MapTitle>
        </TaskWrapper>

          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {isDataLoading === false ? (
              UISchema && (
                <DefaultJsonForm
                  formData={formDataRef.current}
                  schema={matchedJSONSchema}
                  onChange={onFormData}
                  UISchema={UISchema}
                />
              )
            ) : (
              <Skeleton active />
            )}
          </Form>

    </div>
  )
}



