import React, {useState, useRef, useEffect, useCallback} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import {DefaultJsonForm} from '../../components/v2/default-json-form'
import {Alert, Skeleton, Button} from 'antd'
import {SaveOutlined} from '@ant-design/icons'
import {MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper} from './styles'
import defaultSelectedSchema from './json-schema.json'
import {objectToUrl, renameKeys, setEnumFields} from '../utils'

const breadcrumbs = [
  {
    href: '/admin',
    title: 'Админка',
  },
  {
    href: '/new/offices',
    title: 'Список офисов',
  },
]

function setDisabledFields(data, schema) {
  const disabledFields = data.filter(obj => obj.disabled === true)

  disabledFields.forEach(function (obj) {
    if (schema.properties[obj.name]) {
      schema.properties[obj.name].options = {...schema.properties[obj.name].options, disabled: true}
    }
  })
}


export default function AdminNewObjectPage() {
  const {id} = useParams() as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [pageSuccess, setPageSuccess] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)

  const saveCase = useCallback(
    async () => {
      const formData = {...formDataRef.current}
      const factId = (id !== 'new') ? 'new' : id
      await fetchAPI(`/api/office/${factId}`, {
        method: 'POST', body: JSON.stringify(formData)
      }).then(result => {
        if (result?.error) {
          const errors =
            Array.isArray(result['errors']) && result['errors']?.length > 0
              ? result['errors'].map((error) => error['detail'])
              : ['Неизвестная ошибка при выполнении запроса']
          setPageSuccess([])
          setPageErrors(errors)
        } else {
          if( factId === 'new'){
            history.push(`/new/organization/${result.id}`)
          }else {
            setPageSuccess(['Запись успешно обновлена.'])
            setPageErrors([])
          }
        }
      })
    },
    [history, id],
  )

  const getData = useCallback(async () => {
    {
      if(id !== 'new') {
        const data = await fetchAPI(`/api/office/${id}`)
        const formData = data.reduce((obj, cur) => ({...obj, [cur.name]: cur.value}), {})
        formDataRef.current = {...formData}
        setDisabledFields(data, defaultSelectedSchema)
      }
      const enumsList = {types: ['organizations', 'cities', 'regions', 'brands']}
      const enums = await fetchAPI(`/api/enum-list?${objectToUrl(enumsList)}`)

      const fixKeys = renameKeys(enums, {organizations: 'organization_id', brands: 'brand_id', regions: 'region_id', cities: 'city_id'})
      setEnumFields(fixKeys, defaultSelectedSchema)
      setMatchedJSONSchema(defaultSelectedSchema)
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData().catch(e => {
      console.error({e})
    })
  }, [getData])

  const onFormData = useCallback(({formData}) => {
    formDataRef.current = formData
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            {id === 'new' ?
              (<>Добавление офиса</>)
              :
              (<>Офис <span>{formDataRef.current.name}</span></>)
            }
          </MapTitle>
        </TaskWrapper>
        <MapContent>
          {pageErrors.length > 0 ? (
            <AlertWrapper>
              <Alert
                message="При выполнении операции возникла ошибка:"
                showIcon
                type="error"
                description={pageErrors.join('. ')}
              />
            </AlertWrapper>
          ) : null}
          {pageSuccess.length > 0 ? (
            <div style={{marginBottom: 40}}>
              <Alert
                message={pageSuccess.join('. ')}
                showIcon
                type="success"
              />
            </div>
          ) : null}
          {!isDataLoading ? (
            <DefaultJsonForm
              formData={formDataRef.current}
              schema={matchedJSONSchema}
              onChange={onFormData}
              orientation="vertical"
            />
          ) : (
            <Skeleton active/>
          )}

          <Button onClick={saveCase} style={{marginBottom: 16, marginTop: 16}} type="primary">
            <SaveOutlined rev={undefined}/>
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
