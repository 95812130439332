import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Alert, Skeleton, Space, Modal, Button } from 'antd'
import { JSONSchema7 } from 'json-schema'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import EventRibbonTab from '../../forms/case-event-ribbon-tab'
import { createUISchemaHorizontal, recalcUISchemaHorizontal } from '../../../lib/utils/grid-fild-orientation'
import ButtonAddNotes from '../../views/modal-add-notes'
import StepCollectionObject from '../../views/step-collection-object'
import { debounce } from 'lodash'
import { addActionsTimeLine, setCaseId, clearCaseStore, setAppealStage, initialCase, reloadActionsTimeLine, setTargetId  } from '../../store/appeals/actions'
import { accessUISchema, isFormDataDifferenceFilesS3 } from '../card-order/utils'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import StepButtonsTransition from '../../views/step-buttons-transition'
import { addValidateFieldsUISchema } from '../card-order/utils'
import { isFormDataRequired } from '../../../lib/utils/collections'
import StuckFiles from '../../components/tab-stuck-files'
import TableProduct from '../../connectors/json-form-product-table/adaptor-tab'
import { isProductsSpcificationStage } from '../../../lib/utils/cards'
import { setStageFromCard } from '../../../lib/utils/cards'
import dayjs from 'dayjs'
import { modalAdjustedDate } from '../../views/modal-adjusted-date'
import { insetNoteToCollection } from '../../views/modal-add-notes/utils-notes'
import modalOrderCancellation from '../../views/modal-order-cancellation'

function genJSONSchemaStage(JSONSchema, requiredArr){
  const required = getDataOfType(JSONSchema, 'required', Array, []).concat(requiredArr)
  const requiredUnic = Array.from(new Set(required))
  return ({ ...JSONSchema, required: requiredUnic })
}

function genJSONSchemaNamesrequired(JSONSchema){
  return getDataOfType(JSONSchema, 'required', Array, [])
}

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  appealsCurrentStep => appealsCurrentStep,
  workflows => workflows,
  targetId => targetId
)
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [{
  href: '/sales/mounting',
  title: 'Монтаж',
}, {
  href: `/sales/mounting`,
  title:  'Список монтажа',
}]

const name = 'card-mounting'
const cardName = 'mounting'

export default function AdminNewObjectPage() {
  const { id } = useParams()  as any
  const dispatch = useDispatch()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [selectedSchema, setSelectedSchema] = useState<string[]>([])
  const formDataRef = useRef<any>(null)
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<JSONSchema7 | null>(null)
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const { appealsCurrentStep, stageCode, workflows, targetId } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)

  useEffect(() => {
    const currentStageAccess = getDataOfType(workflows, `${cardName}[${appealsCurrentStep}].access-rights`, Array, null)
    if(currentStageAccess === null){
      return
    }
    setUISchema(UISchema => recalcUISchemaHorizontal(accessUISchema(UISchema || {}, currentStageAccess, sessionData)))
  },[workflows, appealsCurrentStep, sessionData])

  const [currentStage, tableProductDisabled ] = useMemo(() => {
    if(appealsCurrentStep < 3){
      return [
        'shipment',
        true
      ]
    }
    if(appealsCurrentStep === 3){
      return [
        'mounting',
        false
      ]
    }
    return [
      'mounting',
      true
    ]
  }, [appealsCurrentStep])

  const custumTabs = useMemo(() => ({
    'stuck-products': {
      isVirtual: true,
      schema: {
        properties: {
          'stuck-products': {
            title: 'Данные заказа',
            description: null,
          }
        }
      },
      render: () => <TableProduct currentStage={currentStage} disabled={tableProductDisabled} id={id} />
    },
    'stuck-files': {
      isVirtual: true,
      schema: {
        properties: {
          'stuck-files': {
            title: 'Прикрепленные файлы',
            description: null,
          }
        }
      },
      render: () => <StuckFiles />
    },
    'event-ribbon': EventRibbonTab
  }), [id, currentStage, tableProductDisabled])
// eslint-disable-next-line
  const saveCase = useCallback(debounce(async formData => {
      const requestBody = {
        data: {
          name: '',
          title: '',
          attributes: formData,
          metadata: { schema: name }
        }
      }

      const result = await fetchAPI(`/api/collections/-/objects/${id}`, {
        method: 'POST',
        body: JSON.stringify(requestBody),
      })

      const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)
      if (updated === null) {
        console.warn('Ошибка сохранения записи')
      }
  }, 1000), [],)

  useEffect(() => {
    dispatch(initialCase({ targetId: null, cardName: cardName }))
    const result = setCaseId({ caseId: id, parentIds: [id] })
    dispatch(result)
    return () => { dispatch(clearCaseStore()) }
  }, [id, dispatch])

  const getData = useCallback(async () => {
    const fields = [
      'title',
      'description',
      'type',
      'properties',
      'required',
      'created_by',
      'created_at',
      'updated_at',
    ].join(',')
    const responseSchemas = await fetchAPI(`/api/schemas?access_key=axioma&fields=${fields}`)
    const fetchedSchemas: JSONSchema7[] = getDataOfType(responseSchemas, 'data.data', Array, [])
    const selectedSchemas = fetchedSchemas.filter(item => item['name'] === name)
    const selectedSchema = (typeof selectedSchemas[0] === 'object') ? selectedSchemas[0] : null
    if (selectedSchema === null) {
      setPageErrors(['Некорректный ответ сервера при cхемы коллекции'])
      return
    }
    const responseObject = await fetchAPI(`/api/collections/objects/${id}`)
    const object = responseObject['data']
    const schema = object['data']['metadata']['schema']
    const formData = object['data']['attributes']
    const parentId = Number(object['parent-id'])
    const stageCode = getDataOfType(object, 'stage-code', String, null)
    dispatch(setTargetId({ targetId: parentId }))
    setSelectedSchema(schema)
    if(object['created-at']){
      const paramsDispatch = addActionsTimeLine({ type: 'info', items: [
        {
          date: object['created-at'],
          text: 'Создание записи'
        }
      ]})
      dispatch(paramsDispatch)
    }
    setPageErrors([])
    formDataRef.current = formData
    const stage = formData['stage'] || 0

    if( selectedSchema.required ) {
      if (stageCode === 'mounting-completed')
        selectedSchema.required = [...selectedSchema.required, 'file-mounting-accepted']
    }

    setMatchedJSONSchema(selectedSchema)
    const UISchema = createUISchemaHorizontal(selectedSchema)
    const currentCardOrganizationId = Number(getDataOfType(object, 'owner-organization', String, null))
    if ('case-manager' in UISchema) {
      UISchema['case-manager'] = {
        ...UISchema['case-manager'],
        options: {
          roleId: 2,
          organizationId: currentCardOrganizationId
        }
      }
    }
    if ('measurer' in UISchema) {
      UISchema['measurer'] = {
        ...UISchema['measurer'],
        options: {
          roleId: 10,
          organizationId: currentCardOrganizationId
        }
      }
    }
    if ('leader-installer' in UISchema) {
      UISchema['leader-installer'] = {
        ...UISchema['leader-installer'],
        options: {
          roleId: 15,
          organizationId: currentCardOrganizationId
        }
      }
    }
    setUISchema(UISchema)
    const currentStageAccess = getDataOfType(workflows, `${cardName}[${stage}].access-rights`, Array, [])
    setUISchema(UISchema => recalcUISchemaHorizontal(accessUISchema(UISchema || {}, currentStageAccess, sessionData)))
    dispatch(setAppealStage({ stageCode: stageCode, type: cardName, isHistory: false }))

    setIsDataLoading(false)
  }, [dispatch, id, sessionData, workflows])

  useEffect(() => {
    if(sessionData === null || workflows === null){
      return
    }
    getData()
  }, [getData, sessionData, workflows])

  const onFormData = useCallback(
    ({ formData }) => {
      const isFilesEdit = isFormDataDifferenceFilesS3(formDataRef.current, formData, matchedJSONSchema)
      if (isFilesEdit) {
        dispatch(reloadActionsTimeLine())
      }
      const nextFormData = { ...formData, stage: appealsCurrentStep }
      formDataRef.current = nextFormData
      saveCase(nextFormData)
    },
    [saveCase, appealsCurrentStep, matchedJSONSchema, dispatch],
  )

const transition = useCallback(async transition => {
  const stageCodeNext = transition['target']
  if (stageCodeNext === 'team-order-acceptance') {
    const isProducts = await isProductsSpcificationStage(targetId, 'mounting')
    if (isProducts === false) {
      Modal.info({
        icon: null,
        title: 'Не передана продукция',
        content: 'Выберите продукцию для внесения в акт приема-передачи'
      })
      return
    }
  }
  const nextDataCard = await setStageFromCard({
    cardName: cardName,
    stageCode: stageCodeNext,
    targetId: targetId,
    isHistory: true,
  })

  setMatchedJSONSchema(matchedJSONSchema => {
    if(matchedJSONSchema?.required && stageCodeNext === 'mounting-completed')
      matchedJSONSchema.required.push('file-mounting-accepted');
    return matchedJSONSchema
  })

  const nextFormData = getDataOfType(nextDataCard, 'data.formData', Object, null)
  const resultStageCode = getDataOfType(nextDataCard, 'data.nextStageCode', String, 'new')
  formDataRef.current = nextFormData
  dispatch(reloadActionsTimeLine())
  dispatch(setAppealStage({ stageCode: resultStageCode, type: cardName }))
},[dispatch, targetId])

const transitionRrequired = useCallback((_, nextFields) => {
  const gen = genJSONSchemaStage(matchedJSONSchema, nextFields)
  const names = genJSONSchemaNamesrequired(gen)
  const isRequired = isFormDataRequired(formDataRef.current, gen)
  if(isRequired){
    return true
  }
  setUISchema(UISchema => addValidateFieldsUISchema(UISchema, names))
  return false
}, [matchedJSONSchema])

const isAadjusted = useMemo(() => {
  //const currentRoles = getDataOfType(sessionData, 'roles', Array, [])
  //const isAccountant = currentRoles.includes(100)
  //console.log(isAccountant)
  const isStagePayment = ['work', 'delivery-planned'].includes(stageCode)
  return isStagePayment
}, [/*sessionData,*/ stageCode])

const onModalAdjustedDate = useCallback(async () => {
  const authorId = getDataOfType(sessionData, 'user.id', Number, 0)
  const result = await modalAdjustedDate()
  if (result === null) {
    return
  }
  const { date, comment, time } = result as any
  const content = `Изменена дата монтажа на: ${dayjs(date).format('DD.MM.YYYY')}. ${comment ?? ''}`
  const nextDataCard = await fetchAPI('/api/web-hooks/set-card-form-data', { method: 'POST', body: JSON.stringify({
    'card-name': cardName,
    'target-id': targetId,
    'add-form-data': {
      'date-adjusted-mounting': date, 'time-adjusted-mounting': time
    }
  })})

  formDataRef.current = getDataOfType(nextDataCard, 'data.formData', Object, null)

  await insetNoteToCollection({ date: new Date().toISOString(), content: content, author: authorId }, targetId)
  dispatch(reloadActionsTimeLine())
}, [sessionData, targetId, dispatch])

const onModalCancellation = useCallback(async () => {
  const stageCodeNext = 'new'
  const result = await modalOrderCancellation()
  if (result !== null) {
    await setStageFromCard({
      cardName: cardName,
      stageCode: stageCodeNext,
      targetId,
      isHistory: true,
      addFormData: {
        'reason-cancellation': result?.['reason-cancellation'],
        comment: result?.['comment'],
      },
    })
    await dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
    dispatch(reloadActionsTimeLine())
  }
}, [dispatch, targetId])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Монтаж №{targetId}</MapTitle>
          <Space size={'small'}>
            <ButtonAddNotes />
            {isAadjusted && <Button onClick={onModalAdjustedDate}>Изменить дату</Button> }
            <StepButtonsTransition type={cardName} transition={transition} transitionRequired={transitionRrequired} />
            <Button danger onClick={onModalCancellation} disabled={!['work', 'team-arrived'].includes(stageCode)}>Отмена</Button>
          </Space>
        </TaskWrapper>
        <MapContent>
            <StepCollectionObject type={cardName} />
            <Form
              {...formItemLayout}
              layout="horizontal"
              form={form}
              fields={[
                {
                  name: 'schema',
                  value: selectedSchema,
                },
              ]}
              scrollToFirstError={true}
            >
              {pageErrors.length > 0 ? (
                <AlertWrapper>
                  <Alert
                    message="При выполнении операции возникла ошибка:"
                    showIcon
                    type="error"
                    description={pageErrors.join('. ')}
                  />
                </AlertWrapper>
              ) : null}
              {isDataLoading === false ? (
                matchedJSONSchema && (
                  <>
                    <DefaultJsonForm
                      formData={formDataRef.current}
                      schema={matchedJSONSchema}
                      onChange={onFormData}
                      customTabs={custumTabs}
                      UISchema={UISchema}
                    />
                    </>
                  )
              ) : (
                <Skeleton active />
              )}
            </Form>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
