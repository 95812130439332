import React, { useState, useRef, useEffect, useCallback } from 'react'
import {  Modal } from 'antd'
import { DefaultJsonForm } from '../../components/default-json-form'
import { BrowserRouter } from 'react-router-dom'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { Provider, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { store } from '../../../browser-app'
import fetchAPI from '../../../lib/utils/fetch-api'

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  targetId => targetId 
)

function isFormDataRequired(formData, schema) {
  const { required } = schema || {}
  const result = Array.from(required).every((value: any) => {
    if (!(value in formData)) {
      return false
    }
    if (formData[value] === undefined) {
      return false
    }
    if (formData[value] === null) {
      return false
    }
    if (formData[value] === '') {
      return false
    }
    return true
  })
  return result
}



const selectedSchema = {
  type: 'object',
  required: ['date', 'time'],
  properties: {
    date: {
      type: 'string',
      format: 'date',
      title: 'Дата скорректированная',
    },
    time: {
      type: 'string',
      format: 'time-range',
      title: 'Время скорретированное',
    },
    comment: {
      type: 'string',
      title: 'Комментарий',
    },
  },
}

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)



export function modalClose(event){
  let target = event.target
  while (Boolean(target?.tagName) === true) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}

export function modalAdjustedDate() {
  let formData = {}
  return new Promise(resolve => Modal.confirm({
    icon: null,
    title: 'Изменение даты',
    /* @ts-ignore */
    content: <Provider store={store}><Content onChange={value => { formData = value }} /></Provider>,
    onCancel: () => resolve(null),
    okButtonProps: { 
      onClick: (event) => {
        const isRequired = isFormDataRequired(formData, selectedSchema)
        if(isRequired){
          resolve(formData)
          modalClose(event)
        }
      }
    }
  }))
}

function Content({ onChange }){
  const formDataRef =  useRef({  })
  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = formData
    onChange(formData)
  }, [onChange])
  const [UISchema, setUISchema] = useState<any>({})
  const { sessionData } = useSelector(createSession)
  const { targetId } = useSelector(createAppealsCurrentStep)
  useEffect(() => {
    //const organizationId = getDataOfType(sessionData, 'organization.id', Number, null)
    const UISchema = {
      'date': {
        css: {
          gridColumn: '1 / span 11',
          gridRow: '1',
        },
        options: {
          isDisabledDatePrev: true,
        },
      },
      'time': {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '2',
        },
        options: {
          isDisabledTimePrev: true,
        },
      },
      comment: {
        options: {
          type: 'textarea',
          minRows: 3,
          maxRows: 3,
        },
        css: {
          gridColumn: '1 / span 24',
          gridRow: '3',
        },
      }
    }

    ;(async (targetId) => {
      const response = await fetchAPI(`/api/order-forecast/${targetId}`)
      const first = getDataOfType(response, 'data', Array, []).filter(item => item['code'] === 'mounting').shift()
      formDataRef.current = Object.assign({ 'date-plan': getDataOfType(first, 'date', String, undefined) }, formDataRef.current)
      setUISchema(UISchema)
    })(targetId)
  }, [sessionData, targetId])

  return (
    /* @ts-ignore */
    <BrowserRouter>
      <DefaultJsonForm
        formData={formDataRef.current}
        schema={selectedSchema}
        onChange={localOnChange}
        UISchema={UISchema}
      />
    </BrowserRouter>
  )
}
