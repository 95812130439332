import React, { useMemo, useEffect, useState, useCallback } from 'react'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { Select, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { SelectWrapper } from './styles'
import { ISelect } from './interface'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { modalOpen } from './modal'

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

function filterOption(input, option){
  return option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

async function getCollectionData({ collectionName }){
  const objectsResult: any = await fetchAPI(`/api/collections/${collectionName}?access_key=axioma`)
  const objects = (objectsResult['data'] && objectsResult['data']['data']) || []
  if(Array.isArray(objects) === false){
    return []
  }
  const objectsFormat = objects.map(({ id, data }) => {
    if(data['name']){
      const label = data['name']
      const organization = data?.['attributes']?.['organization'] || 0
      return { value: id, label, organization }
    }
    const label = Object.values({ ...data['attributes'], ...data['fieldEnumNames'] }).join(
      ' | '
    )
 
    const organization = data?.['attributes']?.['organization'] || 0
    return { value: id, label, organization: organization }
  })

  return objectsFormat
}

async function addCollection({ collectionName, label, organization = 1 }) {
  const requestBody = {
    'parent_id':  0,
    'data': {
      'name': label,
      'attributes': { organization },
      'metadata': {
        'schema': collectionName,
      },
    },
  }

  const result = await fetchAPI(`/api/collections/${collectionName}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
  return getDataOfType(result, 'data.id', [Number, String], null)

}

function SelectObjectFromCollection({ value, schema, onChange, UISchema }: ISelect) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const collectionName = useMemo(() => schema['collection-name'], [schema])
  const [objects, setObjects] = useState(() => [])
  const [localValue, setLocalValue] = useState<any>(null)

  const { sessionData } = useSelector(createSession)
 
  const isCanSave = useCallback((val) => {
    return isNaN(Number(val)) === false
  }, [])

  const objectsFilter = useMemo(() => {
    const arr = [
      1,
      sessionData?.organization?.['id'] 
    ]
    return objects.filter(
      (item: any) => (arr.includes(item?.['organization'])) || ((item?.value === value) === true)
    )
  }, [objects, value, sessionData])

  useEffect(()=> {
    if (isCanSave(value)) {
      setLocalValue(Number(value))
    }
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    const fetchData = async () => {
      const objects = await getCollectionData({ collectionName })
      if (typeof value === 'string' && value) {
        const id = objects.find(item => item.label === value)?.['value']
        //
        if (Boolean(id) === false) {
          const id = await addCollection({ collectionName, label: value })
          const objects = await getCollectionData({ collectionName })
          setObjects(() => objects)
          if (isCanSave(id)){
            setLocalValue(() => Number(id))
          }
          return
        }

        setObjects(() => objects)
        if (isCanSave(id)){
          setLocalValue(() => Number(id))
        }
        return
      }

      setObjects(() => objects)
      if (isCanSave(value)){
        setLocalValue(() => Number(value))
      }
    }

    fetchData()
    // eslint-disable-next-line
  }, [collectionName])

  const isCollectionEditor = useMemo(() => {
    return false;
    return getDataOfType(UISchema, 'isCollectionEditor', Boolean, true ) && (disabled === false)
  }, [UISchema, disabled])

  const onRowSelect = useCallback(value => {
    const [id] = /\d+$/.exec(value) || []
    getCollectionData({ collectionName }).then( objects => {
      setObjects(objects)
      onChange(id)
      if(isCanSave(id)){
        setLocalValue(Number(id))
      }
    })
  }, [collectionName, isCanSave, onChange])

  const onClose = useCallback(() => {
    getCollectionData({ collectionName }).then(objects => {
      setObjects(objects)
    })
  }, [collectionName])

  const localOnChange = useCallback(value => {
    setLocalValue(value)
    onChange(value)
  }, [onChange])

  const onPlus = useCallback(async () => {
    const result: any = await modalOpen( )
    if (result === null) {
      return
    }
    const label = result?.['label']
    if (Boolean(label) === false) {
      return
    }
    const organization = sessionData?.organization?.['id'] 
    const id = await addCollection({ collectionName, label, organization })
    console.log(id)
    //onRowSelect(id)
    onClose()
 
  }, [sessionData, collectionName, onClose /*, onRowSelect,  */])

  return (
    <SelectWrapper>
      <Select
        disabled={disabled}
        showSearch
        placeholder="Выберите запись"
        optionFilterProp="label"
        onChange={localOnChange}
        value={localValue}
        filterOption={filterOption}
        options={objectsFilter}
      />
      {isCollectionEditor && <Button onClick={onPlus} icon={<PlusOutlined rev={undefined}/>} /> }
    </SelectWrapper>
  )
}

// <Button shape="circle" icon={<SearchOutlined />} />
// <CollectionEditor schema={schema} onRowSelect={onRowSelect} onClose={onClose} id={localValue}

export default React.memo(SelectObjectFromCollection, (
  prev,
  next,
) => {
  if(prev.value === next.value){
    return true
  }
  return prev.schema === next.schema;
})
