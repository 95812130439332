import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import { createUISchemaVertical } from '../../../lib/utils/grid-fild-orientation'
import TabGeneral from './tab-general'
import TableCallLog from './table-call-log'
import TableContacts from './table-contacts'
import { Form, Alert, Skeleton, Button, Tabs, Space } from 'antd'
import { SaveOutlined, PlusOutlined } from '@ant-design/icons'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [
  {
    href: '/',
    title: 'Контактный центр',
  },
  {
    href: `/call-center/clients`,
    title: 'Список клиентов',
  },
]

const defaultSelectedSchema = {
  type: "object",
  properties: {
    name: {
      type: "string",
      title: "ФИО"
    },
    email: {
      type: "string",
      format: "email",
      title: "Почта"
    },
    phone: {
      type: "string",
      format: "phone",
      title: "Телефон",
      options: {
        disabled: true
      }
    },
    address: {
      type: "string",
      title: "Адрес проживания"
    },
    "passport-issued": {
      type: "string",
      title: "Кем выдан паспорт"
    },
    "passport-number": {
      type: "string",
      title: "Серия и номер паспорта"
    },
    city: {
      type: "string",
      title: "Город"
    },
    brands: {
      type: "pointer",
      name: "brand",
      title: "Бренды",
      options: {
        disabled: true,
        mode: 'multiple'
      }
    },
    site: {
      type: "string",
      title: "Сайт"
    },
    "source-appeal": {
      type: "pointer",
      view: {
        name: "type-advertising"
      },
      "collection-name": "type-advertising",
      title: "Вид контакта"
    },
    "site-ceo": {
      type: "string",
      title: "Страница в соц сетях"
    },
    "client-type": {
      type: "string",
      title: "Тип клиента",
      enum: [
        "new",
        "work"
      ],
      oneOf: [
        {
          const: "new",
          title: "частное лицо"
        },
        {
          const: "work",
          title: "корпоративный"
        }
      ],
      view: {
        name: "select-from-enum"
      }
    },
    messanger: {
      type: "string",
      title: "Мессенджер",
      enum: [
        "new",
        "work",
        "measurement",
        "prepayment",
        "manufacture",
        "delivery",
        "mounting"
      ],
      oneOf: [
        {
          const: "new",
          title: "Viber"
        },
        {
          const: "work",
          title: "WhatsApp"
        },
        {
          const: "measurement",
          title: "Telegram"
        },
        {
          const: "prepayment",
          title: "Facebook"
        },
        {
          const: "manufacture",
          title: "Instagram"
        },
        {
          const: "delivery",
          title: "Vkontakte"
        },
        {
          const: "mounting",
          title: "Одноклассники"
        }
      ],
      view: {
        name: "select-from-enum"
      }
    }
  }
}

export default function AdminNewObjectPage() {
  const { id } = useParams() as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({})
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)

  const saveCase = useCallback(
    async () => {
      const formData = { ...formDataRef.current }
      await fetchAPI(`/api/user-create-or-update/${id}`, { method: 'POST', body: JSON.stringify({
        'profile-type-id': 2,
        'profile-data': formData,
        'user-data': {}
      })})
      history.push('/call-center/clients')
    },
    [history, id],
  )

  const getData = useCallback(async () => {
    {
      const profileData = await fetchAPI(`/api/profiles?type=user&user-id=${id}`)
        .then( data => getDataOfType(data, 'data[0].profile-data', Object, {}))
      const brands = await fetchAPI(`/api/client-brands/${id}`)
      formDataRef.current = { ...profileData, brands }
      setMatchedJSONSchema( defaultSelectedSchema )
      setUISchema( createUISchemaVertical( defaultSelectedSchema ) )
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData().catch(e=>{console.error({e})})
  }, [getData])

  const onFormData = useCallback(({ formData }) => {
    formDataRef.current = formData
  }, [])
  
  const createOrder = useCallback(async () => {
    const result = await fetchAPI(
      `/api/web-hooks/order?owner=${id}`,
      {
        method: 'POST',
        body: JSON.stringify({
          'telephone': getDataOfType(formDataRef.current, 'phone', String, undefined),
          'name': getDataOfType(formDataRef.current, 'name', String, undefined),
          'email': getDataOfType(formDataRef.current, 'email', String, undefined),
          'city': getDataOfType(formDataRef.current, 'city', String, undefined),
          'address': getDataOfType(formDataRef.current, 'address', String, undefined),
          'address-object': getDataOfType(formDataRef.current, 'address', String, undefined),
        })
      }
    )

    const orderId = getDataOfType(result, 'data.id', Number, null)
    history.push(`/case/${orderId}/edit`)       
  }, [history, id])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>
            Клиент
          </MapTitle>
            <Space size={'small'}>
              <Button
                type="primary"
                icon={<PlusOutlined rev={undefined}/>}
                onClick={createOrder}
              >
                Создать заказ
              </Button>
            </Space>
        </TaskWrapper>
        <MapContent>
          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {!isDataLoading && UISchema ? (
              <DefaultJsonForm
                formData={formDataRef.current}
                schema={matchedJSONSchema}
                onChange={onFormData}
                UISchema={UISchema}
              />
            ) : (
              <Skeleton active />
            )}
          </Form>
          <Tabs type="card">
            <Tabs.TabPane tab="История обращений" key="1">
              <TabGeneral clientId={id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Журнал звонков" key="2">
              <TableCallLog userId={id} />
            </Tabs.TabPane>
            <Tabs.TabPane tab="Дополнительные контакты" key="3">
              <TableContacts userId={id} />
            </Tabs.TabPane>
          </Tabs>
          <Button onClick={saveCase} style={{ marginBottom: 16, marginTop: 16 }} type="primary">
            <SaveOutlined rev={undefined}/>
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}



