import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Table, Alert, Button } from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { tableRowClick, getColumnsOfSchema, formatListData2 } from '../../../lib/utils/list'
import { PlusOutlined } from '@ant-design/icons'
import selectedSchema from './json-schema.json'
import { beforeDataColumns, afterDataColumns } from './utils'

function booleanInversion(value) {
  return typeof value === 'boolean'? !value: value
}

function recalcFormData(item) {
  return {
    ...item,
    'is-active': booleanInversion(item['is-active']) 
  }
}


export default function AdminObjectsListPage() {
  const location = useLocation()
  const history = useHistory()
  const [dataSource, setDataSource] = useState<any[]>([])
  const [isDataLoading, setIsDataLoading] = useState(true)
  const [dataColumns, setDataColumns] = useState<any[]>([])
  const [pageErrors, setPageErrors] = useState<String[]>([])
  const breadcrumbs = useMemo(() => (
    [{
      href: location.pathname,
      title: 'Пользователи',
    }, {
      href: location.pathname,
      title: 'Список пользоватлей',
    }]
  ), [location.pathname])

  // @ts-ignore
  const finalColumns = useMemo(() => [].concat(beforeDataColumns, dataColumns, afterDataColumns).filter(item => item.title), [dataColumns])
  const cbTableRowClick = useCallback(({ id }) => history.push(`/user/${id}`), [history])

  const deleteRecord = useCallback(async (currentId) => {
    try{
      await fetchAPI(`/api/organizations/${currentId}`, { method: 'DELETE'})
      setDataSource(dataSource => dataSource.filter(({ id }) => id !== currentId))
    }catch(error){
      console.error(error)
    }
  }, [])
  const onButtonCreate = useCallback(() => history.push(`/profiles-organization/new`), [history])

  useEffect(() => {
    const getData = async () => {
      const pageErrors: string[] = []
      const collections = await fetchAPI(`/api/profiles?type=organization`)
      const collectionData = getDataOfType(collections, 'data', Array, null)
      if(collectionData === null) {
        pageErrors.push('Некорректный ответ сервера при получении метаданных коллекции')
        return 
      }
      const dataSource: any[] = formatListData2(collectionData.map(value => recalcFormData(value)), selectedSchema)
      const newDataSource = dataSource.map(item => ({ 
        ...item,
        deleteRecord 
      }))
      const newDataColumns: any[] = getColumnsOfSchema(selectedSchema)

      setDataColumns(newDataColumns)
      setDataSource(newDataSource)
      setPageErrors(pageErrors)
      setIsDataLoading(false)
    }

    getData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      {(pageErrors.length > 0) ? (
        <div style={{ marginBottom: 40 }}>
          <Alert
            message="При выполнении операции возникла ошибка:"
            showIcon
            type="error"
            description={pageErrors.join('. ')}
            />
        </div>
      ): null}
      <Button onClick={onButtonCreate} style={{ marginBottom: 16 }}>
        <PlusOutlined rev={undefined}/> Создать организацию
      </Button>
      <Table
        rowKey="id"
        size="small"
        columns={finalColumns}
        dataSource={dataSource}
        pagination={false}
        loading={isDataLoading}
        onHeaderRow={(column: any) => ({
          style: {
            fontSize: (column.key && column.key.substr(-3) === '-at') ? 9 : 13,
          }
        })}
        onRow={record => ({ onClick: event => tableRowClick(event, record, cbTableRowClick)})}
      />
    </B2BLayout>
  )
}
