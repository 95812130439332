import React, { useEffect, useState, useMemo } from 'react'
import fetchAPI from '../../../lib/utils/fetch-api'
import { Select } from './styles'
import { ISelect } from './interface'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

function filterOption(input, option){
  return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

function objectSearchParamsToURL(object) {
  const isCorrectValue = ([, value]) => typeof value === 'string' || Number.isFinite(value)
  const paramsArray: any[] = Object.entries(object).filter(isCorrectValue)
  return new URLSearchParams(paramsArray).toString()
}

function SelectFrom({ value, schema, onChange, UISchema }: ISelect) {
  const [objects, setObjects] = useState<any[]>([{ id: value, value }])
  const width = useMemo(() => UISchema?.options?.width, [UISchema])
  const placeholder = useMemo(() => UISchema?.options?.placeholder ?? "Выберите запись", [UISchema])
  const allowClear = useMemo(() => Boolean(UISchema?.options?.allowClear), [UISchema])
  const showSearch = useMemo(() => Boolean(UISchema?.options?.showSearch), [UISchema])
  const types = useMemo(() => UISchema?.options?.types || [5,1], [UISchema])
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const regions = useMemo(() => UISchema?.options?.regions || [null], [UISchema])
  const isFirst = useMemo(() => Boolean(UISchema?.options?.isFirst), [UISchema])
  useEffect(() => {
    (async () => {
      const params = { type: types.join(',') }
      if( regions )
        Object.assign(params, {region: regions.join(',')})
      const paramsUrl = objectSearchParamsToURL(params)
      const objectsResult: any = await fetchAPI(`/api/organizations?${paramsUrl}`)
      const objectsFormat = getDataOfType(objectsResult, 'data', Array, [])
        .map(({ id, title }) => {
          return { id, value: title }
        })
      setObjects(objectsFormat)
    })()
  }, [types, regions])

  useEffect(() => {
    if (isFirst === false ) {
      return
    }
    // @ts-ignore
    if ((value ?? false) !== false ) {
      return
    }
    if (objects.length === 0) {
      return
    }
    onChange(objects[0]?.id)
    // @ts-ignore
  }, [objects, isFirst, onChange, value])

  const localValue = useMemo(() => Number(value) || undefined, [value])

  return (
    <Select
      allowClear={allowClear}
      showSearch={showSearch}
      width={width}
      placeholder={placeholder}
      optionFilterProp="children"
      onChange={onChange}
      value={localValue}
      filterOption={filterOption}
      disabled={disabled}
    >
    {objects.map(({ id, value }) => (
      <Select.Option value={id} key={Math.random()}>{value}</Select.Option>
    ))}
    </Select>
  )
}

export default SelectFrom

