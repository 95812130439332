import React, { useState, useCallback, useEffect }  from 'react'
import fetchAPI from '../../../lib/utils/fetch-api'
import { Table} from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

function prFormat(value) {
  if (isNaN(value)) {
    return '—'
  }
  return `${value}%`
}


const columns = [
  {
    title: 'Группа',
    dataIndex: 'group',
    key: 'group',
  },
  {
    title: 'Параметр',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Норма',
    dataIndex: 'norm',
    key: 'norm',
    render: value => prFormat(value)
  },
  {
    title: 'Факт',
    dataIndex: 'fact',
    key: 'fact',
    render: value => prFormat(value)
  },
  {
    title: 'Удельный вес',
    dataIndex: 'weight',
    key: 'weight',
  },
  {
    title: 'Норма баллов',
    dataIndex: 'norm-scores',
    key: 'norm-scores',
  },
  {
    title: 'Факт баллов',
    dataIndex: 'fact-scores',
    key: 'fact-scores',
  },
  {
    title: 'Рейтинг',
    dataIndex: 'rating',
    key: 'rating',
    render: value => prFormat(value)
  },



]

function maxLow100(n){
  if(n > 100){
    return 100
  }
  if(n < 0){
    return 0
  }
  return n
}

function getGroupName(code){
  const acc = {
    'sales-manager': 'Менеджеры по продажам',
    'measurer': 'Менеджеры по замерам',
    'leader-mount': 'Монтажные бригады',
  }
  return acc[code] ?? ''
}

function dataGroupCalc(data) {
  const groupCodes = Array.from(new Set(data.map(item => item['group-code'])))
  const groupsAvg = groupCodes.map(value => {
    return data
      .filter(item => item['group-code'] === value)
      .reduce((acc, currenItem, index) => {
        return {
          'fact-scores': acc['fact-scores'] + currenItem['fact-scores'],
          'norm-scores': acc['norm-scores'] + currenItem['norm-scores'],
          'norm': acc['norm'] + currenItem['norm'],
          'fact': acc['fact'] + currenItem['fact'],
          'weight': acc['weight'] + currenItem['weight'],
          'count': index + 1,
          'group-code': value
        }
      }, {
        'fact-scores': 0,
        'norm-scores': 0,
        'norm': 0,
        'fact': 0,
        'weight': 0,
        'count': 0,
        'group-code': value
      })
  }).map(item => {
    const rating = maxLow100(Math.ceil(item['fact-scores'] / item['norm-scores'] * 100))
    const weight = Math.ceil(item['weight'] / item['count'])
    const norm = Math.ceil(item['norm'] / item['count'])
    const fact = Math.ceil(item['fact'] / item['count'])
    return {
      rating,
      weight,
      norm,
      fact,
      name: '',
      group: getGroupName(item['group-code']),
      'group-code': item['group-code'],
      'fact-scores': item['fact-scores'],
      'norm-scores': item['norm-scores'],
    }
  })

  const copyData = data.concat()
  groupsAvg.forEach(itemAvg => {
    const index = copyData.findIndex(itemData => itemData['group-code'] === itemAvg['group-code'])
    copyData.splice(index, 0, itemAvg)
  })
  return copyData
}


function RatingUser({ id }) {
  const [data, setData] = useState<any>([])
  const [title, setTitle] = useState<any>([])
  const getData = useCallback(async id => {
    const roleDataKpiResponse = await fetchAPI(`/api/rating/organization/${id}`)
    const roleDataKpi = getDataOfType(roleDataKpiResponse, 'data', Array, []).filter(item => item['code'] !== 'documents-handed-over')
    const data = roleDataKpi.map(item => ({
        name: item['name'],
        'group-code': item['group-code'],
        norm: item['norm'],
        weight: item['weight'],
        quantity: item['count'],
        fact: item['fact'],
        rating: item['rating'],
        'fact-scores': item['rating_score_sum'],
        'norm-scores': item['score_norm'],
    }))
    setTitle(`рейтинг: ${prFormat(getDataOfType(roleDataKpiResponse, 'rating', Number, 0))}`)
    const groupsAvg = dataGroupCalc(data)
    setData(groupsAvg)
  }, [])

  useEffect(() => { getData(id) }, [getData, id])

  return <>
    <span style={{
          fontWeight: 'bold',
          marginBottom: 10,
          display: 'flex'
    }}>
      {title}
  </span>
  <Table  dataSource={data} columns={columns} pagination={false} />
  </>

}

export default RatingUser

