import React, { useCallback, useEffect, useState } from 'react'
import { Space, Input } from 'antd'
import SelectFromEnum from '../../../components/select-from-enum'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { stage, organization, dateRange } from './utils'
import SelectFromOrganizations from '../../../connectors/select-from-organizations'
import InputDateRange from '../../../components/input-date-range'
import InputPhoneFind from '../../../components/input-phone-find'

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData,
)

function InputFilter({ onChange, value }) {
  const [localValue, setLocalValue] = useState('')

  const onPressEnter = useCallback(({ target }) => {
    onChange(target?.value)
  }, [onChange])

  useEffect(() => {
    if( value )
      setLocalValue(value)
  },[])

  const onLocalChange = useCallback(({ target }) => {
    const value = target?.value
    setLocalValue(value)
    if (typeof value !== 'string' || value === '') {
      onChange(undefined)
    }
  }, [onChange])

  const onSearch = useCallback((value) => {
    onChange(value)
  }, [onChange])

  return <Input.Search prefix="ID:" onPressEnter={onPressEnter} value={localValue} onChange={onLocalChange} onSearch={onSearch} allowClear style={{ width: 200 }} />
}

const UISchemaSelectFromEnum = {
  options: { width: 200, placeholder: 'Стадия', allowClear: true, showSearch: true,  }
}

const rolesAll = [19, 12, 3, 4, 5, 6, 18]

function isDublicate(arr1, arr2) {
  const concatArray = arr1.concat(arr2)
  return concatArray.length > Array.from(new Set(concatArray)).length
}

function Filter({ onChange }) {
  const { sessionData } = useSelector(createSession)
  const [formData, setFormData] = useState<any>(() => {
      const data = {
        id: undefined,
        'organization-id': undefined,
        'date-range': undefined,
        'stage-code': undefined,
        'user-id': undefined,
        page: undefined,
        pageSize: undefined
      }
      return {...data, ...Object.fromEntries(new URLSearchParams(location.search))}
    }
  )
  const [UISchemaSelectFromUsers, setUISchemaSelectFromUsers] = useState({
    options: { width: 200, allowClear: true, showSearch: true, roleId: [2, 12], placeholder: 'ФИО менеджера' }
  })
  const [UISchemaSelectFromOrganizations, setUISchemaSelectFromOrganizations] = useState({
    options: { width: 200, allowClear: true, types: [5, 1, 6], showSearch: true, placeholder: 'Организация' }
  })


  useEffect(() => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    if (isDublicate(roles, rolesAll) === false) {
      setFormData(data => ({ ...data, 'organization-id': organizationId }))
      setUISchemaSelectFromOrganizations(({ options }) => ({ options: { ...options, disabled: true }  }))
      setUISchemaSelectFromUsers(({ options }) => ({ options: { ...options, 'organizationId': organizationId }  }))
    }
  }, [sessionData])


  const onChangeLocal = useCallback((value, name) => {
    const nextformData = {}
    setFormData(prevFormData => Object.assign(nextformData, prevFormData, { [name]: value }))
    onChange(nextformData)
  }, [onChange])
  const onChangeOrganization = useCallback(value => {
    onChangeLocal(value, 'organization-id')
  }, [onChangeLocal])
  const onChangeDateRange = useCallback(value => {
    onChangeLocal(value, 'date-range')
  }, [onChangeLocal])
  const onChangeStageCode = useCallback(value => {
    onChangeLocal(value, 'stage-code')
  }, [onChangeLocal])
  const onChangeId = useCallback(value => {
    onChangeLocal(value, 'id')
  }, [onChangeLocal])
  const onChangePhone = useCallback(value => {
    onChangeLocal(value, 'phone')
  }, [onChangeLocal])

  return (
    <Space>
      <InputFilter onChange={onChangeId} value={formData['id']}/>
      <InputPhoneFind onEnter={onChangePhone} currentValue={formData['phone']} />
      <SelectFromOrganizations
        value={formData['organization-id']}
        schema={organization}
        onChange={onChangeOrganization}
        UISchema={UISchemaSelectFromOrganizations}
      />
      <SelectFromEnum
        value={formData['stage-code']}
        schema={stage}
        onChange={onChangeStageCode}
        UISchema={UISchemaSelectFromEnum}
      />
      <InputDateRange
        value={formData['date-range']}
        schema={dateRange}
        onChange={onChangeDateRange}
      />
    </Space>
  )
}

export default Filter
