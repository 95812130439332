import React, { useMemo, useState, useRef } from 'react'
import { useCallback } from 'react'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import { setCalcDelivery } from '../../store/appeals/actions'
import { Spin } from 'antd'
import { ContentWrapper, FileLink, FileNoLink, FileLinkWrapper, FileButton } from './styles'
import { SuggestItem, SuggestList, SuggestWrapper } from './styles'
import { DashOutlined } from '@ant-design/icons'
import { modalOpen } from './modal'

const createCase = createSelector(
  (state: any) => state.appeals,
  targetId => targetId,
)

const DADATA_TOKEN = '202f07e5711c4f92e5167758fa392229901215ac'

const formatItem = item => {
  const value: string = item?.['value'] ?? ''
  return { value: value, label: value,  
    latitude: Number(getDataOfType(item, 'data.geo_lat', String, 0)),
    longitude: Number(getDataOfType(item, 'data.geo_lon', String, 0)),
    'address': getDataOfType(item, 'unrestricted_value', String, ''),
  }
}
 
 
const getOptions = async ({ query, city }) => {
  if (Boolean(query) === false) {
    return []
  }
  const params = {
    'query': query,
    'count': 20,
    "from_bound": { "value": "street-house" },
    "to_bound": { "value": "street-house" },
    "restrict_value": true,
    'locations' : {
      country_iso_code: 'RU',
      city: city || "Москва",
    }
  }
  const res = await fetchAPI(
    'https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address',
    {
      method: 'POST',
      mode: 'cors',
      credentials: undefined,
      headers: {
        Authorization: `Token ${DADATA_TOKEN}`,
      },
      body: JSON.stringify(params),
    },
  )
  return getDataOfType(res, 'suggestions', Array, []).map(formatItem)
}





export function InputAdr({ value, onChange, schema, UISchema }: any) {
  //const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const disabled = false
  const city = useMemo(() => UISchema?.options?.city, [UISchema])
  const [options, setOptions] = useState<any[]>([])
  const cityRef = useRef(city || 'Москва')
  const [isManual, setManual] = useState(true)
  const inputRef = useRef<any>()
 
  const [searchValue, setSearchValue] = useState(value?.value || value || '')
  const [isShow, setIsShow] = useState(false)

  const localOnChange = useCallback(async value => {

    setSearchValue(value.value)
    onChange(value)
  }, [onChange])

 
  const onSearch = useCallback(async svalue => {
    if (isManual === true) {
      setSearchValue(svalue)
      onChange({ value: svalue })
      return
    }
    setSearchValue(svalue)
    const result = await getOptions({ query: svalue, city: cityRef.current })
    setOptions(result)
  }, [isManual,  onChange])
 
  const handleFocus = useCallback(() => {
    if (isManual === true) {
      return
    }
    setIsShow(true)
  }, [isManual])

  const handleBlur = useCallback(() => {
    if (isManual === true) {
      return
    }
    setTimeout(() => setIsShow(false), 300)
  }, [isManual])

  const onManual = useCallback(() => {
    setManual(is => {
      const nextIs = !is
      return nextIs
    })
  }, [])


  return (
    <SuggestWrapper>
      {/*<Checkbox checked={isManual} onChange={onManual}>Ручной ввод</Checkbox>*/}
      <input
        onChange={({ target }) =>  onSearch(target.value)}
        onFocus={handleFocus}
        onBlur={handleBlur}
        ref={inputRef}
        placeholder={'Введите улицу и дом'}
        defaultValue={searchValue}
        value={searchValue}
        className={'ant-input'}
      />
      {options.length > 0 && (
        <SuggestList isShow={isShow}>
          {options.map(suggest => (
            <SuggestItem key={suggest.value} onClick={() => localOnChange(suggest) as any}>
              {suggest.value}
            </SuggestItem>
          ))}
        </SuggestList>
      )}
    </SuggestWrapper>
  )
 

  /*
  return (
    <div      ref={inputRef}>     
    <Select
      value={value}
      showSearch
      placeholder={"Выберите адрес"}
      options={options}
      disabled={disabled}
      onChange={localOnChange}
      onSearch={onSearch}
      searchValue={searchValue}
      onFocus={log}
      onBlur={log}

      onSelect={log}
 
 
      style={{  maxWidth: 350, minWidth: 350 }}
    />
    </div>
  )
  */
}

/*    onBlur={log}
      onDropdownVisibleChange={log}
      onSelect={log}
*/

const isPreloader = false
function InputText({ value, onChange, UISchema } : any) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const required = useMemo(() => Boolean(UISchema?.required), [UISchema])
  const error = required && !value
  const { targetId } = useSelector(createCase)
  const dispatch = useDispatch()


  /*
    useEffect(() => {
    if (targetId === null) {
      return
    }
    (async (targetId) => {
      const result = await getObjectFirst({ collectionName: 'case-order', parentId: targetId  })
      const city = getDataOfType(result, 'data.attributes.city', String, 'Москва')
      cityRef.current  = city
    })(targetId)
  }, [targetId])
  */

  const onCall = useCallback(async () => {

    const [, addressSend, apartment ] = (value || '').match(/(.+), (\d+)$/) || ['', '', '']

    const result: any = await modalOpen({ value, targetId, city: 'Москва', apartment, address: addressSend })
    if (result === null)
      return

    const latitude = getDataOfType(result, 'latitude', Number, 0)
    const longitude = getDataOfType(result, 'longitude', Number, 0)
    const address = getDataOfType(result, 'address', String, 0)
    const params = { 'target-id': targetId, 'latitude': latitude, 'longitude': longitude, 'address': address }

    onChange(`${result?.value?.value}, ${result?.['apartment']}`)
    
    await fetchAPI('/api/web-hooks/calc-delivery', {
      method: 'POST',
      body: JSON.stringify(params),
    })
    dispatch(setCalcDelivery({ isCalcDelivery: true }))

  }, [value, targetId, dispatch, onChange])
  return (
    <ContentWrapper disabled={disabled} error={error} className="antd-input antd-input-container">
      <FileLinkWrapper>
        {value ? (
          <FileLink>{value}</FileLink>
        ) : (
          <FileNoLink>Не выбран</FileNoLink>
        )}
      </FileLinkWrapper>
      <FileButton disabled={disabled || isPreloader} onClick={onCall} className="ant-input">
        {isPreloader ? <Spin size="small" /> : <DashOutlined rev={undefined}/>}
      </FileButton>
    </ContentWrapper>
  )
}



export default InputText
