import {getDataOfType} from './get-data-of-type'

export function getGridRow(index){
  const remainder = (index + 1) % 5 ? 1 : 0
  return String(Math.trunc((index + 1) / 5) + remainder)
}

export function getGridColumn(index){
  const arr = [1, 6, 11, 16, 21]
  const item = getItem(arr, index)
  return `${item} / span 4`
}

function getItem(arr, index){
	if(index < arr.length){
    return arr[index];
  } 
	const u = index - Math.trunc((index + 1) / arr.length) * arr.length;
	if(u === -1){
    return arr[arr.length -1];
  } 
	return arr[u];
}

export function createUISchemaHorizontal(schema){
  if(!Boolean(schema)) {
    return {}
  }
  const propertiesSchema = schema['properties'] || {}

  const properties = Object.entries(propertiesSchema)
  .map(([key, value]: [string, any], index) => {
    return [key, {...value, css: { gridColumn: getGridColumn(index), gridRow: getGridRow(index)} } ]
  })
  return Object.fromEntries(properties)
}

export function createUISchemaVertical(schema){
  if(!Boolean(schema)) {
    return {}
  }
  const propertiesSchema = schema['properties'] || {}
  const properties = Object.entries(propertiesSchema)
    .map(([key, value]: [string, any], index) => {
      return [key, {...value, css: { gridColumn: `1 / span 12`, gridRow: String(index + 1)} } ]
    })
  return Object.fromEntries(properties)
}

export function recalcUISchemaHorizontal(UISchema){
  if(!Boolean(UISchema)) {
    return {}
  }

  const addHidden: any[] = Object.entries(UISchema).filter(([key, value]) => getDataOfType(value, 'hidden', Boolean, false) === true)
  const properties = Object.entries(UISchema)
    .filter(([key, value]) => getDataOfType(value, 'hidden', Boolean, false) === false)
    .map(([key, value]: [string, any], index) => {
      return [key, {...value, css: { gridColumn: getGridColumn(index), gridRow: getGridRow(index)} } ]
    }).concat(addHidden)
  return Object.fromEntries(properties)
}
