import React, { useCallback } from 'react'
import { message } from 'antd'
import UploadFiles from '../../components/upload-files'
import { insetFileToColletion } from './utils-files-s3'
import { addActionsTimeLine } from '../../store/appeals/actions'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const createCase = createSelector(
  (state: any) => state.appeals,
  targetId => targetId
)

function CaseUploadFile(){
  const { targetId } = useSelector(createCase)
  const dispatch = useDispatch()
  const fileOnError = useCallback(file => {
    message.error(`${file.name} не удалось загрузить`)
  }, [])

  const fileOnDone = useCallback(async file => {
    const formatFile = {
      name: file.name,
      size: file.size,
      url: file.response.url,
      id: file.response.id,
      type: file.type,
      date: Number(new Date())
    }
    
    if (Number.isInteger(file.response.id)) {
      const result =  addActionsTimeLine({ type: 'dynamic-file', items: [{ ...formatFile }]})
      dispatch(result)
      return
    }


    const id = await insetFileToColletion(formatFile, targetId)
    if (id) {
      const result =  addActionsTimeLine({ type: 'file', items: [{ ...formatFile, id }]})
      dispatch(result)
      return
    }
    console.warn('Сбой добавления файла в объекты')
  }, [dispatch, targetId])

  return <UploadFiles fileOnError={fileOnError} fileOnDone={fileOnDone} targetId={targetId} />
}

export default CaseUploadFile