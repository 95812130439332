import React, { useMemo, useEffect, useState, useCallback } from 'react'
import fetchAPI from '../../../lib/utils/fetch-api'
import { Select } from 'antd'
import { CollectionEditor } from '../../components/app-collection-editor'
import { SelectWrapper } from './styles'
import { ISelect } from './interface'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

function filterOption(input, option){
  return option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
}


async function getCollectionData({ collectionName, UISchema }){

  const getActiveRegions = collectionName === 'regions' && UISchema?.url_params?.has_organizations

  const objectsResult: any = getActiveRegions ? await fetchAPI(`/api/collections/get-active-regions?access_key=axioma`): await fetchAPI(`/api/collections/${collectionName}?access_key=axioma`)
  const objects = (objectsResult['data'] && objectsResult['data']['data']) || []
  if(Array.isArray(objects) === false){
    return []
  }
  const objectsFormat = objects.map(({ id, data }) => {
    if( collectionName === 'regions' ) {
      return {value: data?.attributes?.code, label: data?.attributes?.code}
    }else{
      if (data['name']) {
        const label = data['name']
        return {value: id, label}
      }
      const label = Object.values({...data['attributes'], ...data['fieldEnumNames']}).join(' | ')
      return {value: id, label}
    }
  })
  return objectsFormat
}

function SelectObjectFromCollection({ value, schema, onChange, UISchema }: ISelect) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const collectionName = useMemo(() => schema['collection-name'], [schema])
  const [objects, setObjects] = useState(() => [])
  const [localValue, setLocalValue] = useState<any>(null)
  const isCanSave = useCallback((val) => {
    if( collectionName === 'regions' )
      return true
    return !isNaN(Number(val))
  }, [])

  const objectsFilter = useMemo(() => {
    const whereNotIds = getDataOfType(UISchema, 'whereNotIds', Array, [])
    if(whereNotIds.length === 0){
      return objects
    }
    return objects.filter(
      (item: any) => (whereNotIds.includes(item?.value) === false) || ((item?.value === value) === true)
    )
  }, [UISchema, objects, value])

  useEffect(()=> {
    if (isCanSave(value)){
      setLocalValue(value)
    }
    // eslint-disable-next-line
  }, [value])

  useEffect(() => {
    const fetchData = async () => {
      const objects = await getCollectionData({ collectionName, UISchema })
      setObjects(() => objects)
      if (isCanSave(value)){
        setLocalValue(() => value)
      }
    }

    fetchData()
    // eslint-disable-next-line
  }, [collectionName])

  const isCollectionEditor = useMemo(() => {
    return false
    return getDataOfType(UISchema, 'isCollectionEditor', Boolean, true ) && (disabled === false)
  }, [UISchema, disabled])

  const onRowSelect = useCallback(value => {
    const [id] = /\d+$/.exec(value) || []
    getCollectionData({ collectionName, UISchema }).then( objects => {
      setObjects(objects)
      onChange(id)
      if(isCanSave(id)){
        setLocalValue(Number(id))
      }
    })
  }, [collectionName, isCanSave, onChange, UISchema])

  const onClose = useCallback(() => {
    getCollectionData({ collectionName, UISchema }).then(objects => {
      setObjects(objects)
    })
  }, [collectionName, UISchema])

  const localOnChange = useCallback(value => {
    setLocalValue(value)
    onChange(value)
  }, [onChange])

  return (
    <SelectWrapper>
      <Select
        disabled={disabled}
        showSearch
        placeholder="Выберите запись"
        optionFilterProp="label"
        onChange={localOnChange}
        value={localValue}
        filterOption={filterOption}
        options={objectsFilter}
      />
      {isCollectionEditor && <CollectionEditor schema={schema} onRowSelect={onRowSelect} onClose={onClose} id={localValue} />}
    </SelectWrapper>
  )
}

export default React.memo(SelectObjectFromCollection, (
  prev,
  next,
) => {
  if(prev.value === next.value){
    return true
  }
  return prev.schema === next.schema;
})
