import React from 'react'
import { Timeline } from 'antd'
import { TimeLineContentHeaderSpaceWrapper } from './styles'

function EmptyDot({ index }) {
  return (
    <span
      style={{
        width: '2px',
        height: '10px',
        backgroundColor: '#f0f0f0',
        position: 'absolute',
        marginLeft: '-1px',
        marginTop: index ? '-6px': '-2px',
      }}
    />
  )
}

function ActivityTimelineSpace({ type, body, date, id, index }: any){
  return (
    <Timeline.Item dot={<EmptyDot index={index} />}>
      <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
        <div
          style={{
            width: '100%',
            height: '2px',
            backgroundColor: '#f0f0f0',
            position: 'absolute',
            marginTop: '11px',
            marginLeft: '-6px'
          }}
        />
        {body.text && body.text.length && (
          <TimeLineContentHeaderSpaceWrapper color={body.color} slot={body.colorText}>
            <span>{body.text}</span>
          </TimeLineContentHeaderSpaceWrapper>
        )}
      </div>
    </Timeline.Item>
  )
}

export default ActivityTimelineSpace
