import fetchAPI from '../../../lib/utils/fetch-api'
import {
  LOGIN_REQUESTED,
  LOGIN_DONE,
  LOGIN_FAILED
} from './actions'

export function getLoginAction(data) {
  return async dispatch => {
    dispatch({
      type: LOGIN_REQUESTED,
      payload: data,
    })
    try {
      const response = await fetchAPI('/api/session', {
        method: 'POST',
        body: JSON.stringify(data),
      })
      if (response['errors']) {
        return dispatch({
          type: LOGIN_FAILED,
          payload: response,
          error: true,
        })
      }
      return dispatch({
        type: LOGIN_DONE,
        payload: response,
      })
    } catch (err) {
      return dispatch({
        type: LOGIN_FAILED,
        payload: err,
        error: true,
      })
    }
  }
}
