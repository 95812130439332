import { createStore, applyMiddleware, combineReducers } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import thunkMiddleware from 'redux-thunk'
import { pageReducer } from './page/reducer'
import { loginReducer } from './login/reducer'
import { sessionReducer } from './session/reducer'
import { menuReducer } from './menu/reducer'
import { collectionsReducer } from './collections/reducer'
import { appealsReducer } from './appeals/reducer'
import { viewedReducer } from './viewed/reducer'

const reducers = combineReducers({
  page: pageReducer,
  login: loginReducer,
  session: sessionReducer,
  menu: menuReducer,
  collections: collectionsReducer,
  appeals: appealsReducer,
  viewed: viewedReducer,
})

export default (initialState?: any) => {
  const middlewares = composeWithDevTools(
    applyMiddleware(thunkMiddleware)
  )
  return createStore(reducers, initialState, middlewares)
}
