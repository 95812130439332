import React, { useRef, useCallback } from 'react'
import { Modal } from 'antd'
import { DefaultJsonForm } from '../../../components/default-json-form'
import { isFormDataRequired } from '../../../../lib/utils/collections'

const selectedSchema = {
  type: 'object',
  required: ['user', 'password', 'host', 'port'],
  properties: {
    'user': {
      type: 'string',
      title: 'E-mail',
    },
    'password': {
      type: 'string',
      title: 'Пароль',
    },
    'host': {
      type: 'string',
      title: 'Хост',
    },
    'port': {
      type: 'string',
      title: 'Порт',
    },
    'tls': {
      type: 'boolean',
      default: false,
      title: 'TLS',
    },
  },
}

export default function modalAddMail(): Promise<any> {
  let formData = {} as any;
  return new Promise(resolve => {
    Modal.confirm({
      icon: null,
      title: 'Добавить почту',
      content: <Content onChange={value => {
        formData = value
      }} schema={selectedSchema} />,
      onOk: () => new Promise((ok, err) => {
        const isRequired = isFormDataRequired(formData, selectedSchema)
        if(isRequired){
          resolve(formData)
          ok(null)
        }
        err()
      }),
      onCancel(){
        resolve(null)
      }
    })
  })
}

function Content({ onChange, schema }){
  const formDataRef =  useRef({})
  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = formData
    onChange(formData)
  }, [onChange])

  return (
    <DefaultJsonForm
      formData={formDataRef.current}
      schema={schema}
      onChange={localOnChange}
    />
  )
}