import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

export async function deleteCollectionObject(currentId){
  try {
    await fetchAPI(`/api/collections/objects/${currentId}`, { method: 'DELETE'})
    return true
  } catch {
    return false
  }
}

export async function updateCollectionObject(currentId, attributes){
  try {
    const response = await fetchAPI(`/api/collections/objects/${currentId}`)
    const object = response['data']['data'] || {}
    const requestBody = {
      'data': { ...object, 'attributes': attributes }
    }
    await fetchAPI(`/api/collections/-/objects/${currentId}`, { method: 'POST',  body: JSON.stringify(requestBody)})
    return true
  } catch {
    return false
  }
}



export async function insertCollectionObject(name, { attributes = {}, parentId, metadata = {} }){

  const requestBody = {
    'parent_id' : parentId || 0,
    'data': { 'attributes': attributes, metadata }
  }

  const result = await fetchAPI(`/api/collections/${name}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
  if (result['data'] && result['data']['id']) {
    return result['data']['id']
  }
  return null
}

function stageFormat(stage){
  return {
    id: getDataOfType(stage, 'id', [Number, String], null),
    ...getDataOfType(stage, 'data.attributes', Object, {}),
  }
}

export async function getWorkflows(){
  const nameWorkflows = 'workflows'
  const [responseGroups, responseWorkflows] = await Promise.all([
    fetchAPI(`/api/collections/objects-groups/${nameWorkflows}`),
    fetchAPI(`/api/collections/${nameWorkflows}/?access_key=axioma`)
  ])
  const workflows = {}
  const groups = getDataOfType(responseGroups, 'data', Array, [])
  const allWorkflows = getDataOfType(responseWorkflows, 'data.data', Array, []).sort((a, b) => a['id'] - b['id'])

  allWorkflows.forEach(item => {
     const groupId = item['group-id']
     if((groupId ?? null) === null){
       return
     }
     const groupName = getDataOfType(groups.find(item => item['id'] === groupId), 'name', [Number, String], null)
     if(groupName === null){
       return
     }
     if((groupName in workflows) === false){
      workflows[groupName] = []
     }
     workflows[groupName].push(stageFormat(item))
  })
  return workflows
}

