import React, { useState, useRef, useEffect, useCallback } from 'react'
import {  Modal } from 'antd'
import { DefaultJsonForm } from '../../components/default-json-form'
import { BrowserRouter } from 'react-router-dom'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { Provider, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { store } from '../../../browser-app'
import fetchAPI from '../../../lib/utils/fetch-api'

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  targetId => targetId,
)

function isFormDataRequired(formData, schema) {
  const { required } = schema || {}
  const result = Array.from(required).every((value: any) => {
    if (value in formData === false) {
      return false
    }
    if (formData[value] === undefined) {
      return false
    }
    if (formData[value] === null) {
      return false
    }
    if (formData[value] === '') {
      return false
    }
    return true
  })
  return result
}

const selectedSchema = {
  type: 'object',
  required: ['date', 'payment', 'prepayment-scan'],
  properties: {
    'date': {
      type: 'string',
      format: 'date',
      title: 'Дата оплаты',
    },
    'payment': {
      type: 'number',
      title: 'Cумма оплаты',
    },
    'prepayment-scan': {
      type: 'string',
      format: 'file-s3',
      title: 'Скан чека',
    }
  },
}

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData,
)

const createCase = createSelector(
    (state: any) => state.appeals,
    targetId => targetId
)

export function modalClose(event){
  let target = event.target
  while (Boolean(target?.tagName) === true) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}

export function modalAddPayment(options) {
  let formData = { }
  return new Promise(resolve => Modal.confirm({
    icon: null,
    title: 'Внесние предоплаты',
    /* @ts-ignore */
    content: <Provider store={store}><Content options={options} onChange={value => { formData = value }} /></Provider>,
    onCancel: () => resolve(null),
    cancelText: 'Отмена',
    okButtonProps: { 
      onClick: (event) => {
        const inputPay = getDataOfType(formData, 'payment', Number, 0)
        const sumPay = getDataOfType(formData, 'sumPay', Number, 0)
        const file = getDataOfType(formData, 'prepayment-scan', Array, null)

        if( !file ){
          Modal.info({
            title: 'Не загружен скан',
            content: (
              <div>
                <p>Загрузите скан чека</p>
              </div>
            )})
          return
        }

        if (inputPay <= 0) {
          Modal.info({
            title: 'Некорректная сумма',
            content: (
              <div>
                <p>Сумма должна быть больше нуля</p>
              </div>
            )})
          return
        }

        if ( sumPay - inputPay < 0) {
          Modal.info({
            title: 'Некорректная сумма',
            content: (
              <div>
                <p>Введенная сумма больше, чем необходимо для оплаты заказа</p>
              </div>
            )})
          return
        }
        
        const isRequired = isFormDataRequired(formData, selectedSchema)
        if(isRequired){
          resolve(formData)
          modalClose(event)
        }
      }
    }
  }))
}

function Content({ onChange, options }){
  const formDataRef = useRef({ 'date': new Date().toISOString(), 'type-pay': true })
  const [UISchema, setUISchema] = useState<any>({})
  const { sessionData } = useSelector(createSession)
  const [sumPay, setSumPay] = useState<any>(0)
  const { targetId } = useSelector(createCase)

  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = Object.assign(formData, { sumPay })

    onChange(formDataRef.current)
  }, [onChange, sumPay])

  useEffect(() => {
    const UISchema = {
      'date': {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '1',
        },
       options: {
         disabled: getDataOfType(options, 'disable', Boolean, false),
         isDisabledDatePrev: true,
        },
      },
      'payment': {
        css: {
          gridColumn: '24 / span 24',
          gridRow: '1',
        },
        options: {
          disabled: getDataOfType(options, 'disable', Boolean, false),
        },
      },
      'prepayment-scan': {
        options: {
          multiple: false
        }
      }
    };

    (async () => {
      const payments = await fetchAPI(`/api/web-hooks/add-payment-contract`, { method: 'POST',  body: JSON.stringify({
          targetId: Number(targetId),
          pay: {sum: 0,date: ''}
        })})
      const sum = getDataOfType(payments, 'sum', Number, 0)
      const paymentsSum = getDataOfType(payments, 'paymentsSum', Number, 0)
      const factSum = (sum - paymentsSum) 
      setSumPay(factSum)
      formDataRef.current = Object.assign({ 'payment': factSum }, formDataRef.current)
      onChange(formDataRef.current)
      setUISchema(UISchema)
    })()
  }, [sessionData, options, onChange])
  return (
    /* @ts-ignore */
    <BrowserRouter>
      <DefaultJsonForm
        formData={formDataRef.current}
        schema={selectedSchema}
        onChange={localOnChange}
        UISchema={UISchema}
      />
    </BrowserRouter>
  )
}