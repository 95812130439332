import styled, { StyledComponent } from 'styled-components'
import { Link } from 'react-router-dom'

export const ActionsWrapper: StyledComponent<'div', any> = styled.div`
  display: flex;
  align-items: center;
`

export const ActionButton: StyledComponent<'button', any> = styled.button`
  margin-left: 10px;
  font-size: 20px;
  border: none;
  background: none;
  cursor: pointer;
`

export const LinkButton = styled(Link)`
  display: inline-block;
  background: #fff;
  border-radius: 2px;
  border: 1px solid rgb(217, 217, 217);
  height: 24px;
  padding: 0 7px;
  margin: 3px;
  color: rgba(0, 0, 0, 0.65);
  font-size: 14px;
  white-space: nowrap;
  transition: color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), border-color 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  &:hover {
    color: #40a9ff;
    border-color: #40a9ff;
  }
`

export const TableWrapper = styled.div`
  .ant-table-row-selected {
    .ant-table-cell {
      background: #1890ff;
      color: #fff;
    }
    &:hover {
      .ant-table-cell {
        background: #40a9ff;
      }
    }
  }
  .ant-table-cell {
    padding: 0;
  }
`

export const ButtonWrapper = styled.div`
  button {
    margin-right: 15px;
  }
`

export const SelectedLabel: StyledComponent<'label', any> = styled.label`
  display: block;
  padding: 8px 0;
`

export const SelectedFlexLabel: StyledComponent<'label', any> = styled.label`
  display: flex;
  padding: 8px 0;
`

export const EditGroupWrapper = styled.div`
  display: inline-flex;
  justify-content: stretch;
  align-items: center;
  
  max-width: 85%;
`
