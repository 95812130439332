import React from 'react'
import { ColumnsType } from 'antd/lib/table/Table'
import { Tooltip } from 'antd'
import { TableOutlined, ToolOutlined, FileTextTwoTone, FolderTwoTone, FolderOpenTwoTone } from '@ant-design/icons'

import { CellRecord, IRow } from './interface'
import SchemaCell from '../../components/app-collection-object-list/schema-cell'
import dayjs from '../../../lib/utils/configured-dayjs'
import { EditGroupWrapper, IdBlock, LinkWrapper, TitleWrapper } from './styles'
import { ICollectionsGroup } from '../../views/admin-edit-group-form'
import { DarkLink } from '../../components/dark-link'

const getCreateGroupURL = () => 'collections/group/'
const getUpdateGroupURL = id => `collections/group/${id}`

export const columns: (
  sortCallback?: any,
  groups?: ICollectionsGroup[],
  onAfterGroupCreate?: () => void,
  handleGroupUpdate?: (record: CellRecord) => void,
  expandedList?: Record<number, boolean>
) => ColumnsType<IRow> = (
  sortCallback,
  groups,
  onAfterGroupCreate,
  handleGroupUpdate,
  expandedList,
) => [
  {
    title: 'Наименование',
    width: 280,
    dataIndex: 'title',
    key: 'title',
    fixed: 'left',
    sorter: sortCallback && sortCallback('title'),
    onCell: record => ({
      record,
      editable: record['isGroup'],
      dataIndex: 'title',
      title: record['isGroup'] ? 'Нажмите, чтобы отредактировать' : '',
      handleSave: handleGroupUpdate,
      groups,
      onAfterGroupCreate,
      getCreateGroupURL,
      getUpdateGroupURL,
    }),
    render: (text, record) =>
      record['isGroup'] ? (
        <Tooltip title="Нажмите, чтобы отредактировать">
          <EditGroupWrapper>
            {expandedList && expandedList[record['key']] ? (
              <FolderOpenTwoTone twoToneColor='#5f6368' rev={undefined}/>
            ) : (
              <FolderTwoTone twoToneColor='#5f6368' rev={undefined}/>
            )}
            {record.title}
          </EditGroupWrapper>
        </Tooltip>
      ) : (
        <TitleWrapper>
          <DarkLink to={`/admin/collections/${record.name}/objects`} title="Открыть записи справочника">
            <FileTextTwoTone twoToneColor='#1890ff' rev={undefined}/>&nbsp;
            {record.title}
          </DarkLink>
        </TitleWrapper>
      ),
  }, {
    title: 'ID',
    width: 60,
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    defaultSortOrder: 'descend',
    sorter: sortCallback && sortCallback('id'),
    render: (text, record) => (
      <DarkLink to={`/admin/collections/${record.name}/objects`} title="Открыть записи справочника">
        <IdBlock>
          {record.id}
        </IdBlock>
      </DarkLink>
    ),
  }, {
    title: 'Кодовое имя (англ)',
    dataIndex: 'name',
    key: 'name',
    width: 140,
    fixed: 'left',
    sorter: sortCallback && sortCallback('name'),
    render: (text, { name }) => (
      <DarkLink to={`/admin/collections/${name}/objects`} title="Открыть записи справочника">
        {text}
      </DarkLink>
    ),
  }, {
    title: 'Используемая структура(ы)',
    dataIndex: 'schema',
    key: 'schema',
    width: 220,
    fixed: 'left',
    render: names => {
      return names && names.map(name => SchemaCell(name))
    },
  }, {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
    width: 180,
    fixed: 'left',
    render: (text, { name }) => (
      <DarkLink to={`/admin/collections/${name}/objects`} title="Открыть записи справочника">
        {text}
      </DarkLink>
    ),
  },
  {
    title: 'Автор',
    width: 120,
    dataIndex: 'ownerUser',
    key: 'ownerUser',
    render: (text, { name }) => (
      <DarkLink to={`/admin/collections/${name}/objects`} title="Открыть записи справочника">
        {text || <>&nbsp;</>}
      </DarkLink>
    ),
  },
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    key: 'created-at',
    sorter: sortCallback && sortCallback('created-at'),
    render: (text, record: IRow) => record['isGroup'] ? null : (
      <Tooltip placement="top" title={dayjs(record.createdAt).format('YYYY-MM-DD HH:mm:ss')}>
        <DarkLink to={`/admin/collections/${record.name}/objects`} title="Открыть записи справочника">
          <span>{dayjs(record.createdAt).fromNow()}</span>
        </DarkLink>
      </Tooltip>
    ),
    width: 100,
  }, {
    title: 'Дата обновления',
    dataIndex: 'updatedAt',
    key: 'updated-at',
    sorter: sortCallback && sortCallback('updated-at'),
    render: (text, record) => record['isGroup'] ? null : (
      <Tooltip title={dayjs(record.updatedAt).format('YYYY-MM-DD HH:mm:ss')}>
        <DarkLink to={`/admin/collections/${record.name}/objects`} title="Открыть записи справочника">
          <span>{dayjs(record.updatedAt).fromNow()}</span>
        </DarkLink>
      </Tooltip>
    ),
    width: 100,
  }, {
    title: 'Действие',
    key: 'operation',
    fixed: 'right',
    width: 100,
    render: (text, record) => record['isGroup'] ? null : (
      <>
        <LinkWrapper
          to={`/admin/collections/${record.name}/objects`}
          title="Просмотр содержимого (записей) справочника"
        >
          <TableOutlined rev={undefined}/>
        </LinkWrapper>
        <LinkWrapper
          to={`/admin/collections/${record.name}`}
          title="Настройка справочника"
        >
          <ToolOutlined rev={undefined}/>
        </LinkWrapper>
      </>
    ),
  },
]
