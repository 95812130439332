import type {ColumnsType} from 'antd/es/table'
import dayjs from 'dayjs'
import React, {useCallback} from 'react'
import {Popconfirm} from 'antd'
import {DataType} from './interface'
import {ButtonActionWrapper} from '../../components/list-operations/styles'
import {DeleteTwoTone} from '@ant-design/icons'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'
import {Stage} from '../../components/activity-timeline/styles'
import {formatPrice} from '../utils'
import {Value} from "../elements";

const ColumnId: ColumnsType<DataType> = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    width: 70,
    fixed: 'left',
    render: (_, {id, stage_code}) => <span style={ stage_code === 'new' ? {fontWeight:'bold'} : {} }>{id}</span>
  },
  {
    title: 'Стадия',
    dataIndex: 'stage',
    key: 'stage',
    width: 170,
    render: (_, {stage_code, stages}) => <StageContainer stage={stage_code} stages={stages}/>
  }
]

const ColumnOrganization: ColumnsType<DataType> = [
  {
    title: 'Организация',
    width: 200,
    dataIndex: 'organization',
    key: 'organization',
    sorter: true,
    render: val => <Value data={val}/>
  }
]

const ColumnsGeneral: ColumnsType<DataType> = [
  {
    title: 'Менеджер',
    dataIndex: 'manager_name',
    key: 'manager_name',
    width: 120,
    sorter: true,
    render: val => <Value data={val}/>
  },
  {
    title: 'Дата обращения',
    dataIndex: 'appeal_date',
    key: 'appeal_date',
    width: 130,
    sorter: true,
    render: val => dayjs(val).format('DD.MM.YYYY HH:mm')
  },
  {
    title: 'В работе менеджера с',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 130,
    sorter: true,
    render: val => dayjs(val).format('DD.MM.YYYY HH:mm')
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updated_at',
    key: 'updated_at',
    width: 130,
    sorter: true,
    render: val => <span>{dayjs(val).fromNow()}</span>
  },
  {
    title: 'Дата задачи',
    dataIndex: 'task_date',
    key: 'task_date',
    width: 130,
    sorter: true,
    render: val => <span>{dayjs(val).fromNow()}</span>
  },
  {
    title: 'Контактное лицо клиента',
    dataIndex: 'name',
    width: 120
  },
  {
    title: 'Телефон клиента',
    dataIndex: 'phone',
    key: 'phone',
    width: 120
  },
  {
    title: 'Вид контакта',
      dataIndex: 'source_appeal',
    key: 'source_appeal',
    width: 120,
    render: val => <Value data={val}/>
  },
  {
    title: 'Признак заказа',
    dataIndex: 'order_attribute',
    key: 'order_attribute',
    width: 120,
    render: val => <Value data={val}/>
  },
  {
    title: 'Адрес объекта',
    dataIndex: 'address',
    key: 'address',
    width: 120,
    render: val => <Value data={val}/>
  },
  {
    title: 'Сумма заказа',
    dataIndex: 'products_total',
    key: 'products_total',
    width: 130,
    sorter: true,
    render: val => formatPrice(val || 0)
  },
  {
    title: 'Предоплата',
    dataIndex: 'payments_total',
    key: 'payments_total',
    width: 130,
    sorter: true,
    render: val => formatPrice(val || 0)
  },
  {
    title: 'Задолженность',
    dataIndex: 'debt',
    key: 'debt',
    width: 130,
    sorter: true,
    render: val => formatPrice(val || 0)
  },
  {
    title: 'Дата замера',
    dataIndex: 'measurement_date',
    key: 'measurement_date',
    width: 130,
    sorter: true,
    render: val => dayjs(val).format('DD.MM.YYYY')
  },
  {
    title: 'Дата оплаты',
    dataIndex: 'payment_date',
    key: 'payment_date',
    width: 130,
    sorter: true,
    render: val => dayjs(val).format('DD.MM.YYYY')
  },
  {
    title: 'Дата доставки',
    dataIndex: 'delivery_date',
    key: 'delivery_date',
    width: 130,
    sorter: true,
    render: val => dayjs(val).format('DD.MM.YYYY')
  },
  {
    title: 'Дата монтажа',
    dataIndex: 'mount_date',
    key: 'mount_date',
    width: 130,
    sorter: true,
    render: val => dayjs(val).format('DD.MM.YYYY')
  },
  {
    title: 'Номер производственного заказа',
    dataIndex: 'production_number',
    key: 'production_number',
    width: 180,
    render: val => <Value data={val}/>
  },
  {
    title: 'Причина отмены',
    width: 200,
    dataIndex: 'reason_cancel',
    key: 'reason_cancel',
    render: val => <Value data={val}/>
  },
  {
    title: 'Дата создания',
    dataIndex: 'created_at',
    key: 'created_at',
    width: 130,
    sorter: true,
    render: val => <span>{dayjs(val).fromNow()}</span>
  }
]

const ColumnAction: ColumnsType<DataType> = [
  {
    title: 'Действие',
    key: 'operation',
    className: 'no-edit',
    fixed: 'right',
    width: 100,
    align: 'center',
    render: (_, {id, deleteRecord}) => {
      return (
        <span onClick={e => {
          e.stopPropagation()
        }}>
          <DeleteButton id={id} deleteRecord={deleteRecord}/>
        </span>
      )
    }
  }
]

export const getColumns = (leader, sorter) => {
  // собирает колонки для разных ролей
  const columns = leader ? ColumnId.concat(ColumnOrganization, ColumnsGeneral, ColumnAction) : ColumnId.concat(ColumnsGeneral)
  // устанавливает порядок сортировки
  return columns.map(item => {
    if (item.key === sorter.sort_field)
      item.sortOrder = sorter.sort_order
    else
      delete item.sortOrder
    return item
  })
}

export function DeleteButton({id, deleteRecord}) {
  const localDeleteRecord = useCallback(() => deleteRecord(id), [deleteRecord, id])
  return (
    <Popconfirm
      title="Вы уверены, что хотите удалить эту запись?"
      okText="Да"
      cancelText="Нет"
      onConfirm={() => localDeleteRecord()}
    >
      <ButtonActionWrapper title="Удалить">
        <DeleteTwoTone  rev={undefined} />
      </ButtonActionWrapper>
    </Popconfirm>
  )
}

export function StageContainer({ stage, stages }) {
  if (stages && stages.length) {
    const current = stages.find(item => item.code === stage)
    const style = getDataOfType(current, 'style', Object, {})
    return <Stage style={style}>{current.name}</Stage>
  }
  return <>...</>
}
