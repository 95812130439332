import styled from 'styled-components'

export const TableWrapper = styled.div`
  tr {
    cursor: pointer;
  }
`

export const FilterWrapper = styled.div`
  display: flex;
  gap: 4px;
  flex-wrap: wrap;
  padding-bottom: 8px;
`
