import { css } from 'styled-components'
import theme from './theme'

export default css`
  :root {
    font-size: ${theme.fonts.size};
  }
  *,
  *::after,
  *::before {
    box-sizing: border-box;
  }
  html,
  body,
  #app {
    height: 100%;
  }
  #app {
    transition: filter 0.3s;

    &.modal-open {
      filter: blur(3px);
    }
  }
  body {
    color: ${theme.colors.black};
    font-family: ${theme.fonts.main};
    font-weight: 400;
    line-height: 20px;

    background-color: ${theme.colors.grayForBody};

    padding: 0;
  }
  * {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -moz-tap-highlight-color: rgba(0, 0, 0, 0);
  }
  img {
    max-width: 100%;
    vertical-align: middle;
  }
  ul {
    padding: 0;
    margin: 0;
    list-style-type: none;
  }
  .logo {
    height: 32px;
    background: rgba(255, 255, 255, 0.2);
    margin: 16px;
  }

  .site-layout .site-layout-background {
    background: #fff;
  }
  [data-theme='dark'] .site-layout .site-layout-background {
    background: #141414;
  }
`
