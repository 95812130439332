import fetchAPI from '../../lib/utils/fetch-api'
import dayjs from 'dayjs'
import {getDataOfType} from './get-data-of-type'

export function createTableFields(attributes, fieldEnumNames){
  const fields = {}
  const attributesKeys = Object.keys(attributes)
  attributesKeys.forEach(key => {
    if(key in fieldEnumNames){
      fields[key] = Object.values(fieldEnumNames[key]).filter(value => typeof value === "string").join(" | ")
      return
    }
    if(Array.isArray(attributes[key])){
      fields[key] = `Набор из ${attributes[key].length} записей`
      return
    }
    fields[key] = attributes[key]
  })
  return fields
}

export function calcTotalWidth(...columnsArgs:  any[]){
  return columnsArgs.reduce((acc, columns) => {
    return acc + columns.reduce((acc: any, { width }) => acc + width, 0)
  }, 0)
}

export function tableRowClick(event, record, cb){
  let target = event.target
  while (target.tagName === 'tr' || !Boolean(target.tagName)) {
    target = target?.parentNode
  }
  const className = getDataOfType(target, 'className', String, null)
  if (className === null) {
    return null
  }
  const classes = className.split(' ')
  if (classes.includes('no-edit') || classes.includes('ant-table-selection-column')) {
    return null
  }
  cb(record)
}

export function getColumnsOfSchema(schema, addValues = {}) {
  return Object.entries(schema?.properties ?? {}).map(([key, value]: any) => ({
    title: value?.title ?? key,
    dataIndex: key,
    key,
    ...addValues,
  }))
}

export function formatFormDataOfSchema(formData, schema) {
  const properties = schema?.properties ?? {}
  const formatData = Object.entries(formData ?? {}).map(([key, value]: any) => {
    if (!(key in properties)) {
      return [key, value]
    }
    switch (properties[key]?.type) {
      case 'string': {
        if (properties[key]?.format === 'date') {
          return [key, value && dayjs(value).format('DD.MM.YYYY')]
        }
        if (properties[key]?.format === 'date-time') {
          return [key, value && dayjs(value).format('DD.MM.YYYY HH:mm')]
        }
        if (properties[key]?.format === 'time') {
          return [key, value && dayjs(value).format('HH:mm')]
        }
        break
      }
      case 'number': {
        const number = getDataOfType(formData, key, [Number, String], '')
        return [key, number]
      }
      case 'boolean': {
        const boolean = getDataOfType(formData, key, Boolean, null)
        if (boolean === true){
          return [key, 'да']
        }
        if (boolean === false){
          return [key, 'нет']
        }
        return [key, '']
      }
      case 'array': {
        const { length } = getDataOfType(formData, key, Array, [])
        return [key, `Набор из ${length} записей`]
      }
      case 'object': {
        const { length } = Object.keys(getDataOfType(formData, key, Object, {}))
        return [key, `Объект из ${length} свойств`]
      }
    }
    return [key, value]
  }) as any
  return Object.fromEntries(formatData)
}

export async function fetchSchema({ name }){
  const schemasResult = await fetchAPI('/api/schemas?access_key=axioma&fields=title,metadata,properties')
  const fetchedSchemas = getDataOfType(schemasResult, 'data.data', Array, [])
  const selectedSchemas = fetchedSchemas.filter(item => item['name'] === name)
  return getDataOfType(selectedSchemas, '[0]', Object, null)
}

export function listColumnsHidden(columns, columnsHidden){
  return columns
    .filter(item => item['title'])
    .filter(item => columnsHidden.includes(item['key']) === false)
}

export function formatListData(collectionsData, schema){
  return collectionsData.map(collectionItem => {
    const formData = getDataOfType(collectionItem, 'data.attributes', Object, {})
    const schemaName = getDataOfType(collectionItem, 'data.metadata.schema', [String, Array], null)
    const formatFormData = formatFormDataOfSchema(formData, schema)
    return ({
      id: collectionItem['id'],
      'parent-id': Number(collectionItem['parent-id']),
      organizationTitle: collectionItem['organization-title'],
      ...formatFormData,
      schema: schemaName,
      'workflow-id': collectionItem?.['workflow-id'],
      createdAt: collectionItem['created-at'],
      updatedAt: collectionItem['updated-at'],
      reqCreatedAt: collectionItem['req-created-at'],
      'contract-sum': collectionItem?.contractSum,
      'contract-prepayment': collectionItem?.contractPrepayment,
      'contract-indebtedness': collectionItem?.contractIndebtedness
    })
  })
}

export function formatListData2(data, schema){
  return data.map(item => {
    return formatFormDataOfSchema(item, schema)
  })
}