import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { JSONSchema7, JSONSchema7Definition } from 'json-schema'
import { createSelector } from 'reselect'
import WhiteSpace from 'antd-mobile/lib/white-space'
import WingBlank from 'antd-mobile/lib/wing-blank'
import Toast from 'antd-mobile/lib/toast'
import { debounce } from 'lodash'
import modalOrderCancellation from '../../../views/modal-order-cancellation'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import {
  clearCaseStore,
  initialCase,
  reloadActionsTimeLine,
  setAppealStage,
  setTargetId,
} from '../../../store/appeals/actions'
import { cardName, collectionName } from './consts'
import { B2BMobile } from '../../../layouts/b2b-mobile'
import {
  ErrorsList,
  HeaderBlock,
  ValidationsMenu,
  ClientCard,
  DocumentCard,
  TDocumentItem,
  ManagerCard,
  CommentCard,
  TDataListItem,
  HeaderLeftContent,
} from '../components'
import { BackToMeasurementsList } from './styles'
import { ActionsList } from './measurements-actions-list'
import { setStageFromCard } from '../../../../lib/utils/cards'
import { MeasurementDateCard } from './measurement-date-card'
import { parseDate } from '../../../utilits'
import { getDocumentList, getValidatorsData } from '../utils'
import { OrderNumberCard } from '../components/order-number-card'
import ActivityTimeLine from  '../../../connectors/tab-stuck-files-and-note'

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  stageCode => stageCode,
  workflows => workflows,
  targetId => targetId,
)
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

export const MeasurementsEditPage = () => {
  const { id } = useParams()  as any
  const dispatch = useDispatch()
  const history = useHistory()
  const parentIdRef = useRef<any>(null)
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [measurementsData, setMeasurementsData] = useState<Record<string, any>>({})
  const { workflows, targetId } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)
  const [forecast, setForecast] = useState<Record<string, any>>({})
  const [managerData, setManagerData] = useState<Record<string, any>>({})
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<JSONSchema7 | null>(null)
  const [showSubMenuModal, setShowSubMenuModal] = useState(false)
  const [validations, setValidations] = useState<JSONSchema7Definition[]>([])
  const [isAdditionalValidations, setIsAdditionalValidations] = useState(false)
  const [nextStage, setNextStage] = useState('')
  const [nextStageIndex, setNextStageIndex] = useState(0)
  const [documents, setDocuments] = useState<TDocumentItem[]>([])
  const [author, setAuthor] = useState(0)
  const [hideHeaderPopover, setHideHeaderPopover] = useState<boolean | undefined>(undefined)

  const prepareForecast = useCallback((forecastRequest, measurementObject) => {
    const measurementsForecast = forecastRequest['data'].find(f => f['code'] === cardName)
    const planDate = measurementObject ?
      `${parseDate(measurementObject['date-measurement'], 'dd.MM.yyyy')} ${measurementObject['time-measurement']}`
      : null
    const adjustedDate = measurementObject && measurementObject['date-adjusted-measurement'] ?
      `${parseDate(measurementObject['date-adjusted-measurement'], 'dd.MM.yyyy')} ${measurementObject['time-adjusted-measurement']}`
      : null

    return {
      ...measurementsForecast,
      'date-plan': planDate,
      'date-adjusted': adjustedDate,
    }
  }, [])

  const updateForecast = useCallback(async () => {
    const forecastResponse = await fetchAPI(`/api/order-forecast/${parentIdRef.current}`)
    const managerData = forecastResponse?.['data']?.[0]?.responsibles?.[0]
    managerData && setManagerData(managerData)

    if (forecastResponse && forecastResponse['data'] && Array.isArray(forecastResponse['data'])) {
      const measurementsForecast = prepareForecast(forecastResponse, measurementsData)

      if (measurementsForecast) {
        setForecast(measurementsForecast)
      }
    }
  }, [measurementsData, prepareForecast])

  const currentStages = useMemo(() => {
    return getDataOfType(workflows, cardName, Array, [])
  }, [workflows])

  const authorId = useMemo(() => {
    return getDataOfType(sessionData, 'user.id', Number, 0)
  }, [sessionData])

  const getData = useCallback(async () => {
    const fields = [
      'title',
      'description',
      'type',
      'properties',
      'required',
      'created_by',
      'created_at',
      'updated_at',
    ].join(',')
    const [schemasResponse, collectionsObjectsResponse] = await Promise.all([
      fetchAPI(`/api/schemas?access_key=axioma&fields=${fields}`),
      fetchAPI(`/api/collections/objects/${id}`),
    ])
    const fetchedSchemas: JSONSchema7[] = getDataOfType(schemasResponse, 'data.data', Array, [])
    const selectedSchemas = fetchedSchemas.filter(item => item['name'] === collectionName)
    const selectedSchema = (typeof selectedSchemas[0] === 'object') ? selectedSchemas[0] : null

    if (selectedSchema === null) {
      setPageErrors(['Некорректный ответ сервера при схемы коллекции'])
      return
    }

    const collectionsObjectsResponseData = collectionsObjectsResponse['data'] || {}
    const measurementsDataObject = collectionsObjectsResponseData['data']['attributes']
    const parentId = Number(collectionsObjectsResponseData['parent-id'])
    const stage = measurementsDataObject['stage'] || 0

    setMatchedJSONSchema(selectedSchema)
    setAuthor(authorId)
    dispatch(setTargetId({ targetId: parentId }))
    parentIdRef.current = parentId
    dispatch(reloadActionsTimeLine())

    setPageErrors([])
    setMeasurementsData(measurementsDataObject)
    dispatch(setAppealStage({ step: stage }))
    setIsDataLoading(false)
  }, [authorId, dispatch, id])

  useEffect(() => {
    getData()
    const timer = setInterval(() => getData(), 3000)
    return () => clearInterval(timer)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (measurementsData) {
      updateForecast()
    }
  }, [measurementsData, updateForecast])

  useEffect(() => {
    const updateDocList = async () => {
      const docList = await getDocumentList(matchedJSONSchema, measurementsData)

      if (docList.length > 0) {
        setDocuments(docList)
      }
    }

    if (matchedJSONSchema && matchedJSONSchema['properties']) {
      updateDocList()
    }
  }, [matchedJSONSchema, measurementsData])

  useEffect(() => {
    // ts-ignore
    dispatch(initialCase({ targetId: null, cardName: cardName }))
    return () => {
      dispatch(clearCaseStore())
    }
  }, [id, dispatch])

  useEffect(() => {
    if (isDataLoading) {
      Toast.loading('Загрузка...', 1);
    } else {
      Toast.hide()
    }
  }, [isDataLoading])

  const handleOpenMeasurementsList = useCallback(() => {
    history.push('/sales/measurements')
  }, [history])

  const stagesName = useMemo(() => {
    const stage = currentStages[measurementsData['stage']] || { name: '' }

    return stage['name']
  }, [measurementsData, currentStages])

  const haveManagerData = useMemo(() => {
    return Object.keys(managerData).length > 0 && managerData['profile-data']
  }, [managerData])

  const haveDocument = useMemo(() => documents.length > 0, [documents])

  const handleCall = useCallback((value: string) => () => {
      Object.assign(window.location, { href: `tel:${value}` })
    },
  [])
// eslint-disable-next-line
  const saveCase = useCallback(debounce(async formData => {
    const requestBody = {
      data: {
        name: '',
        title: '',
        attributes: formData,
        metadata: { schema: collectionName }
      }
    }

    const result = await fetchAPI(`/api/collections/-/objects/${id}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })
    const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)

    if (updated === null) {
      console.warn('Ошибка сохранения записи')
    }

    return result
  }, 1000), [])

  const setData = useCallback(newFromData => {
    let result = { ...measurementsData, ...newFromData }

    setMeasurementsData(prevState => {
      result = {
        ...prevState,
        ...newFromData,
      }

      return result
    })

    return result
  }, [measurementsData])

  const updateData = useCallback(async nextFormData => {
    const formData = setData(nextFormData)
    const response = await saveCase(formData)

    dispatch(reloadActionsTimeLine())

    return response
  }, [dispatch, saveCase, setData])

  const handleSubMenuClose = useCallback(() => {
    setShowSubMenuModal(false)
  }, [])

  const isDisabledSwitchStage = useMemo(() => {
    let result = validations.length > 0

    if (result && measurementsData) {
      /** result = false if all validations check */
      result = validations.some(valid => !measurementsData[valid['code']])
    }

    return result
  }, [measurementsData, validations])

  const handleCloseHeaderPopover = useCallback(() => {
    setHideHeaderPopover(true)
  }, [])

  const actionsList = useMemo(() => {
    const result: TDataListItem[] = []

    const handleStageToWork = async (parentId, stage, stageCodeNext) => {
      setData({ stage })
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      setData({ stage })
    }

    const handleMeasurementLeft = async (parentId, stage, stageCodeNext) => {
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      setData({ stage })
    }

    const handleMeasurerArrived = async (parentId, stage, stageCodeNext) => {
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      setData({ stage })
    }

    const handleMeasurementReady = async (parentId, stage, stageCodeNext) => {
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      setData({ stage })
    }

    const handleContractSigned = async (parentId, stage, stageCodeNext) => {
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      dispatch(setAppealStage({ stageCode: 'done', type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      setData({ stage })
    }

    const handleDone = async (parentId, stage, stageCodeNext) => {
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId: parentId,
        isHistory: true,
      })
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
      setData({ stage })
    }

    const handleTransition = async (parentId, stage, stageCodeNext) => {
      await setStageFromCard({
        cardName: cardName, stageCode: stageCodeNext, targetId: parentId, isHistory: true
      })
      dispatch(reloadActionsTimeLine())
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      setData({ stage })
    }

    const handleOpenSubMenu = (validationsList, nextStage, nextStageIndex, isAdditional = false) => {
      setValidations(validationsList)
      setIsAdditionalValidations(isAdditional)
      setShowSubMenuModal(true)

      if (!isAdditional) {
        setNextStage(nextStage)
        setNextStageIndex(nextStageIndex)
      }
    }

    const handleGetValidatorsData = validator => getValidatorsData(matchedJSONSchema, validator)

    const onCancel = async () => {
      const result = await modalOrderCancellation()
      const stageCodeNext = 'new'
      await setStageFromCard({
        cardName: cardName, stageCode: stageCodeNext, targetId , isHistory: true,
        addFormData: {
          'reason-cancellation': result?.['reason-cancellation'],
          comment: result?.['comment'],
        }
      })
      dispatch(reloadActionsTimeLine())
      dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      setData({ stage: 0 })
    }

    result.push(
      <ActionsList
        id={id}
        stageList={currentStages}
        formData={measurementsData}
        parentId={parentIdRef.current}
        matchedJSONSchema={matchedJSONSchema}
        onStageToWork={handleStageToWork}
        onMeasurementLeft={handleMeasurementLeft}
        onMeasurerArrived={handleMeasurerArrived}
        onMeasurementReady={handleMeasurementReady}
        onContractSigned={handleContractSigned}
        onDone={handleDone}
        onTransition={handleTransition}
        sessionData={sessionData}
        getValidatorsData={handleGetValidatorsData}
        openSubMenu={handleOpenSubMenu}
        onClose={handleCloseHeaderPopover}
        onCancel={onCancel}
      />
    )

    return result
  }, [
    dispatch,
    handleCloseHeaderPopover,
    id,
    matchedJSONSchema,
    measurementsData,
    sessionData,
    setData,
    currentStages,
    targetId
  ])

  const handleModalStageChange = useCallback(async () => {
    await setStageFromCard({
      cardName: cardName,
      stageCode: nextStage,
      targetId: parentIdRef.current,
      isHistory: true,
    })
    setData({ stage: nextStageIndex })
    setShowSubMenuModal(false)
  }, [nextStage, nextStageIndex, setData])

  const currentManagerData = useMemo(() => {
    
    return managerData['profile-data'] ? managerData['profile-data'] : {
      name: '',
      phone: '',
      email: '',
    }
  }, [managerData])

  const isShowHeaderPopover = useMemo(() => {
    return showSubMenuModal || (hideHeaderPopover === true) ? false : undefined
  }, [hideHeaderPopover, showSubMenuModal])

  const handlerDropHeaderPopoverShowState = useCallback(() => {
    setHideHeaderPopover(undefined)
  }, [])

  const getProductionNumber = useCallback(data => {
    const number = getDataOfType(
      data,
      'production-order-number',
      String,
      0,
    ) || 0

    return Number(number)
  }, [])

  const getOrderNumber = useCallback(data => {
    const number = getDataOfType(
      data,
      'number-contract',
      String,
      0,
    ) || 0

    return Number(number)
  }, [])

  const checkShowOrderNumber = useCallback((data) => (
    Boolean(getOrderNumber(data) || getProductionNumber(data))
    ), [getOrderNumber, getProductionNumber]
  )
  

  //console.log({ haveManagerData, currentManagerData })
  return (
    <B2BMobile>
      <HeaderBlock
        leftContent={
          <HeaderLeftContent id={parentIdRef.current} name={stagesName} />
        }
        popoverOverlay={actionsList}
        show={isShowHeaderPopover}
        onAfterClose={handlerDropHeaderPopoverShowState}
      />
      <WhiteSpace size="lg" />
      <ErrorsList pageErrors={pageErrors} />
      <WingBlank size="lg">
        <BackToMeasurementsList onClick={handleOpenMeasurementsList}>{'<'} К списку заказов</BackToMeasurementsList>
        <WhiteSpace size="lg" />
        <ClientCard onCall={handleCall} data={measurementsData} />
        <MeasurementDateCard data={forecast} />
        {checkShowOrderNumber(measurementsData) && (
          <OrderNumberCard
            orderNumber={getOrderNumber(measurementsData)}
            productionNumber={getProductionNumber(measurementsData)}
          />
        )}
        {haveManagerData && (
          <ManagerCard data={currentManagerData} onCall={handleCall} />
        )}
        <CommentCard
          targetId={parentIdRef.current}
          taskOwnerId={measurementsData['measurer']}
        />
        {haveDocument && (
          <DocumentCard data={documents} />
        )}
      </WingBlank>
      <ValidationsMenu
        cardName={cardName}
        targetId={parentIdRef.current}
        author={author}
        validations={validations}
        show={showSubMenuModal}
        isCantSwitchStage={isDisabledSwitchStage}
        onClose={handleSubMenuClose}
        onSwitchStage={handleModalStageChange}
        data={measurementsData}
        updateData={updateData}
        isAdditional={isAdditionalValidations}
        adjustedDateKey="date-adjusted-measurement"
        adjustedTimeKey="time-adjusted-measurement"
      />
      <ActivityTimeLine />
    </B2BMobile>
  )
}
