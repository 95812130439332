import React, { useEffect, useState, useMemo } from 'react'
import fetchAPI from '../../../lib/utils/fetch-api'
import { Select } from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { ISelect } from './interface' 

function SelectFrom({ value, schema, UISchema, onChange }: ISelect) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const allowClear = useMemo(() => Boolean(UISchema?.options?.allowClear), [UISchema])
  const [objects, setObjects] = useState<any[]>([{ id: value, value }])
  const filter = useMemo(() => {
    const roleId = UISchema?.options?.roleId
    const organizationId = UISchema?.options?.organizationId
    const rolesIds = [].concat(roleId).join(',')
    const organizationsIds = [].concat(organizationId).join(',')
    if(roleId && organizationId){
      return `filter[role]=${rolesIds}&filter[organization]=${organizationId}`
    }  
    if(roleId){
      return `filter[role]=${rolesIds}`
    }
    if(organizationId){
      return `filter[organization]=${organizationsIds}`
    }
    return ''
  }, [UISchema])

  useEffect(() => {
    (async () => {
      const objectsResult: any = await fetchAPI(`/api/users?${filter}`)
      const objectsFormat = getDataOfType(objectsResult, 'data', Array, []).map(({ id, login, ...item }) => {
        const name = item?.['profile-data']?.['name']
        if (name) {
          return { value: id, label: name }
        }
        return { value: id, label: login }
      })
      setObjects(objectsFormat)
    })()
  }, [filter, UISchema])

  const localValue = useMemo(() => Number(value) || undefined, [value])
  const objectsFilter = useMemo(() => {
    const whereNotIds = getDataOfType(UISchema, 'options.whereNotIds', Array, []).filter(id => id !== localValue)
    return objects.filter(({ value }) => whereNotIds.includes(value) === false ) 
  }, [objects, localValue, UISchema])

  return (
    <Select
      className="select-field"
      placeholder="Пользователь"
      allowClear={allowClear}
      optionFilterProp="children"
      onChange={onChange}
      value={localValue}
      options={objectsFilter}
      disabled={disabled}
    />
  )
}

export default SelectFrom

