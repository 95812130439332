import React from 'react'
import ActivityTimeLine from '../../connectors/activity-timeline'
import CaseExecutionPlan from '../../views/case-execution-plan'

function EventRibbonTab({ name, schema, formData, onChange }) {
return (
  <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>
    <div style={{ flexBasis: '50%' }}>
     <CaseExecutionPlan />
    </div>
    <div style={{ flexBasis: '50%', paddingLeft: 20 }} className="actions-time-line">
      <ActivityTimeLine />
    </div>
  </div>
  )
}

export default EventRibbonTab


