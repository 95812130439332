import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useParams } from 'react-router-dom'
import { Form, Alert, Skeleton, Button, Space } from 'antd'
import { SaveOutlined } from '@ant-design/icons'
import { AlertWrapper } from './styles'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import { createUISchemaVertical } from '../../../lib/utils/grid-fild-orientation'
import fieldsSchema from './json-schema2.json'
import { objectFilter } from '../../../lib/utils/get-table-transform-schema-and-form-data'
import TableConversion from '../card-report/table-conversion'
import { modalAddMutualSettlements } from '../../views/modal-add-mutual-settlements'


function downloadUrl(url, exportName) {
  const downloadAnchorNode = document.createElement('a')
  downloadAnchorNode.setAttribute('href', url)
  downloadAnchorNode.setAttribute('download', exportName)
  document.body.appendChild(downloadAnchorNode) // required for firefox
  downloadAnchorNode.click()
  downloadAnchorNode.remove()
}

const fieldsFormDataCode = {
  'conversion-organization': ['date-range', 'organization', 'users'],
  'rating-organization': ['organization'],
  'income-expenses': ['order-group', 'date-range','organization']
}

function isFormDataValid(formData, reportCode) {
  const fields = getDataOfType(fieldsFormDataCode, reportCode, Array, []) as string[]
  const keys = Object.keys(formData)
  return fields.map(item => keys.includes(item)).every(item => item) &&
  Object.values(formData).every(item => item)
}


const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}




// UISchema?.options?.organizationId

function fieldsSchemaFromCode(reportCode) {
  const fields = getDataOfType(fieldsFormDataCode, reportCode, Array, []) as string[]
  const filter = ([key]) => fields.includes(key)
  return {
    ...fieldsSchema,
    properties: objectFilter(fieldsSchema.properties, filter)
  }
}



export default function AdminNewObjectPage() {
  const { id } = useParams()  as any
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const formDataRef = useRef<any>({ users: [], 'order-group': 'order' })
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const [object, setObject] = useState<any>({})
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [tableData, setTableData] = useState<any>({ titles: [], rows: [] })
  //const { sessionData } = useSelector(createSession)

  const onPrint = useCallback(
    async () => {
      const formData = formDataRef.current
      const reportCode = getDataOfType(object, 'data.attributes.code', String, null)
      if (isFormDataValid(formData, reportCode) === false) {
        return
      }

      const params = Object.entries(formData)
        .map(([key, value]: any[]) => {
          const localValue = Array.isArray(value) ? value.join(',') : value
          return `${key}=${encodeURIComponent(localValue)}`
        })
        .join('&')
      downloadUrl(`/api/print-report/excel/${reportCode}?${params}`, `${reportCode}.xlsx`)
    },
    [object]
  ) 

  const getData = useCallback(async (id) => {
    //const roles = getDataOfType(sessionData, 'roles', Array, [])
    //const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    //const isDisabledOrganization = isDublicate(roles, rolesAll) === false
    //if (isDisabledOrganization) {
      formDataRef.current.organization = id
    //}
    

    const responseObject = await fetchAPI(`/api/collections/objects/${id}`)
    const object = responseObject['data']
    setObject(object)
    
    {
      const result = await fetchAPI(`/api/profiles-types?type=organization`)
      const profilesTypes = getDataOfType(result, 'data', Array, [])

      const defaultPrifileId = getDataOfType(profilesTypes, '[0].id', [String, Number], null)

      {
        const result = await fetchAPI(`/api/profiles?type=organization&organization-id=${id}`)
        console.log('result', result)
        const data = getDataOfType(result, 'data', Array, [])       
        const prifileId = getDataOfType(data, '[0].id', [String, Number], defaultPrifileId)
        const prifileTypeId = Number(getDataOfType(data, '[0].profile-type', [String, Number], 3))
        console.log(prifileId, prifileTypeId)

        const reportCode = getDataOfType(object, '', String, 'income-expenses')
        const jsonShema = fieldsSchemaFromCode(reportCode)
        console.log('jsonShema', jsonShema)
        console.log('reportCode', reportCode)
        const UISchema = createUISchemaVertical(jsonShema)
        if (reportCode === 'rating-organization') {
          UISchema.organization = {
            ...UISchema.organization,
            options: { 
              types: [5],
              disabled: true
            }
          }
        }
        if (reportCode === 'income-expenses') {
          UISchema.organization = {
            ...UISchema.organization,
            options: { 
              types: [5,2,3],
              disabled: true
            }
          }
        }
        if (reportCode === 'conversion-organization') {
          UISchema.users = {
            ...UISchema.users,
            options: { 
              organizationId: id,
              roleId: [2,10]
            }
          }
          UISchema.organization = {
            ...UISchema.organization,
            options: { 
              types: [5],
              disabled: true
            }
          }
        }

        setUISchema(UISchema)
        setMatchedJSONSchema(jsonShema)
      }
    }


    setPageErrors([])
    setTableData({ titles: [], rows: [] })
    setIsDataLoading(false)
  }, [])

  useEffect(() => {
    getData(id)
  }, [getData, id])

  const onFormData = useCallback(
    ({ formData }) => {
      if (formData['organization'] !== formDataRef.current['organization']) {
        formData['users'] = []
      }
      //const formDataPrev = {...formDataRef.current }
      formDataRef.current = { ...formData }
      // Проверяем, что изменили организацию
      const organization = getDataOfType(formData, 'organization', Number, 0)


        setUISchema(UISchema => {
          const UISchemaNext = { ...UISchema }
          if ("users" in UISchemaNext) {
            UISchemaNext.users = {
              ...UISchemaNext.users,
              options: { 
                organizationId: organization,
                roleId: [2,10]
              }
            }
          }
          return UISchemaNext
        })
        //formDataRef.current.users = []
      

    }, []
  )



  const updateTable = useCallback(async () => {
    const reportCode = getDataOfType(object, '', String, 'income-expenses')
    const formData = formDataRef.current
    if (isFormDataValid(formData, reportCode) === false) {
      return
    }
    const params = Object.entries(formData)
      .map(([key, value]: any[]) => {
        const localValue = Array.isArray(value) ? value.join(',') : value
        return `${key}=${encodeURIComponent(localValue)}`
      })
      .join('&')
    
    const result = await fetchAPI(`/api/report-data/excel/${reportCode}?${params}`)
    const data = getDataOfType(result, 'data', Object, {})

    console.log(data)

    setTableData(data)
  },
  [object])

  const addPay = useCallback(async () => {
    const result = modalAddMutualSettlements({})
    console.log(result)
  }, [])


  return (
        <>
          <Button onClick={addPay} style={{ marginBottom: 16, marginTop: 16 }} />

          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {isDataLoading === false ? (
              UISchema && (
                  <DefaultJsonForm
                    formData={formDataRef.current}
                    schema={matchedJSONSchema}
                    onChange={onFormData}
                    UISchema={UISchema}
                  />
              )
            ) : (
              <Skeleton active />
            )}
          </Form>
          <Space>
            <Button onClick={updateTable} style={{ marginBottom: 16, marginTop: 16 }} >
              <SaveOutlined rev={undefined}/>
              Применить фильтры
            </Button>
            <Button onClick={onPrint} style={{ marginBottom: 16, marginTop: 16, display: 'none' }} type="primary">
              <SaveOutlined rev={undefined}/>
              Экспорт в эксель
            </Button>
          </Space>
          {isDataLoading === false ? (
            <TableConversion titles={tableData.titles} rows={tableData.rows} />
          ): (
            <Skeleton active />
          )}
        </>


  )
}
