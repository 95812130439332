import React from 'react'
import { Space } from 'antd'
import { DarkLink } from '../../components/dark-link'

function CellDocument({ parentId }) {
  return (
    <Space direction="vertical">
      {Number(parentId) ? <DarkLink to={`/admin/collections/tasks/objects/${parentId}/null/edit`}>{`№ ${parentId}`}</DarkLink> : 'Не назначен'}
    </Space>
  )
}

export default CellDocument
