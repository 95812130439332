import React, { useCallback, useMemo, useState } from "react"
import { IPhone } from './interface'
import {SearchOutlined, CloseCircleOutlined} from '@ant-design/icons'
import { Button } from 'antd'
import PhoneInput from "react-phone-input-2";
import 'react-phone-input-2/lib/style.css'
import ru from 'react-phone-input-2/lang/ru.json'

/*
function normalizePhone(value: string | number): string | number {
   if(typeof value !== 'string'){
    return value
  }
  return value.replace(/[-_() ]/g, '')
}
function formatPhone(value: string | number): string | number {
  if(typeof value !== 'string'){
    return value
  }
  const formatString = '__ (___)-___-____'
  return Array.prototype.reduce.call(
    normalizePhone(value),
    (acc: any, symbol) => acc.replace('_', symbol),
    formatString
  ) as string
}
*/
function InputPhone({ onEnter, UISchema, currentValue} : IPhone) {
  const [value, onChange] = useState('')
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])

  /*
  const _handleChange = useCallback(({ target }) => {
    onChange(normalizePhone(target.value) as string)
  }, [onChange, currentValue])
  */

  const handleChange = (phone) => {
    onChange(`+${phone}`)
  }
  /*
  const valueFormat = useMemo(() => {
    if( currentValue && value.length === 0 ) {
      onChange(currentValue)
    }
    return formatPhone(value)
  }, [value])
  */
  const onCall = useCallback(() => onEnter(value === '+7' ? undefined : value), [value, onEnter])

  const onKeyDown = useCallback(({ keyCode }) => {
    if (keyCode === 13) {
      onEnter(value === '+7' ? undefined : value)
    }  
  }, [value, onEnter])


  const onClear = useCallback(() => {
    onChange('')
    onEnter('')
  }, [onChange, onEnter])
  /*
    <InputMask disabled={disabled} mask="+7 (999) 999-99-99" onChange={handleChange} onKeyDown={onKeyDown} value={valueFormat} className='ant-input'/>
  */
  const areaCodes = ['310', '311', '312', '313', '315', '318', '321', '324', '325', '326', '327', '336', '7172', '73622'];
  const areaCodesWithBrackets = areaCodes.map((code) => `(${code})`);

  return (
    <div style={{ display: 'flex', width: 'max-content'}}>
      <PhoneInput
        enableSearch={true}
        searchPlaceholder={'Поиск'}
        localization={ru}
        country={'ru'}
        onlyCountries={['ru','kz','by','tj','uz','am','kg','az','md','tm']}
        value={value}
        disabled={disabled}
        containerStyle={{width: 'auto'}}
        inputStyle={{borderRadius: '2px',height: '32px'}}
        areaCodes={areaCodesWithBrackets}
        masks={{kz: '(...) ..-..-..'}}
        onEnterKeyPress={ e => {
          onKeyDown(e)
        }}
        onChange={phone => {
          handleChange(phone)
        }}
      />
      <Button icon={<CloseCircleOutlined rev={undefined}/>} onClick={onClear}  />
      <Button icon={<SearchOutlined rev={undefined}/>} onClick={onCall}  />
    </div>
  )
}

export default React.memo(InputPhone, (prev, next) => {
  const isNewValue = prev.value === next.value
  const isNewUISchema = prev?.UISchema === next?.UISchema
  return isNewValue && isNewUISchema;
})
