import React from 'react'
import ActivityTimeLine from '../../connectors/tab-stuck-files'

function EventRibbonTab() {
return (
  <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }}>

    <div style={{ flexBasis: '50%', paddingLeft: 20 }} className="actions-time-line">
      <ActivityTimeLine />
    </div>
    <div style={{ flexBasis: '50%' }}>
      
    </div>
  </div>
  )
}

export default EventRibbonTab


