import React, { useCallback, useState } from 'react'
import {Input, Space} from 'antd'
import InputDateRange from '../../../components/input-date-range'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { dateRange } from './utils'
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

function InputFilter({ onChange, placeholder }) {
  const onPressEnter = useCallback(({ target }) => {
    onChange(target?.value)
  }, [onChange])
  const onLocalChange = useCallback(({ target }) => {
    const value = target?.value
    if (typeof value !== 'string' || value === '') {
      onChange(undefined)
    }
  }, [onChange])
  const onSearch = useCallback((value) => {
    onChange(value)
  }, [onChange])
  return <Input.Search placeholder={placeholder} onPressEnter={onPressEnter} onChange={onLocalChange} onSearch={onSearch} allowClear style={{ width: 300 }} />
}

function Filter({ onChange }) {
  const { sessionData } = useSelector(createSession)
  const [formData, setFormData] = useState<any>(() =>({
    'filter[date_application]': undefined,
    'filter[subject]': undefined,
    'filter[from]': undefined,
    'filter[to]': undefined,
  }))

  const onChangeLocal = useCallback((value, name) => {
    const nextformData = {}
    setFormData(prevFormData => Object.assign(nextformData, prevFormData, {[name]: value ? encodeURIComponent(value) : value}))
    onChange(nextformData)
  }, [onChange])

  const onChangeDateRange = useCallback(value => {
    onChangeLocal(value, 'filter[date]')
  }, [onChangeLocal])
  const onChangeSubject = useCallback(value => {
    onChangeLocal(value, 'filter[subject]')
  }, [onChangeLocal])
  const onChangeFrom = useCallback(value => {
    onChangeLocal(value, 'filter[from]')
  }, [onChangeLocal])
  const onChangeTo = useCallback(value => {
    onChangeLocal(value, 'filter[to]')
  }, [onChangeLocal])

  return (
    <Space wrap>
      <InputDateRange
        value={formData['date']}
        schema={dateRange}
        onChange={onChangeDateRange}
      />
      <InputFilter onChange={onChangeSubject} placeholder="Тема письма" />
      <InputFilter onChange={onChangeFrom} placeholder="От кого" />
      <InputFilter onChange={onChangeTo} placeholder="Кому" />

    </Space>
  )
}

export default Filter