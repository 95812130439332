import React, { useState, useCallback, useMemo } from 'react'
import { Tooltip } from 'antd'
import { DashOutlined, ShrinkOutlined } from '@ant-design/icons'

function FieldHideText({ text = '' }) {
  const [isShowFullText, setShowFullText] = useState(false)

  const showFullText = useCallback(() => {
    setShowFullText(true)
  }, [])

  const hiddenFullText = useCallback(() => {
    setShowFullText(false)
  }, [])

  const enumTextLength = useMemo(() => {
    if (!text) {
      return 0
    }
    const { length } = text
    if (length > 1000) {
      return 4
    }
    if (length > 300) {
      return 3
    }
    if (length > 40) {
      return 2
    }
    return 1
  }, [text])

  const fullTitle = useMemo(() => {
    return enumTextLength === 4 ? String(text).substr(0, 1000) + '…' : String(text)
  }, [enumTextLength, text])

  const middleTitle = useMemo(() => {
    if(enumTextLength === 2){
      return String(text)
    }
    return String(text).substr(0, 300) + '…'
  }, [enumTextLength, text])

  const startTitle = useMemo(() => {
    return enumTextLength > 1 ? String(text).substr(0, 40) + '…' : String(text)
  }, [enumTextLength, text])

  if (isShowFullText) {
    return (
      <>
        {fullTitle}
        <ShrinkOutlined style={{ marginLeft: 4 }} onClick={hiddenFullText} rev={undefined} />
      </>
    )
  }

  if (enumTextLength > 1) {
    return (
      <>
        {String(text).substr(0, 40) + '…'}
        <Tooltip title={middleTitle}>
          <DashOutlined style={{ marginLeft: 4 }} onClick={showFullText} rev={undefined} />
        </Tooltip>
      </>
    )
  }

  return startTitle || <>&nbsp;</>
}

export default FieldHideText
