import React from 'react'
import styled from 'styled-components'

interface ValueType {
  data: any
  emptyText?: string
}

export const ValueSpan = styled.span`
  width: 100%;
  display: flex;
  justify-content: center;
  color: #CCC;
  user-select: none
`

/**
 * Пытается вывести значение из data, если его не существует выводит текст из $emptyText, по умолчанию "- не задано -"
 *
 * @param data
 * @param emptyText
 * @constructor
 */
export function Value( {data, emptyText}:ValueType ){
  const text = emptyText || '- не задано -'
  return (data && data.length ) ? data : <ValueSpan>{text}</ValueSpan>
}