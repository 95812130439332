import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import * as Sentry from '@sentry/browser'
import configureStore from './react-app/store'
import './browser-app.less'
import App from './react-app/entries/b2b'
import * as serviceWorker from './serviceWorker'
import { getLoadSessionAction } from './react-app/store/session/action-creators'
import { LOAD_PAGE_DONE, LOAD_PAGE_FAILED } from './react-app/store/page/actions'

if (process?.env?.NODE_ENV === 'production') {
  Sentry.init({
    dsn: '',
  })
}

export const store = configureStore({
  page: {
    isLoading: true,
  },
  session: {
    sessionData: {},
  },
})

const dataToLoad = [store.dispatch(getLoadSessionAction())]

Promise.all(dataToLoad)
  .then(data => {
    store.dispatch({ type: LOAD_PAGE_DONE, payload: data })
  })
  .catch(err => {
    store.dispatch({ type: LOAD_PAGE_FAILED, payload: err })
  })

// eslint-disable-next-line react/no-deprecated
ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <App store={store} />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)

serviceWorker.unregister()
