import React, { useMemo, useImperativeHandle, useRef, forwardRef } from 'react'
import { InputNumber } from 'antd'
import { INumber } from './interface'

function InputInteger({ value, onChange, UISchema, onPressEnter = () => null}: INumber, ref ){
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const inputRef = useRef<any>()
  useImperativeHandle(ref, () => ({
    focus: () => {
      inputRef?.current?.focus()
    },
    onfocusout: (fn) => {
      inputRef.current.onfocusout = fn
    }
  }))
  return <InputNumber ref={inputRef} value={value} onChange={onChange} disabled={disabled} onPressEnter={onPressEnter} />
}

export default forwardRef(InputInteger) /* React.memo(InputInteger, (prev, next) => {
  if(prev.value === next.value){
    return true
  }
  if (prev?.UISchema === next?.UISchema) {
    return true
  }
  return false
})
*/
