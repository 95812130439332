import React, {useCallback, useMemo, useState} from 'react'
import {Form} from 'antd'
import {Select} from 'antd/es'
import Search from 'antd/es/input/Search'
import {DateRangePicker} from '../../input-date-range/styles'
import {datesToMoment, datesToString, objectsIsEqual} from './utils'
import {InputInteger, InputPhone} from './elements'


function FiltersList({filters = [], onChange}: { filters: filtersType[], onChange: any }) {
  const [values, setValues] = useState<any>(filters.reduce((obj, cur) => ({...obj, [cur.name]: cur.value}), {}))

  const onChangeHandler = useCallback(current => {
    const newState = {...values, ...current}

    if (!objectsIsEqual(newState, values)) {
      onChange(Object.fromEntries(Object.entries(newState).filter(([key, value]) => value !== undefined)))
      setValues(newState)
    }
  }, [values])

  /**
   * Возврачает инпут, в соответствии с входящими параметрами
   *
   * @param type
   * @param options
   * @param placeholder
   * @param name
   * @param disabled
   */
  const getInput = ({type, options, placeholder, name, disabled}: filtersType) => {
    const width = options?.width || 200
    const value = values?.[name] || null
    if (type === 'input-string') {
      return <Search
        allowClear
        placeholder={placeholder || 'Заполните'}
        defaultValue={value}
        style={{width}}
        onSearch={v => onChangeHandler({[name]: (v && v.length) ? v : undefined})}
      />
    }
    if (type === 'input-integer') {
      return <InputInteger
        defaultValue={value}
        onSearch={v => onChangeHandler({[name]: v ? +v : undefined})}
        onChange={() => {}}
      />
    }
    if (type === 'phone') {
      return <InputPhone
        defaultValue={value}
        onChange={v => onChangeHandler({[name]: v ? +v : undefined})}
      />
    }
    if (type === 'select') {
      return <Select
        allowClear
        placeholder={placeholder || 'Выберите'}
        defaultValue={value}
        options={options?.enum || []}
        disabled={disabled || false}
        style={{width}}
        onChange={v => onChangeHandler({[name]: (v && v.length) ? v : undefined})}
      />
    }
    if (type === 'dates') {
      return <DateRangePicker
        defaultValue={datesToMoment(value)}
        style={{width}}
        onChange={v => onChangeHandler({[name]: datesToString(v)})}
      />
    }
    return null
  }

  /**
   * Формирует список для вывода
   */
  const filtersList = useMemo(() => {
    return filters.map(item => {
      return {
        render: getInput(item),
        name: item.name,
        key: item.name
      }
    })
  }, [values])

  return (
    <div style={{display: 'flex', flexWrap: 'wrap', gap: '4px', position: 'relative', width: '100%'}}>
      {filtersList.map(
        ({render, name, key}) => <Form.Item key={key} name={name}>{render}</Form.Item>
      )}
    </div>
  )
}

export default FiltersList
