import React, { useMemo } from 'react'
import { Timeline, Space } from 'antd'
import {
  ClockCircleOutlined,
} from '@ant-design/icons'
import { formatDate } from './activity-timeline-Item'
import {
  TimeLineContentWrapper,
  TimeLineContentHeaderWrapper,
  DotStyle
} from './styles'

const dotColor = '#2fc4f5'

function ActivityTimelineInfo({ type, body, date, id, index }: any){
  const dateFormat = useMemo(() => formatDate(date), [date])
  return (
    <Timeline.Item
      dot={
        <DotStyle color={dotColor}>
          <ClockCircleOutlined rev={undefined} />
        </DotStyle>
    }>
      <TimeLineContentWrapper>
        <TimeLineContentHeaderWrapper>
          <Space>
            <span style={{ fontWeight: 'bold' }}>История</span>
            <span style={{ color: '#a8adb4' }}>{dateFormat}</span>
          </Space>
        </TimeLineContentHeaderWrapper>
        <div dangerouslySetInnerHTML={{ __html: body.text}}/>
      </TimeLineContentWrapper>
    </Timeline.Item>
  )
}

export default ActivityTimelineInfo