import styled, { StyledComponent } from 'styled-components'

export const BackToMountingList: StyledComponent<'a', any> = styled.a`
  font-size: 19px;
  color: #404040;
  padding: 21px 0;
  text-align: left;
`

export const FieldGroup = styled.div`
  display: flex;
  margin: 10px 0;
`

export const FieldName = styled.span`
  font-weight: bold;
  color: #108EE9;
  margin-right: 10px;
`

export const ActionListItemText: StyledComponent<'span', any> = styled.span`
  white-space: nowrap;
`
