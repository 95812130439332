import React, { useCallback, useEffect, useState } from 'react'
import { Space, Input } from 'antd'
import SelectFromEnum from '../../../components/select-from-enum'
import InputDateRange from '../../../components/input-date-range'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { dateRange, stage, organization, user } from './utils'
import SelectFromOrganizations from '../../../connectors/select-from-organizations'
import SelectFromUser from '../../../connectors/select-from-user'
import fetchAPI from '../../../../lib/utils/fetch-api'
import InputPhoneFind from '../../../components/input-phone-find'


const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)


function InputFilter({ onChange, value }) {
const [localValue, setLocalValue] = useState('')

const onPressEnter = useCallback(({ target }) => {
    onChange(target?.value)
}, [onChange])

useEffect(() => {
  if( value )
    setLocalValue(value)
  },[])

  const onLocalChange = useCallback(({ target }) => {
    const value = target?.value
    setLocalValue(value)
    if (typeof value !== 'string' || value === '') {
      onChange(undefined)
    }
  }, [onChange])

  const onSearch = useCallback((value) => {
    onChange(value)
  }, [onChange])

  return <Input.Search prefix="ID:" onPressEnter={onPressEnter} value={localValue} onChange={onLocalChange} onSearch={onSearch} allowClear style={{ width: 200 }} />
}

const rolesAll = [19, 12, 3, 4, 5, 6, 18]

function isDublicate(arr1, arr2) {
  const concatArray = arr1.concat(arr2)
  return concatArray.length > Array.from(new Set(concatArray)).length
}

function Filter({ onChange }) {
  const { sessionData } = useSelector(createSession)
  const [formData, setFormData] = useState<any>(() => {
    const userId = getDataOfType(sessionData, 'user.id', Number, 0)
    return {
      'date-range': undefined,
      region: undefined,
      city: undefined,
      'organization-id': undefined,
      'stage-code': undefined,
      'status': undefined,
      users: userId,
      type: undefined,
      'target-id': undefined,
    }
  })

  const [typeTask, setTypeTask] = useState<any>({})

  const [UISchemaSelectFromUsers, setUISchemaSelectFromUsers] = useState({
    options: { width: 300, allowClear: true, showSearch: true, roleId: [2, 10, 15], placeholder: 'Ответственный' }
  })
  /*
  const [ setUISchemaSelectFromOrganizations] = useState({
    options: { width: 200, allowClear: true, types: [5], showSearch: true, placeholder: 'Организация' }
  }) */
  const [UISchemaSelectFromOrganizations, setUISchemaSelectFromOrganizations] = useState({
    options: { width: 200, allowClear: true, types: [5, 1, 6], showSearch: true, placeholder: 'Организация' }
  })

  /*
  const [ UISchemaSelectFromEnum, setUISchemaSelectFromEnum] = useState({
    options: { width: 200, placeholder: 'Регион', allowClear: true, showSearch: true,  }
  })
  const [ UISchemaSelectFromEnum2, setUISchemaSelectFromEnum2] = useState({
    options: { width: 200, placeholder: 'Город', allowClear: true, showSearch: true,  }
  })
  */
  const [ UISchemaSelectFromEnum3] = useState({
    options: { width: 200, placeholder: 'Стадия', allowClear: true, showSearch: true,  }
  })
  const [ UISchemaSelectFromEnum4] = useState({
    options: { width: 200, placeholder: 'Тип задачи', allowClear: true, showSearch: true,  }
  })

  useEffect(() => {
    const roles = getDataOfType(sessionData, 'roles', Array, [])
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    
    if (isDublicate(roles, rolesAll) === false) {
      setFormData(data => ({ ...data, 'organization-id': 0 }))
      setFormData(data => ({ ...data, 'organization-id': organizationId }))
      setUISchemaSelectFromOrganizations(({ options }) => ({ options: { ...options, disabled: true }  }))
      setUISchemaSelectFromUsers(({ options }) => ({ options: { ...options, 'organizationId': organizationId }  }))
    }
  }, [sessionData])

  useEffect(() => {
    const typeTask = {
      "type": "string",
      "title": "Тип задачи",
      "enum": [],
      "oneOf": [],
      "view": { "name": "select-from-enum" }
    }
    fetchAPI('/api/collections/objects-grouped/task-type')
     .then(result => {
       const items = result?.['data']?.['data'] || []
       const oneOf = items.map(item => ({
         'const': item.id,
         'title': item.name || item?.data?.attributes?.title
       }))
       const all = items.map(item => item.id)
       Object.assign(typeTask, { oneOf: oneOf, 'enum': all })
       setTypeTask(typeTask)
     })
  }, [])

  const onChangeLocal = useCallback((value, name) => {
    const nextformData = {} 
    setFormData(prevFormData => Object.assign(nextformData, prevFormData, { [name]: value ? encodeURIComponent(value) : value }))
    onChange(nextformData)
  }, [onChange])
 
  const onChangeDateRange = useCallback(value => {
    onChangeLocal(value, 'date-range')
  }, [onChangeLocal])

  const onChangeOrganization = useCallback(value => {
    onChangeLocal(value, 'organization-id')
  }, [onChangeLocal])

  const onChangeStageCode = useCallback(value => {
    onChangeLocal(value, 'status')
  }, [onChangeLocal])

  const onChangeUser = useCallback(value => {
    onChangeLocal(value, 'users')
  }, [onChangeLocal])

  const onChangeTypeTask = useCallback(value => {
    onChangeLocal(value, 'type')
  }, [onChangeLocal])

  const onChangeId = useCallback(value => {
    onChangeLocal(value, 'target-id')
  }, [onChangeLocal])

  const onChangePhone = useCallback(value => {
    onChangeLocal(value, 'phone')
  }, [onChangeLocal])
 
  return (
    <Space>
      <InputFilter onChange={onChangeId} value={formData['id']} />
      <InputPhoneFind onEnter={onChangePhone} currentValue={formData['phone']} />
      <SelectFromUser
        value={formData['users']}
        schema={user}
        onChange={onChangeUser}
        UISchema={UISchemaSelectFromUsers}
      />
      <SelectFromOrganizations
        value={formData['organization-id']}
        schema={organization}
        onChange={onChangeOrganization}
        UISchema={UISchemaSelectFromOrganizations}
      />
      <SelectFromEnum
        value={formData['status']}
        schema={stage}
        onChange={onChangeStageCode}
        UISchema={UISchemaSelectFromEnum3}
      />
      <SelectFromEnum
        value={formData['type']}
        schema={typeTask}
        onChange={onChangeTypeTask}
        UISchema={UISchemaSelectFromEnum4}
      />
      <InputDateRange
        value={formData['date-range']}
        schema={dateRange}
        onChange={onChangeDateRange}
      />
    </Space>
  )
}

export default Filter