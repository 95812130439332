/**
 TODO: заблокировать редактирование, если текущий юзер не владелец карточки
 **/

import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import {Form, Alert, Skeleton, Button, Space, Modal} from 'antd'
import { JSONSchema7 } from 'json-schema'
import { MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper } from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { DefaultJsonForm } from '../../components/default-json-form'
import EventRibbonTab from '../../forms/case-event-ribbon-tab'
import { createUISchemaHorizontal, recalcUISchemaHorizontal } from '../../../lib/utils/grid-fild-orientation'
import ButtonAddNotes from '../../views/modal-add-notes'
import StepCollectionObject from '../../views/step-collection-object'
import { isFormDataRequired } from '../../../lib/utils/collections'
import { debounce } from 'lodash'
import { addActionsTimeLine, reloadActionsTimeLine, setCaseId, clearCaseStore, initialCase, setAppealStage, setTargetId, reloadTablePayment } from '../../store/appeals/actions'
import { accessUISchema } from '../card-order/utils'
import { useDispatch, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import TableProduct from '../../connectors/json-form-product-table/adaptor-tab'
import StepButtonsTransition from '../../views/step-buttons-transition'
import { addValidateFieldsUISchema, isFormDataDifferenceFilesS3 } from '../card-order/utils'
import StuckFiles from '../../components/tab-stuck-files'
import { setStageFromCard } from '../../../lib/utils/cards'
import { modalAddPayment } from '../../views/modal-add-payment'
import dayjs from 'dayjs'
import { modalAdjustedDate } from '../../views/modal-adjusted-date'
import { insetNoteToCollection } from '../../views/modal-add-notes/utils-notes'
import modalOrderCancellation from '../../views/modal-order-cancellation'

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  appealsCurrentStep => appealsCurrentStep,
  stageCode => stageCode,
  workflows => workflows,
  targetId => targetId,
)
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

function genJSONSchemaStage(JSONSchema, requiredArr){
  const required = getDataOfType(JSONSchema, 'required', Array, []).concat(requiredArr)
  const requiredUnic = Array.from(new Set(required))
  return ({ ...JSONSchema, required: requiredUnic })
}

function genJSONSchemaNamesrequired(JSONSchema){
  return getDataOfType(JSONSchema, 'required', Array, [])
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
}

const breadcrumbs = [{
  href: '/sales/measurements',
  title: 'Замеры',
}, {
  href: `/sales/measurements`,
  title:  'Список замеров',
}]

const name = 'card-froze'
const cardName = 'measurement'

export default function AdminNewObjectPage() {
  const { id } = useParams()  as any
  const dispatch = useDispatch()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [isEditable, setIsEditable] = useState<Boolean>(false)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [selectedSchema, setSelectedSchema] = useState<string[]>([])
  const formDataRef = useRef<any>(null)
  const formRef = useRef<any>({})
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<JSONSchema7 | null>(null)
  const [UISchema, setUISchema] = useState<any>(null)
  const [form] = Form.useForm()
  const { appealsCurrentStep, workflows, targetId, stageCode } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)

  useEffect(() => {
    const currentStageAccess = getDataOfType(workflows, `${cardName}[${appealsCurrentStep}].access-rights`, Array, null)
    if(currentStageAccess === null){
      return
    }
    setUISchema(UISchema => recalcUISchemaHorizontal(accessUISchema(UISchema || {}, currentStageAccess, sessionData)))
  },[workflows, appealsCurrentStep, sessionData])

  const custumTabs = useMemo(() => ({
    'stuck-products': {
      isVirtual: true,
      schema: {
        properties: {
          'stuck-products': {
            title: 'Данные заказа',
            description: null,
          }
        }
      },
      render: () => <TableProduct currentStage={'manufacture'} disabled={true} id={id} />
    },
    'stuck-files': {
      isVirtual: true,
      schema: {
        properties: {
          'stuck-files': {
            title: 'Прикрепленные файлы',
            description: null,
          }
        }
      },
      render: () => <StuckFiles />
    },
    'event-ribbon': EventRibbonTab
  }), [id])
// eslint-disable-next-line
  const saveCase = useCallback(debounce(async formData => {
    const requestBody = {
      data: {
        name: '',
        title: '',
        attributes: formData,
        metadata: { schema: name }
      }
    }

    const result = await fetchAPI(`/api/collections/-/objects/${id}`, {
      method: 'POST',
      body: JSON.stringify(requestBody),
    })

    const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)
    if (updated === null) {
      console.warn('Ошибка сохранения записи')
    }
  }, 1000), [],)

  const getData = useCallback(async () => {
    const fields = [
      'title',
      'description',
      'type',
      'properties',
      'required',
      'created_by',
      'created_at',
      'updated_at',
    ].join(',')
    const responseSchemas = await fetchAPI(`/api/schemas?access_key=axioma&fields=${fields}`)
    const fetchedSchemas: JSONSchema7[] = getDataOfType(responseSchemas, 'data.data', Array, [])
    const selectedSchemas = fetchedSchemas.filter(item => item['name'] === name)
    const selectedSchema = (typeof selectedSchemas[0] === 'object') ? selectedSchemas[0] : null
    if (selectedSchema === null) {
      setPageErrors(['Некорректный ответ сервера при cхемы коллекции'])
      return
    }

    const responseObject = await fetchAPI(`/api/collections/objects/${id}`)
    const object = responseObject['data']
    const schema = object['data']['metadata']['schema']
    const formData = object['data']['attributes']
    const parentId = Number(object['parent-id'])
    const stageCode = getDataOfType(object, 'stage-code', String, null)

    // закрывает карточку для редактирования сторонним юзерам
    if( sessionData.roles.some( item => (item === 28 || item === 19 || item === 2 ) ) ) {
      setIsEditable(true)
    }
    if( sessionData.roles.length === 1 && sessionData.roles[0] === 10 && (!object['owner-user'] || +object['owner-user'] === +sessionData.user.id)){
      setIsEditable(true)
    }

    dispatch(setTargetId({ targetId: parentId }))
    setSelectedSchema(schema)
    if(object['created-at']){
      const paramsDispatch = addActionsTimeLine({ type: 'info', items: [
        {
          date: object['created-at'],
          text: 'Создание записи'
        }
      ]})
      dispatch(paramsDispatch)
    }
    setPageErrors([])
    formDataRef.current = formData
    const stage = formData['stage'] || 0

    if(stageCode === 'measurer-arrived' && selectedSchema.required)
      selectedSchema.required = [...selectedSchema.required, 'measurement-scan']

    if(stageCode === 'measurement-ready' && selectedSchema.required)
      selectedSchema.required = [...selectedSchema.required, 'measurement-scan', 'number-contract', 'scan-spec-products', 'contract-scan', 'check-scan', 'production-order-number']

    setMatchedJSONSchema(selectedSchema)
    const UISchema = createUISchemaHorizontal(selectedSchema)
    const currentCardOrganizationId = Number(getDataOfType(object, 'owner-organization', String, null))
    if ('case-manager' in UISchema) {
      UISchema['case-manager'] = {
        ...UISchema['case-manager'],
        options: {
          roleId: 2,
          organizationId: currentCardOrganizationId
        }
      }
    }
    if ('measurer' in UISchema) {
      UISchema['measurer'] = {
        ...UISchema['measurer'],
        options: {
          roleId: 10,
          organizationId: currentCardOrganizationId
        }
      }
    }
    if ('leader-installer' in UISchema) {
      UISchema['leader-installer'] = {
        ...UISchema['leader-installer'],
        options: {
          roleId: 15,
          organizationId: currentCardOrganizationId
        }
      }
    }
    setUISchema(UISchema)

    const currentStageAccess = getDataOfType(workflows, `${cardName}[${stage}].access-rights`, Array, [])

    setUISchema(UISchema => recalcUISchemaHorizontal(accessUISchema(UISchema || {}, currentStageAccess, sessionData)))
    dispatch(setAppealStage({ stageCode: stageCode, type: cardName, isHistory: false }))
    setIsDataLoading(false)
  }, [dispatch, id, sessionData, workflows])

  useEffect(() => {
    if(sessionData === null || workflows === null){
      return
    }
    getData()
  }, [getData, sessionData, workflows])

  useEffect(() => {
    dispatch(initialCase({ targetId: null, cardName: cardName }))
    const result = setCaseId({ parentIds: [id] })
    dispatch(result)
    return () => { dispatch(clearCaseStore()) }
}, [id, dispatch])

  const onFormData = useCallback(
    ({ formData }) => {
      const isFilesEdit = isFormDataDifferenceFilesS3(formDataRef.current, formData, matchedJSONSchema)
      if (isFilesEdit) {
        dispatch(reloadActionsTimeLine())
      }
      const nextFormData = { ...formData, stage: appealsCurrentStep }
      formDataRef.current = nextFormData
      saveCase(nextFormData)
    },
    [saveCase, appealsCurrentStep, matchedJSONSchema, dispatch],
  )
/*
  const updateData =  useCallback(nextformData => {
    const formData = { ...formDataRef.current, ...nextformData }
    formDataRef.current = formData
    saveCase(formData)
    dispatch(reloadActionsTimeLine())
  }, [saveCase, dispatch])
  */

  const transition = useCallback(async transition => {
    const stageCodeNext = transition['target']
    const nextDataCard = await setStageFromCard({
      cardName: cardName,
      stageCode: stageCodeNext,
      targetId: targetId,
      isHistory: true,
    })
    const error = getDataOfType(nextDataCard, 'data.onStageData.error', String, null)
    if(error){
      Modal.warning({
        title: 'Ошибка!',
        content: error,
      })
      setIsEditable(true)
      return
    }

    const nextFormData = getDataOfType(nextDataCard, 'data.formData', Object, null)
    formDataRef.current = nextFormData

    setMatchedJSONSchema(matchedJSONSchema => {
      if(matchedJSONSchema?.required && stageCodeNext === 'measurer-arrived') {
        matchedJSONSchema.required.push('measurement-scan');
      }
      return matchedJSONSchema
    })

    setSelectedSchema([])
    dispatch(reloadActionsTimeLine())
    dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName }))
  },[dispatch, targetId])

  const transitionRrequired = useCallback((_, nextFields) => {
    const gen = genJSONSchemaStage(matchedJSONSchema, nextFields)
    const names = genJSONSchemaNamesrequired(gen)
    const isRequired = isFormDataRequired(formDataRef.current, gen)
    if(isRequired){
      return true
    }
    setUISchema(UISchema => addValidateFieldsUISchema(UISchema, names))
    return false
  }, [matchedJSONSchema])

  const isAddPayment = useMemo(() => {
    const currentRoles = getDataOfType(sessionData, 'roles', Array, [])
    const isAccountant = currentRoles.includes(100)
    const isStagePayment = appealsCurrentStep === 4
    return isStagePayment
  }, [stageCode, sessionData, appealsCurrentStep])

  const onButtonPayment = useCallback(async () => {
    const result: any = await modalAddPayment({ disable: true })
    if (result !== null) {
      const result2 = await fetchAPI(`/api/web-hooks/add-payment-contract`, {
        method: 'POST',
        body: JSON.stringify({
          targetId: targetId,
          pay: {
            sum: result['payment'],
            date: result['date'],
          },
        }),
      })
    }
    dispatch(reloadTablePayment())
  }, [targetId, dispatch])

  const isAadjusted = useMemo(() => {
    // const currentRoles = getDataOfType(sessionData, 'roles', Array, [])
    // const isAccountant = currentRoles.includes(100)
    const isStagePayment = [1].includes(appealsCurrentStep)
    return isStagePayment
  }, [appealsCurrentStep])


  const onModalAdjustedDate = useCallback(async () => {
    const authorId = getDataOfType(sessionData, 'user.id', Number, 0)
    const result = await modalAdjustedDate()
    if (result === null) {
      return
    }
    const { date, comment, time } = result as any
    const content = `Изменена дата замера на: ${dayjs(date).format('DD.MM.YYYY')} ${time}. ${comment ?? ''}`
    const nextDataCard = await fetchAPI('/api/web-hooks/set-card-form-data', { method: 'POST', body: JSON.stringify({
      'card-name': cardName,
      'target-id': targetId,
      'add-form-data': {
        'date-adjusted-measurement': date, 'time-adjusted-measurement': time
      }
    })})
  
    formDataRef.current = getDataOfType(nextDataCard, 'data.formData', Object, null)
  
    await insetNoteToCollection({ date: new Date().toISOString(), content: content, author: authorId }, targetId)
    dispatch(reloadActionsTimeLine())
  }, [sessionData, targetId, dispatch])

  const onModalCancellation = useCallback(async () => {
    const stageCodeNext = 'new'
    const result = await modalOrderCancellation()
    if (result !== null) {
      await setStageFromCard({
        cardName: cardName,
        stageCode: stageCodeNext,
        targetId,
        isHistory: true,
        addFormData: {
          'reason-cancellation': result?.['reason-cancellation'],
          comment: result?.['comment'],
        },
      })
      await dispatch(setAppealStage({ stageCode: stageCodeNext, type: cardName, isHistory: false }))
      dispatch(reloadActionsTimeLine())
    }
  }, [dispatch, targetId])


  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
          <MapTitle>Замер №{targetId}</MapTitle>
          { isEditable &&
            (<Space size={'small'}>
              <ButtonAddNotes />
              {isAddPayment && (
                <Button onClick={onButtonPayment}>
                  Внести оплату
                </Button>
              )}
              {isAadjusted && <Button onClick={onModalAdjustedDate}>Изменить дату</Button> }
              <StepButtonsTransition type={cardName} transition={transition} transitionRequired={transitionRrequired} />
              <Button danger onClick={onModalCancellation} disabled={!['work', 'measurer-arrived'].includes(stageCode)}>Отмена</Button>
            </Space>
            )
          }
        </TaskWrapper>
        <MapContent>
          { isEditable && (<StepCollectionObject type={cardName}/>) }
          <Form
            {...formItemLayout}
            layout="horizontal"
            form={form}
            fields={[
              {
                name: 'schema',
                value: selectedSchema,
              },
            ]}
            scrollToFirstError={true}
          >
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {isDataLoading ? (
                <Skeleton active/>
            ) : (
              matchedJSONSchema && isEditable ? (
                <>
                  <DefaultJsonForm
                    formData={formDataRef.current}
                    schema={matchedJSONSchema}
                    onChange={onFormData}
                    customTabs={custumTabs}
                    UISchema={UISchema}
                    ref={formRef}
                  />
                </>
              ) : (
                <><h2 style={{textAlign:'center'}}>Карточка недоступна</h2></>
              )

            )}

          </Form>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
