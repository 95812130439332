import React from 'react';
import { Breadcrumb, Button } from 'antd'
import { HomeOutlined } from '@ant-design/icons'
import { Link, useHistory } from 'react-router-dom'

export const Breadcrumbs = ({ breadcrumbs }) => {
  const history = useHistory();
    return (
      <>
        {breadcrumbs.length > 0 && (
          <Breadcrumb>
            {breadcrumbs.map(({ href, title, back }, index) => {
              if (back === true) {
                return (
                  <Breadcrumb.Item key={index}>
                    <a onClick={() => history.goBack()}>{title}</a>
                  </Breadcrumb.Item>
                )
              }
              if (href === '/') {
                return (
                  <Breadcrumb.Item key={index}>
                    <HomeOutlined rev={undefined}/>
                    <Link to={href}>{title}</Link>
                  </Breadcrumb.Item>
                )
              }
              if (href !== '') {
                return (
                  <Breadcrumb.Item key={index}>
                    <Link to={href}>{title}</Link>
                  </Breadcrumb.Item>
                )
              }
              return (
                <Breadcrumb.Item key={index}>
                  {title}
                </Breadcrumb.Item>
              )
            })}
          </Breadcrumb>
        )}
      </>
    )
}
