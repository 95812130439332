import React, {useMemo} from 'react'
import { Timeline, Space } from 'antd'
import { DollarOutlined } from '@ant-design/icons'
import { formatDate } from './activity-timeline-Item'
import { TimeLineContentWrapper, TimeLineContentHeaderWrapper, DotStyle } from './styles'
import filesize from 'filesize'


function formatCurrency(value){
  return (value || 0).toLocaleString('ru-RU', { style: 'currency', currency: 'RUB', currencyDisplay: 'symbol' }).replace("₽", "");
}

function ActivityTimelinePayment({ type, body, date, id, index }: any){
  const dateFormat = useMemo(() => formatDate(date), [date])

  if(body?.warning)
    return (
      <Timeline.Item
        dot={
          <DotStyle color="#d20c0c">
            <DollarOutlined rev={undefined} />
          </DotStyle>
        }
      >
        <TimeLineContentWrapper style={{ backgroundColor: '#ff9e9e' }}>
          <TimeLineContentHeaderWrapper>
            <Space>
              <span style={{ fontWeight: 'bold' }}>
                По заказу есть задолженность!
              </span>
            </Space>
          </TimeLineContentHeaderWrapper>
        </TimeLineContentWrapper>
      </Timeline.Item>
    )

  return (
    <Timeline.Item
      dot={
        <DotStyle color="#67c364">
          <DollarOutlined rev={undefined} />
        </DotStyle>
      }
    >
      <TimeLineContentWrapper>
        <TimeLineContentHeaderWrapper>
          <Space>
            <span style={{ fontWeight: 'bold' }}>Оплата</span>
            <span style={{ color: '#a8adb4' }}>{dateFormat}</span>
          </Space>
        </TimeLineContentHeaderWrapper>
        <div>
          Внесена предоплата в размере <b>{formatCurrency(body?.price)} руб.</b>. Задолженность: <b>{ formatCurrency(+body?.debt) } руб.</b> &nbsp;
          {!body?.paymentScan ?
              ('Скан отсутствует')
            :
              (
                <div>
                  <a href={body?.paymentScan?.url} download={true}>Скан чека</a> &nbsp;
                  <span style={{ color: '#a8adb4' }}>{`( ${filesize(body?.paymentScan?.size)} )`}</span>
                </div>
            )
          }
        </div>
      </TimeLineContentWrapper>
    </Timeline.Item>
  )
}

export default ActivityTimelinePayment