import React, { useMemo, useState, useEffect } from 'react'
import { Avatar, Popover, Progress } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { UserProfileMenu } from './user-profile-menu'
import { UserProfileWrapper, RateWrapper, OrganizationWrapper } from './styles'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import fetchAPI from '../../../lib/utils/fetch-api'

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData,
)

function isDublicate(arr1, arr2) {
  const concatArray = arr1.concat(arr2)
  return concatArray.length > Array.from(new Set(concatArray)).length
}

export const UserProfile = () => {
  const { sessionData } = useSelector(createSession)
  const [rating, setRating] = useState<any>(null)
  const [user, setUser] = useState<any>(null)

  const name = useMemo(() => {
    return user?.name
  }, [sessionData, user])

  const organizationTitle = useMemo(() => {
    return getDataOfType(sessionData, 'organization.title', String, null)
  }, [sessionData])

  useEffect(() => {
    const roles = getDataOfType(sessionData, 'roles', Array, null)
    const userId = getDataOfType(
      sessionData,
      'user.id',
      Number,
      null,
    );
    
    (async () => {
      const user = await fetchAPI(`/api/profiles?type=user&user-id=${userId}`)
      const profileData = getDataOfType(user.data[0], 'profile-data', Object, null)
      setUser( profileData )

      const result = await fetchAPI(`/api/rating/${userId}?format=numeric`)
      const rating = getDataOfType(result, 'data.rating', Number, 100)
      if (isDublicate(roles, [2,10,15])) {
        return setRating(Number(rating).toFixed(2))
      }
      setRating(null)
    })()
  }, [sessionData])

  return (
    <UserProfileWrapper>
      <OrganizationWrapper>
        {organizationTitle}
      </OrganizationWrapper>
      <Popover
        style={{ width: 200 }}
        placement="bottomLeft"
        content={UserProfileMenu}
        trigger="click"
      >
        <span style={{ display: 'flex' }}>
          <Avatar
            size={40}
            style={{
              backgroundColor: '#87d068',
            }}
            icon={<UserOutlined rev={undefined}/>}
          />
          <span style={{ color: 'white', padding: 15 }}>{name}</span>
        </span>
      </Popover>
      {rating !== null && (
        <RateWrapper>
          <Progress
            type="circle"
            strokeColor={{
              '0%': '#108ee9',
              '100%': '#87d068',
            }}
            status={'normal'}
            percent={rating}
            width={77}
          />
        </RateWrapper>
      )}
    </UserProfileWrapper>
  )
}
