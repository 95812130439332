import React, {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useState} from 'react'
import {Form} from 'antd'
import {debounce} from 'lodash'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'
import InputNumber from '../input-number'
import InputInteger from '../input-integer'
import InputCheckbox from '../Input-checkbox'
import InputDateTime from '../input-date-time'
import InputDate from '../input-date'
import InputTime from '../input-time'
import InputTimeRange from '../input-time-range'
import InputDateRange from '../input-date-range'
import InputPhone from '../input-phone'
import InputPhoneClient from '../input-phone-client'
import InputText from '../input-text'
import ArrayMultiField from '../array-multi-field'
import SelectFromEnum from '../select-from-enum'
import CollectionSelect from '../../connectors/json-form-select-object'
import CollectionSelectTypeAdvertising from '../../connectors/json-form-select-object-type-advertising'
import SelectFromRoles from '../../connectors/select-from-roles'
import UserSelect from '../app-user-select'
import AdaptorObjectField from './adaptor-field'
import FileS3 from '../../connectors/multi-file-s3-json-form/multi-file-s3-json-form'
import SelectFromOrganizationTypes from '../../connectors/select-from-organization-types'
import SelectFromUser from '../../connectors/select-from-user'
import SelectFromOrganizationInn from '../../connectors/select-from-organization-inn'
import SelectFromUsers from '../../connectors/select-from-users'
import SelectFromOrganizations from '../../connectors/select-from-organizations'
import SelectFromOrganizationsV2 from '../../connectors/select-from-organizations-v2'
import ContractOrderPrice from '../../connectors/contract-order-price'
import ContractOrderPricePay from '../../connectors/contract-order-price-pay'
import ContractDebt from '../../connectors/contract-debt'
import InputRadioGroup from '../../components/input-radio-group'
import InputTextAddress from '../../components/input-text-address'
import SelectFromRegions from '../../connectors/select-from-regions'
import SelectFromCity from '../../connectors/select-from-city'
import MultiSelect from '../../connectors/multi-select'

import './index.css'


export function getInputNode(value, UISchema?: any, data?: unknown){
  if (value?.type === 'string') {
    if (value?.view?.name === 'input-radio-group') {
      return <InputRadioGroup schema={value} UISchema={UISchema} />
    }
    if (value?.view?.name === 'input-text-address') {
      return <InputTextAddress schema={value} UISchema={UISchema} />
    }
    if(value?.format === 'date-time'){
      return <InputDateTime UISchema={UISchema} />
    }
    if(value?.format === 'date'){
      return <InputDate UISchema={UISchema} schema={value} value={data} onChange={() => {}}/>
    }
    if(value?.format === 'time'){
      return <InputTime UISchema={UISchema} />
    }
    if (value?.format === 'phone') {
      return <InputPhone UISchema={UISchema} />
    }
    if (value?.format === 'phone-client') {
      return <InputPhoneClient UISchema={UISchema} />
    }
    if(value?.format === 'platform-collection'){
      return <CollectionSelect schema={value} UISchema={UISchema} />
    }
    if(value?.format === 'platform-user'){
      return <UserSelect schema={value} UISchema={UISchema} />
    }
    if(value?.format === 'file-s3'){
      return <FileS3 value={data} onChange={() => {}} schema={value} UISchema={UISchema} />
    }
    if (value?.format === 'time-range') {
      return <InputTimeRange schema={value} UISchema={UISchema} />
    }
    if (value?.format === 'date-range') {
      return <InputDateRange schema={value} UISchema={UISchema} />
    }
    if (value?.view?.name === 'select-from-enum'){
      return <SelectFromEnum schema={value} UISchema={UISchema} />
    }
    return <InputText schema={value} UISchema={UISchema} />
  }
  if (value?.type === 'pointer'){
    if (value?.view?.name === 'select-from-organization-types') {
      return <SelectFromOrganizationTypes UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-organizations'){
      return <SelectFromOrganizations UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-organizations-v2'){
      return <SelectFromOrganizationsV2 UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-regions') {
      return <SelectFromRegions UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-city') {
      return <SelectFromCity UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-user') {
      return <SelectFromUser UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-users') {
      return <SelectFromUsers UISchema={UISchema} />
    }
    else if (value?.view?.name === 'select-from-organization-inn') {
      return <SelectFromOrganizationInn UISchema={UISchema} />
    }
    else if(value?.view?.name === 'type-advertising') {
      return <CollectionSelectTypeAdvertising schema={value} UISchema={UISchema}/>
    }
    else {
      return <MultiSelect schema={value} value={data} UISchema={UISchema}/>
    }
  }
  if(value?.type === 'boolean'){
    return <InputCheckbox UISchema={UISchema} />
  }
  if(value?.type === 'number'){
    if (value?.view?.name === 'contract-order-price') {
      return <ContractOrderPrice UISchema={UISchema} />
    }
    if (value?.view?.name === 'contract-order-price-pay') {
      return <ContractOrderPricePay UISchema={UISchema} />
    }
    return <InputNumber UISchema={UISchema} />
  }
  if(value?.type === 'integer'){
    return <InputInteger UISchema={UISchema} />
  }
  if (value?.type === 'array') {
    if (value?.view?.name === 'select-from-roles') {
      return <SelectFromRoles schema={value} UISchema={UISchema} />
    }
    return <ArrayMultiField schema={value} UISchema={UISchema} />
  }
  if (value?.type === 'object') {
    return <AdaptorObjectField schema={value} />
  }
  return null
}

function getInputNodeCustom(schema, UISchema?: any, data?: unknown){
  if (UISchema?.render) {
    return <UISchema.render schema={schema} UISchema={UISchema} />
  }
  return getInputNode(schema, UISchema, data)
}

function getFiledRules(props, requiredUISchema): any[] {
  const { required, type, format }: { required: boolean, type: string, format: string | undefined } = props
  const message = 'Неверный формат'
  const isString = type === 'string'
  if(isString && (format === 'email')){
    return [{ type: 'email', required, message }]
  }
  return [{ required: requiredUISchema, message: 'Обязательное поле' }]
}

function getGridColumn(){
  return `1 / span 12`
}

function getGridRow(index){
  return index + 1
}

function getUISchemaValue(UISchema, key){
  return getDataOfType(UISchema, key, Object, null)
}

function FormDataEditor({ formData = {}, schema, UISchema, onChange }: { formData,  schema, UISchema?, onChange }, ref){
  const [form] = Form.useForm()
  const [required, setRequired] = useState<string[]>(schema?.required||[])

  useImperativeHandle(ref, () => ({
    validateFields: params => form.validateFields(params)
  }))

  useEffect(() => {
    const antFromData = form.getFieldsValue()
    const formDataKeys = Object.keys(formData || {})
    const nextAntFormData = {}

    if( schema?.required )
      setRequired(schema.required.filter( (key) => !formData[key] || formData[key].length === 0 ) )

    formDataKeys.forEach(key => {
      if (typeof formData[key] === 'object') {
        return
      }
      if (Array.isArray(formData[key])) {
        nextAntFormData[key] = formData[key]
      }
      if (!(formData[key] === antFromData[key])) {
        nextAntFormData[key] = formData[key]
      }
    })
    form.setFieldsValue(nextAntFormData)
  }, [form, formData, schema])

  const onValuesChange = useCallback(debounce((_, nextFormData = {}) => {
    Object.assign(formData ?? {}, nextFormData)
    onChange({ formData: formData ?? ({}) , schema })
    setRequired(schema.required.filter( (key) => !formData[key] || formData[key].length === 0 ) )
  }, 200), [schema, onChange, formData])

  const properties: any = useMemo(() => {
    const propertiesSchema = schema["properties"] || {}
    const requiredSchema: any[] = schema["required"] || []

    const properties = Object.entries(propertiesSchema).map(([key, value]: [string, any]) => {
      const nextValue = Object.assign({ key }, value)
      const UISchemaValue = getUISchemaValue(UISchema, key)
      if(UISchemaValue?.required)
        UISchemaValue.required = required.includes(key)
      const InputNode: any = getInputNodeCustom(nextValue, UISchemaValue, formData?.[nextValue?.["key"]])
      return { ...value, title: value["title"] || key, name: key, render: InputNode, required: requiredSchema.includes(key) }
    })

    return properties.filter(({ render }) => render !== null)
  }, [schema, formData, UISchema])

  const gridStyle = useMemo(() => {
    if(typeof UISchema !== 'object'){
      const rowMax = Math.trunc(properties.length / 5) + 1
      return { gridTemplateRows: `repeat(${rowMax}, auto)`}
    }

    const rows: any[] = Object.values(UISchema).map(item => {
      return getDataOfType(item, 'css.gridRow', String, `1`)
    })
    const maxRow: number = Math.max(...rows)

    return { gridTemplateRows: `repeat(${maxRow}, auto)`}
  },[UISchema, properties])

  useEffect(() => {
    form.validateFields()
  }, [UISchema, form, formData])

  return (
    <Form layout="vertical" form={form} onValuesChange={onValuesChange} initialValues={formData}>
      <div className="form-data-grid" style={gridStyle}>
        {properties
        .filter(({ name }) => getDataOfType(UISchema, [name, 'hidden'], Boolean, false) === false)
        .map(({ title, name, render, ...props }, index: number) => {
          const gridColumn = getDataOfType(UISchema, [name, 'css', 'gridColumn'], String, getGridColumn())
          const gridRow = getDataOfType(UISchema, [name, 'css', 'gridRow'], String, getGridRow(index))
          const requiredField = props?.required && !formData?.[name] // Решение проблемы с выводом надписи "Обязательное поле"
          const validateStatus = requiredField?"error":""
          const rules = getFiledRules(props, requiredField)

            return (
              <div style={{gridColumn, gridRow}} key={name}>
                { name === 'order-price' ?
                  (<div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <Form.Item label={title} name={name}>
                      {render}
                    </Form.Item>
                    <Form.Item label='Задолженность' name={name}>
                      <ContractDebt/>
                    </Form.Item>
                    <Form.Item label='Предоплата' name='contract-order-price-pay'>
                      <ContractOrderPricePay UISchema={UISchema}/>
                    </Form.Item>
                  </div>)
                :
                  (<Form.Item label={title} name={name} validateStatus={validateStatus} rules={rules} required={requiredField}>{render}</Form.Item>)
                }
              </div>
            )
        })}
      </div>
    </Form>
  )
}

export default forwardRef(FormDataEditor)
