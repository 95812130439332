import React, { useState, useEffect, useMemo, useCallback } from 'react'
import B2BLayout from '../../../layouts/b2b'
import { Table } from 'antd'
import { getColumns } from './columns'
import { createSelector } from 'reselect'
import { useSelector } from 'react-redux'
import { getDataOfType } from '../../../../lib/utils/get-data-of-type'
import fetchAPI from '../../../../lib/utils/fetch-api'
import { useHistory } from 'react-router-dom'
import { objectToUrl } from '../../utils'
import FiltersList from "../../../components/v2/filters"
import axios from "axios";

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

type TablePaginationPosition = 'bottomCenter'

const CustomersList = () => {
  const history = useHistory()
  const [data, setData] = useState([])
  const [availableFilters, setAvailableFilters] = useState({
    organizations: [],
    brands: []
  })
  const [urlParams] = useState(Object.fromEntries(new URLSearchParams(location.search)))
  const [currentFilters] = useState<any>({
    phone: urlParams?.phone,
    manager: urlParams.manager ? +urlParams.manager : null,
    id: urlParams.id ? +urlParams.id : null,
    organization: urlParams.organization ? +urlParams.organization : null,
    stage: urlParams?.stage,
    dates: urlParams.dates
  })
  const {sessionData} = useSelector(createSession)
  const isLeader = getDataOfType(sessionData, 'organization.id', Number, null) === 1
  const [loading, setLoading] = useState(false)
  const [bottomCenter] = useState<TablePaginationPosition>('bottomCenter')
  const [sorter, setSorter] = useState<any>({})
  const [pagination, setPagination] = useState<any>({
    current: 1,
    pageSize: 10,
    total: 10,
    position: [bottomCenter],
    showSizeChanger: false
  })

  /**
   * Хлебные крошки
   */
  const breadcrumbs = useMemo(() => (
    [{
      href: '/cases',
      title: 'Контактный центр',
    }, {
      href: location.pathname,
      title: 'Клиенты',
    }]
  ), [location.pathname])

  useEffect(() => {
    try {
      /* Пытаюсь получить стадии и фильтры */
      getAvailableFilters()
      /* Пытаюсь получить список */
      getData()
    } catch (e) {
      alert('Не удалось получить данные, пожалуйста, перезагрузите страницу')
    }
  }, [])

  /**
   * Обновляет состояние пагинации
   */
  const updatePagination = useCallback(newState => {
    setPagination({
      ...pagination,
      ...newState
    })
  }, [pagination, setPagination])

  /**
   * Получает список доступных фильтров для обращений
   */
  const getAvailableFilters = useCallback(() => {
    const enumsList = {types: ['organizations', 'brands']}
    type FilterTypes = {
      organizations: [],
      brands: []
    }
    axios.get(`/api/enum-list?${objectToUrl(enumsList)}`)
      .then((enums: Record<string, any>) => {
        const filteredEnums: FilterTypes = {
          organizations: enums.organizations || [],
          brands: enums.brands || []
        }
        setAvailableFilters(filteredEnums)
      }).catch(error => {
      console.error('Произошла ошибка при загрузке данных:', error)
    })
  }, [])

  /**
   * Получает записи
   */
  const getData = useCallback(() => {
    try {
      setLoading(true)
      fetchAPI(`/api/customers/get-list/${location.search}`)
        .then(({data, pagination, sorter}) => {
          if (data) {
            updatePagination(pagination)
            setSorter(sorter)
            setData(data)
          }
          setLoading(false)
        })
    } catch (error) {
      console.error('Ошибка при получении данных:', error)
    }
  }, [])

  /**
   * Отслеживает изменение состояния таблицы
   *
   * @param pagination
   * @param filters
   * @param sorter
   */
  const handleTableChange = (pagination, filters, sorter) => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    let current = {
      ...currentUrlParams,
      sort_order: sorter?.order,
      sort_field: sorter?.field,
      page: pagination.current
    }
    if (current?.page === 1) {
      delete current.page
    }
    if (current?.sort_order === undefined) {
      delete current.sort_order
      delete current.sort_field
    }
    const urlParams = new URLSearchParams(current).toString()
    history.push({search: urlParams})
    getData()
  }

  /**
   * Отправляет на страницу деталки
   * @param record
   */
  const handleRowClick = record => {
    const {id} = record
    history.push(`/client-new/${id}`)
  }

  /**
   * Отслеживает состояние фильтров
   * @param filters
   */
  const handleFilterChange = filters => {
    const currentUrlParams = Object.fromEntries(new URLSearchParams(location.search))
    const obj = {...currentUrlParams, ...filters}

    /* в строку попадают только непустые строковые и положительные числовые значения */
    const newParams = Object.keys(obj).reduce((acc, key) => {
      if (
        (typeof obj[key] === 'string' && obj[key].length)
        ||
        (typeof obj[key] === 'number' && obj[key] > 0)
      ) {
        acc[key] = obj[key]
      }
      return acc
    }, {})

    const newUrlParams = new URLSearchParams(newParams).toString()
    history.push({search: newUrlParams})
    getData()
  }

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <FiltersList
        filters={[
          {
            name: 'id',
            placeholder: 'ID',
            type: 'input-integer',
            value: currentFilters.id || undefined,
            options: {
              width:'170px'
            }
          },
          {
            name: 'phone',
            type: 'phone',
            value: currentFilters.phone || undefined,
          },
          {
            name: 'brand',
            placeholder: 'Бренд',
            type: 'select',
            value: currentFilters.brands || undefined,
          },
          {
            name: 'organization',
            placeholder: 'Организация',
            type: 'select',
            value: currentFilters.organization || undefined,
            options: {
              enum: availableFilters && availableFilters?.organizations || []
            }
          },
          {
            name: 'dates',
            type: 'dates'
          },
        ]}
        onChange={handleFilterChange}
      />
      <Table
        showSorterTooltip={{overlay: "Нажмите для сортировки", placement: 'bottom'}}
        columns={getColumns(isLeader, sorter)}
        rowKey={r => r?.id}
        dataSource={data}
        pagination={pagination}
        loading={loading}
        onChange={handleTableChange}
        onRow={record => ({onClick: () => handleRowClick(record)})}
        scroll={{
          x: 'max-content',
          y: '72vh'
        }}
        size="small"
      />
    </B2BLayout>
  )
}

export default CustomersList
