import React, { useRef, useCallback, useState, useEffect } from 'react'
import { Modal } from 'antd'
import { DefaultJsonForm } from '../../components/default-json-form'
import { isFormDataRequired } from '../../../lib/utils/collections'
import { BrowserRouter } from 'react-router-dom'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { Provider, useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { store } from '../../../browser-app'
import fetchAPI from '../../../lib/utils/fetch-api'
const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  targetId => targetId 
)


const selectedSchema = {
  type: 'object',
  required: ['date-carriage', 'time-carriage', 'responsible'],
  properties: {
    'responsible': {
      type: 'pointer',
      view: {
        name: 'select-from-user'
      },
      title: 'Водитель',
    },
    'date-carriage': {
      type: 'string',
      format: 'date',
      title: 'Дата выезда',
    },
    'date-plan': {
      type: 'string',
      format: 'date',
      title: 'Плановая дата доставки',
    },
    'time-carriage': {
      type: 'string',
      format: 'time-range',
      title: 'Время выезда',
    },
    'comment': {
      type: 'string',
      title: 'Комментарий',
    },
  },
}

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)


export function modalClose(event){
  let target = event.target
  while (Boolean(target?.tagName) === true) {
    if (target?.tagName.toLowerCase() === 'div') {
      break
    }
    target = target?.parentNode
  }
  try{
    Array.from(target?.children as any[])[0].click()
    return true
  }catch{
    return false
  }
}


export function modalAppointDrivert() {
  let formData = {}
  return new Promise(resolve => Modal.confirm({
    icon: null,
    title: 'Выбор экипажа',
    /* @ts-ignore */
    content: <Provider store={store}><Content onChange={value => { formData = value }} /></Provider>,
    onCancel: () => resolve(null),
    okButtonProps: { 
      onClick: (event) => {
        const isRequired = isFormDataRequired(formData, selectedSchema)
        if(isRequired){
          resolve(formData)
          modalClose(event)
        }
      }
    }
  }))
}

function Content({ onChange }){
  const formDataRef =  useRef({})
  const localOnChange = useCallback(({ formData }) => {
    formDataRef.current = formData
    onChange(formData)
  }, [onChange])
  const [UISchema, setUISchema] = useState<any>({})
  const { sessionData } = useSelector(createSession)
  const { targetId } = useSelector(createAppealsCurrentStep)

  useEffect(() => {
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, null)
    const UISchema = {
      responsible: {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '1',
        },
       options: {
          organizationId: organizationId,
          roleId: 14,
        },
      },
      'date-measurement': {
        css: {
          gridColumn: '1 / span 11',
          gridRow: '2',
        },
        options: {
          isDisabledDatePrev: true,
        },
      },
      'date-plan': {
        css: {
          gridColumn: '12 / span 13',
          gridRow: '2',
        },
        options: {
          disabled: true,
          isDisabledDatePrev: true,
        },
      },
      'time': {
        css: {
          gridColumn: '1 / span 24',
          gridRow: '4',
        },
        options: {
          isDisabledTimePrev: true,
        },
      },
      comment: {
        options: {
          type: 'textarea',
          minRows: 3,
          maxRows: 3,
        },
        css: {
          gridColumn: '1 / span 24',
          gridRow: '5',
        },
      }
    }
    ;(async (targetId) => {
      const response = await fetchAPI(`/api/order-forecast/${targetId}`)
      const first = getDataOfType(response, 'data', Array, []).filter(item => item['code'] === 'delivery').shift()
      /*      
      const stageManufacture = getDataOfType(response, 'data', Array, []).find(item => item['code'] === 'manufacture')
      const dateManufacture =  getDataOfType(stageManufacture, 'date-adjusted', String, 
        getDataOfType(stageManufacture, 'date', String, true)
      ) 
      */
      formDataRef.current = Object.assign({ 'date-plan': getDataOfType(first, 'date', String, undefined) }, formDataRef.current)
      setUISchema(Object.assign({}, UISchema, { 'date-measurement': {
        css: {
          gridColumn: '1 / span 11',
          gridRow: '2',
        },
        options: {
          isDisabledDatePrev: true,
        },
      }}))
    })(targetId)
  }, [sessionData, targetId])


  return (
    /* @ts-ignore */
    <BrowserRouter>
      <DefaultJsonForm
        formData={formDataRef.current}
        schema={selectedSchema}
        onChange={localOnChange}
        UISchema={UISchema}
      />
    </BrowserRouter>
  )
}