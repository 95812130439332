import React, { useMemo } from 'react'
import { Select } from './styles'
import { ISelect } from './interface'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

function filterOption(input, option){
  return option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
}

function SelectFromEnum({ value, schema, onChange, UISchema }: ISelect) {
  const props: {} = useMemo(() => {
    const props = {
      width: UISchema?.options?.width,
      allowClear: Boolean(UISchema?.options?.allowClear),
      showSearch: Boolean(UISchema?.options?.showSearch),
      placeholder: UISchema?.options?.placeholder ?? "Выберите запись",
      optionFilterProp: "children",
      onChange: onChange,
      defaultValue: value,
      filterOption: filterOption,
      disabled: Boolean(UISchema?.options?.disabled),
    }
    if(Boolean(UISchema?.options?.multiple)) {
      Object.assign(props, { mode: 'multiple' })
    }

    return props
  }, [UISchema])

  const objects: any[] = useMemo(() => {
    const values = getDataOfType(schema, 'enum', Array, [])
    const oneOf = getDataOfType(schema, 'oneOf', Array, [])
    const oneOfObject = Object.fromEntries(oneOf.map(item => [item['const'], item['title']]))
    return values.map(value => ({
      'name': value,
      'title': getDataOfType(oneOfObject, value, [String, Number], value)
    }))
  }, [schema])

  return (
    <Select
      {...props}
    >
    {objects.map(item => (
      <Select.Option key={item['name']} value={item['name']}>{item['title']}</Select.Option>
    ))}
    </Select>
  )
}

export default SelectFromEnum

