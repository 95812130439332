import React, {useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react'
import {Space, Timeline, Tooltip} from 'antd'
import {formatDate} from './activity-timeline-Item'
import {ArrowRightOutlined, BookOutlined, EditOutlined, InfoOutlined, RetweetOutlined, RollbackOutlined} from '@ant-design/icons'
import ActivityTimelineContext from './activity-timeline-context'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'
import {
  Avatar,
  DotStyle,
  NoteEditButton,
  NoteSaveButton,
  NoteTextArea,
  Stage,
  TimeLineContentHeaderWrapper,
  TimeLineContentWrapper,
} from './styles'
import fetchAPI from '../../../lib/utils/fetch-api'
import { get } from 'lodash'

const dotColor = '#2fc4f5'
const autoSize = { minRows: 7, maxRows: 12 }

/**
 * TODO раскидать на разные компоненты
 * @param type
 * @param body
 * @param date
 * @param id
 * @constructor
 */

function ActivityTimelineNote({ type, body, date, id }: any) {
  const dateFormat = useMemo(() => formatDate(date), [date])
  const [localText, setLocalText] = useState(body.content)
  const [isEditNote, setEditNote] = useState(false)
  const [isEditable, setIsEditable] = useState(false)
  const cardRef = useRef<any>(null)
  const contextProps = useContext(ActivityTimelineContext)
  const [organizations, setOrganizations] = useState<any>(null)
  const [managers, setManagers] = useState<any>(null)

  const author = useMemo(() => {
    const { usersProfile } = contextProps['task']
    const authorId = getDataOfType(body, 'author', [Number, String], 0)
    const item = usersProfile.find(({ id }) => String(authorId) === String(id))
    return (getDataOfType(item, 'profile-data.name', String, '') || getDataOfType(item, 'name', String, '')) + ` (ID: ${authorId})`
  }, [contextProps, body])

  function isJSON(str) {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  useEffect( () => {
    if( isJSON(body.content) ){
      const content = JSON.parse(body.content)
      if (content?.organization) {
        fetchAPI('/api/get-organization-profiles', {
          method: 'POST',
          body: JSON.stringify({ids: Object.values(content.organization)})
        }).then(data => {
          setOrganizations(data.data)
        })
      }

      if (content?.manager) {
        fetchAPI('/api/users?filter[role]=2').then(data => {
          setManagers(data.data)
        })
      }
    }
  }, [body])

  useEffect(() => {
    setLocalText(body.content)
    setIsEditable( !body?.type )
  },[body.content, setLocalText])

  useEffect(()=> {
    if(cardRef.current){
      cardRef.current.style.maxHeight = 'max-content'
    }
  },[])

  const saveNote = useCallback(async () => {
    setEditNote(false)
    const { editNote } = contextProps[type]
    await editNote({ id, content: localText })
  },[contextProps, type, id, localText, setEditNote])

  const noteName = useMemo(()=>{
    if(!body?.type)
      return 'Заметка'

    switch (body.type){
      case 'region-change':
        return 'Регион изменен'
      case 'manager-change':
        return 'Переназначен менеджер'
      default:
        return body?.title
    }
  }, body)

  const urlAvatar = 'https://bipbap.ru/wp-content/uploads/2017/05/VOLKI-krasivye-i-ochen-umnye-zhivotnye.jpg';

  function getData(path: string) {
    return get(JSON.parse(body.content), path)
  }

  function getOrganization(id){
    if( !organizations )
      return '...'
    const o = organizations.find( item => +item.owner_organization === +id )
    return o?.['profile_data']?.title
  }

  function getManager(id){
    if( !managers )
      return '...'
    const m = managers.find( item => +item.id === +id )
    return m?.["profile-data"]?.name
  }

  const dotIcon = body => {
    if(!body?.type)
      return (<BookOutlined rev={undefined}/>)
    switch (body.type) {
      case 'region-change':
        return (<InfoOutlined rev={undefined}/>)
      case 'manager-change':
        return (<RollbackOutlined rev={undefined}/>)
      case 'repeated-appeal':
        return (<RetweetOutlined rev={undefined}/>)
    }
  }

  return (
    <Timeline.Item
      dot={
        <DotStyle color={dotColor}>
          {dotIcon(body)}
        </DotStyle>
      }
    >
      <TimeLineContentWrapper>
        <TimeLineContentHeaderWrapper>
          <Space>
            <span style={{ fontWeight: 'bold' }}>{noteName}</span>
            <span style={{ color: '#a8adb4' }}>{dateFormat}</span>
            { isEditable &&
              (<NoteEditButton disabled={isEditNote} onClick={() => setEditNote(true)}>
                <EditOutlined rev={undefined}/>
              </NoteEditButton>)}
          </Space>
          { (body?.type === 'region-change' || body?.type === 'manager-change') ?
            (
              <Space>
                {author &&
                <Space>
                  <Avatar src={urlAvatar} />
                  <Tooltip placement="top" title="Автор"><span>{author}</span></Tooltip>
                </Space>
                }
              </Space>
            )
              :
            (
              <Space>
                <Tooltip placement="top" title="Автор заметки">
                  <span>{author}</span>
                </Tooltip>
              </Space>
            )
          }
        </TimeLineContentHeaderWrapper>
        {isEditNote ? (
          <>
            <NoteTextArea
              autoSize={autoSize}
              value={localText}
              onChange={({ target }) => setLocalText(target.value)}
            />
            <NoteSaveButton onClick={saveNote}>Сохранить</NoteSaveButton>
          </>
        ) : (
          <>
            {body?.type === 'region-change' && (
              <>
                <Space>
                  <Stage color={'#e8f1fb'}>
                    {getOrganization(getData('organization.old'))} ({getData('region.old')})
                  </Stage>
                  <ArrowRightOutlined style={{ color: '#b1bac3' }} rev={undefined} />
                  <Stage color={'#e8f1fb'}>
                    {getOrganization(getData('organization.new'))} ({getData('region.new')})
                  </Stage>
                </Space>
                <div style={{ margin: '10px 0 5px' }}>
                  Причина изменения: {getData('reason')}
                </div>
              </>
            )}
            {body?.type === 'manager-change' && (
              <>
                <Space>
                  <Stage color={'#e8f1fb'}>{getManager(getData('manager.old'))}</Stage>
                  <ArrowRightOutlined style={{ color: '#b1bac3' }} rev={undefined} />
                  <Stage color={'#e8f1fb'}>{getManager(getData('manager.new'))}</Stage>
                </Space>
              </>
            )}
            {body?.type === 'repeated-appeal' && (
              <div
                ref={cardRef}
                style={{
                  transition: 'all 1.5s ease-out 0s',
                  whiteSpace: 'break-spaces',
                  fontSize: 'unset',
                  fontFamily: 'unset',
                  lineHeight: '1.2em',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: body.content }} />
              </div>
            )}
            {!body?.type && (
              <div
                ref={cardRef}
                style={{
                  transition: 'all 1.5s ease-out 0s',
                  whiteSpace: 'break-spaces',
                  fontSize: 'unset',
                  fontFamily: 'unset',
                  lineHeight: '1.2em',
                  overflow: 'hidden',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                }}
              >
                <div dangerouslySetInnerHTML={{ __html: localText }}/>
              </div>
            )}
          </>
        )}
      </TimeLineContentWrapper>
    </Timeline.Item>
  )
}

export default ActivityTimelineNote