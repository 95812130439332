import React, {useState, useRef, useEffect, useCallback} from 'react'
import {useParams, useHistory} from 'react-router-dom'
import {Form, Alert, Skeleton, Button, Modal} from 'antd'
import {SaveOutlined} from '@ant-design/icons'
import {MapContent, MapTitle, MapWrapper, AlertWrapper, TaskWrapper, MapSubTitle} from './styles'
import B2BLayout from '../../layouts/b2b'
import './index.css'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'
import {DefaultJsonForm} from '../../components/v2/default-json-form'
import requestFieldsSchema from './json-schema-request.json'
import candidateFieldsSchema from './json-schema-candidate.json'
import fetchAPI from '../../../lib/utils/fetch-api'
import dayjs from 'dayjs'
import {objectMap} from '../../../lib/utils/get-table-transform-schema-and-form-data'
import {isFormDataRequired} from '../../../lib/utils/collections'

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 8},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 24},
  },
}

const breadcrumbs = [
  {
    href: '/hr',
    title: 'Учет кандидатов',
  },
]

export default function AdminNewObjectPage() {
  const {id} = useParams() as any
  const history = useHistory()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(true)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [pageSuccess, setPageSuccess] = useState<string[]>([])
  const formDataRefCandidate = useRef<any>({})
  const formDataRefRequest = useRef<any>({})
  const [form] = Form.useForm()
  const [matchedJSONSchemaCandidate, setMatchedJSONSchemaCandidate] = useState<any>(null)
  const [matchedJSONSchemaRequest, setMatchedJSONSchemaRequest] = useState<any>(null)
  const [schemaCity, setSchemaCity] = useState<any>([])
  const [defaultCity, setDefaultCity] = useState<any>([null, null])
  const [dateCreated, setDateCreated] = useState<any>('')

  const saveCase = useCallback(
    async () => {
      const formData = {
        candidate: {...formDataRefCandidate.current},
        request: {...formDataRefRequest.current},
      }
      const isRequiredCandidate = isFormDataRequired(formDataRefCandidate.current, matchedJSONSchemaCandidate)
      const isRequiredRequest = isFormDataRequired(formDataRefRequest.current, matchedJSONSchemaRequest)
      if (isRequiredCandidate === false || isRequiredRequest === false) {
        Modal.warning({
          title: 'Предупреждение',
          content: 'Заполните все обязательные поля'
        })
        return
      }
      const hrId = id !== 'new' && id || ''

      await fetchAPI(`/api/hr/request/${hrId}`, {method: 'POST', body: JSON.stringify(formData)})
        .then(result => {
          if (result?.errors) {
            const errors =
              Array.isArray(result['errors']) && result['errors']?.length > 0
                ? result['errors'].map((error) => error['detail'])
                : ['Неизвестная ошибка при выполнении запроса']
            setPageSuccess([])
            setPageErrors(errors)
          } else {
            setPageSuccess([id === 'new' ? 'Запись успешно создана.' : 'Запись успешно обновлена.'])
            setPageErrors([])
          }
        })

      //history.push('/hr')
    },
    [history, id, defaultCity, matchedJSONSchemaCandidate, matchedJSONSchemaRequest]
  )

  const getData = useCallback(async () => {
    {
      const result = id !== 'new' && await fetchAPI(`/api/hr/request/${id}`) || []

      const candidateData = getDataOfType(result, 'candidate', Object, {})
      const requestData = getDataOfType(result, 'request', Object, {})
      const candidate = {}
      const request = {}

      Object.keys(candidateFieldsSchema?.properties).forEach(code => {
        if (candidateData[code]) candidate[code] = candidateData[code]
      });
      Object.keys(requestFieldsSchema?.properties).forEach(code => {
        if (requestData[code]) request[code] = requestData[code]
      });

      {
        await fetchAPI('/api/hr/request/enum')
          .then(result => {
            const enumItems = result?.['enum'] || []
            if (result?.['city']) {
              enumItems.city_id = Object.values(objectMap(result['city'], ([key, value]) => {
                return [key, {id: parseInt(key), value: value}]
              }))
            }
            if (result?.['region']) {
              enumItems.region_id = Object.values(objectMap(result['region'], ([key, value]) => {
                return [key, {id: parseInt(key), value: value}]
              }))
            }

            Object.keys(enumItems).forEach(code => {
              if (candidateFieldsSchema?.properties.hasOwnProperty(code) || requestFieldsSchema?.properties.hasOwnProperty(code)) {
                const enumValues = result?.['enum']?.[code] || {}
                const oneOf: Record<string, string>[] = []

                Object.keys(enumValues).forEach(enumValue => {
                  return oneOf.push({
                    'const': enumValues[enumValue].id,
                    'title': enumValues[enumValue].value
                  })
                })

                if (candidateFieldsSchema?.properties.hasOwnProperty(code)) {
                  Object.assign(candidateFieldsSchema?.properties[code], {
                    oneOf: oneOf,
                    'enum': enumValues.map(item => item.id),
                    view: {'name': 'select-from-enum'},
                    options: {
                      allowClear: true
                    }
                  })
                }

                if (requestFieldsSchema?.properties.hasOwnProperty(code)) {
                  Object.assign(requestFieldsSchema?.properties[code], {
                    oneOf: oneOf,
                    'enum': enumValues.map(item => item.id),
                    view: {'name': 'select-from-enum'},
                    options: {
                      allowClear: true
                    }
                  })
                }

                if (candidate.hasOwnProperty(code)) {
                  candidate[code] = candidate[code] ? parseInt(candidate[code]) : null
                }
                if (request.hasOwnProperty(code)) {
                  request[code] = request[code] ? parseInt(request[code]) : null
                }
              }
            })

            /*const cities = result?.['city'] || {}
            const cityOptions: IOption[] = []

            Object.keys(cities).forEach(id => {
              const city = cities[id]
              const regionOptions: any[] = [];
              for (const regionId in city.region) {
                regionOptions.push({
                  value: regionId,
                  label: city.region[regionId],
                })
              }

              cityOptions.push({
                value: id,
                label: city.name,
                children: regionOptions
              })
            })

            setSchemaCity(cityOptions)*/
          })
      }

      request['came_request'] = request['came_request'] ? 1 : 0
      request['work_comment'] = request['work_comment'] ? 1 : 0
      request['result_trial_period'] = request['result_trial_period'] ? 1 : 0

      /*if (candidateData?.['city_id'] || candidateData?.['region_id']) {
        setDefaultCity([candidateData?.['city_id'].toString(), candidateData?.['region_id'].toString()])
      }*/

      if (requestData['created_at']) {
        setDateCreated(dayjs(requestData['created_at']).format('DD.MM.YYYY HH:mm'))
      }

      formDataRefCandidate.current = {...candidate}
      formDataRefRequest.current = {...request}

      setMatchedJSONSchemaCandidate(candidateFieldsSchema);
      setMatchedJSONSchemaRequest(requestFieldsSchema);
    }

    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    getData()
  }, [getData])

  const onFormDataCandidate = useCallback(
    ({formData}) => {
      formDataRefCandidate.current = formData
    }, []
  )

  const onFormDataRequest = useCallback(
    ({formData}) => {
      formDataRefRequest.current = formData
    }, []
  )

  const onChangeCity = useCallback(
    (value) => {
      //formDataRef.current = {...formDataRef.current, ...{city_id: parseInt(value[0]) || null}, ...{region_id: parseInt(value[1]) || null}}
      setDefaultCity([
        parseInt(value[0]) || null,
        parseInt(value[1]) || null,
      ])
    }, []
  )

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>

      <MapWrapper>
        {id === 'new' ? (
          <TaskWrapper>
            <MapTitle>
              Новая запись
            </MapTitle>
          </TaskWrapper>
        ) : (
          <>
            <TaskWrapper>
              <MapTitle>
                Запись <span>{`№ ${id}`}</span>
              </MapTitle>
            </TaskWrapper>
            <TaskWrapper>
              <MapSubTitle>
                <span>{`от ${dateCreated}`}</span>
              </MapSubTitle>
            </TaskWrapper>
          </>
        )}
        <MapContent>
          <Form {...formItemLayout} layout="horizontal" form={form} scrollToFirstError={true}>
            {pageErrors.length > 0 ? (
              <AlertWrapper>
                <Alert
                  message="При выполнении операции возникла ошибка:"
                  showIcon
                  type="error"
                  description={pageErrors.join('. ')}
                />
              </AlertWrapper>
            ) : null}
            {pageSuccess.length > 0 ? (
              <div style={{marginBottom: 40}}>
                <Alert
                  message={pageSuccess.join('. ')}
                  showIcon
                  type="success"
                />
              </div>
            ) : null}
            {!isDataLoading ? (
              (
                <>
                  <TaskWrapper>
                    <MapTitle>
                      Кандидат
                    </MapTitle>
                  </TaskWrapper>
                  <DefaultJsonForm
                    formData={formDataRefCandidate.current}
                    schema={matchedJSONSchemaCandidate}
                    onChange={onFormDataCandidate}
                    orientation="horizontal"
                  />
                  <TaskWrapper>
                    <MapTitle>
                      Обращение
                    </MapTitle>
                  </TaskWrapper>
                  <DefaultJsonForm
                    formData={formDataRefRequest.current}
                    schema={matchedJSONSchemaRequest}
                    onChange={onFormDataRequest}
                    orientation="horizontal"
                  />
                  {/*<Form.Item label="Город/район" style={{flexDirection: 'column', alignItems: 'flex-start'}}>
                    <Cascader
                      defaultValue={defaultCity}
                      options={schemaCity}
                      onChange={onChangeCity}
                      placeholder="Выберите город и район"
                      changeOnSelect
                      style={{width: '320px'}}
                    />
                  </Form.Item>*/}
                </>
              )
            ) : (
              <Skeleton active/>
            )}
          </Form>
          <Button onClick={saveCase} style={{marginBottom: 16, marginTop: 16}} type="primary">
            <SaveOutlined rev={undefined}/>
            Сохранить
          </Button>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
