import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useHistory, Link } from 'react-router-dom'
import { Layout, Form, Typography, Row, Input, Button } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { getLoginAction } from '../../store/login/action-creators'
import { getLoadSessionAction } from '../../store/session/action-creators'
import { LOGIN_DONE } from '../../store/login/actions'
import { RESTORE_SESSION_DONE } from '../../store/session/actions'
import { IStore } from '../../store/interfaces'
import { getLocationFromRequestedQuery } from '../../../lib/utils/requested-query'
import {Logo} from "../../assets/icons/logo";

const layout = {
  labelCol: { span: 24 },
  wrapperCol: { span: 24 },
};
const tailLayout = {
  wrapperCol: { offset: 0, span: 24 },
};

export default function LoginPage () {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const { lastResponse, hasErrors } = useSelector((state: IStore) => state.login)

  const onFinish = async values => {
    const loginFinalAction: any = await dispatch(getLoginAction(values))

    if (loginFinalAction.type === LOGIN_DONE) {
      const sessionFinalAction: any = await dispatch(getLoadSessionAction())
      // @todo Process location.search for redirect_uri and redirect_query
      if (sessionFinalAction.type === RESTORE_SESSION_DONE) {
        const nextLocation = getLocationFromRequestedQuery(location.search)
        history.push(nextLocation)
      }
    }
  };

  const onFinishFailed = errorInfo => {
    console.error('LoginPage: form failed', errorInfo);
  }

  const currentYear = () => new Date().getFullYear();
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout className="site-layout" style={{ backgroundColor: 'white', padding: '0 24px 24px' }}>
        <Layout.Content style={{
            padding: '80px 0 0 0',
            margin: 0,
            minHeight: 280,
            userSelect: 'none'
          }}>
          <Row justify="center" align="middle">
            <Form
              {...layout}
              name="basic"
              layout="vertical"
              initialValues={{ }}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              scrollToFirstError={true}
              style={{
                width: 512,
              }}
            >
              <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'center', marginBottom: 40 }}>
                <div style={{ fontSize: 60 }}>
                  <Logo/>
                  <div style={{fontSize:'50%'}}>** new **</div>
                </div>
                {(location && (location.search !== '')) ? (
                  <div>
                    <Typography.Text>
                    Для доступа в данный раздел<br />
                    необходимо войти в систему.
                    </Typography.Text>
                  </div>
                ) : null}
                {hasErrors ? (
                  <div>
                    <br />
                    <Typography.Text type="danger">
                    Ошибка!
                    {(lastResponse && lastResponse['errors']) ? (
                      lastResponse['errors'].map((error, index) => (
                        <li key={index}>{error['detail']}</li>
                      ))
                     ) : null}
                    </Typography.Text>
                  </div>
                ): null}
              </Form.Item>
              <Form.Item
                label="Логин:"
                name="login"
                rules={[
                  { required: true, message: 'Введите e-mail пользователя' },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                label="Пароль:"
                name="password"
                rules={[
                  { required: true, message: 'Введите пароль' },
                ]}
              >
                <Input.Password />
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Link className="login-form-forgot" to="/password-recovery"
                  style={{ fontSize: '90%', float: 'right', marginTop: -20, marginBottom: 20 }}>
                  Забыли пароль?
                </Link>
              </Form.Item>
              <Form.Item {...tailLayout}>
                <Link to="/register" style={{ marginTop: 4, display: 'inline-block' }}>Регистрация</Link>
                <Button type="primary" htmlType="submit" style={{ float: 'right' }}>
                  Войти <ArrowRightOutlined rev={undefined}/>
                </Button>
              </Form.Item>
            </Form>

          </Row>
        </Layout.Content>
        <Layout.Footer style={{ textAlign: 'center', background: 'none' }}>
          &copy; 2020-{currentYear()} ООО «Аксиома». Все права защищены.
        </Layout.Footer>
      </Layout>
    </Layout>
  )
}
