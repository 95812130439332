import React, { useMemo, useCallback, useState, useEffect } from 'react'
import { Form, Space, Button } from 'antd'
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons'
import { SpaceWrapper } from './styles'
import { getInputNode } from '../../components/json-form-object-editor'
import { Tooltip } from 'antd'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const ListItem = ({ items, name, schema, remove, add, isMe, toMe, isMinusDisable }) => {
  const { sessionData } = useSelector(createSession)
  const addCarry = useCallback(() => { 
    add() 
  }, [add])
  const removeCarry = useCallback(() => {
    return isMinusDisable ? null : remove(name)
  },[isMinusDisable, name, remove])
  const toMeCarry = useCallback(() => toMe(name), [name, toMe])
  const minusStyle = useMemo(
    () =>
      isMinusDisable
        ? { cursor: 'not-allowed', color: '#bdbdbd', padding: 5, fontSize: 20 }
        : { padding: 5, fontSize: 20 },
    [isMinusDisable]
  )

  const InputNode = useMemo(() => {
    const organizationId = getDataOfType(sessionData, 'organization.id', Number, 0)
    return getInputNode(schema['items'], { 
      options: { whereNotIds: items, organizationId: organizationId }
    })
  } ,[schema, items, sessionData])

  return (
    <SpaceWrapper>
      <Form.Item name={name}>{InputNode}</Form.Item>
      <Tooltip placement="top" title="Добавить">
        <PlusOutlined style={{ padding: 5, fontSize: 20 }} onClick={addCarry} rev={undefined} />
      </Tooltip>
      <Tooltip placement="top" title="Удалить">
        <MinusCircleOutlined style={minusStyle}  onClick={removeCarry} rev={undefined} />
      </Tooltip>
      <Tooltip placement="top" title="Назначить на себя">
        <Button disabled={isMe} onClick={toMeCarry}>На себя</Button>
      </Tooltip>
    </SpaceWrapper>
  )
}

const ArrayMultiFild = ({ value, onChange, schema }) => {
  const { sessionData } = useSelector(createSession)

  const userProfileId = useMemo(() => {
    return getDataOfType(sessionData, 'user.id', Number, 0)
  }, [sessionData])

  const [isMinusDisable, setMinusDisable] = useState(() => value?.length ? false: true)

  const [form] = Form.useForm()
  const items = useMemo(() => {
    if(Array.isArray(value)){
      return value
    }
    return [undefined]
  }, [value])

  const isMe = useMemo(() => {
    return items.some(item => item === userProfileId)
  }, [items, userProfileId])

  const localOnChange = useCallback((_,  all ) => {
    const { value } = all.find(({ name }) => name[0] === 'items' && name[1] === undefined)
    if (value?.length === 1) {
      setMinusDisable(() => true)
      return onChange(value)
    }
    setMinusDisable(() => false)
    return onChange(value)
  }, [onChange])

  useEffect(() => {
    form.resetFields(['items'])
  }, [items, form])

  const toMe = useCallback(name => {
    const items = form.getFieldValue('items')
    const newItems = items.map((item, index) => {
      if (index === name) {
        return userProfileId
      }
      return item
    })
    form.resetFields(['items'])
    form.setFieldsValue({ items: newItems })
    onChange(newItems)
  }, [form, onChange, userProfileId])

  return (
    <Form
      form={form}
      name="array"
      layout="vertical"
      onFieldsChange={localOnChange}
      autoComplete="off"
      initialValues={{ items }}
    >
      <Form.List name="items">
        {(fields, { add, remove }) => (
          <Space direction="vertical" style={{ width: '100%' }}>
            {fields.map(({ name, key }) => (
              <ListItem items={items} name={name} schema={schema} key={key} add={add} remove={remove} isMinusDisable={isMinusDisable} isMe={isMe} toMe={toMe}  />
            ))}
          </Space>
        )}
      </Form.List>
    </Form>
  )
}

export default ArrayMultiFild