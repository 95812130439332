import React, {  useState, useCallback, useMemo, useRef } from 'react'
import { Table } from 'antd'
import InputCheckbox from '../../components/Input-checkbox'
import InputNumber from '../../components/input-number'
import { useEffect } from 'react'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { useSelector, useDispatch } from 'react-redux'
import { createSelector } from 'reselect'
import fetchAPI from '../../../lib/utils/fetch-api'
import { isView } from '../../layouts/b2b/main-menu'
import { debounce } from 'lodash'
import { reloadTablePayment } from '../../store/appeals/actions'

const roles = [3, 4, 5, 6, 12, 18, 19, 2, 10, 28, 29]
const name = 'order-services'

const allServices = [
  {
    code: "mounting",
    name: "Монтаж",
    description: "",
  },
  {
    code: "demounting",
    name: "Доп. услуги",
    description: "",
  },
  {
    code: "delivery",
    name: "Доставка",
    description: "",
  },
  {
    code: "garbage-remove",
    name: "Вывоз мусора",
    description: "",
  },
  {
    code: "agent-commission",
    name: "Агентское вознаграждение",
    description: "",
  },
]

function addServices(services) {
  const nextServices = [...services]
  const getCodeFromItem = item => item['code']
  const servicesCodes = services.map(getCodeFromItem)
  allServices.forEach(item => {
    const nextKey = nextServices.length + 1
    if (servicesCodes.includes(item['code']) === false) {
      nextServices.push(Object.assign({ 
        key: nextKey,
        price: 0,
        include: false        
      }, item))
    }
  })
  return nextServices
}

function formatCurrency(val){
  return (val || 0).toLocaleString('ru-RU', {style: 'currency', currency: 'RUB', currencyDisplay: 'symbol'}).replace("₽", "")
}

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  cardName => cardName,
  stageCode => stageCode,
  targetId => targetId,
)

const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

export function priceFormat(price: number) {
  return Number(price).toFixed(0)
}


const EditableTable = () => {
const dispatch = useDispatch()
 const { cardName, stageCode, targetId } = useSelector(createAppealsCurrentStep)
 const { sessionData } = useSelector(createSession)
 const [localData, setLocalData] = useState<any>([])
 const idRef = useRef(0)

 const isEdit = useMemo(()=> {
  if (cardName !== 'order') {
    return false
  }
  return ['work', 'measurement', 'prepayment'].includes(stageCode)
}, [cardName, stageCode])


 const save = useCallback(async formData => {
  const requestBody = {
    data: {
      name: '',
      title: '',
      attributes: formData,
      metadata: { schema: name }
    }
  }
  const result = await fetchAPI(`/api/collections/-/objects/${idRef.current}`, {
    method: 'POST',
    body: JSON.stringify(requestBody),
  })
  const updated = getDataOfType(result, 'data.updated-at', [String, Number], null)
  if (updated === null) {
    console.warn('Ошибка сохранения записи')
  }
  dispatch(reloadTablePayment())
}, [dispatch])
// eslint-disable-next-line
const saveData = useCallback(debounce(save, 1000) as any, [])
const saveTable = useCallback(data => {
  const nextData = data.filter(item => item['include'])
  saveData({ table: nextData })
 }, [saveData])

  const setInclude = useCallback(item => {
    const code = getDataOfType(item, 'code', String, null)
    setLocalData(localData => {
      const nextData = localData.map(item => {
        const localCode = getDataOfType(item, 'code', String, [])
          if (code === localCode) {
            const localInclude = getDataOfType(item, 'include', Boolean, false) === false
            const nextItem = { ...item, include: localInclude,  }
            if (localInclude === false) {
              nextItem['price'] = 0
            }
            return nextItem
          }
          return { ...item }
          }).filter(item => {
            const price = item['price']
            if (price === 0 && isEdit === false) {
            return false
            }
            return true
          })
          saveTable(nextData)
      return nextData
    })
  }, [saveTable, isEdit])


 const setPrice = useCallback(nextItem => {
   const code = getDataOfType(nextItem, 'code', String, null)
   setLocalData(localData => {
     const nextData = localData.map(item => {
       const localCode = getDataOfType(item, 'code', String, [])
       if (code === localCode) {
         const localPrice = getDataOfType(nextItem, 'price', Number, 0)
         return { ...item, price: localPrice }
       }
       return { ...item }
     })
     saveTable(nextData)
     return nextData
   })
 }, [saveTable])


const columns = useMemo(()=> {
  const columns = [
    {
      dataIndex: 'include',
      editable: true,
      required: false,
      title: 'Включена',
      type: 'string',
      width: 30,
      render: (value, item) => <InputCheckbox UISchema={{ options: { disabled: false} }} onChange={() => setInclude(item)} value={value} />,
    },
    {
      dataIndex: 'name',
      editable: true,
      required: false,
      title: 'Услуга',
      type: 'string',
      width: 300,
    },
    {
      dataIndex: 'price',
      editable: true,
      required: false,
      title: 'Цена (руб)',
      type: 'number',
      width: 150,
      render: (value, item) => (item['include'] === false || (isEdit === false ))? formatCurrency(value) : <InputNumber UISchema={{ options: { disabled: false} }} onChange={(price) => setPrice({ ...item, price })} value={value} />,
    },
    {
      dataIndex: 'description',
      editable: true,
      required: false,
      title: 'Описание',
      type: 'string',
    },
  ]


  return columns.filter(item => {
    const code = item['code']
    const dataIndex = item['dataIndex']
    if (code === 'include' && isEdit === false) {
      return false
    }
    if (code === 'price' && (isView(sessionData, roles) === false) ) {
      return false
    }
    if (cardName !== 'order' && dataIndex === 'include') {
      return false
    }
    const stages = ['prepayment', 'manufacture', 'delivery', 'mounting', 'audit', 'done']
    if (cardName === 'order' && dataIndex === 'include' && stages.includes(stageCode)) {
      return false
    }
    return true
  })
}, [isEdit, setInclude, setPrice, sessionData, cardName, stageCode])


  const getData = useCallback(async () => {
    const response = await fetchAPI(`/api/collections/${name}/?parentId=${targetId}`)
    const first = getDataOfType(response, 'data.data[0]', Object, {})
    idRef.current = getDataOfType(first, 'id', Number, null)
    const dataProto = getDataOfType(first, 'data.attributes.table', Array, [])
    const services = dataProto.map(item => ({ include: true, ...item }))
    const nextServices = addServices(services)
    setLocalData(nextServices)
  }, [targetId])

  useEffect(() => {
    if (targetId === null) {
      return
    }
    getData()
  }, [targetId, getData])

  const filterLocalData = useMemo(() => {
    return isEdit ? [...localData] : localData.filter(item => item?.price)
  }, [isEdit, localData])

  const isViewService = useMemo(() => {
    const cards = ['manufacture']
    return cards.includes(cardName) === false
  }, [cardName])

  if (isViewService === false) {
    return null
  }

  return (
     <div style={{ marginBottom: 40, marginTop: 20 }}>
        <Table
          bordered
          dataSource={filterLocalData}
          columns={columns}
          pagination={false}
        />
    </div>
  )
}

export default EditableTable
