import React, { useEffect, useState, useMemo } from 'react'
import { Steps  } from 'antd'
import { StepsContainer, Step } from './styles'
import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import { CloseCircleOutlined } from '@ant-design/icons'
const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  stageCode => stageCode,
  appealsCurrentStep => appealsCurrentStep,
  workflows => workflows,
  targetId => targetId,
  cardName => cardName
)

function StepCollectionObject({ type }){
  const [stages, setStages] = useState<Array<object>>([])
  const [step, setStep ] = useState<any>(0) 
  const { appealsCurrentStep, workflows, targetId, stageCode, cardName } = useSelector(createAppealsCurrentStep)
  useEffect(()=> {
      (async () => {
        const result = await fetchAPI('/api/web-hooks/get-workflows-stages', {
          method: 'POST',
          body: JSON.stringify({
            'card-name': cardName,
            'target-id': targetId,
            'stage-code': stageCode
          })
        })
        const stages: Array<object> = getDataOfType(result, `data`, Array, [])
        const step: Number = getDataOfType(result, `step`, Number,  0)
        setStep(step)
        setStages(stages)
      })()    
  }, [type, workflows, appealsCurrentStep, targetId, cardName, stageCode])

  const [color] : any = useMemo(() => {
    const currentStage: object = getDataOfType(stages, `[${appealsCurrentStep}]`, Object, null)
    if(currentStage === null){
      return [[], 0]
    }
    const color = getDataOfType(currentStage, `color`, String, '') 
    return [color as string]
  }, [stages, appealsCurrentStep])

  return (
    <StepsContainer>
      <Steps size="small" current={step}>
        {stages.map(({ name, status }: any, index) => {
          if (index === step) {
            return (
              <Step color={color} key={index} title={name} />
            )
          }
          return <Steps.Step key={index} title={name} icon={status? null: <CloseCircleOutlined style={{ color: 'red' }} rev={undefined} />} />
        })}
      </Steps>
    </StepsContainer>
  )
}

export default StepCollectionObject

