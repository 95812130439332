import { getDataOfType } from '../../../lib/utils/get-data-of-type'

export function getStageName(stages, index){
  return getDataOfType(stages, `[${index}].name`, String, '')
}

export function getStageColor(stages, index){
  return getDataOfType(stages, `[${index}].color`, String, '')
}

export function getStage(stages, index){
  const currentStage = getDataOfType(stages, `[${index}]`, Object, null)

  if (currentStage === null) {
    return null
  }

  return {
    'name': getDataOfType(currentStage, `name`, String, ''),
    'code': getDataOfType(currentStage, `code`, String, ''),
    'color': getDataOfType(currentStage, `color`, String, ''),
    'font-weight': getDataOfType(currentStage, `font-weight`, String, ''),
    'font-color': getDataOfType(currentStage, `font-color`, String, ''),
  }
}

export function getStageFromCode(stages, stageCode){
  const currentStageIndex = stages.findIndex(stage => stage['code'] === stageCode )
  const currentStage = getDataOfType(stages, `[${currentStageIndex}]`, Object, null)
  //console.log({currentStageIndex,  currentStage, stages, stageCode})

  if (currentStage === null) {
    return null
  }

  return {
    'name': getDataOfType(currentStage, `name`, String, ''),
    'code': getDataOfType(currentStage, `code`, String, ''),
    'color': getDataOfType(currentStage, `color`, String, ''),
    'font-weight': getDataOfType(currentStage, `font-weight`, String, ''),
    'font-color': getDataOfType(currentStage, `font-color`, String, ''),
    'number': currentStageIndex
  }
}