import fetchAPI from '../../../lib/utils/fetch-api'
import { getDataOfType } from '../../../lib/utils/get-data-of-type'

function getGroupName(groups, id) {
  return getDataOfType(
    groups.filter(item => item['id'] === id),
    '[0].name',
    String,
    null
  )
}

export function getGroupId(groups, name) {
  return getDataOfType(
    groups.filter(item => item['name'] === name),
    '[0].id',
    [String, Number],
    null
  )
}

async function createGroups(names, collectionName){
  const result = await Promise.all(
    names.map(name => fetchAPI('/api/collections/objects-groups', {
      method: 'POST',
      body: JSON.stringify({ data: {
        'name': name,
         'collection-name': collectionName
      }})
    }))
  )
  return names.map((name, index) => ({
    'name': name,
    'id': Number(getDataOfType(result, `[${index}].id`, [String, Number], 0))
  }))
}

export async function importCollection(name, json) {
  const responseGroupsPrev = await fetchAPI(`/api/collections/objects-groups/${name}`)
  const groupsPrev = getDataOfType(responseGroupsPrev, 'data', Array, [])
  const groupsNames = groupsPrev.map(item => item['name'])
  const collectionGroupsNames = Array.from(new Set(
    json.map(item => item['group-name'])
  ))
  .filter(name => typeof name === 'string')

  const notExistGroupsNames = collectionGroupsNames.filter(item => groupsNames.includes(item) === false)
  await createGroups(notExistGroupsNames, name)
  const responseGroupsNext = await fetchAPI(`/api/collections/objects-groups/${name}`)
  const groupsNext = getDataOfType(responseGroupsNext, 'data', Array, [])
  const length = json?.length ?? 0
  let index = -1 
  while (++index !== length) {
    const currentObject = json[index]

    await fetchAPI(`/api/collections/${name}`, {
      method: 'POST',
      body: JSON.stringify({ data: {
        'name': currentObject['name'],
        ...currentObject['data'],
        'group-id': getGroupId(groupsNext, currentObject['group-name'])
      }})
    })
  }
}

export async function exportCollection(name) {
  const responseGroups = await fetchAPI(`/api/collections/objects-groups/${name}`)
  const groups = getDataOfType(responseGroups, 'data', Array, [])
  const responseObjects = await fetchAPI(`/api/collections/${name}/?access_key=axioma&limit=10000`)
  const objects = getDataOfType(responseObjects, 'data.data', Array, []).sort((a, b) => Number(a['id']) - Number(b['id']))
  const objectsFormat = objects.map(item => {
    const name = item['name']
    const groupName = getGroupName(groups, item['group-id'])
    const data = {
      'name': name,
      'attributes': getDataOfType(item, 'data.attributes', Object, {}),
      'metadata': getDataOfType(item, 'data.metadata', Object, {}),
    }
    return {
      'name': name,
      'group-name': groupName,
      'data': data 
    }
  })
  return objectsFormat
}