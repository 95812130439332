import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import {Alert, Button, Skeleton, Space} from 'antd'
import {AlertWrapper, MapContent, MapTitle, MapWrapper, TaskWrapper} from './styles'
import B2BLayout from '../../layouts/b2b'
import fetchAPI from '../../../lib/utils/fetch-api'
import {getDataOfType} from '../../../lib/utils/get-data-of-type'
import {DefaultJsonForm} from '../../components/v2/default-json-form'
import StepCollectionObject from '../../views/step-collection-object'
import ModalCreateTask from '../../views/modal-create-task'
import {recalcUISchemaHorizontal} from '../../../lib/utils/grid-fild-orientation'
import ButtonAddNotes from '../../views/modal-add-notes'
import {clearCaseStore, initialCase, reloadActionsTimeLine, setTargetId} from '../../store/appeals/actions'
import ModalReasonCancellation from '../../views/modal-cancellation'
import StuckFiles from '../../components/tab-stuck-files'
import TableProduct from '../../connectors/json-form-product-table/adaptor-tab'
import {
  accessUISchema,
  addValidateFieldsUISchema,
  isFormDataDifferenceFilesS3,
  UISchemaDisabled,
  UpdateTaskIsStage
} from '../../pages/card-order/utils'
import {createSelector} from 'reselect'
import StepButtonsTransition from '../../views/step-buttons-transition'
import {isFormDataRequired} from '../../../lib/utils/collections'
import {setStageFromCard} from '../../../lib/utils/cards'
import { debounce } from 'lodash'
import defaultSelectedSchema from './json-schema.json'
import {fetchV2_1, setEnumFields} from '../utils'
import CaseExecutionPlan from '../../views/case-execution-plan'
import ActivityTimeLine from '../../connectors/activity-timeline'

function genJSONSchemaStage(JSONSchema, requiredArr) {
  const required = getDataOfType(JSONSchema, 'required', Array, []).concat(requiredArr)
  const requiredUniq = Array.from(new Set(required))
  return ({ ...JSONSchema, required: requiredUniq })
}

function genJSONSchemaNamesRequired(JSONSchema){
  return getDataOfType(JSONSchema, 'required', Array, [])
}

const createAppealsCurrentStep = createSelector(
  (state: any) => state.appeals,
  appealsCurrentStep => appealsCurrentStep,
  workflows => workflows
)
const createSession = createSelector(
  (state: any) => state.session,
  sessionData => sessionData
)

const breadcrumbs = [{
  href: '/call-center/cases',
  title: 'Контактный центр',
}, {
  href: `/appeals/`,
  title:  'Обращения',
}]

function setDisabledFields(data, schema) {
  const disabledFields = data.filter(obj => obj.disabled === true)
  disabledFields.forEach(function (obj) {
    if (schema.properties[obj.name]) {
      schema.properties[obj.name].options = {...schema.properties[obj.name].options, disabled: true}
    }
  })
}

const cardName = 'case'

export default function AdminNewObjectPage() {
  const history = useHistory()
  const { id } = useParams() as any
  const dispatch = useDispatch()
  const [isDataLoading, setIsDataLoading] = useState<Boolean>(false)
  const [isModalCreateTask, setModalCreateTask] = useState<boolean>(false)
  const [isCancellation, setCancellation] = useState<boolean>(false)
  const [pageErrors, setPageErrors] = useState<string[]>([])
  const [pageSuccess, setPageSuccess] = useState<string[]>([])
  const formDataRef = useRef<any>(null)
  const [matchedJSONSchema, setMatchedJSONSchema] = useState<any>(null)
  const [UISchema, setUISchema] = useState<any>(null)
  const [isBusy, setIsBusy] = useState<boolean>(false)
  const { appealsCurrentStep, workflows } = useSelector(createAppealsCurrentStep)
  const { sessionData } = useSelector(createSession)
  const [readyToSave, setReadyToSave] = useState<boolean>(false) /* чтобы избавиться от сохранения формы в момент загрузки */

  useEffect(() => {
    const currentStageAccess = getDataOfType(workflows, `${cardName}[${appealsCurrentStep}].access-rights`, Array, null)
    if(currentStageAccess === null)
      return
    setUISchema(UISchema => recalcUISchemaHorizontal(accessUISchema(UISchema || {}, currentStageAccess, sessionData)))
  },[workflows, appealsCurrentStep, sessionData])

  const customTabs = useMemo(() => ({
    'event-ribbon': {
      isVirtual: true,
      schema: {
        properties: {
          'event-ribbon': {
            title: 'Лента событий',
            description: null,
          }
        }
      },
      render: () =>
        <div style={{width: '100%', display: 'flex', flexDirection: 'row'}}>
          <div style={{flexBasis: '50%'}}>
            <CaseExecutionPlan/>
          </div>
          <div style={{flexBasis: '50%', paddingLeft: 20}} className="actions-time-line">
            <ActivityTimeLine/>
          </div>
        </div>
    },
    'stuck-products': {
      isVirtual: true,
      schema: {
        properties: {
          'stuck-products': {
            title: 'Данные заказа',
            description: null,
          }
        }
      },
      render: () => <TableProduct currentStage={'manufacture'} disabled={true} id={Number(id)} />
    },
    'stuck-files': {
      isVirtual: true,
      schema: {
        properties: {
          'stuck-files': {
            title: 'Прикрепленные файлы',
            description: null,
          }
        }
      },
      render: () => <StuckFiles />
    },
  }), [id])

  const saveCase = useCallback(
    debounce( async () => {
      const formData = {...formDataRef.current}
      await fetchV2_1(
        `/api/appeal/${id}`,
        {
          method: 'POST',
          body: JSON.stringify(formData)
        })
        .then(result => {
          if (result?.errors) {
            const errors =
              Array.isArray(result.errors) && result.errors?.length > 0
                ? result.errors
                : ['Неизвестная ошибка при выполнении запроса.']
            setPageSuccess([])
            setPageErrors(errors)
          } else {
            setPageSuccess(['Запись успешно обновлена.'])
            setPageErrors([])
          }
        })
        .catch( e => {
          console.log({e})
          setPageErrors(['Сервер не отвечает, проверьте подключение к интернету!'])
        })
    }, 1000),
    [history, id]
  )

  useEffect(() => {
    dispatch(initialCase({targetId: Number(id), cardName: 'case'}))
    dispatch(setTargetId({targetId: Number(id)}))
    dispatch(reloadActionsTimeLine())
    return () => {
      dispatch(clearCaseStore())
    }
  }, [id, dispatch])

  const getData = useCallback(async () => {
    {
      const enums = await fetchAPI(`/api/enum/appeals`)
      setEnumFields(enums, defaultSelectedSchema)

      const data = await fetchAPI(`/api/appeal/${id}`)
      const formData = data.reduce((obj, cur) => ({...obj, [cur.name]: cur.value}), {})
      setDisabledFields(data, defaultSelectedSchema)
      formDataRef.current = {...formData}

      setMatchedJSONSchema(defaultSelectedSchema)
    }
    setPageErrors([])
    setIsDataLoading(false)
  }, [id])

  useEffect(() => {
    if(sessionData === null || workflows === null){
      return
    }
    getData()
  }, [getData, sessionData, workflows])

  const onFormData = useCallback(
    ({ formData }) => {
      if( !readyToSave ) {
        setReadyToSave(!readyToSave)
        return
      }
      const isFilesEdit = isFormDataDifferenceFilesS3(formDataRef.current, formData, matchedJSONSchema)
      if (isFilesEdit) {
        dispatch(reloadActionsTimeLine())
      }
      formDataRef.current = {...formData, stage: appealsCurrentStep}
      saveCase()
    },
    [saveCase, appealsCurrentStep, matchedJSONSchema, dispatch, isBusy, setReadyToSave, readyToSave]
  )

  const onCancellation = useCallback(async ({ comment }) => {
    await UpdateTaskIsStage({ parentId: id, stage: 'consultation', formData: {
      'date-completion': new Date().toISOString(),
      'status': 'cancel',
      'comment' : comment
    }})
    setCancellation(false)
    setUISchema(UISchema => UISchemaDisabled(UISchema))
    dispatch(reloadActionsTimeLine())
  },[id, dispatch])

  /**
   * Переход на следующую стадию
   * */
  const transition = useCallback(async transition => {
    const targetId = Number(id)
    console.log({targetId})
    return
    if (transition['target'] === 'done') {
      setIsBusy(true)
      await fetchAPI(`/api/web-hooks/create-contract/${id}`)
      const result = await setStageFromCard({
        cardName: cardName, stageCode: 'done', targetId: targetId, isHistory: false
      })
      const orderId = getDataOfType(result, 'data.onStageData.order-id', Number, null)
      if (orderId === null) {
        setIsBusy(false)
        return
      }
      history.push(`/order/${orderId}/edit`)
      setIsBusy(false)
      return
    }
  },[history, id, setIsBusy])

  const transitionRequired = useCallback((_, nextFields) => {
    const gen = genJSONSchemaStage(matchedJSONSchema, nextFields)
    const names = genJSONSchemaNamesRequired(gen)
    const isRequired = isFormDataRequired(formDataRef.current, gen)
    if(isRequired){
      return true
    }
    setUISchema(UISchema => addValidateFieldsUISchema(UISchema, names))
    return false
  }, [matchedJSONSchema])

  return (
    <B2BLayout breadcrumbs={breadcrumbs}>
      <MapWrapper>
        <TaskWrapper>
            <MapTitle>Обращение <span>{`№ ${id}`}</span></MapTitle>
          <Space size={'small'}>
            <ButtonAddNotes />
            <Button disabled>Отправить сообщение</Button>
            <Button onClick={() => setModalCreateTask(true)}>Создать задачу</Button>
            <StepButtonsTransition type={cardName} transition={transition} transitionRequired={transitionRequired} isBusy={isBusy}/>
          </Space>
        </TaskWrapper>
        <MapContent>
          <ModalCreateTask
            visible={isModalCreateTask}
            onClose={() => setModalCreateTask(false)}
          />
          <ModalReasonCancellation visible={isCancellation}  onClose={() => setCancellation(false)} onOk={onCancellation} />
          <>
            <StepCollectionObject type={cardName}/>
              {pageErrors.length > 0 ? (
                <AlertWrapper>
                  <Alert
                    message="При выполнении операции возникла ошибка:"
                    showIcon
                    type="error"
                    description={pageErrors.join('. ')}
                  />
                </AlertWrapper>
              ) : null}
              {pageSuccess.length > 0 ? (
                <AlertWrapper>
                  <Alert
                    message={pageSuccess.join('. ')}
                    showIcon
                    type="success"
                  />
                </AlertWrapper>
              ) : null}
              {!isDataLoading ? (
                matchedJSONSchema !== null && UISchema && (
                  <DefaultJsonForm
                    formData={formDataRef.current}
                    schema={matchedJSONSchema}
                    onChange={onFormData}
                    customTabs={customTabs}
                    orientation="horizontal"
                  />
                )
              ) : (
                <Skeleton active />
              )}
          </>
        </MapContent>
      </MapWrapper>
    </B2BLayout>
  )
}
