import React, { useCallback, useRef, useEffect, useState, useMemo } from 'react'
import { Spin } from 'antd'
import { IFileS3 } from './interface'
import { ContentWrapper, FileLink, FileNoLink, FileLinkWrapper, FileButton } from './styles'
import { fileUpload, insetFileToColletion, getFileData } from './utils-files-s3'
import { DashOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { useSelector } from 'react-redux'
import { createSelector } from 'reselect'
import checkIsMobile from 'is-mobile'

const createCase = createSelector(
  (state: any) => state.appeals,
  targetId => targetId
)

const styleDisplay = { display: 'none' }

function FiileS3({
  value,
  onChange,
  schema,
  UISchema,
  onStartUpload = () => {},
}: IFileS3) {
  const disabled = useMemo(() => Boolean(UISchema?.options?.disabled), [UISchema])
  const fileName = useMemo(() => schema?.title, [schema])
  const key = useMemo(() => schema?.key, [schema])
  const [isPreloader, setPreloader] = useState(true)
  const [file, setFile] = useState<any>({})
  const { targetId } = useSelector(createCase)
  const [isMobile, setIsMobile] = useState(false)

  useEffect(() => {
    const mobile = checkIsMobile()

    setIsMobile(mobile)
  }, [])

  const inputRef = useRef<any>(null)
  const localOnChange = useCallback(async ({ target }) => {
    if(Boolean(target.files[0]) === false){
      return
    }

    onStartUpload(true)
    setPreloader(true)
    const response = await fileUpload(target.files)
    const url = 'url' in response ? response['url'] : ''

    if(url){
      const file = {
         code: key,
        'name-field': target.files[0]['name'],
        name: fileName || target.files[0]['name'],
        size: target.files[0]['size'],
        type: target.files[0]['type'],
        url,
        date: new Date().toISOString()
      }

      const id = await insetFileToColletion(file, targetId)
      if(id){
        onChange(id)
        setFile({ ...file, id })
        target.value = ''
        setPreloader(false)
        return
      }
      onChange('')
      setFile({})
      target.value = ''
      setPreloader(false)
    }
    onChange('')
    setFile({})
    target.value = ''
    setPreloader(false)
  }, [onStartUpload, onChange, key, fileName, targetId])

  useEffect(() => {
    const getFile = async () => {
      const file = await getFileData(value)

      file && setFile(file)
      setPreloader(false)
    }

    if (value) {
      getFile()
    } else {
      setPreloader(false)
    }
  },[value])

  const onButton = useCallback(() => {
    inputRef?.current?.click()
  }, [])

  const onDelete = useCallback(() => {
      setFile({})
      onChange('')
  }, [onChange])

  const viewFileName = useMemo(() => {
    if ('name-field' in file) {
      return file['name-field']
    }
    return file.name
  }, [file])

  return (
    <ContentWrapper disabled={disabled} className="ant-input">
      <input ref={inputRef} type="file" onChange={localOnChange} style={styleDisplay} />
      <FileLinkWrapper isMobile={isMobile}>
        {file.id ? (
          <>
            <FileLink href={file.url}>{viewFileName}</FileLink>
            {disabled || <CloseCircleOutlined onClick={onDelete} rev={undefined} />}
          </>
        ) : (
          <FileNoLink>Не выбран</FileNoLink>
        )}
      </FileLinkWrapper>
        <FileButton disabled={disabled || isPreloader} onClick={onButton} className="ant-input">
          {isPreloader ? <Spin size="small" /> : <DashOutlined rev={undefined}/>}
        </FileButton>
    </ContentWrapper>
  )
}

export default React.memo(FiileS3, (prev, next) => {
  const isNewValue = prev.value === next.value
  const isNewUISchema = prev?.UISchema === next?.UISchema

  return isNewValue && isNewUISchema
})
