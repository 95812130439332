import React from 'react'
import { ColumnsType } from 'antd/lib/table/Table'
import { ToolOutlined } from '@ant-design/icons'

import dayjs from '../../../lib/utils/configured-dayjs'
import { IRow } from './interface'
import { LinkWrapper } from './styles'
import { SortTypes } from '../../hooks/use-chunk-dataload'
import { DarkLink } from '../../components/dark-link'

export const columns: (sortCallback?: any) => ColumnsType<IRow> = sortCallback => [
  {
    title: 'ID',
    width: 60,
    dataIndex: 'id',
    key: 'id',
    fixed: 'left',
    defaultSortOrder: 'descend',
    sorter: sortCallback && sortCallback('id', 'id', SortTypes.NUMBER),
    render: (text, record) => (
      <DarkLink to={`/admin/schemas/${record.name}`} title="Настройка схемы">
        {record.id}
      </DarkLink>
    )
  },
  {
    title: 'Наименование',
    width: 220,
    dataIndex: 'title',
    key: 'title',
    fixed: 'left',
    sorter: sortCallback && sortCallback('title', 'title', SortTypes.STRING),
    render: (text, record) => (
      <DarkLink to={`/admin/schemas/${record.name}`} title="Настройка схемы">
        {record.title}
      </DarkLink>
    ),
  },
  {
    title: 'Кодовое имя (англ)',
    dataIndex: 'name',
    key: 'name',
    width: 140,
    fixed: 'left',
    sorter: sortCallback && sortCallback('name', 'name', SortTypes.STRING),
    render: (text, record) => (
      <DarkLink to={`/admin/schemas/${record.name}`} title="Настройка схемы">
        {record.name}
      </DarkLink>
    )
  },
  {
    title: 'Описание',
    dataIndex: 'description',
    key: 'description',
    width: 180,
    fixed: 'left',
    render: (text, record) => (
      <DarkLink to={`/admin/schemas/${record.name}`} title="Настройка схемы">
        {record.description}
      </DarkLink>
    )
  },
  {
    title: 'Область видимости данных',
    width: 80,
    dataIndex: 'scope',
    key: 'scope',
    fixed: 'left',
    render: (text, record) => (
      <DarkLink to={`/admin/schemas/${record.name}`} title="Настройка схемы">
        {record.scope}
      </DarkLink>
    )

  },
  {
    title: 'Автор',
    width: 120,
    dataIndex: 'ownerUser',
    key: 'ownerUser',
    render: (text, record) => (
      <DarkLink to={`/admin/schemas/${record.name}`} title="Настройка схемы">
        {record.ownerUser}
      </DarkLink>
    )
  },
  {
    title: 'Дата создания',
    dataIndex: 'createdAt',
    key: 'created-at',
    width: 100,
    sorter: sortCallback && sortCallback('created-at', 'id', SortTypes.NUMBER),
    render: (text, record) => (
      <DarkLink to={`/admin/schemas/${record.name}`} title="Настройка схемы">
        <span title={dayjs(record.updatedAt).format('YYYY-MM-DD HH:mm:ss')}>
          {dayjs(record.updatedAt).fromNow()}
        </span>
      </DarkLink>
    ),
  },
  {
    title: 'Дата обновления',
    dataIndex: 'updatedAt',
    key: 'updated-at',
    width: 100,
    sorter: sortCallback && sortCallback('updated-at', 'id', SortTypes.NUMBER),
    render: (text, record) => (
      <DarkLink to={`/admin/schemas/${record.name}`} title="Настройка схемы">
        <span title={dayjs(record.updatedAt).format('YYYY-MM-DD HH:mm:ss')}>
          {dayjs(record.updatedAt).fromNow()}
        </span>
      </DarkLink>
    ),
  },
  {
    title: 'Действие',
    key: 'operation',
    fixed: 'right',
    width: 100,
    render: (text, record) => (
      <LinkWrapper
        to={`/admin/schemas/${record.name}`}
        title="Настройка схемы"
      >
        <ToolOutlined rev={undefined}/>
      </LinkWrapper>
    ),
  },
]
